import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { basePath } from '../../routes';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';

const ProductDevelopmentAndMarketAnalysis = () => {

    const slides = [
        {
            id: 1,
            title: 'Comprehensive Market Analysis',
            text: `Leverage our expertise to gain a deep 
            understanding of the financial market. We 
            provide thorough analysis of market trends,
            customer behaviour, competitive landscapes, 
            and regulatory changes. This holistic view 
            enables informed decision-making and 
            strategic planning.`
        },
        {
            id: 2,
            title: 'Customer Insight and Segmentation',
            text: `Understanding your customer base is crucial. 
            We utilise advanced analytics to segment the
            market and identify the unique needs and 
            preferences of different customer groups. 
            This insight informs the development of 
            targeted and relevant financial products.`
        },
        {
            id: 3,
            title: 'Innovative Product Development',
            text: `With a clear understanding of the market and
            your customers, we collaborate with you to 
            design and develop financial products that 
            resonate with your target audience. From 
            concept to launch, our team supports you 
            in creating solutions that are not only 
            innovative but also viable and compliant.`
        },
        {
            id: 4,
            title: 'Go-to-Market Strategies',
            text: `Launching a new product requires a strategic
            approach. We help you devise effective go-to-
            market strategies that ensure successful 
            product introduction, customer adoption, 
            and market penetration.`
        },
        {
            id: 5,
            title: 'Continuous Monitoring and Iteration',
            text: `Post-launch, we continue to monitor the 
            performance of your new products, gathering
            data and feedback to refine and improve 
            offerings. This iterative process ensures that 
            your products remain competitive and aligned
            with customer needs.`
        }
    ]
    const [activeSlide, setActiveSlide] = useState(1);
    const activeSlideData = slides.find(slide => slide.id === activeSlide);
    const [animate, setAnimate] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        setAnimate(true);
        const timeout = setTimeout(() => {
            setAnimate(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, [activeSlide]);


    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                setActiveSlide(activeSlide === slides.length ? 1 : activeSlide + 1);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [activeSlide, isHovered]);

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage productDevelopmentAndMarketAnalysis">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Our Benefits',
                        }
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Our Benefits',
                        }
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <h2 className='pageTitle'>Product Development & Market Analysis</h2>
                    <p className='text'><Speaker id="109" text="Product Development & Market Analysis
Innovation and strategic market insight are key differentiators for businesses aiming to capture and retain market share. Blukite Finance's Product Development & Market Analysis service is specifically designed to equip your business with the tools and insights needed to stay ahead of the curve. By combining in-depth market analysis with cutting-edge product development strategies, we help your business not only meet but anticipate customer needs and market trends."/>Innovation and strategic market insight are key differentiators for businesses aiming to capture and retain market share. Blukite Finance's Product Development & Market Analysis
                        service is specifically designed to equip your business with the tools and insights needed to stay ahead of the curve. By combining in-depth market analysis with cutting-edge
                        product development strategies, we help your business not only meet but anticipate customer needs and market trends.</p>
                    <p className='subtitle font-normal text-center mt-10'>Navigating the Path to Innovation</p>
                    <Select
                        defaultValue={1}
                        className='block lg:hidden mt-5'
                        style={{
                            width: '100%',
                        }}
                        onChange={(value) => setActiveSlide(value)}
                        options={[
                            {
                                value: 1,
                                label: 'Comprehensive Market Analysis',
                            },
                            {
                                value: 2,
                                label: 'Customer Insight and Segmentation',
                            },
                            {
                                value: 3,
                                label: 'Innovative Product Development',
                            },
                            {
                                value: 4,
                                label: 'Go-to-Market Strategies',
                            },
                            {
                                value: 5,
                                label: 'Continuous Monitoring and Iteration',
                            }
                        ]}
                    />
                    <div className='flex-row flex xl:flex-col gap-10 xl:gap-0' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        <div className='timeline hidden lg:flex mt-5 xl:mt-32'>
                            <div onClick={() => setActiveSlide(1)} className={activeSlide === 1 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Comprehensive Market Analysis</p></div>
                            <div onClick={() => setActiveSlide(2)} className={activeSlide === 2 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Customer Insight and Segmentation</p></div>
                            <div onClick={() => setActiveSlide(3)} className={activeSlide === 3 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Innovative Product Development</p></div>
                            <div onClick={() => setActiveSlide(4)} className={activeSlide === 4 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Go-to-Market Strategies</p></div>
                            <div onClick={() => setActiveSlide(5)} className={activeSlide === 5 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Continuous Monitoring and Iteration</p></div>
                        </div>

                        <div data-aos="fade-up" data-aos-duration="700" className='my-6 lg:my-12 flex flex-col xl:flex-row w-full xl:w-9/12 mx-auto items-center gap-5 lg:gap-10'>
                            <div className='flex-1 w-full'><img className='rounded object-cover h-64 xl:h-72 w-full' src={`${basePath}images/ProductDevelopmentAndMarketAnalysis/${activeSlideData.id}.png`} alt="Comprehensive Market Analysis iamge" /></div>
                            <p className='flex-1 text-base sm:text-lg'><Speaker id={109 + activeSlideData.id} text={activeSlideData.title + "." + activeSlideData.text}/>{activeSlideData.text}</p>
                        </div>
                    </div>
                </section>
                <section id="section2" className=''>
                    <div className='bg-lightBlue p-5 lg:p-16 my-5 sm:my-16 rounded'>

                        <div className=' flex-col gap-5 lg:gap-10 flex xl:flex-row'>
                            <div className='max-w-full w-full mx-auto xl:max-w-64 min-w-full xl:min-w-64 uppercase italic font-normal text-xl lg:text-3xl text-center xl:text-left'>WHY CHOOSE BLUKITE FINANCE?</div>
                            {/* <div className='w-full sm:w-5/12 uppercase italic font-normal text-xl lg:text-3xl text-center xl:text-left'>WHY PARTNER WITH BLUKITE FINANCE?</div> */}
                            <p className='font-light text-base lg:text-lg ml-5 lg:leading-tight'><Speaker id="115" text="WHY CHOOSE BLUKITE FINANCE? In such dynamic industry, Blukite Finance is your ideal partner. Our Product Development & Market Analysis service is designed to navigate the complexities of the financial market, delivering solutions that drive growth, enhance customer satisfaction, and secure competitive advantage.
                            Choosing us for Product Development & Market Analysis means partnering with a team that brings: Expertise.
                            A deep understanding of the financial services ecosystem and the forces shaping it.
                            Innovation.
                            A committed to using cutting- edge tech and methods in product development.
                            Strategic Insight.
                            The ability to translate complex market data into actionable strategies.
                            Collaboration.
                            A collaborative approach that values your business's unique vision and goals." /> In such dynamic industry, Blukite Finance is your ideal partner. Our Product Development & Market Analysis service is designed to navigate the complexities of the financial market, delivering solutions that drive growth, enhance customer satisfaction, and secure competitive advantage.
                                <br />
                                <p className='font-light text-base italic lg:text-lg py-2 lg:py-3'>Choosing us for Product Development & Market Analysis means partnering with a team that brings:</p>
                            </p>
                        </div>

                        <div className='cards mt-10 mb-5'>
                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/1.svg`} alt="icon for Expertise card" />
                                <p className='text-xl font-normal mt-3'>Expertise</p>
                                <p className='mt-2'>A deep understanding of the
                                    financial services ecosystem
                                    and the forces shaping it.</p>
                            </div>

                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/2.svg`} alt="icon for Expertise card" />
                                <p className='text-xl font-normal mt-3'>Innovation</p>
                                <p className='mt-2'>A committed to using cutting-
                                    edge tech and methods in
                                    product development.</p>
                            </div>

                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/3.svg`} alt="icon for Expertise card" />
                                <p className='text-xl font-normal mt-3'>Strategic Insight</p>
                                <p className='mt-2'>The ability to translate
                                    complex market data into
                                    actionable strategies.</p>
                            </div>

                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/4.svg`} alt="icon for Expertise card" />
                                <p className='text-xl font-normal mt-3'>Collaboration</p>
                                <p className='mt-2'>A collaborative approach that
                                    values your business's unique
                                    vision and goals.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ProductDevelopmentAndMarketAnalysis