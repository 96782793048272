import React, { useState } from 'react';
import './contactUs.scss';
import { Button, Form, Input, Select, notification, Spin } from 'antd';
import { basePath } from '../../routes';
import { countries } from '../../countries';
import { closePopup } from '../../actions/popup';
import userEmail from '../../components/popups/userEmail.js';

const ContactUs = () => {

    const { TextArea } = Input;
    const [isLoading, setIsLoading] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [inputSize, setInputSize] = useState('large');
    const [form] = Form.useForm();  // Initialize form instance

    const onFinish = (values) => {
        setIsLoading(true);

        const url = 'https://s01.blukite.systems/dev/api/api/1/send-email/html';

        const sendEmailToAdmin = () => {
            return fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: 'katyakatya2736@gmail.com', subject: 'BKF',
                    html: `
                    <!DOCTYPE html>
                    <html>
                    <!-- Your HTML email template -->
                    </html>
                    `
                }),
            });
        };

        const sendEmailToUser = () => {
            return fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: values.email,
                    subject: 'Thank You for Your Enquiry with Blukite Finance',
                    html: userEmail()
                })
            });
        };

        sendEmailToAdmin()
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                return sendEmailToUser();
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(() => {
                notification.success({
                    message: 'Sent!',
                    description: 'Your message has been sent successfully',
                });
            })
            .catch((error) => {
                console.log(error);
                notification.error({
                    message: 'Error!',
                    description: 'Something went wrong',
                });
            })
            .finally(() => {
                setIsLoading(false);
                form.resetFields(); // Clear the form fields
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="container mt-20 sm:mt-24 lg:mt-24 infoPage conatctUs">
            <div className="content fadeIn w-full">
                <section id="section1">
                    <h2 className='pageTitle text-center'>Contact Us</h2>
                    <p className='text-center'>Have questions or suggestions? Just send us a message!</p>

                    <div className='rounded-xl contactUs__contentWrapper  p-3 flex flex-col md:flex-row w-full xl:w-10/12 mx-auto mt-5 sm:mt-10 mb-20 gap-5'>
                        <div className='contactUs__contactInfo p-5 sm:p-10 rounded-xl w-full md:w-5/12 font-normal flex flex-col'>
                            <p className='text-xl sm:text-2xl'>Contact Information</p>
                            <div className='mt-4 sm:mt-7'>
                                <p>Existing Customer Support:</p>
                                <div className='flex gap-3 items-center mt-1'>
                                    <img className='w-5' src={`${basePath}images/ico/email.svg`} alt="email icon" />
                                    <a href="mailto:info@blukite.finance">info@blukite.finance</a>
                                </div>
                            </div>
                            <div className='mt-4 sm:mt-7'>
                                <p>General Enquiries:</p>
                                <div className='flex gap-3 items-center mt-1'>
                                    <img className='w-5' src={`${basePath}images/ico/email.svg`} alt="email icon" />
                                    <a href="mailto:info@blukite.finance">info@blukite.finance</a>
                                </div>
                            </div>
                            <div className='mt-4 sm:mt-7'>
                                <p>Regulatory, Legal & Compliance Enquiries:</p>
                                <div className='flex gap-3 items-center mt-1'>
                                    <img className='w-5' src={`${basePath}images/ico/email.svg`} alt="email icon" />
                                    <a href="mailto:info@blukite.finance">info@blukite.finance</a>
                                </div>
                            </div>
                            <div className='mt-4 sm:mt-7'>
                                <p>Technical Support:</p>
                                <div className='flex gap-3 items-center mt-1'>
                                    <img className='w-5' src={`${basePath}images/ico/email.svg`} alt="email icon" />
                                    <a href="mailto:info@blukite.finance">info@blukite.finance</a>
                                </div>
                            </div>
                            <div className='mt-4 sm:mt-7'>
                                <p>Press Enquiries:</p>
                                <div className='flex gap-3 items-center mt-1'>
                                    <img className='w-5' src={`${basePath}images/ico/email.svg`} alt="email icon" />
                                    <a href="mailto:info@blukite.finance">info@blukite.finance</a>
                                </div>
                            </div>
                            <div className='flex gap-2 mt-auto'>
                                <a href='https://www.facebook.com/profile.php?id=61558973578857' target='_blank' className='footer__socialIcon'>
                                    <img className='w-7' src={`${basePath}images/ico/facebook.svg`} alt="facebook" />
                                </a>
                                <a href='https://www.linkedin.com/company/blukite-net/?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3BKEoIgVqmQ3WfxeIhpuiKxA%3D%3D' target='_blank' className='footer__socialIcon'>
                                    <img className='w-7' src={`${basePath}images/ico/linkedin.svg`} alt="linkedin" />
                                </a>
                                <a href='https://twitter.com/BlukiteFinance' target='_blank' className='footer__socialIcon'>
                                    <img className='w-7' src={`${basePath}images/ico/twitter.svg`} alt="twitter" />
                                </a>
                                <a href='https://www.instagram.com/blukite.finance?igsh=OTJ2cXN6NzVzM3o4&utm_source=qr' target='_blank' className='footer__socialIcon'>
                                    <img className='w-7' src={`${basePath}images/ico/instagram.svg`} alt="instagram" />
                                </a>
                            </div>
                        </div>
                        <div className='w-full md:w-7/12 p-5 sm:p-10 relative flex flex-col'>
                            {isLoading && <div className='w-full h-full absolute top-0 left-0 bg-transparentwhite z-40 rounded-lg'><Spin className=' absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' size='large' /></div>}

                            <p className='text-xl sm:text-2xl'>Book a consultation with Blukite Finance</p>
                            <p className='text'>Fill out the contact form below and one of our experts will reach out to you.</p>

                            <Form
                                form={form}  // Bind the form instance
                                layout="vertical"
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                className='mt-5'
                            >
                                <Form.Item
                                    label="First and Last Name"
                                    name="firstName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your first name',
                                        },
                                    ]}
                                >
                                    <Input size={inputSize} placeholder='John' />
                                </Form.Item>

                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: "email",
                                            message: "The input is not valid E-mail!",
                                        },
                                    ]}
                                >
                                    <Input size={inputSize} placeholder='simple@example.com' />
                                </Form.Item>

                                <Form.Item
                                    label="Responsibility"
                                    name="responsibility"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your responsibility',
                                        },
                                    ]}
                                >
                                    <Input size={inputSize} placeholder='Chief Executive' />
                                </Form.Item>

                                <Form.Item
                                    label="Country of operation"
                                    name="country"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input country of operation',
                                        },
                                    ]}
                                >
                                    <Select
                                        size={inputSize}
                                        showSearch
                                        options={countries.map((item) => ({
                                            value: item.name,
                                            label: item.name,
                                        }))}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Please share what you are most interested in seeing during the consultation"
                                    name="expectations"
                                    className='mt-4'
                                >
                                    <TextArea size='large'
                                        count={{
                                            show: true,
                                            max: 1000,
                                            strategy: (txt) => txt.length,
                                            exceedFormatter: (txt, { max }) => txt.slice(0, max)
                                        }} rows={2} />
                                </Form.Item>
                                <div className='mt-auto'>
                                    <Form.Item
                                        wrapperCol={{}}
                                        className='mb-0 mt-7'
                                    >
                                        <Button type="primary" htmlType="submit" className="customButton rounded w-full sm:w-fit mt-3 py-5 px-7 text-lg cursor-pointer hover:bg-brightBlue justify-between transition whitespace-nowrap flex-nowrap bg-blue text-white w-fit flex items-center gap-5">
                                            Submit
                                            <img className="w-7" src="../images/ico/rightArrow-light.svg" alt="arrow" />
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ContactUs;
