

import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import { useNavigate } from "react-router-dom";
import { PlainButton } from '../../components/buttons/buttons';
import Speaker from '../../components/speaker/speaker';
// import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';
// import './complianceAndEthics.scss'


const GridComponent = () => {
    const [expandedCard, setExpandedCard] = useState(null);

    const toggleCard = (id) => {
        if (expandedCard === id) {
            setExpandedCard(null);
        } else {
            setExpandedCard(id);
        }
    };

    const conflictOfInterestItems = [
        {
            id: 1, title: 'Identifying Conflicts of Interest', desc: 'Conflicts of interest can impact decisions and integrity, so we commit to early identification.',
            text:
                <div>
                    <p className='text'>Conflicts of interest can arise in various scenarios, potentially affecting decision-making processes and the company’s integrity. We are committed to early identification
                        through:</p>
                    <p className='mt-1.5'><span className='font-normal'>• Regular Training: </span>Educating our team on what constitutes a conflict of interest, with practical examples relevant to their roles.
                    </p>
                    <p className='mt-1.5'><span className='font-normal'>•  Self-Reporting Mechanisms: </span> Encouraging employees to proactively disclose any situations that might lead to a conflict of interest, including secondary employment,
                        investments, or personal relationships that intersect with business activities.
                    </p>
                    <p className='mt-1.5'><span className='font-normal'>•  Annual Declarations: </span>  Requiring annual declarations from employees and management to formally disclose any potential conflicts of interest.
                    </p>
                </div>,
            textSpeaker: `Identifying Conflicts of Interest.
                Conflicts of interest can arise in various scenarios, potentially affecting decision-making processes and the company’s integrity. We are committed to early identification 
through:
• Regular Training: Educating our team on what constitutes a conflict of interest, with practical examples relevant to their roles.
• Self-Reporting Mechanisms: Encouraging employees to proactively disclose any situations that might lead to a conflict of interest, including secondary employment, 
investments, or personal relationships that intersect with business activities.
• Annual Declarations: Requiring annual declarations from employees and management to formally disclose any potential conflicts of interest.`,
            iconId: 3
        },
        {
            id: 2, title: 'Mitigating Conflicts of Interest', desc: ' Upon identification of a potential conflict, Blukite Finance takes decisive steps to mitigate its impact:',
            text:
                <div>
                    <p className='text'>Upon identification of a potential conflict, Blukite Finance takes decisive steps to mitigate its impact:</p>
                    <p className='mt-1.5'><span className='font-normal'>•  Evaluation Committee: </span>A dedicated committee reviews reported conflicts, assessing the severity and potential impact on business operations or decision-making.
                    </p>
                    <p className='mt-1.5'><span className='font-normal'>• Recusal: </span>Individuals identified as having a conflict are recused from related decisions or discussions, ensuring that business decisions are made impartially and based
                        on merit.
                    </p>
                    <p className='mt-1.5'><span className='font-normal'>• Transparency:  </span>Where conflicts are identified, and recusal is not deemed sufficient, details of the conflict and how it is being managed may be disclosed to relevant
                        stakeholders to maintain transparency.
                    </p>
                </div>,
            textSpeaker: `Mitigating Conflicts of Interest. 

                Upon identification of a potential conflict, Blukite Finance takes decisive steps to mitigate its impact:
• Evaluation Committee: A dedicated committee reviews reported conflicts, assessing the severity and potential impact on business operations or decision-making.
• Recusal: Individuals identified as having a conflict are recused from related decisions or discussions, ensuring that business decisions are made impartially and based 
on merit.
• Transparency: Where conflicts are identified, and recusal is not deemed sufficient, details of the conflict and how it is being managed may be disclosed to relevant 
stakeholders to maintain transparency.
`,
            iconId: 21
        },

        {
            id: 3, title: 'Managing and Resolving Conflicts', desc: 'We actively develop and implement comprehensive action plans tailored to each identified conflict of interest.', text:

                <div>
                    <p className='text'>At Blukite Finance, we have established rigorous procedures to effectively manage and resolve any conflicts of interest that are identified, ensuring our operations and
                        decisions are made with the highest level of integrity and in the best interest of our stakeholders. We actively develop and implement comprehensive action plans
                        tailored to each identified conflict of interest. These plans are designed to address and neutralise potential biases, including, but not limited to:</p>
                    <p className='mt-1.5'><span className='font-normal'>• Adjustments to Roles or Responsibilities: </span>Reassigning tasks or altering responsibilities to ensure decisions are made impartially.
                    </p>
                    <p className='mt-1.5'><span className='font-normal'>• Changes in Project Allocation: </span>Modifying project assignments to prevent any conflict from influencing business outcomes.
                    </p>
                </div>,
            textSpeaker: `Managing and Resolving Conflicts. 

                  At Blukite Finance, we have established rigorous procedures to effectively manage and resolve any conflicts of interest that are identified, ensuring our operations and 
decisions are made with the highest level of integrity and in the best interest of our stakeholders. We actively develop and implement comprehensive action plans 
tailored to each identified conflict of interest. These plans are designed to address and neutralise potential biases, including, but not limited to:
• Adjustments to Roles or Responsibilities: Reassigning tasks or altering responsibilities to ensure decisions are made impartially.
• Changes in Project Allocation: Modifying project assignments to prevent any conflict from influencing business outcomes.
`,
            iconId: 2
        },

        {
            id: 4, title: 'Ongoing Monitoring', desc: 'Our commitment to integrity extends beyond initial mitigation as we continuously monitor the effectiveness of our actions.', text:
                <div>
                    <p className='text'>Our commitment to integrity extends beyond initial mitigation. We continuously monitor the effectiveness of our actions to ensure that:</p>
                    <p className='mt-1.5'><span className='font-normal'>• Mitigation Measures Remain Effective: </span>Regular reviews are conducted to assess the impact of our mitigation strategies, ensuring they effectively neutralise potential
                        conflicts.
                    </p>
                    <p className='mt-1.5'><span className='font-normal'>• Business Operations and Stakeholder Relationships are Unaffected: </span>Continuous oversight helps us ensure that resolved conflicts remain neutralised without adverse
                        effects on our operations or stakeholder trust.
                    </p>
                </div>,
            textSpeaker: `Ongoing Monitoring. 
Our commitment to integrity extends beyond initial mitigation. We continuously monitor the effectiveness of our actions to ensure that:
• Mitigation Measures Remain Effective: Regular reviews are conducted to assess the impact of our mitigation strategies, ensuring they effectively neutralise potential 
conflicts.
• Business Operations and Stakeholder Relationships are Unaffected: Continuous oversight helps us ensure that resolved conflicts remain neutralised without adverse
 effects on our operations or stakeholder trust.
`  ,
            iconId: 22
        },
        {
            id: 5, title: 'Comprehensive Documentation', desc: 'Maintaining transparency and accountability is a cornerstone of our approach.', text:
                <div>
                    <p className='text'>Maintaining transparency and accountability is a cornerstone of our approach. We meticulously document:</p>
                    <p className='mt-1.5'><span className='font-normal'>• All Identified Conflicts of Interest:  </span>Every conflict, regardless of size, is recorded.
                    </p>
                    <p className='mt-1.5'><span className='font-normal'>•  Mitigation and Resolution Steps: </span>Detailed records of actions taken to address conflicts are kept, providing a clear audit trail.
                    </p>
                    <p className='mt-1.5'><span className='font-normal'>• Outcome Monitoring:  </span> Documentation includes follow-ups on the effectiveness of mitigation strategies, ensuring a dynamic and responsive approach.
                    </p>
                </div>,
            textSpeaker: `Comprehensive Documentation. 
Maintaining transparency and accountability is a cornerstone of our approach. We meticulously document:
• All Identified Conflicts of Interest: Every conflict, regardless of size, is recorded.
• Mitigation and Resolution Steps: Detailed records of actions taken to address conflicts are kept, providing a clear audit trail.
• Outcome Monitoring: Documentation includes follow-ups on the effectiveness of mitigation strategies, ensuring a dynamic and responsive approach.
`,
            iconId: 23
        },
        {
            id: 6, title: 'Review and Update Process', desc: 'Understanding the ever-evolving business landscape, Blukite Finance is dedicated to regular policy reviews.', text:
                <div>
                    <p className='text'>Understanding that the business landscape is ever-evolving, Blukite Finance is dedicated to:</p>
                    <p className='mt-1.5'><span className='font-normal'>• Regular Policy Reviews: </span>Our Conflict-of-Interest Policy is reviewed regularly to ensure it remains effective and relevant in the face of new business developments,
                        regulatory changes, and evolving best practices.
                    </p>
                    <p className='mt-1.5'><span className='font-normal'>• Adaptation and Enhancement: </span>Updates are made to refine our processes, align with industry standards, and incorporate feedback from stakeholders, ensuring our
                        policy’s ongoing effectiveness and relevance.
                    </p>
                </div>,
            textSpeaker: `Review and Update Process. 
Understanding that the business landscape is ever-evolving, Blukite Finance is dedicated to:
• Regular Policy Reviews: Our Conflict-of-Interest Policy is reviewed regularly to ensure it remains effective and relevant in the face of new business developments, 
regulatory changes, and evolving best practices.
• Adaptation and Enhancement: Updates are made to refine our processes, align with industry standards, and incorporate feedback from stakeholders, ensuring our 
policy’s ongoing effectiveness and relevance.
`,
            iconId: 24
        },
    ]

    return (
        <main className='mt-10'>
            <ul className="coreSolutions__keyFeaturesItems">
                {conflictOfInterestItems.map(x => {
                    const isExpanded = expandedCard === x.id;
                    return (
                        <React.Fragment key={x.id}>
                            <li className={isExpanded ? 'coreSolutions__keyFeaturesItem--active' : ''} onClick={() => toggleCard(x.id)}>
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/${x.iconId}.svg`} alt={`icon for ${x.title}`} />
                                <p className='mt-3 text-lg font-normal'>{x.title}</p>
                                <p className='mt-3'>{x.desc}</p>
                                <button
                                    type="button"
                                    onClick={() => toggleCard(x.id)}
                                    data-quick-view
                                    className='text-blue font-medium  mt-auto flex items-center -ml-5'
                                >
                                    Read more
                                    <img className='w-4 ml-3 coreSolutions__keyFeaturesReadMoreArrow' src={`${basePath}images/ico/keyFeatureArrow.svg`} alt="Arrow icon" />
                                </button>

                            </li>
                            {isExpanded && (
                                <div className={`coreSolutions__keyFeaturesItem--fullwidth coreSolutions__keyFeaturesItem--activeExpanded`}>
                                    <div className='flex justify-between'>
                                        <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/${x.iconId}.svg`} alt={`icon for ${x.title}`} />
                                        <img className='w-16 ml-3 cursor-pointer' onClick={() => toggleCard(x.id)} src={`${basePath}images/ico/keyFeatureCloseArrow.svg`} alt="Arrow icon" />
                                    </div>
                                    <p className='mt-3 text-lg font-normal'>{x.title}</p>
                                    <p><Speaker id={578 + x.id} text={x.textSpeaker} /> {x.text}</p>
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
            </ul>
        </main>
    );
};

const ComplianceAndEthics = () => {

    const [activeSlide, setActiveSlide] = useState(1);
    const [animate, setAnimate] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const guidingPrinciplesSlides = [
        {
            title: "Global Standards, Local Expertise",
            descriptionSmall: <div>
                <p className='text-center'>
                    Operating across diverse jurisdictions, we are meticulous in our
                    approach to understanding and implementing the required legal
                    frameworks relevant to each region.
                </p>
                <p className='mt-1.5 text-left'><span className='font-normal'>•  Global Standards: </span>We adhere to international regulatory standards
                    that govern the financial services industry, ensuring that our
                    operations are consistently compliant on a global scale. This
                    includes, but is not limited to, anti-money laundering (AML)
                    measures, counter-terrorist financing (CTF) regulations, and data
                    protection laws such as the GDPR.
                </p>
            </div>,
            description: <div>
                <p className='text-center'>
                    Operating across diverse jurisdictions, we are meticulous in our
                    approach to understanding and implementing the required legal
                    frameworks relevant to each region.
                </p>
                <p className='mt-1.5 text-left'><span className='font-normal'>•  Global Standards: </span>We adhere to international regulatory standards
                    that govern the financial services industry, ensuring that our
                    operations are consistently compliant on a global scale. This
                    includes, but is not limited to, anti-money laundering (AML)
                    measures, counter-terrorist financing (CTF) regulations, and data
                    protection laws such as the GDPR.
                </p>
                <p className='mt-1.5 text-left'><span className='font-normal'>• Local Expertise:</span> Recognising the nuances of local regulations, we
                    employ a team of legal experts and compliance officers within each
                    jurisdiction. These professionals possess in-depth knowledge of their
                    respective local regulatory landscapes and are responsible for
                    ensuring that all aspects of our operations conform to national laws
                    and standards.</p>
            </div>,
            id: 1,
        },
        {
            title: "Ensuring Compliance and Fostering an Ethical Culture",
            descriptionSmall: <div>
                <p className='text-center'>To ensure these guidelines are more than just words on paper, Blukite
                    Finance has implemented several measures:</p>
                <p className='mt-1.5 text-left'><span className='font-normal'>• Ethics Training: </span>All employees undergo regular ethics training,
                    which covers our ethical guidelines, case studies on ethical
                    dilemmas, and the importance of ethical behaviour in maintaining
                    trust and integrity in the financial sector.
                </p>
                <p className='mt-1.5 text-left'><span className='font-normal'>• Ethics Committee: </span>We have established an Ethics Committee
                    responsible for overseeing the implementation of ethical practices,
                    addressing ethical concerns, and advising on ethical dilemmas.</p>
            </div>,
            description:
                <div>
                    <p className='text-center'>To ensure these guidelines are more than just words on paper, Blukite
                        Finance has implemented several measures:</p>
                    <p className='mt-1.5 text-left'><span className='font-normal'>• Ethics Training: </span>All employees undergo regular ethics training,
                        which covers our ethical guidelines, case studies on ethical
                        dilemmas, and the importance of ethical behaviour in maintaining
                        trust and integrity in the financial sector.
                    </p>
                    <p className='mt-1.5 text-left'><span className='font-normal'>• Ethics Committee: </span>We have established an Ethics Committee
                        responsible for overseeing the implementation of ethical practices,
                        addressing ethical concerns, and advising on ethical dilemmas.</p>
                    <p className='mt-1.5 text-left'><span className='font-normal'>• Whistleblower Protections: </span>Our whistleblower policy encourages
                        employees and stakeholders to report unethical behaviour without
                        fear of retaliation. This includes secure and confidential channels for
                        raising concerns.</p>
                </div>,
            id: 2,
        },
        {
            title: "Commitment to Continuous Improvement",
            descriptionSmall: <div>
                <p className='text-center'>Ethical business practices are a journey, not a destination. Blukite
                    Finance is committed to continuous improvement, regularly reviewing
                    and updating our policies and practices to reflect new insights,
                    feedback from our stakeholders, and evolving ethical standards in
                    the financial industry.</p>
                <p className='text-center'>This commitment ensures that we stay at the forefront of ethical
                    financial services, fostering trust and integrity in all our operations.
                    Our continuous improvement efforts include actively seeking and
                    incorporating feedback from our clients, partners, and employees to
                    better align our practices with their needs and expectations.</p>
            </div>,
            description: <div>
                <p className='text-center'>Ethical business practices are a journey, not a destination. Blukite
                    Finance is committed to continuous improvement, regularly reviewing
                    and updating our policies and practices to reflect new insights,
                    feedback from our stakeholders, and evolving ethical standards in
                    the financial industry.</p>
                <p className='text-center'>This commitment ensures that we stay at the forefront of ethical
                    financial services, fostering trust and integrity in all our operations.
                    Our continuous improvement efforts include actively seeking and
                    incorporating feedback from our clients, partners, and employees to
                    better align our practices with their needs and expectations. We
                    conduct thorough audits and reviews of our processes and policies
                    to identify areas for enhancement and ensure compliance with the
                    latest ethical guidelines. Additionally, we invest in ongoing training
                    and development programs for our staff to keep them informed about
                    the latest ethical standards and best practices in the industry.
                    Maintaining transparency in our operations is crucial, and we
                    regularly report our progress and improvements in ethical practices
                    to our stakeholders. Furthermore, we explore and implement
                    innovative solutions that promote ethical behavior and sustainability
                    in financial services. Blukite Finance believes that by committing to
                    these continuous improvement efforts, we can build a more ethical
                    and responsible financial ecosystem for our clients and the broader
                    community.</p>
            </div>,
            id: 3,
        }
    ]
    const [currentGuidingPrinciplesSlideIndex, setCurrentGuidingPrinciplesSlideIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);

    useEffect(() => {
        setAnimate(true);
        const timeout = setTimeout(() => {
            setAnimate(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, [activeSlide]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                setActiveSlide(activeSlide === 3 ? 1 : activeSlide + 1);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [activeSlide, 4, isHovered]);

    useEffect(() => {
        updateSlides(true);
    }, [currentGuidingPrinciplesSlideIndex]);

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {

        if (touchStart - touchEnd > 150) {
            handleNext();
        }

        if (touchStart - touchEnd < -150) {
            handlePrevious();
        }
    }

    const handleNext = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        const currentSlide = document.getElementById(`slide-${currentGuidingPrinciplesSlideIndex}`);
        currentSlide.classList.add('moving-out');

        setTimeout(() => {
            setCurrentGuidingPrinciplesSlideIndex((currentGuidingPrinciplesSlideIndex + 1) % guidingPrinciplesSlides.length);
            updateSlides(true);
            setIsTransitioning(false);
        }, 400);
    };

    const handlePrevious = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        setCurrentGuidingPrinciplesSlideIndex(currentGuidingPrinciplesSlideIndex === 0 ? guidingPrinciplesSlides.length - 1 : currentGuidingPrinciplesSlideIndex - 1);
        updateSlides(false);

        const currentSlide = document.getElementById(`slide-${currentGuidingPrinciplesSlideIndex}`);
        currentSlide.classList.add('moving-in');
        setIsTransitioning(false);
    };

    function applyStyles(currentGuidingPrinciplesSlideIndex, isNext) {
        let styles = [
            'transform: translateX(0%) scale(1);',
            'transform: translateX(100%) scale(1);',
            'transform: translateX(200%) scale(1);',
        ];

        for (let i = 0; i < styles.length; i++) {
            let slideIndex = (currentGuidingPrinciplesSlideIndex + i) % styles.length;
            let slideElement = document.getElementById(`slide-${slideIndex}`);
            slideElement.style = styles[i];

            if (isNext) {
                if (i !== styles.length - 1) {
                    slideElement.classList.add('slide');
                } else {
                    slideElement.classList.remove('slide');
                }

            } else {
                if (slideIndex !== 0) {
                    slideElement.classList.add('slide');
                } else {
                    slideElement.classList.remove('slide');
                }
            }
        }
    }

    const updateSlides = (isNext) => {
        guidingPrinciplesSlides.forEach((slide, index) => {
            applyStyles(currentGuidingPrinciplesSlideIndex, isNext)
        });
    };

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        const offset = 100; // Offset in pixels
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage complianceAndEthics liquidityProvidersCreditSolutions">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Legal Compliance',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Ethical Business Practices',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'Conflict of Interest Policy',
                        },
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Legal Compliance',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Ethical Business Practices',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'Conflict of Interest Policy',
                        },
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <h2 className='pageTitle'>Compliance and Ethics</h2>
                    <p className='text'>
                        <Speaker id="566" text="Compliance and Ethics. 
                        At Blukite Finance, our foundation is built on unwavering commitment to the highest standards of compliance and ethics. We believe in conducting our operations with utmost integrity, ensuring our practices not only comply with legal standards but also uphold our ethical values. This section of our website is dedicated to transparently sharing our principles, practices, and procedures that guide our everyday actions and long-term strategies." />
                        At Blukite Finance, our foundation is built on unwavering commitment to the highest standards of compliance and ethics. We believe in conducting our operations with utmost integrity,
                        ensuring our practices not only comply with legal standards but also uphold our ethical values. This section of our website is dedicated to transparently sharing our principles, practices,
                        and procedures that guide our everyday actions and long-term strategies. </p>
                    <div className='mt-10 flex flex-col xl:flex-row justify-between gap-3 xl:gap-32'>
                        <p className='uppercase whitespace-nowrap text-base sm:text-xl font-normal'>Our Policy: A Blueprint for Integrity</p>
                        <p className='text mt-0'>
                            <Speaker id="567" text="OUR POLICY: A BLUEPRINT FOR INTEGRITY. Our comprehensive compliance and ethics policy is the bedrock of our business conduct. It encompasses everything from our approach to regulatory adherence to fostering an environment of ethical behaviour across all levels of our organisation. Key highlights include:" />
                            Our comprehensive compliance and ethics policy is the bedrock of our business conduct.
                            It encompasses everything from our approach to regulatory adherence to fostering an
                            environment of ethical behaviour across all levels of our organisation. Key highlights include:
                        </p>
                    </div>

                    <div className='flex flex-col lg:flex-row gap-14 sm:gap-10 mt-10 sm:mt-20'>
                        <div className='flex flex-col sm:flex-row lg:flex-col flex-1 gap-10 items-start'>
                            <img className='w-full sm:w-4/12 lg:w-full' src={`${basePath}images/complianceAndEthics/policy1.svg`} alt="image to Commitment to Legal Compliance topic" />
                            <div className='flex flex-col flex-1'>
                                <p className='uppercase font-normal text-lg'>Commitment to Legal Compliance</p>
                                <p className='text mb-5 sm:mb-10'>
                                    <Speaker id="568" text="COMMITMENT TO LEGAL COMPLIANCE. At Blukite Finance, our unwavering commitment to legal compliance is foundational to our operations and corporate ethos. We recognise that the finance industry is highly regulated, and the trust of our customers, partners, and stakeholders is paramount. Our dedication to compliance is not just about adhering to the letter of the law but embracing the spirit of responsibility and integrity that those laws represent." />
                                    At Blukite Finance, our unwavering commitment to
                                    legal compliance is foundational to our operations
                                    and corporate ethos. We recognise that the finance
                                    industry is highly regulated, and the trust of our
                                    customers, partners, and stakeholders is paramount.
                                    Our dedication to compliance is not just about
                                    adhering to the letter of the law but embracing the
                                    spirit of responsibility and integrity that those laws
                                    represent.
                                </p>
                                <div className='mt-auto w-full sm:w-7/12 lg:w-full xl:w-7/12'>
                                    <PlainButton text="More info" onClick={() => scrollToSection('section2')} />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row lg:flex-col flex-1  gap-10 items-start'>
                            <img className='w-full sm:w-4/12 lg:w-full' src={`${basePath}images/complianceAndEthics/policy2.svg`} alt="image to Commitment to Legal Compliance topic" />
                            <div className='flex flex-col flex-1'>
                                <p className='uppercase font-normal text-lg'>Ethical Business Practices</p>
                                <p className='text mb-5 sm:mb-10'>
                                    <Speaker id="569" text="ETHICAL BUSINESS PRACTICES. At Blukite Finance, we recognise that our actions impact not only our immediate stakeholders but also the broader financial ecosystem. Our ethical practices are based on three pillars. Honesty, we communicate transparently, ensuring our products, services, and operations are truthful and accurate. Integrity, we uphold high moral standards, making decisions that are legally and ethically sound. Fairness, we ensure equity and treat everyone with respect and dignity." />
                                    At Blukite Finance, we recognise that our actions
                                    impact not only our immediate stakeholders but also
                                    the broader financial ecosystem. Our ethical practices
                                    are based on three pillars. Honesty, we communicate
                                    transparently, ensuring our products, services, and
                                    operations are truthful and accurate. Integrity, we
                                    uphold high moral standards, making decisions that
                                    are legally and ethically sound.  Fairness, we ensure
                                    equity and treat everyone with respect and dignity.
                                </p>
                                <div className='mt-auto w-full sm:w-7/12 lg:w-full xl:w-7/12'>
                                    <PlainButton text="More info" onClick={() => scrollToSection('section3')} />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row lg:flex-col flex-1  gap-10 items-start'>
                            <img className='w-full sm:w-4/12 lg:w-full' src={`${basePath}images/complianceAndEthics/policy3.svg`} alt="image to Commitment to Legal Compliance topic" />
                            <div className='flex flex-col flex-1'>
                                <p className='uppercase font-normal text-lg'>Conflict of Interest Policy</p>
                                <p className='text mb-5 sm:mb-10'>
                                    <Speaker id="570" text="CONFLICT OF INTEREST POLICY. Our Conflict-of-Interest Policy serves as a cornerstone of ethical governance, ensuring that all employees and associates of Blukite Finance act in the utmost interest of the company and its clients, avoiding situations where personal interests could conflict with professional duties. This policy applies universally across our operations, encompassing all employees, management, and board members." />
                                    Our Conflict-of-Interest Policy serves as a
                                    cornerstone of ethical governance, ensuring that all
                                    employees and associates of Blukite Finance act in
                                    the utmost interest of the company and its clients,
                                    avoiding situations where personal interests could
                                    conflict with professional duties. This policy applies
                                    universally across our operations, encompassing all
                                    employees, management, and board members.

                                </p>
                                <div className='mt-auto w-full sm:w-7/12 lg:w-full xl:w-7/12'>
                                    <PlainButton text="More info" onClick={() => scrollToSection('section4')} />
                                </div>
                            </div>
                        </div>

                    </div>

                </section>

                <section id="section2" className='my-10 sm:my-32'>
                    <h2 className='pageTitle'>Our Guiding Principle: Unwavering Adherence to Laws and Regulations</h2>
                    <p className='text'>
                        <Speaker id="571" text="Our Guiding Principle: Unwavering Adherence to Laws and Regulations. Blukite Finance's pledge is to maintain the highest standards of legal compliance in every action we take and in every service we offer. Our commitment to these principles is unwavering, as we believe it is essential not only for our success but for the integrity and reliability of the financial services industry as a whole." />
                        Blukite Finance's pledge is to maintain the highest standards of legal compliance in every action we take and in every service we offer. Our commitment to these principles is
                        unwavering, as we believe it is essential not only for our success but for the integrity and reliability of the financial services industry as a whole.</p>

                    <div className='flex xl:flex-row flex-col justify-between gap-5 mt-10'>
                        <p className='font-normal subtitle italic leading-tight sm:w-auto'>Commitment to Legal Compliance</p>
                        <div className='mt-0 xl:mt-7 gap-10 flex justify-between'>
                            <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handlePrevious()} onClick={handlePrevious} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderLeftArrow.svg`} alt="slider left arrow" />
                            <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handleNext()} onClick={handleNext} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderRightArrow.svg`} alt="slider right arrow" />
                        </div>
                    </div>

                    <div className='border-r border-separateLight'>
                        <div id="slider" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                            <div className="cards mt-2 xl:mt-10">
                                {guidingPrinciplesSlides.map((slide, index) => (
                                    <div id={`slide-${index}`} key={index} className={`cards__card ${index === currentGuidingPrinciplesSlideIndex ? 'activeCard' : index === (currentGuidingPrinciplesSlideIndex + 1) % guidingPrinciplesSlides.length ? 'next' : 'sliderHidden'}`}>
                                        <div className="cards__content text-base">
                                            <p className="font-normal text-base sm:text-lg leading-tight italic">{slide.title}</p>

                                            <div className="hidden sm:block mt-3"><Speaker id={571 + slide.id} text={slide.title + "./" + slide.description} /> {slide.description}</div>
                                            <div className="block sm:hidden mt-3"><Speaker id={571 + slide.id} text={slide.title + "./" + slide.description} /> {slide.descriptionSmall}</div>

                                            {slide.id === 1 ?
                                                <img className='relative sm:absolute sm:left-1/2 sm:-bottom-0 sm:transform sm:-translate-x-1/2' src={`${basePath}/images/complianceAndEthics/4.svg`} alt={`images for ${slide.title}`} />
                                                : slide.id === 2 ?
                                                    <img className='relative sm:absolute sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:bottom-0' src={`${basePath}/images/complianceAndEthics/5.svg`} alt={`images for ${slide.title}`} />
                                                    : null}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>





                </section>


                <section id="section3" className='my-10 sm:my-32'>
                    <h2 className='pageTitle'>Our Moral Compass: Foundational Principles
                    </h2>
                    <p className='text'>
                        <Speaker id="575" text="Our Moral Compass: Foundational Principles. At Blukite Finance, we believe that ethical business practices are fundamental to building lasting relationships based on trust and respect. Our commitment to honesty, integrity, and fairness guides every decision we make and every action we take. This is our promise to our customers, employees, partners, and the communities we serve. At Blukite Finance, we recognise that our actions impact not only our immediate stakeholders but also the broader financial ecosystem." />
                        At Blukite Finance, we believe that ethical business practices are fundamental to building lasting relationships based on trust and respect. Our commitment to honesty, integrity, and
                        fairness guides every decision we make and every action we take. This is our promise to our customers, employees, partners, and the communities we serve. At Blukite Finance, we
                        recognise that our actions impact not only our immediate stakeholders but also the broader financial ecosystem.


                    </p>
                    <div className='flex items-start'>
                        <p className='subtitle font-normal italic text-xl mt-14'>Our ethical business practices are built upon 3 foundational pillars:
                        </p>
                    </div>
                    <div className='tailoredServicesOverview__sliderWrapper flex-col xl:flex-row' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        <Speaker id="576" text="Our ethical business practices are built upon 3 foundational pillars:. 
                        Honesty.
                        We commit to transparent communication with all our stakeholders, ensuring that our products, services, and operations are represented truthfully and accurately.
                        Integrity.
                        We uphold the highest moral standards in all our dealings, making decisions that are not only legally compliant but also ethically sound.
                        Fairness.
                        We strive for equity in our interactions with customers, employees, partners, and the community, ensuring that everyone is treated with respect and dignity. 
                        "/>
                        <div className='tailoredServicesOverview__slider'>
                            <div className={activeSlide === 1 ? 'tailoredServicesOverview__sliderItem--active' : 'tailoredServicesOverview__sliderItem'} onClick={() => setActiveSlide(1)}>
                                <div className='flex gap-2'>
                                    <img src={`${basePath}/images/ico/complianceAndEthics/1.svg`} alt="icon for the text with title Honesty" />
                                    <p className='font-normal'>Honesty</p>
                                </div>
                                <p className='mt-1'>
                                    We commit to transparent communication with all our stakeholders, ensuring that our products, services, and operations are represented truthfully and accurately.
                                </p>
                            </div>

                            <div className={activeSlide === 2 ? 'tailoredServicesOverview__sliderItem--active' : 'tailoredServicesOverview__sliderItem'} onClick={() => setActiveSlide(2)}>
                                <div className='flex gap-2'>
                                    <img src={`${basePath}/images/ico/complianceAndEthics/2.svg`} alt="icon for the text with title Integrity" />
                                    <p className='font-normal'>Integrity</p>
                                </div>
                                <p className='mt-1'>
                                    We uphold the highest moral standards in all our dealings, making decisions that are not only legally compliant but also ethically sound.
                                </p>
                            </div>
                            <div className={activeSlide === 3 ? 'tailoredServicesOverview__sliderItem--active' : 'tailoredServicesOverview__sliderItem'} onClick={() => setActiveSlide(3)}>
                                <div className='flex gap-2'>
                                    <img src={`${basePath}/images/ico/complianceAndEthics/3.svg`} alt="icon for the text with title Fairness" />
                                    <p className='font-normal'>Fairness</p>
                                </div>
                                <p className='mt-1'>We strive for equity in our interactions with customers, employees, partners, and the community, ensuring that everyone is treated with respect and dignity. </p>
                            </div>
                        </div>
                        <div className={`tailoredServicesOverview__sliderImgWrapper hidden sm:flex ${animate ? 'fadeIn' : ''}`}>
                            <img src={`${basePath}/images/complianceAndEthics/${activeSlide + 5}.svg`} className='tailoredServicesOverview__sliderImg' alt="" />
                        </div>
                    </div>

                    <p className='text-center italic mt-16 sm:mt-20 text-lg leading-tight sm:leading-normal'>To translate these principles into action, Blukite Finance has developed a comprehensive set of guidelines that govern our business conduct</p>

                    <div
                        className="animatedInfo__cards mt-10"
                    >
                        <div
                            className="animatedInfo__card animatedInfo"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/17.svg`} alt="icon for Anti-Corruption Policies card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Anti-Corruption Policies</div>
                            <div className="animatedInfo__cardText">We have zero tolerance for corruption and bribery. Our policies clearly
                                outline prohibited behaviours and the procedures for reporting and
                                addressing any incidents of corruption.
                            </div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card animatedInfo"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/18.svg`} alt="icon for Fair Treatment of Customers card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Fair Treatment of Customers</div>
                            <div className="animatedInfo__cardText">We are dedicated to offering products and services that are not only
                                innovative and beneficial but also fair and transparent. This includes clear
                                communication of terms and conditions, privacy policies, and the rights
                                and responsibilities of all parties.
                            </div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>
                    </div>
                    <div
                        className="animatedInfo__cards mt-7 mb-10"
                    >

                        <div
                            className="animatedInfo__card"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/19.svg`} alt="icon for Responsible Marketing card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Responsible Marketing</div>
                            <div className="animatedInfo__cardText">Our marketing strategies are developed with ethical considerations at
                                the forefront, ensuring that all promotional materials are honest,
                                respectful, and non-exploitative.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/20.svg`} alt="icon for Workplace Ethics card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Workplace Ethics</div>
                            <div className="animatedInfo__cardText">We foster a working environment that values diversity, inclusivity, and
                                mutual respect. This extends to our hiring practices, professional
                                development opportunities, and day-to-day workplace interactions.
                            </div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>
                    </div>

                    <p className='text mt-16'>
                        <Speaker id="577" text="Ensuring compliance and fostering an ethical culture at Blukite Finance involves implementing several key measures. Firstly, all employees undergo regular ethics training, which encompasses our ethical guidelines, case studies on ethical dilemmas, and the importance of ethical behavior in maintaining trust and integrity within the financial sector. Secondly, we have established an Ethics Committee responsible for overseeing the implementation of ethical practices, addressing ethical concerns, and advising on ethical dilemmas. Additionally, our whistleblower policy encourages employees and stakeholders to report unethical behavior without fear of retaliation, offering secure and confidential channels for raising concerns. Recognizing that ethical business practices are a journey, not a destination, Blukite Finance is committed to continuous improvement. We regularly review and update our policies and practices to reflect new insights, feedback from our stakeholders, and evolving ethical standards in the financial industry." />
                        Ensuring compliance and fostering an ethical culture at Blukite Finance involves implementing several key measures. Firstly, all employees undergo regular ethics training, which
                        encompasses our ethical guidelines, case studies on ethical dilemmas, and the importance of ethical behavior in maintaining trust and integrity within the financial sector. Secondly,
                        we have established an Ethics Committee responsible for overseeing the implementation of ethical practices, addressing ethical concerns, and advising on ethical dilemmas.
                        Additionally, our whistleblower policy encourages employees and stakeholders to report unethical behavior without fear of retaliation, offering secure and confidential channels for
                        raising concerns. Recognizing that ethical business practices are a journey, not a destination, Blukite Finance is committed to continuous improvement. We regularly review and
                        update our policies and practices to reflect new insights, feedback from our stakeholders, and evolving ethical standards in the financial industry.


                    </p>
                </section>
                <section id="section4" className='my-10 sm:my-32'>
                    <h2 className='pageTitle'>Conflict of Interest Policy: Safeguarding Integrity</h2>
                    <p className='text'>
                        <Speaker id="578" text="With these established procedures in place, Blukite Finance reaffirms its commitment to upholding the highest standards of ethical conduct and decision-making integrity. Our proactive approach in identifying, mitigating, and managing conflicts of interest safeguards the interests of our clients and stakeholders, fostering an environment of trust and confidence in our governance and operational excellence." />
                        With these established procedures in place, Blukite Finance reaffirms its commitment to upholding the highest standards of ethical conduct and decision-making integrity. Our proactive
                        approach in identifying, mitigating, and managing conflicts of interest safeguards the interests of our clients and stakeholders, fostering an environment of trust and confidence in our
                        governance and operational excellence.
                    </p>
                    <GridComponent />
                </section>
            </div>
        </div>
    );
}

export default ComplianceAndEthics