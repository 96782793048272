import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';

const CardIssuingAndFulfilment = () => {

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage productDevelopmentAndMarketAnalysis">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Card Fulfilment Process',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Our Benefits',
                        }
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Card Fulfilment Process',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Our Benefits',
                        }
                    ]}
                />
            </div>

            <div className="content cardIssuingAndFulfilment">
                <section id="section1">
                    <div className='w-full xl:w-5/12 2xl:w-7/12'>
                        <h2 className='pageTitle'>Card Issuing & Fulfilment</h2>
                        <p className='text'>
                            <img className='block xl:hidden float-none sm:float-right ml-5 w-full sm:w-6/12' src={`${basePath}/images/handWithCard.svg`} alt="illustration: hand with card" />
                            <Speaker id="116" text="Card Issuing & Fulfilment. Blukite Finance offers a comprehensive Card Issuing & Fulfilment service, expertly
                            designed to streamline the card production and distribution process for our clients.
                            Leveraging our extensive industry knowledge and partnerships, we guide businesses
                            through the scheme selection process, ensuring an optimal fit for their project
                            requirements and securing the most advantageous terms. Whether you are launching a new card program or looking to optimise an existing one, Blukite Finance is your ideal partner. Our Card Issuing & Fulfilment service is designed to remove the complexities of card production and distribution, allowing you to focus on what matters most, growing your business and serving your customers."/>Blukite Finance offers a comprehensive Card Issuing & Fulfilment service, expertly
                            designed to streamline the card production and distribution process for our clients.
                            Leveraging our extensive industry knowledge and partnerships, we guide businesses
                            through the scheme selection process, ensuring an optimal fit for their project
                            requirements and securing the most advantageous terms.
                        </p>
                        <p className='text'>
                            Whether you are launching a new card program or looking to optimise an existing one,
                            Blukite Finance is your ideal partner. Our Card Issuing & Fulfilment service is designed
                            to remove the complexities of card production and distribution, allowing you to focus
                            on what matters most, growing your business and serving your customers.
                        </p>
                    </div>
                    <img data-aos="fade-left" data-aos-duration="700" className='hidden xl:block cardIssuingAndFulfilment__bannerImg' src={`${basePath}/images/handWithCard.svg`} alt="illustration: hand with card" />
                </section>

                <section id="section2" className='my-28'>
                    <p className='subtitle font-normal italic text-center'>Simplified Scheme Selection & Streamlined Card Fulfilment</p>
                    <div className='flex flex-col gap-10 my-10 mx-auto smallWidthContainer'>
                        <div className='py-4 px-7 rounded-xl customShadow min-w-full sm:min-w-96 simplifiedSchemeSelection__card w-full lg:w-7/12'>
                            <p className='font-normal text-lg'>Navigate Scheme Options</p>
                            <p className='mt-1'><Speaker id="117" text="Navigate Scheme Options. Choosing the right card scheme is critical for the success of your card program. We help you understand the nuances of different card schemes, including Visa, Mastercard, and others, ensuring a clear path to the best choice for your project." /> Choosing the right card scheme is critical for the success of your card program. We help you understand
                                the nuances of different card schemes, including Visa, Mastercard, and others, ensuring a clear path to the
                                best choice for your project.</p>
                            <svg width="247" height="138" viewBox="0 0 247 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_503_293)">
                                    <path id="dashed-line" d="M-1.00165 8L155.199 8C177.827 8 189.14 8 196.17 15.0294C203.199 22.0589 203.199 33.3726 203.199 56L203.199 130" stroke="#36A9E0" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 8" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_503_293">
                                        <rect width="247" height="138" fill="white" transform="matrix(-1 0 0 1 247 0)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>


                        <div className='py-4 px-7 rounded-xl customShadow min-w-full sm:min-w-96 simplifiedSchemeSelection__card w-full lg:w-7/12 ml-auto'>
                            <p className='font-normal text-lg'>Negotiate Terms</p>
                            <p className='mt-1'><Speaker id="118" text="Negotiate Terms. Our team works diligently to negotiate terms that benefit your project, focusing on cost-efficiency, feature sets, and market reach. Our goal is to secure arrangements that align with your strategic objectives and budgetary considerations." />Our team works diligently to negotiate terms that benefit your project, focusing on cost-efficiency, feature
                                sets, and market reach. Our goal is to secure arrangements that align with your strategic objectives and
                                budgetary considerations.</p>

                            <svg width="247" height="138" viewBox="0 0 247 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_503_293)">
                                    <path id="dashed-line" d="M-1.00165 8L155.199 8C177.827 8 189.14 8 196.17 15.0294C203.199 22.0589 203.199 33.3726 203.199 56L203.199 130" stroke="#36A9E0" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 8" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_503_293">
                                        <rect width="247" height="138" fill="white" transform="matrix(-1 0 0 1 247 0)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <div className='py-4 px-7 rounded-xl customShadow min-w-full sm:min-w-96 simplifiedSchemeSelection__card w-full lg:w-7/12'>
                            <p className='font-normal text-lg'>Leveraging External Expertise</p>
                            <p className='mt-1'><Speaker id="119" text="Leveraging External Expertise. Currently, we partner with leading external service providers to manage card fulfilment efficiently. Our partners are chosen for their reliability, quality of service, and ability to meet diverse client needs. This collaboration allows us to prioritise delivering unparalleled value and satisfaction to our clients." />Currently, we partner with leading external service providers to manage card fulfilment efficiently. Our
                                partners are chosen for their reliability, quality of service, and ability to meet diverse client needs. This
                                collaboration allows us to prioritise delivering unparalleled value and satisfaction to our clients.</p>

                            <svg width="247" height="138" viewBox="0 0 247 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_503_293)">
                                    <path id="dashed-line" d="M-1.00165 8L155.199 8C177.827 8 189.14 8 196.17 15.0294C203.199 22.0589 203.199 33.3726 203.199 56L203.199 130" stroke="#36A9E0" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 8" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_503_293">
                                        <rect width="247" height="138" fill="white" transform="matrix(-1 0 0 1 247 0)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <div className='py-4 px-7 rounded-xl customShadow min-w-full sm:min-w-96 simplifiedSchemeSelection__card w-full lg:w-7/12 ml-auto'>
                            <p className='font-normal text-lg'>Transitioning to In-House Services</p>
                            <p className='mt-1'>
                                <Speaker id="120" text="Transitioning to In-House Services. Recognising the value of having integrated services, Blukite Finance is in the process of establishing in- house bureau services for card personalisation and fulfilment. This upcoming capability will offer our clients even greater control, customisation options, and cost savings." />Recognising the value of having integrated services, Blukite Finance is in the process of establishing in-
                                house bureau services for card personalisation and fulfilment. This upcoming capability will offer our
                                clients even greater control, customisation options, and cost savings.</p>
                        </div>
                    </div>
                </section>

                <section id="section3">
                    <div className='bg-lightBlue p-5 lg:p-16 my-10 sm:my-32 rounded'>
                                    <Speaker id="121" text="WHY CHOOSE BLUKITE FINANCE?
                                    Expert Guidance.
                                    Our knowledge of the card issuing landscape ensures you make informed decisions that benefit your project.
                                    Future-Ready Services.
                                    With plans to bring bureau services in-house, we aim for a more streamlined fulfillment process.
                                    Comprehensive Support.
                                From scheme selection to card delivery, we provide end- to-end support, simplifying the card issuing process."/>
                        <div className=' flex-col gap-5 lg:gap-10 flex xl:flex-row'>

                            <div className='mb-5 self-center'>
                                <p className='uppercase italic font-normal text-xl lg:text-3xl leading-relaxed'>
                                    WHY Choose Blukite Finance?</p>
                            </div>
                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/1.svg`} alt="icon for Expert Guidance" />
                                <p className='text-xl font-normal mt-3'>Expert Guidance</p>
                                <p className='mt-2'>Our knowledge of the card
                                    issuing landscape ensures
                                    you make informed decisions
                                    that benefit your project.</p>
                            </div>

                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/2.svg`} alt="icon for Future-Ready Services" />
                                <p className='text-xl font-normal mt-3'>Future-Ready Services</p>
                                <p className='mt-2'>With plans to bring bureau
                                    services in-house, we aim for
                                    a more streamlined fulfillment
                                    process.</p>
                            </div>

                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/5.svg`} alt="icon for Comprehensive Support" />
                                <p className='text-xl font-normal mt-3'>Comprehensive Support</p>
                                <p className='mt-2'>From scheme selection to
                                    card delivery, we provide end-
                                    to-end support, simplifying
                                    the card issuing process.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default CardIssuingAndFulfilment