import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';

const DebtCollection = () => {

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage debtCollection">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Customer Monitoring',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Debt Collection',
                        },
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Customer Monitoring',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Debt Collection',
                        },
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <h2 className='pageTitle'>Debt Collection</h2>
                </section>

                <section id="section2" className=''>
                    <p className='subtitle font-normal italic'>Customer Monitoring</p>
                    <p className='text'>
                    <Speaker id="79" text="Customer Monitoring. Blukite Finance's Customer Monitoring service is an integral part of our commitment to fostering responsible financial practices and enhancing debt management strategies.
                        Through our comprehensive monitoring tools, we provide businesses with the ability to keep a close watch on customer behaviours, transactions, and financial health indicators.
                        This vigilance is crucial for identifying potential issues early on, enabling proactive engagement and support for customers in managing their financial obligations effectively.
                        Real-time Analytics: Utilise cutting-edge analytics to monitor customer transactions and behaviours in real time, allowing for immediate insights into potential risk factors or financial distress signals.
                        Custom Alerts: Set up custom alerts to notify you of specific customer activities that may require intervention, such as missed payments or unusual spending patterns.
                        Debt Management Support: Leverage our tools to provide targeted support to customers, helping them to manage and reduce their debts responsibly and sustainably.
                        Consumer Best Practices: Promote consumer best practices through educational resources and personalised advice, reinforcing the importance of financial responsibility and smart debt management. By implementing Blukite Finance's Customer Monitoring service, businesses can ensure they are not only adhering to regulatory standards but also prioritising the well-being and financial health of their customers. This approach not only helps in maintaining customer loyalty but also contributes to the overall stability and success of the financial ecosystem.
                        "/>
                        Blukite Finance's Customer Monitoring service is an integral part of our commitment to fostering responsible financial practices and enhancing debt management strategies.
                        Through our comprehensive monitoring tools, we provide businesses with the ability to keep a close watch on customer behaviours, transactions, and financial health indicators.
                        This vigilance is crucial for identifying potential issues early on, enabling proactive engagement and support for customers in managing their financial obligations effectively.
                    </p>

                    <div className='flex flex-col lg:flex-row gap-16 justify-between xl:justify-center my-5 sm:my-10'>

                        <div className='w-fit mx-auto lg:mx-0'>
                            <img src={`${basePath}images/debtCollection_1.svg`} alt="illustration for Debt Collection" />
                        </div>
                        <div className='w-full lg:w-8/12 xl:w-5/12'>
                            <div className='flex items-center gap-5 mb-5' data-aos="fade-up" data-aos-duration="700">
                                <img src={`${basePath}images/ico/checkmark.svg`} alt="checkmark icon" />
                                <p><span className='font-medium'>Real-time Analytics: </span> Utilise cutting-edge analytics to monitor customer
                                    transactions and behaviours in real time, allowing for immediate insights
                                    into potential risk factors or financial distress signals.</p>
                            </div>
                            <div className='flex items-center gap-5 mb-5' data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                                <img src={`${basePath}images/ico/checkmark.svg`} alt="checkmark icon" />
                                <p><span className='font-medium'>Custom Alerts: </span> Set up custom alerts to notify you of specific customer
                                    activities that may require intervention, such as missed payments or
                                    unusual spending patterns.</p>
                            </div>
                            <div className='flex items-center gap-5 mb-5' data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
                                <img src={`${basePath}images/ico/checkmark.svg`} alt="checkmark icon" />
                                <p><span className='font-medium'>Debt Management Support: </span>Leverage our tools to provide targeted
                                    support to customers, helping them to manage and reduce their debts
                                    responsibly and sustainably.</p>
                            </div>
                            <div className='flex items-center gap-5 mb-5' data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                                <img src={`${basePath}images/ico/checkmark.svg`} alt="checkmark icon" />
                                <p><span className='font-medium'>Consumer Best Practices:</span> Promote consumer best practices through
                                    educational resources and personalised advice, reinforcing the importance
                                    of financial responsibility and smart debt management.</p>
                            </div>

                        </div>
                    </div>

                    <p className='text'>
                        By implementing Blukite Finance's Customer Monitoring service, businesses can ensure they are not only adhering to regulatory standards but also prioritising the well-being and
                        financial health of their customers. This approach not only helps in maintaining customer loyalty but also contributes to the overall stability and success of the financial ecosystem.
                    </p>
                </section>

                <section id="section3" className='mb-10'>
                    <p className='subtitle font-normal italic'>Debt Collection</p>
                    <p className='text'><Speaker id="85" text="Debt Collection. In the specialised area of debt collection, Blukite Finance upholds the highest standards of regulatory compliance and consumer protection. We partner with reputable debt recovery firms that are fully aligned with Blukite policies, which are meticulously designed to safeguard consumer rights and ensure fair treatment. These partnerships are built on a foundation of respect, understanding, and compassion, especially towards vulnerable consumers, those living with disabilities, and the aging community."/> In the specialised area of debt collection, Blukite Finance upholds the highest standards of regulatory compliance and consumer protection. We partner with reputable debt
                        recovery firms that are fully aligned with Blukite policies, which are meticulously designed to safeguard consumer rights and ensure fair treatment. These partnerships are built on
                        a foundation of respect, understanding, and compassion, especially towards vulnerable consumers, those living with disabilities, and the aging community.</p>
                    <p className='subtitle italic'>Our Approach to <span className='font-normal'>Debt Collection</span> Includes:</p>

<div className='debtCollection__numbersContainerWrapper'>
                        <img className='debtCollection__numbersContainerLine' data-aos="fade-in" src={`${basePath}images/curvedLineWithDots.svg`}/>
                    <div className='debtCollection__numbersContainer'>
                        <div className='debtCollection__numberContainer' data-aos="fade-left" data-aos-duration="700">
                            <p className='debtCollection__bigNumber'>1</p>
                            <div>
                                <p className='font-normal text-lg'>Compliance Training</p>
                                <p>All partner firms are required to undergo
                                    comprehensive training to meet Blukite
                                    standards, focusing on working effectively
                                    and empathetically with vulnerable groups.</p>
                            </div>
                        </div>
                        <div className='debtCollection__numberContainer' data-aos="fade-left" data-aos-duration="700" data-aos-delay="200">
                            <p className='debtCollection__bigNumber'>2</p>
                            <div>
                                <p className='font-normal text-lg'>Consumer Protection Policies</p>
                                <p>Our policies emphasise the importance of
                                    treating all consumers fairly, with specific
                                    guidelines for engaging with those who may
                                    be more susceptible to financial hardship.</p>
                            </div>
                        </div>
                        <div className='debtCollection__numberContainer' data-aos="fade-left" data-aos-duration="700" data-aos-delay="400"> 
                            <p className='debtCollection__bigNumber'>3</p>
                            <div>
                                <p className='font-normal text-lg'>Ethical Debt Recovery</p>
                                <p>We advocate for ethical debt recovery
                                    practices that respect the dignity and
                                    circumstances of each individual,
                                    ensuring that recovery efforts are
                                    conducted with sensitivity and integrity.</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    <p className='text my-20'><Speaker id="87" text="Blukite Finance's approach to debt collection reflects our broader commitment to responsible financial services. By ensuring our partners are well-trained and adhere to our high standards, we contribute to a more ethical and consumer-friendly financial environment."/>Blukite Finance's approach to debt collection reflects our broader commitment to responsible financial services. By ensuring our partners are well-trained and adhere to our high
                        standards, we contribute to a more ethical and consumer-friendly financial environment.</p>

                </section>

            </div>
        </div>
    );
}

export default DebtCollection