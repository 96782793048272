import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { basePath } from '../../routes';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';
import './tailoredServices.scss'

const StrategicTechnologyIntegrationForBuildingSocieties = () => {

    const [activeSlide, setActiveSlide] = useState(2);
    const [activeSlide2, setActiveSlide2] = useState(2);
    const [slides2, setSlides2] = useState([
        {
            id: 1,
            title: 'The Lifeline of Your Operations',
            text: `Modernising legacy systems with Blukite's seamless API integration is akin to 
            rejuvenating the very lifeline of your operations. It's about ensuring your infrastructure 
            doesn't just survive but thrives in the digital era. This digital upgrade enables Building 
            Societies to preserve their historical strengths while unlocking new levels of efficiency 
            and service capabilities.`
        },
        {
            id: 2,
            title: 'Thriving in the Digital Age',
            text: `In an age where member expectations are shaped by the speed and convenience of 
            digital giants, sticking with outdated systems is a risk too great to bear. Blukite's 
            technology ensures your society is not just a participant in the digital transformation 
            but a leader, setting benchmarks for innovation and member satisfaction.`
        },
        {
            id: 3,
            title: 'Adaptability as Your Superpower',
            text: `In the fast-evolving financial landscape, agility is not just an asset; it's your competitive 
            edge. Blukite's solution empowers Building Societies with the ability to rapidly respond 
            to market changes, regulatory updates, and member needs. This agility ensures that 
            your services remain reliable, efficient, and in tune with the expectations of a 
            digital-first membership.`
        },
        {
            id: 4,
            title: 'Seizing Opportunities with Confidence',
            text: `With Blukite, operational agility becomes your strategy for futureproofing. It's about 
            confidently seizing opportunities as they arise, from launching new financial products to 
            adopting innovative service models. Our solution ensures that Building Societies can 
            navigate the complexities of the modern financial environment with ease, making bold 
            strides into new markets.`
        }
    ])

    const [animate, setAnimate] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [slides, setSlides] = useState([
        {
            id: 1,
            imageId: 8,
            textSpeaker: `Universal Design Principles: Our platform is built on the foundation of universal design principles, ensuring that digital interfaces are intuitively usable by everyone, regardless of their physical or cognitive abilities. This approach includes readable fonts for people with visual impairments, easy navigation for those with mobility challenges, and clear, concise content that is understandable for users with cognitive disabilities.
            Assistive Technologies Compatibility:The platform is fully compatible with assistive technologies, such as screen readers, speech recognition software, and keyboard-only navigation, facilitating an inclusive user experience. This compatibility is crucial for users with visual impairments or those who cannot use a mouse due to mobility challenges.
            `,
            text: [
                { title: "Universal Design Principles: ", text: "Our platform is built on the foundation of universal design principles, ensuring that digital interfaces are intuitively usable by everyone, regardless of their physical or cognitive abilities. This approach includes readable fonts for people with visual impairments, easy navigation for those with mobility challenges, and clear, concise content that is understandable for users with cognitive disabilities." },
                { title: "Assistive Technologies Compatibility:", text: "The platform is fully compatible with assistive technologies, such as screen readers, speech recognition software, and keyboard-only navigation, facilitating an inclusive user experience. This compatibility is crucial for users with visual impairments or those who cannot use a mouse due to mobility challenges." },
            ]
        },
        {
            id: 2,
            imageId: 9,
            textSpeaker: `Inclusion and Consumer Duty: Blukite's commitment to inclusivity extends beyond accessibility features. It encompasses a broader responsibility towards responsible lending practices, ensuring that financial products are tailored to meet the diverse needs of consumers. This includes clear, understandable information about products and services, and support for making informed financial decisions.
            Mitigating Friction for Vulnerable Populations: The Enhanced Accessibility functionality specifically addresses the friction points that individuals living with disabilities and members of the aging community face. By simplifying processes, reducing physical and cognitive load, and providing multiple ways to interact with financial services, Blukite makes banking more accessible and less stressful for these populations.`,
            text: [
                { title: "Inclusion and Consumer Duty: ", text: "Blukite's commitment to inclusivity extends beyond accessibility features. It encompasses a broader responsibility towards responsible lending practices, ensuring that financial products are tailored to meet the diverse needs of consumers. This includes clear, understandable information about products and services, and support for making informed financial decisions." },
                { title: "Mitigating Friction for Vulnerable Populations: ", text: "The Enhanced Accessibility functionality specifically addresses the friction points that individuals living with disabilities and members of the aging community face. By simplifying processes, reducing physical and cognitive load, and providing multiple ways to interact with financial services, Blukite makes banking more accessible and less stressful for these populations." },
            ]
        },
        {
            id: 3,
            imageId: 10,
            textSpeaker: `Training and Support: Blukite Finance also recognises the importance of training and support for both staff and users to maximise the benefits of its accessible platform. This includes detailed guides, customer service training focused on accessibility needs, and readily available support to assist users.`,
            text: [
                { title: "Training and Support: ", text: "Blukite Finance also recognises the importance of training and support for both staff and users to maximise the benefits of its accessible platform. This includes detailed guides, customer service training focused on accessibility needs, and readily available support to assist users." },
            ]
        },
    ])

    const activeSlideData = slides.find(slide => slide.id === activeSlide);
    const activeSlideData2 = slides2.find(slide => slide.id === activeSlide2);

    const [complianceSlides, setComplianceSlides] = useState([
        {
            id: 1, imageId: "23", title: "Advanced Security", textTitle: "Advanced Security Measures", text: `Blukite's platform is designed with security as a foundational 
        element, incorporating encryption, secure data storage, and 
        real-time monitoring to guard against unauthorised access 
        and data breaches. These measures ensure that member 
        data is protected in line with the highest industry standards.` },
        {
            id: 2, imageId: "24", title: "Regulatory Compliance", textTitle: "Regulatory Compliance by Design", text: `Blukite's technology is built to be inherently compliant with 
        the latest financial regulations. This includes automated 
        updates to compliance protocols to reflect new regulations, 
        ensuring that Building Societies remain ahead of regulatory 
        changes without the need for constant manual oversight.`},
        {
            id: 3, imageId: "25", title: "Fraud Prevention", textTitle: "Fraud Detection and Prevention", text: `Utilising advanced analytics and machine learning, Blukite's 
        platform can identify patterns indicative of fraudulent activity,
        alerting societies to potential threats before they impact 
        members. This proactive approach is crucial in an era where 
        financial crimes are becoming increasingly sophisticated.`},
        {
            id: 4, imageId: "26", title: "FAIR Consumer Treatment", textTitle: "Supporting Fair Consumer Treatment", text: `Blukite empowers Building Societies to enhance their 
        consumer protection practices. This includes tools for clear 
        communication, product transparency, and personalised 
        services that respect the individual needs and circumstances
        of each member, fostering a culture of fairness and respect.` },
        {
            id: 5, imageId: "27", title: "Education & Support", textTitle: "Continuous Education and Support", text: `Understanding that regulations and threats are ever-evolving, 
        Blukite provides ongoing education and support to Building 
        Societies. This ensures that societies are not only compliant 
        today but are also equipped to navigate the future regulatory 
        landscape confidently.` },
    ])
    const complianceOptions = complianceSlides.map((slide) => ({ value: slide.id, label: slide.title }));
    const [activeComplianceSlide, setActiveComplianceSlide] = useState(1);
    const activeComplianceSlideData = complianceSlides.find(slide => slide.id === activeComplianceSlide);


    const [currentProductOfferIndex, setCurrentProductOfferIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const productOfferingsSlides = [
        {
            title: "Tailored for Growth",
            description: `Blukite’s platform is a catalyst for growth, designed with 
            customisation and flexibility at its core. It empowers 
            Building Societies to broaden their horizons beyond 
            traditional mortgages, venturing into the realms of 
            consumer loans, digital banking solutions, wealth 
            management, and beyond. This strategic diversification 
            not only enriches your service portfolio but also opens 
            doors to new market segments craving innovation.`,
            id: 1,
        },
        {
            title: "Meeting the Needs of Every Member",
            description: `Our solution acknowledges the unique financial journeys of
            your members. From first-time homebuyers in need of 
           personalised mortgage advice to seasoned investors 
           seeking sophisticated wealth management tools, Blukite 
           facilitates a holistic approach to financial service. This 
           adaptability ensures that every member’s need is not just 
           met but anticipated and catered to with precision.`,
            id: 2,
        },
        {
            title: "The Digital Banking Revolution",
            description: `In an age where digital convenience is paramount, Blukite 
            Finance equips Building Societies with state-of-the-art 
            digital banking solutions. From seamless online banking 
            interfaces to mobile apps that bring financial management
             to the fingertips of your members, we ensure your society
             is at the forefront of the digital revolution, enhancing 
            engagement and satisfaction.`,
            id: 3,
        },
        {
            title: "Inclusivity and Accessibility",
            description: `Diversifying your product offerings is also a statement of 
            inclusivity. Blukite’s technology is designed to ensure that 
            financial services are accessible to all, including those with
             disabilities, thereby promoting financial inclusion. This 
            commitment to accessibility broadens your society’s appeal,
             attracting members who value equity and diversity.`,
            id: 4,
        },
        {
            title: "Customisation at Its Best",
            description: `The power of customisation cannot be overstated. With 
            Blukite, Building Societies can fine-tune products and 
            services to the specific needs and preferences of different 
            member segments. Whether it’s creating flexible loan 
            products that cater to the gig economy workers or 
            designing savings products for the digitally native 
            youth, customisation is the key to attracting and 
            retaining a wide and varied membership base.`,
            id: 5,
        }
    ];

    function applyStyles(currentProductOfferIndex, isNext) {
        let styles = [
            'transform: translateX(0%) scale(1);',
            'transform: translateX(100%) scale(1);',
            'transform: translateX(200%) scale(1);',
            'transform: translateX(300%) scale(1);',
            'transform: translateX(400%) scale(1);',
        ];

        for (let i = 0; i < styles.length; i++) {
            let slideIndex = (currentProductOfferIndex + i) % styles.length;
            let slideElement = document.getElementById(`slide-${slideIndex}`);
            slideElement.style = styles[i];

            if (isNext) {
                if (i !== styles.length - 1) {
                    slideElement.classList.add('slide');
                } else {
                    slideElement.classList.remove('slide');
                }

            } else {
                if (slideIndex !== 0) {
                    slideElement.classList.add('slide');
                } else {
                    slideElement.classList.remove('slide');
                }
            }
        }
    }

    const updateSlides = (isNext) => {
        productOfferingsSlides.forEach((slide, index) => {
            applyStyles(currentProductOfferIndex, isNext)
        });
    };

    const handleNext = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        const currentSlide = document.getElementById(`slide-${currentProductOfferIndex}`);
        currentSlide.classList.add('moving-out');

        setTimeout(() => {
            setCurrentProductOfferIndex((currentProductOfferIndex + 1) % productOfferingsSlides.length);
            updateSlides(true);
            setIsTransitioning(false);
        }, 400); // Match this timeout to the CSS transition duration
    };

    const handlePrevious = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        setCurrentProductOfferIndex(currentProductOfferIndex === 0 ? productOfferingsSlides.length - 1 : currentProductOfferIndex - 1);
        updateSlides(false);

        const currentSlide = document.getElementById(`slide-${currentProductOfferIndex}`);
        currentSlide.classList.add('moving-in');
        setIsTransitioning(false);
    };

    // swipes
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {

        if (touchStart - touchEnd > 150) {
            handleNext();
        }

        if (touchStart - touchEnd < -150) {
            handlePrevious();
        }
    }


    useEffect(() => {
        updateSlides(true);
    }, [currentProductOfferIndex]);



    useEffect(() => {
        setAnimate(true);
        const timeout = setTimeout(() => {
            setAnimate(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, [activeSlideData]);


    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                setActiveSlide(activeSlide === slides.length ? 1 : activeSlide + 1);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [activeSlide, slides.length, isHovered]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                setActiveSlide2(activeSlide2 === slides2.length ? 1 : activeSlide2 + 1);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [activeSlide2, slides2.length, isHovered]);

    const nextSlide = () => {
        setActiveSlide(activeSlide === slides.length ? 1 : activeSlide + 1);
    };

    const prevSlide = () => {
        setActiveSlide(activeSlide === 1 ? slides.length : activeSlide - 1);
    };

    const nextSlide2 = () => {
        setActiveSlide2(activeSlide2 === slides2.length ? 1 : activeSlide2 + 1);
    };

    const prevSlide2 = () => {
        setActiveSlide2(activeSlide2 === 1 ? slides2.length : activeSlide2 - 1);
    };


    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage relative tailoredServicesBuildingSocieties">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Opportunities Unlocked',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Enhanced Accessibility',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'Modernising Legacy Systems',
                        },
                        {
                            key: 'section5',
                            href: '#section5',
                            title: 'Optimising Efficiency',
                        },
                        {
                            key: 'section6',
                            href: '#section6',
                            title: 'Expanding Product Offerings',
                        },
                        {
                            key: 'section7',
                            href: '#section7',
                            title: 'Data-Driven Decisions',
                        },
                        {
                            key: 'section8',
                            href: '#section8',
                            title: 'Member Experience',
                        },
                        {
                            key: 'section9',
                            href: '#section9',
                            title: 'Security and Compliance',
                        },
                        {
                            key: 'section10',
                            href: '#section10',
                            title: 'Partnership for the Future',
                        },
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Opportunities Unlocked',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Enhanced Accessibility',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'Modernising Legacy Systems',
                        },
                        {
                            key: 'section5',
                            href: '#section5',
                            title: 'Optimising Efficiency',
                        },
                        {
                            key: 'section6',
                            href: '#section6',
                            title: 'Expanding Product Offerings',
                        },
                        {
                            key: 'section7',
                            href: '#section7',
                            title: 'Data-Driven Decisions',
                        },
                        {
                            key: 'section8',
                            href: '#section8',
                            title: 'Member Experience',
                        },
                        {
                            key: 'section9',
                            href: '#section9',
                            title: 'Security and Compliance',
                        },
                        {
                            key: 'section10',
                            href: '#section10',
                            title: 'Partnership for the Future',
                        },
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <h2 className='pageTitle'>Strategic Technology Integration for Building Societies</h2>
                    <p className='text'>
                        <Speaker id="143" text="Strategic Technology Integration for Building Societies
                        Building Societies, with their rich history and member-focused ethos, stand at a pivotal moment in the financial industry. The integration of new technologies poses both challenges and significant opportunities for these venerable institutions. Blukite Finance emerges as a strategic ally in this journey, offering a solution that not only modernises their operations but also expands their service offerings to meet the evolving demands of their members. This transformation is not just about keeping pace with technological advancements but seizing the opportunity to lead in the delivery of innovative, accessible financial services.
                        The landscape of financial services is shifting rapidly, driven by technological innovation and changing consumer expectations. Building Societies, traditionally known for their community-based approach and focus on savings and mortgages, are now exploring how to extend their role in their members' financial lives. Blukite Finance's API-driven platform is specifically designed to facilitate this transformation with minimal disruption to existing operations. Its scalable, secure architecture ensures that Building Societies can not only integrate new technologies but also future proof their services against upcoming digital trends. The adoption of Blukite Finance's technology solution offers a path to:"/>

                        Building Societies, with their rich history and member-focused ethos, stand at a pivotal moment in the financial industry. The integration of new technologies poses both challenges
                        and significant opportunities for these venerable institutions. Blukite Finance emerges as a strategic ally in this journey, offering a solution that not only modernises their operations
                        but also expands their service offerings to meet the evolving demands of their members. This transformation is not just about keeping pace with technological advancements but
                        seizing the opportunity to lead in the delivery of innovative, accessible financial services.</p>
                    <p className='text'>The landscape of financial services is shifting rapidly, driven by technological innovation and changing consumer expectations. Building Societies, traditionally known for their
                        community-based approach and focus on savings and mortgages, are now exploring how to extend their role in their members' financial lives. Blukite Finance's API-driven platform is
                        specifically designed to facilitate this transformation with minimal disruption to existing operations. Its scalable, secure architecture ensures that Building Societies can not only
                        integrate new technologies but also future proof their services against upcoming digital trends. The adoption of Blukite Finance's technology solution offers a path to:</p>
                    <p className='subtitle italic font-normal mt-14 text-center'>A New Era for Building Societies with Blukite Finance</p>

                    <div className='customerMonitoring__offers mt-0 sm:mt-10 mb-0 sm:mb-20'>


                        <div className='customerMonitoring__offerDashedLine hidden lg:block'>
                            <img src={`${basePath}images/CustomerMonitoring/dashedLine_2.svg`} alt="dashed line" />

                        </div>



                        {/* <img className='customerMonitoring__offerDashedLine hidden lg:block' src={`${basePath}images/CustomerMonitoring/dashedLine.svg`} alt="dashed line" /> */}
                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerImgWrapper justify-end' data-aos="fade-right" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/TailoredServicesBuildingSocieties/1.svg`} alt="image for Enhanced Operational Efficiency" />
                            </div>
                            <div className='customerMonitoring__offerNumber self-start'>1</div>
                            <div className='customerMonitoring__offerItem self-start' data-aos="fade-left" data-aos-duration="700" data-aos-delay="300">

                                <p className='font-medium'>Enhanced Operational Efficiency</p>
                                <p className='mt-2'><Speaker id="144" text="Enhanced Operational Efficiency. Transitioning from legacy systems to a more agile, technology- driven infrastructure, enabling societies to offer faster, more reliable services." />Transitioning from legacy systems to a more agile, technology-
                                    driven infrastructure, enabling societies to offer faster, more
                                    reliable services.</p>
                            </div>
                        </div>

                        <div className='customerMonitoring__offer flex-col-reverse lg:flex-row'>
                            <div className='customerMonitoring__offerItem' data-aos="fade-right" data-aos-duration="700" data-aos-delay="300">
                                <p className='font-medium text-right'>Diversified Product Offerings</p>
                                <p className='mt-2 text-right'><Speaker isRight id="145" text="Diversified Product Offerings. Expanding beyond traditional mortgages and savings to include
                                    personalised consumer loans, digital banking solutions, and
                                    wealth management services, tailored to the unique needs of
                                    their members."/>Expanding beyond traditional mortgages and savings to include
                                    personalised consumer loans, digital banking solutions, and
                                    wealth management services, tailored to the unique needs of
                                    their members.</p>
                            </div>
                            <div className='customerMonitoring__offerNumber'>2</div>
                            <div className='customerMonitoring__offerImgWrapper' data-aos="fade-left" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/TailoredServicesBuildingSocieties/2.svg`} alt="image for Diversified Product Offerings" />
                            </div>
                        </div>

                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerImgWrapper justify-end' data-aos="fade-right" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/TailoredServicesBuildingSocieties/3.svg`} alt="image for Improved Member Experience" />
                            </div>
                            <div className='customerMonitoring__offerNumber'>3</div>
                            <div className='customerMonitoring__offerItem' data-aos="fade-left" data-aos-duration="700" data-aos-delay="300">
                                <p className='font-medium'>Improved Member Experience</p>
                                <p className='mt-2'><Speaker id="146" text="Improved Member Experience. Leveraging digital platforms to provide a seamless, intuitive user
                                    experience, from online banking to mobile app interfaces,
                                    enhancing member engagement and satisfaction."/>Leveraging digital platforms to provide a seamless, intuitive user
                                    experience, from online banking to mobile app interfaces,
                                    enhancing member engagement and satisfaction.</p>
                            </div>
                        </div>

                        <div className='customerMonitoring__offer flex-col-reverse lg:flex-row'>
                            <div className='customerMonitoring__offerItem' data-aos="fade-right" data-aos-duration="700" data-aos-delay="300">
                                <p className='font-medium text-right'>Inclusive Financial Services</p>
                                <p className='mt-2 text-right'><Speaker id="147" isRight text="Inclusive Financial Services. Emphasising accessibility, Blukite's solution ensures that financial
                                    services are available and user-friendly for all members, including
                                    those with disabilities, thereby promoting financial inclusion."/>Emphasising accessibility, Blukite's solution ensures that financial
                                    services are available and user-friendly for all members, including
                                    those with disabilities, thereby promoting financial inclusion.</p>
                            </div>
                            <div className='customerMonitoring__offerNumber'>4</div>
                            <div className='customerMonitoring__offerImgWrapper' data-aos="fade-left" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/TailoredServicesBuildingSocieties/4.svg`} alt="image for Inclusive Financial Services" />
                            </div>
                        </div>

                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerImgWrapper justify-end' data-aos="fade-right" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/TailoredServicesBuildingSocieties/5.svg`} alt="image for Strategic Market Positioning" />
                            </div>
                            <div className='customerMonitoring__offerNumber'>5</div>
                            <div className='customerMonitoring__offerItem' data-aos="fade-left" data-aos-duration="700" data-aos-delay="300">
                                <p className='font-medium'>Strategic Market Positioning</p>
                                <p className='mt-2'><Speaker id="148" text="Strategic Market Positioning. With data-driven insights and advanced analytics, Building
                                    Societies can better understand market trends and member
                                    needs, positioning themselves strategically for growth and
                                    innovation."/>With data-driven insights and advanced analytics, Building
                                    Societies can better understand market trends and member
                                    needs, positioning themselves strategically for growth and
                                    innovation.</p>
                            </div>
                        </div>

                    </div>
                </section>

                <section id="section2" className='my-10 sm:my-20'>
                    <p className='subtitle italic font-normal mt-0 sm:mt-14 text-center'>Opportunities Unlocked by Blukite Finance</p>
                    <p className='text text-left sm:text-center'>
                        <Speaker id="149" text="Opportunities Unlocked by Blukite Finance
                    Integrating Blukite Finance's solutions presents Building Societies with a unique opportunity to redefine their role in the financial lives of their members. By embracing digital transformation, Building Societies can enhance operational efficiency, expand their market reach, and strengthen community ties, all while maintaining the trust and loyalty they have built over the years. This approach not only positions Building Societies for sustainable growth but also enables them to lead in the creation of a more inclusive, resilient, and innovative financial landscape."/>
                        Integrating Blukite Finance's solutions presents Building Societies with a unique opportunity to redefine their role in the financial lives of their members. By embracing digital
                        transformation, Building Societies can enhance operational efficiency, expand their market reach, and strengthen community ties, all while maintaining the trust and loyalty they
                        have built over the years. This approach not only positions Building Societies for sustainable growth but also enables them to lead in the creation of a more inclusive, resilient,
                        and innovative financial landscape.</p>


                    <div className='mt-5 sm:mt-10'>
                        <div className='flex flex-col lg:flex-row mt-7 gap-7'>
                            <div className='customShadow rounded-3xl p-5 xl:p-8 relative overflow-hidden tailoredServicesBuildingSocieties__imageCard'>
                                <img src={`${basePath}/images/TailoredServicesBuildingSocieties/6.svg`} className='hidden xl:block absolute -bottom-5 right-3 w-9/12' data-aos="fade-up" data-aos-duration="800" />
                                <div>
                                    <img src={`${basePath}/images/ico/TailoredServicesBanks/4.svg`} alt="icons for Technological Innovation and Efficiency" />

                                    <p className='w-full xl:w-9/12 font-normal text-lg mt-5 leading-tight'>Technological Innovation and Efficiency</p>
                                    <div className='w-full'>
                                        <p className='mt-1'><span className='font-normal'>
                                            <Speaker id="150" text="Technological Innovation and Efficiency.
Streamlining Operations: Automate routine tasks and processes, from loan application processing to customer service inquiries, freeing up staff to focus on high-value interactions and personalised service.
Data-Driven Insights for Service Improvement: Harness the power of big data analytics to gain insights into member behaviour and preferences, enabling the development of services and products that directly respond to member needs."/>
                                            Streamlining Operations: </span>Automate routine tasks and processes, from loan application processing to customer service inquiries, freeing up staff to focus on high-value interactions and personalised service.</p>
                                        <p className='mt-1'><span className='font-normal'>Data-Driven Insights for Service Improvement: </span>Harness the power of big data analytics to gain insights into member behaviour and preferences, enabling the development of services and products that directly respond to member needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='customShadow rounded-3xl p-5 xl:p-8 relative overflow-hidden tailoredServicesBuildingSocieties__imageCard'>
                                <img src={`${basePath}/images/TailoredServicesBuildingSocieties/7.svg`} className='hidden xl:block absolute bottom-0 right-5 w-7/12' data-aos="fade-up" data-aos-duration="800" />
                                <div>
                                    <img src={`${basePath}/images/ico/TailoredServicesBuildingSocieties/1.svg`} alt="icons for Community and Member Value" />
                                    <p className='w-full xl:w-9/12 font-normal text-lg mt-5 leading-tight'>Community and Member Value</p>
                                    <div className='w-full'>
                                        <p className='mt-1'><span className='font-normal'>
                                            <Speaker id="151" text="Community and Member Value.
Enhancing Financial Literacy: Offer platforms and tools for financial education tailored to different member segments, improving financial literacy and empowering members to make informed decisions.

Personalised Financial Advice: With advanced analytics, Building Societies can provide personalised advice and product recommendations, enhancing member satisfaction and loyalty.

Community Engagement Initiatives: Use digital platforms to support and promote local community events and charities, reinforcing the Building Society’s role as a community pillar."/>
                                            Enhancing Financial Literacy: </span>Offer platforms and tools for financial education tailored to different member segments, improving financial literacy and empowering members to make informed decisions.</p>
                                        <p className='mt-1'><span className='font-normal'>Personalised Financial Advice: </span>With advanced analytics, Building Societies can provide personalised advice and product recommendations, enhancing member satisfaction and loyalty.</p>
                                        <p className='mt-1'><span className='font-normal'>Community Engagement Initiatives: </span>Use digital platforms to support and promote local community events and charities, reinforcing the Building Society’s role as a community pillar.</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='customShadow rounded-3xl p-5 xl:p-8 relative overflow-hidden items-center flex flex-col xl:flex-row gap-5' data-aos="fade-right" data-aos-duration="800">
                            <div className='flex-1'>
                                <img src={`${basePath}/images/ico/TailoredServicesBanks/3.svg`} alt="icons for Access to New Markets" />
                                <p className='font-normal text-lg mt-5'>Access to New Markets</p>
                                <p className='mt-1'><span className='font-normal'>
                                    <Speaker id="152" text="Access to New Markets.
                                    Diverse Demographic Engagement: Integration allows Building Societies to tailor their offerings to attract a wide range of demographics, including tech-savvy millennials who prefer digital-first solutions and underserved communities in need of more accessible financial products.
                                    Innovative Product Development: Utilise Blukite's technology to launch innovative products quickly, such as digital wallets, instant loan approvals, and blockchain-based services, to capture interest in emerging financial technology markets."/>
                                    Diverse Demographic Engagement: </span>Integration allows Building Societies to tailor their offerings to attract a wide range of demographics, including tech-savvy millennials who prefer digital-first solutions and underserved communities in need of more accessible financial products.</p>
                                <p className='mt-1'><span className='font-normal'>Innovative Product Development: </span>Utilise Blukite's technology to launch innovative products quickly, such as digital wallets, instant loan approvals, and blockchain-based services, to capture interest in emerging financial technology markets.</p>
                            </div>
                            <div className='flex-1'>
                                <img className='w-10/12 mx-auto' src={`${basePath}/images/TailoredServicesBuildingSocieties/map.svg`} alt="illustration for System Integration Solutions" />
                            </div>
                        </div>

                        <div className='flex flex-col lg:flex-row mt-7 gap-7'>
                            <div className='customShadow rounded-3xl p-5 xl:p-8' data-aos="fade-up-right" data-aos-duration="800">
                                <div>
                                    <img src={`${basePath}/images/ico/TailoredServicesBanks/2.svg`} alt="icons for Operational Resilience" />

                                    <p className='w-full xl:w-9/12 leading-tight font-normal text-lg mt-5'>Operational Resilience</p>
                                    <div className='w-full'>
                                        <p className='mt-1'><span className='font-normal'>
                                            <Speaker id="153" text="Operational Resilience.
                                        Agility in Regulatory Compliance: Blukite’s solutions enhance adaptability to regulatory changes, ensuring Building Societies remain compliant with minimal effort, thereby safeguarding their operations against potential legal and financial risks.
                                        Disaster Recovery and Business Continuity: Enhanced resilience through cloud-based solutions and data redundancy ensures uninterrupted service to members, even in the face of technical or natural disruptions, maintaining trust and reliability."/>
                                            Agility in Regulatory Compliance: </span>Blukite’s solutions enhance adaptability to regulatory changes, ensuring Building Societies remain compliant with minimal effort, thereby safeguarding their operations against potential legal and financial risks.</p>
                                        <p className='mt-1'><span className='font-normal'>Disaster Recovery and Business Continuity: </span>Enhanced resilience through cloud-based solutions and data redundancy ensures uninterrupted service to members, even in the face of technical or natural disruptions, maintaining trust and reliability.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='customShadow rounded-3xl p-5 xl:p-8' data-aos="fade-up-right" data-aos-duration="800">
                                <div>
                                    <img src={`${basePath}/images/ico/TailoredServicesBuildingSocieties/2.svg`} alt="icons for Enhancing Accessibility and Inclusion" />
                                    <p className='w-full xl:w-9/12 leading-tight font-normal text-lg mt-5'>Enhancing Accessibility and Inclusion</p>
                                    <div className='w-full'>
                                        <p className='mt-1'><span className='font-normal'>
                                            <Speaker id="154" text="Enhancing Accessibility and Inclusion.
                                            Universal Design Principles: Make financial services accessible to everyone, including those with disabilities, through the adoption of universal design principles in digital applications, ensuring equitable access to all members.
                                            Multi-Channel Service Delivery: Offer members multiple ways to interact with their Building Society, from traditional in-branch services to online and mobile platforms, catering to all preferences and ensuring no member is left behind."/>
                                            Universal Design Principles: </span>Make financial services accessible to everyone, including those with disabilities, through the adoption of universal design principles in digital applications, ensuring equitable access to all members.</p>
                                        <p className='mt-1'><span className='font-normal'>Multi-Channel Service Delivery: </span>Offer members multiple ways to interact with their Building Society, from traditional in-branch services to online and mobile platforms, catering to all preferences and ensuring no member is left behind.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section id="section3" className='my-10 sm:my-20'>
                    <h2 className='pageTitle'>Enhanced Accessibility: Beyond Compliance</h2>
                    <p className='text'>
                    <Speaker id="155" text="Enhanced Accessibility: Beyond Compliance. Blukite Finance's approach to accessibility is part of a larger commitment to promoting financial health for all members of society. By integrating Enhanced Accessibility features with responsible lending practices, Blukite ensures that all users, regardless of their background or abilities, have equal access to financial services that are safe, fair, and supportive of their financial well-being. This commitment to inclusivity not only meets regulatory standards but sets a new benchmark for what it means to provide equitable financial services. Building Societies leveraging Blukite's technology are positioned not just as financial institutions but as champions of a more inclusive, understanding, and supportive financial environment. The integration of Blukite Finance's Enhanced Accessibility functionality into Building Societies' offerings represents a significant step forward in reducing the barriers to financial services. This effort not only enhances the user experience for individuals facing various challenges but also aligns with broader societal goals of inclusion, equity, and responsible financial stewardship."/>
                        Blukite Finance's approach to accessibility is part of a larger commitment to promoting financial health for all members of society. By integrating Enhanced Accessibility features with
                        responsible lending practices, Blukite ensures that all users, regardless of their background or abilities, have equal access to financial services that are safe, fair, and supportive of
                        their financial well-being. This commitment to inclusivity not only meets regulatory standards but sets a new benchmark for what it means to provide equitable financial services.
                        Building Societies leveraging Blukite's technology are positioned not just as financial institutions but as champions of a more inclusive, understanding, and supportive financial
                        environment. The integration of Blukite Finance's Enhanced Accessibility functionality into Building Societies' offerings represents a significant step forward in reducing the barriers to
                        financial services. This effort not only enhances the user experience for individuals facing various challenges but also aligns with broader societal goals of inclusion, equity, and
                        responsible financial stewardship.</p>

                    <div className='my-10 flex flex-col xl:flex-row gap-10 items-center w-full lg:w-10/12 mx-auto' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        <div className='w-7/12 sm:w-4/12'>
                            <img src={`${basePath}/images/TailoredServicesBuildingSocieties/${activeSlideData.imageId}.svg`} />
                        </div>

                        <div className='w-full xl:w-8/12'>
                            <div className={`my-0 xl:my-10 flex gap-5 items-start ${animate ? 'fadeIn' : ''}`}>
                                <div>
                                    {activeSlideData.text.map((x, i) => {
                                        return (
                                            <div className='mt-1' key={i}>
                                                <p className='text'>
                                                    <span className='font-medium'>
                                                        {!i && <Speaker id={155 + activeSlideData.id} text={activeSlideData.textSpeaker} />}
                                                        {x.title}           
                                                    </span>
                                                    {x.text}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className='mt-7 flex justify-between'>
                                <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && prevSlide()} onClick={prevSlide} className='w-16 cursor-pointer shadow-none hover:shadow-lg transition' src={`${basePath}images/ico/diversity_sliderLeftArrow.svg`} alt="slider left arrow" />
                                <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && nextSlide()} onClick={nextSlide} className='w-16 cursor-pointer shadow-none hover:shadow-lg transition' src={`${basePath}images/ico/diversity_sliderRightArrow.svg`} alt="slider right arrow" />
                            </div>
                        </div>
                    </div>


                </section>


                <section id="section4" className='my-10 sm:my-20'>
                    <h2 className='pageTitle'>Modernising Legacy Systems with Blukite Finance</h2>
                    <p className='subtitle italic font-normal'>Revolutionising Building Society Operations</p>
                    <p className='text'>
                    <Speaker id="159" text="Modernising Legacy Systems with Blukite Finance.
                        Revolutionising Building Society Operations.
                        In the rapidly evolving digital finance landscape, Building Societies are poised at a crucial juncture where embracing technological advancement isn't just beneficial, it's essential for staying competitive and meeting the modern member's needs. Blukite Finance emerges as a transformative ally in this journey, offering a solution uniquely designed to modernise legacy systems without the need for disruptive overhauls. Blukite's cutting-edge technology is engineered for effortless integration with existing infrastructures. This synergy is achieved through the use of APIs (Application Programming Interfaces), which act as a bridge between Blukite's innovative platform and Building Societies' traditional systems. The result? A significant boost in operational efficiency, security, and user experience, all accomplished with minimal disruption to daily operations.
                        "/>
                        In the rapidly evolving digital finance landscape, Building Societies are poised at a crucial juncture where embracing technological advancement isn't just beneficial, it's essential for
                        staying competitive and meeting the modern member's needs. Blukite Finance emerges as a transformative ally in this journey, offering a solution uniquely designed to modernise
                        legacy systems without the need for disruptive overhauls. Blukite's cutting-edge technology is engineered for effortless integration with existing infrastructures. This synergy is
                        achieved through the use of APIs (Application Programming Interfaces), which act as a bridge between Blukite's innovative platform and Building Societies' traditional systems. The
                        result? A significant boost in operational efficiency, security, and user experience, all accomplished with minimal disruption to daily operations.</p>
                    
                        <Speaker id="160" text="Key Benefits of Upgrading with Blukite. 
                        Enhanced Operational Efficiency. 
                        By leveraging Blukite's platform, Building Societies can streamline a wide array of processes, from member onboarding to loan management. This efficiency not only reduces operational costs but also accelerates service delivery, enhancing member satisfaction.
                        Superior User Experience.
                        In the digital age, a seamless user experience is crucial for member
                                retention and attraction. Blukite's platform enhances digital interfaces,
                                making them more intuitive and accessible. This improvement extends
                                across online banking platforms, mobile apps, and in-branch digital
                                services, ensuring a consistent and enjoyable experience for all members.
                                Bolstered Security Measures.
                                Security is paramount in the financial sector. Blukite's platform is
                                embedded with the latest security technologies, ensuring that member
                                data is protected against emerging cyber threats. This robust security
                                framework supports Building Societies in maintaining trust and
                                complying with stringent regulatory standards.
                                Future-Proofing Services.
                                As financial technologies continue to evolve, Blukite's platform ensures
                                that Building Societies remain at the forefront of innovation. Its flexible
                                architecture allows for easy updates and the addition of new features,
                                ensuring that societies can quickly adapt to future trends and member
                                expectations.
                        
                        "/>
                    <p className='subtitle italic'><span className='font-normal'>Key Benefits</span> of Upgrading with Blukite</p>


                    <div
                        className="animatedInfo__cards mt-10"
                    >
                        <div
                            className="animatedInfo__card animatedInfo"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/underwritingAndLoanOrigination/1.svg`} alt="icon for Enhanced Operational Efficiency card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Enhanced Operational Efficiency</div>
                            <div className="animatedInfo__cardText">By leveraging Blukite's platform, Building Societies can streamline a
                                wide array of processes, from member onboarding to loan
                                management. This efficiency not only reduces operational costs but
                                also accelerates service delivery, enhancing member satisfaction.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card animatedInfo"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/underwritingAndLoanOrigination/2.svg`} alt="icon for Superior User Experience card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Superior User Experience</div>
                            <div className="animatedInfo__cardText">In the digital age, a seamless user experience is crucial for member
                                retention and attraction. Blukite's platform enhances digital interfaces,
                                making them more intuitive and accessible. This improvement extends
                                across online banking platforms, mobile apps, and in-branch digital
                                services, ensuring a consistent and enjoyable experience for all members.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>
                    </div>
                    <div
                        className="animatedInfo__cards mt-7 mb-10"
                    >

                        <div
                            className="animatedInfo__card"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/underwritingAndLoanOrigination/3.svg`} alt="icon for Bolstered Security Measures card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Bolstered Security Measures</div>
                            <div className="animatedInfo__cardText">Security is paramount in the financial sector. Blukite's platform is
                                embedded with the latest security technologies, ensuring that member
                                data is protected against emerging cyber threats. This robust security
                                framework supports Building Societies in maintaining trust and
                                complying with stringent regulatory standards.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/underwritingAndLoanOrigination/4.svg`} alt="icon for Future-Proofing Services card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Future-Proofing Services</div>
                            <div className="animatedInfo__cardText">As financial technologies continue to evolve, Blukite's platform ensures
                                that Building Societies remain at the forefront of innovation. Its flexible
                                architecture allows for easy updates and the addition of new features,
                                ensuring that societies can quickly adapt to future trends and member
                                expectations.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>
                    </div>

                    <p className='text'>
                    <Speaker id="161" text="The partnership between Building Societies and Blukite Finance is more than just a technological upgrade, it's a strategic alliance that propels societies into the future of finance. By modernising legacy systems with Blukite's platform, Building Societies can unlock new levels of efficiency, security, and member satisfaction, setting a new standard for member - focused financial services. In embracing Blukite's solution, Building Societies are not just preserving their cherished traditions of community and member focus; they're enhancing them, ensuring that these values thrive in the digital era. This transformative journey marks a new chapter in their storied history, one where innovation and tradition converge to create a brighter, more inclusive financial future for all members."/>

                        The partnership between Building Societies and Blukite Finance is more than just a technological upgrade, it's a strategic alliance that propels societies into the future of finance. By
                        modernising legacy systems with Blukite's platform, Building Societies can unlock new levels of efficiency, security, and member satisfaction, setting a new standard for member -
                        focused financial services. In embracing Blukite's solution, Building Societies are not just preserving their cherished traditions of community and member focus; they're enhancing
                        them, ensuring that these values thrive in the digital era. This transformative journey marks a new chapter in their storied history, one where innovation and tradition converge to
                        create a brighter, more inclusive financial future for all members.</p>
                    <p className='subtitle italic font-normal'>Legacy System Modernisation</p>
                    <p className='text'>
                    <Speaker id="162" text="Legacy System Modernisation. Legacy systems in Building Societies often represent a double-edged sword: while embodying the reliability and history of the institution, they can also hinder adaptability and efficiency in the digital age. Blukite's API-driven technology elegantly addresses this challenge by enabling a digital upgrade that integrates seamlessly with existing infrastructures. This approach allows Building Societies to retain the core of their operational systems while infusing them with new capabilities, ensuring a smooth transition to modernised processes without the need for extensive overhauls.
                    Reduced Costs and Complexity.
                    Modernising legacy systems through API integration significantly reduces the costs and complexity associated with traditional system upgrades, allowing for a more efficient allocation of resources.
                    Preservation of Institutional Knowledge.
                    By integrating with existing infrastructures, Building Societies can preserve the valuable institutional knowledge embedded in their legacy systems, ensuring continuity and stability.
                    Enhanced Member Services.
                    Modernised systems can handle a broader range of services more efficiently, enabling Building Societies to offer their members enhanced digital banking experiences.
                    "/>
                    Legacy systems in Building Societies often represent a double-edged sword: while embodying the reliability and history of the institution, they can also hinder adaptability and
                        efficiency in the digital age. Blukite's API-driven technology elegantly addresses this challenge by enabling a digital upgrade that integrates seamlessly with existing infrastructures.
                        This approach allows Building Societies to retain the core of their operational systems while infusing them with new capabilities, ensuring a smooth transition to modernised
                        processes without the need for extensive overhauls.</p>

                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-0 sm:gap-10 lg:gap-20 relative my-5 sm:my-16 text-left sm:text-center'>
                        <img className='hidden xl:block absolute top-10 w-9/12 -z-10  left-1/2 transform -translate-x-1/2' src={`${basePath}/images/ico/WhiteLabelConsumerApp/dashedLine.svg`} alt="dashed arrow illustration" />

                        <div className='flex flex-row sm:flex-col items-center gap-2 text-sm sm:text-base sm:gap-0'>
                            <img src={`${basePath}/images/TailoredServicesBuildingSocieties/11.svg`} className='h-32 sm:h-44 bg-white p-0 w-24 sm:w-44' alt="icon for Reduced Costs and Complexity" />
                            <div className=''>
                                <p className='font-normal mt-3'>Reduced Costs and Complexity</p>
                                <p className='mt-1 leading-tight'>Modernising legacy systems through API integration significantly reduces the costs and complexity associated with traditional system upgrades, allowing for a more efficient allocation of resources.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center gap-2 text-sm sm:text-base sm:gap-0'>
                            <img src={`${basePath}/images/TailoredServicesBuildingSocieties/12.svg`} className='h-32 sm:h-44 bg-white p-0 w-24 sm:w-52' alt="icon for Preservation of Institutional Knowledge" />
                            <div className=''>
                                <p className='font-normal mt-3'>Preservation of Institutional Knowledge</p>
                                <p className='mt-1 leading-tight'>By integrating with existing infrastructures, Building Societies can preserve the valuable institutional knowledge embedded in their legacy systems, ensuring continuity and stability.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center gap-2 text-sm sm:text-base sm:gap-0'>
                            <img src={`${basePath}/images/TailoredServicesBuildingSocieties/13.svg`} className='h-32 sm:h-44 bg-white p-0 w-24 sm:w-32' alt="icon for Enhanced Member Services" />
                            <div className=''>
                                <p className='font-normal mt-3'>Enhanced Member Services</p>
                                <p className='mt-1 leading-tight'>
                                    Modernised systems can handle a broader range of services more efficiently, enabling Building Societies to offer their members enhanced digital banking experiences.
                                </p>
                            </div>
                        </div>
                    </div>

                    <p className='subtitle italic font-normal mt-10 sm:mt-20'>Operational Agility</p>
                    <p className='text'>
                        
                    <Speaker id="162" text="Operational Agility.
                    The financial sector is characterised by rapid market changes and evolving regulatory landscapes. Blukite's solution imbues Building Societies with the agility needed to swiftly respond to these changes, enhancing both service reliability and operational efficiency. This agility is pivotal not only in adapting to market demands but also in seizing opportunities that arise from technological advancements and shifts in consumer behaviour.
                    Impact on Building Societies:
                    Responsive Service Offerings.
                    Agile operations enable Building Societies to quickly adjust their service offerings in response to member needs and market trends, maintaining relevance and competitiveness..
                    Efficient Resource Management.
                    Operational agility allows for more efficient management of resources, ensuring that staff can be redeployed from routine tasks to areas that add more value to members, such as personalised financial advice and community engagement.
                    Future-proofing the Institution.
                    Embracing operational agility positions Building Societies to better anticipate and adapt to future changes, ensuring long-term sustainability and growth.
                    "/>
                        The financial sector is characterised by rapid market changes and evolving regulatory landscapes. Blukite's solution imbues Building Societies with the agility needed to swiftly
                        respond to these changes, enhancing both service reliability and operational efficiency. This agility is pivotal not only in adapting to market demands but also in seizing opportunities
                        that arise from technological advancements and shifts in consumer behaviour.</p>

                    <p className='subtitle italic font-normal text-center mt-10'>Impact on Building Societies:</p>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-0 sm:gap-10 lg:gap-20 relative my-5 sm:my-16 text-left sm:text-center'>
                        <img className='hidden xl:block absolute top-10 w-9/12 -z-10  left-1/2 transform -translate-x-1/2' src={`${basePath}/images/ico/WhiteLabelConsumerApp/dashedLine.svg`} alt="dashed arrow illustration" />

                        <div className='flex flex-row sm:flex-col items-center gap-2 text-sm sm:text-base sm:gap-0'>
                            <img src={`${basePath}/images/TailoredServicesBuildingSocieties/14.svg`} className='h-32 sm:h-44 bg-white p-0 w-24 sm:w-44' alt="icon for Responsive Service Offerings" />
                            <div className=''>
                                <p className='font-normal mt-3'>Responsive Service Offerings</p>
                                <p className='mt-1 leading-tight'>Agile operations enable Building Societies to quickly adjust their service offerings in response to member needs and market trends, maintaining relevance and competitiveness.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center gap-2 text-sm sm:text-base sm:gap-0'>
                            <img src={`${basePath}/images/TailoredServicesBuildingSocieties/15.svg`} className='h-32 sm:h-44 bg-white p-0 w-24 sm:w-44' alt="icon for Efficient Resource Management" />
                            <div className=''>
                                <p className='font-normal mt-3'>Efficient Resource Management</p>
                                <p className='mt-1 leading-tight'>Operational agility allows for more efficient management of resources, ensuring that staff can be redeployed from routine tasks to areas that add more value to members, such as personalised financial advice and community engagement.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center gap-2 text-sm sm:text-base sm:gap-0'>
                            <img src={`${basePath}/images/TailoredServicesBuildingSocieties/16.svg`} className='h-32 sm:h-44 bg-white p-0 w-24 sm:w-44' alt="icon for Future-proofing the Institution" />
                            <div className=''>
                                <p className='font-normal mt-3'>Future-proofing the Institution</p>
                                <p className='mt-1 leading-tight'>
                                    Embracing operational agility positions Building Societies to better anticipate and adapt to future changes, ensuring long-term sustainability and growth.
                                </p>
                            </div>
                        </div>
                    </div>

                    <p className='text mt-10 sm:mt-0'>
                    <Speaker id="163" text="The integration of Blukite Finance's API-driven technology into the fabric of Building Societies represents a strategic leap towards modernising legacy systems and embracing operational agility. This transformation not only aligns with the ethos of Building Societies, combining tradition with innovation, but also ensures they remain at the forefront of providing inclusive, efficient, and personalised financial services. Through this partnership, Building Societies can redefine their operational paradigms, opening doors to new possibilities and reinforcing their commitment to serving their communities in the digital era."/>
                        The integration of Blukite Finance's API-driven technology into the fabric of Building Societies represents a strategic leap towards modernising legacy systems and embracing
                        operational agility. This transformation not only aligns with the ethos of Building Societies, combining tradition with innovation, but also ensures they remain at the forefront of
                        providing inclusive, efficient, and personalised financial services. Through this partnership, Building Societies can redefine their operational paradigms, opening doors to new
                        possibilities and reinforcing their commitment to serving their communities in the digital era.</p>

                </section>
                <section id="section5" className='my-10 sm:my-20'>
                    <h2 className='pageTitle'>Optimising Operational Efficiency with Blukite's Essential Solutions</h2>
                    <p className='text'>
                    <Speaker id="164" text="Optimising Operational Efficiency with Blukite's Essential Solutions. Building Societies face the imperative challenge of not just keeping pace but staying ahead. Blukite Finance introduces indispensable solutions that transform these challenges into opportunities for growth, innovation, and unparalleled service delivery. Our API-driven technology and commitment to operational agility are not merely enhancements; they are critical upgrades that Building Societies cannot afford to overlook."/>
                        Building Societies face the imperative challenge of not just keeping pace but staying ahead. Blukite Finance introduces indispensable solutions that transform these challenges into
                        opportunities for growth, innovation, and unparalleled service delivery. Our API-driven technology and commitment to operational agility are not merely enhancements; they are critical
                        upgrades that Building Societies cannot afford to overlook.</p>


                    <div className='my-10 flex flex-col xl:flex-row gap-10 items-center' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        <div className='hidden sm:flex items-center flex-wrap justify-center gap-5 sm:w-auto w-full'>
                            <div className={activeSlide2 === 4 ? 'fadeIn sliderItem--active' : 'sliderItem'} onClick={() => setActiveSlide2(4)}><img className='w-10 sm:w-12' src={`${basePath}images/ico/TailoredServicesBuildingSocieties/3.svg`} alt="icon for New Opportunities" /><p className='text-center font-normal'>New Opportunities</p></div>
                            <div className='flex flex-row sm:flex-col gap-5'>
                                <div className={activeSlide2 === 1 ? 'fadeIn sliderItem--active' : 'sliderItem'} onClick={() => setActiveSlide2(1)}><img className='w-10 sm:w-12' src={`${basePath}images/ico/TailoredServicesBuildingSocieties/4.svg`} alt="icon for Lifiline of your Operations" /><p className='text-center font-normal'>Lifiline of your Operations</p></div>
                                <div className={activeSlide2 === 3 ? 'fadeIn sliderItem--active' : 'sliderItem'} onClick={() => setActiveSlide2(3)}><img className='w-10 sm:w-12' src={`${basePath}images/ico/TailoredServicesBuildingSocieties/6.svg`} alt="icon for Adaptability is a Key" /><p className='text-center font-normal'>Adaptability is a Key</p></div>
                            </div>
                            <div className={activeSlide2 === 2 ? 'fadeIn sliderItem--active' : 'sliderItem'} onClick={() => setActiveSlide2(2)}><img className='w-10 sm:w-12' src={`${basePath}images/ico/TailoredServicesBuildingSocieties/5.svg`} alt="icon for Digital Transformation" /><p className='text-center font-normal'>Digital Transformation</p></div>
                        </div>

                        <div className='ml-0 xl:ml-16 flex-1 w-full xl:w-auto'>

                            <div className={`my-0 sm:my-10 flex gap-5 items-start ${animate ? 'fadeIn' : ''}`}>
                                <div>
                                    <p className='uppercase text-2xl font-light'>{activeSlideData2.title}</p>
                                    <p className='text'>
                                    <Speaker id={164 + activeSlideData2.id} text={activeSlideData2.title + "." + activeSlideData2.text}/>
                                    {activeSlideData2.text}</p>
                                </div>
                            </div>

                            <div className='mt-7 flex justify-between'>
                                <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && prevSlide2()} onClick={prevSlide2} className='w-16 cursor-pointer shadow-none hover:shadow-lg transition' src={`${basePath}images/ico/diversity_sliderLeftArrow.svg`} alt="slider left arrow" />
                                <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && nextSlide2()} onClick={nextSlide2} className='w-16 cursor-pointer shadow-none hover:shadow-lg transition' src={`${basePath}images/ico/diversity_sliderRightArrow.svg`} alt="slider right arrow" />
                            </div>
                        </div>
                    </div>

                    <p className='text'>
                    <Speaker id="169" text="In essence, Blukite Finance's offerings are not just solutions; they are the very foundations upon which the future of Building Societies will be built. By embracing Blukite's technology, Building Societies ensure that they are not left behind in an era marked by rapid technological advancements and shifting consumer behaviours. It's an investment in becoming an institution that's indispensable to its members, today, tomorrow, and beyond."/>
                        In essence, Blukite Finance's offerings are not just solutions; they are the very foundations upon which the future of Building Societies will be built. By embracing Blukite's technology,
                        Building Societies ensure that they are not left behind in an era marked by rapid technological advancements and shifting consumer behaviours. It's an investment in becoming an
                        institution that's indispensable to its members, today, tomorrow, and beyond.</p>


                    <div className='bg-lightBlue p-5 lg:p-16 flex-col mb-20 my-10 sm:my-20 items-center gap-5 lg:gap-10 flex xl:flex-row rounded' data-aos="fade-up">
                        <div className=' flex-col gap-5 lg:gap-10 flex xl:flex-row items-center'>
                            <div className='max-w-full w-full mx-auto xl:max-w-64 uppercase italic font-normal text-xl lg:text-3xl text-center xl:text-left'>PARTNER WITH
                                BLUKITE Finance</div>

                            <div className='flex'>
                                <img className='w-8 lg:w-14 self-start' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                                <p className='font-light text-base lg:text-lg ml-5 lg:leading-tight'>
                                    
                                <Speaker id="170" text="PARTNER WITH BLUKITE FINANCE. Choosing Blukite means opting for a partnership that guarantees not just survival but prosperity in the digital age. It's an essential step for any Building Society looking to solidify its place in the future of finance, where operational efficiency, modernised systems, and unparalleled agility are not just desired but required for success."/>
                                    Choosing Blukite means opting for a partnership that guarantees not just survival but prosperity in the digital age. It's an essential step for any Building Society looking to solidify its place in the future of finance, where operational efficiency, modernised systems, and unparalleled agility are not just desired but required for success.
                                </p>
                                <img className='w-8 lg:w-14 rotate-180 self-end ml-5' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                            </div>
                        </div>

                    </div>


                </section>
                <section id="section6" className='my-10 sm:my-20'>
                    <h2 className='pageTitle'>Expanding Product Offerings</h2>
                    <p className='subtitle italic font-normal mt-5 sm:mt-14'>The Core of Building Society Evolution with Blukite Finance</p>
                    <p className='text'>
                    <Speaker id="171" text="Expanding Product Offerings
The Core of Building Society Evolution with Blukite Finance
In today’s financial landscape, staying competitive requires more than just a strong foundation in traditional services. It demands innovation, customisation, and an ability to meet the ever-evolving needs of a diverse membership base. Blukite Finance emerges as a transformative force for Building Societies, enabling a strategic expansion of product offerings that transcends the conventional, propelling your institution into the future of finance.
"/>
                        In today’s financial landscape, staying competitive requires more than just a strong foundation in traditional services. It demands innovation, customisation, and an ability to meet the
                        ever-evolving needs of a diverse membership base. Blukite Finance emerges as a transformative force for Building Societies, enabling a strategic expansion of product offerings that
                        transcends the conventional, propelling your institution into the future of finance.</p>


                    <div className='flex xl:flex-row flex-col justify-between gap-5 mt-5'>
                        <p className='font-normal subtitle italic leading-tight sm:w-auto'>Expanding Horizons: Beyond Mortgages and Attracting a Wider Membership Base</p>
                        <div className='mt-0 xl:mt-7 gap-10 flex justify-between'>
                            <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handlePrevious()} onClick={handlePrevious} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderLeftArrow.svg`} alt="slider left arrow" />
                            <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handleNext()} onClick={handleNext} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderRightArrow.svg`} alt="slider right arrow" />
                        </div>
                    </div>

                    <div className='border-r border-separateLight'>
                        <div id="slider" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                            <div className="cards mt-2 xl:mt-10">
                                {productOfferingsSlides.map((slide, index) => (
                                    <div id={`slide-${index}`} key={index} className={`cards__card ${index === currentProductOfferIndex ? 'activeCard' : index === (currentProductOfferIndex + 1) % productOfferingsSlides.length ? 'next' : 'sliderHidden'}`}>
                                        <div className="cards__content">
                                            <p className="font-normal text-lg sm:text-xl">{slide.title}</p>
                                            <p className="text-sm sm:text-base leading-tight font-light mt-3">
                                            <Speaker id={172 + index} text={slide.title + ". " + slide.description}/>
                                                {slide.description}</p>
                                            {slide.id === 1 ?
                                                <img className='absolute left-0 bottom-0' src={`${basePath}/images/TailoredServicesBuildingSocieties/17.svg`} alt={`images for ${slide.title}`} />
                                                : slide.id === 2 ?
                                                    <img className='absolute -right-9 -bottom-10' src={`${basePath}/images/TailoredServicesBuildingSocieties/18.svg`} alt={`images for ${slide.title}`} />
                                                    : slide.id === 3 ?
                                                        <img className='absolute left-1/2 transform -translate-x-1/2 bottom-0' src={`${basePath}/images/TailoredServicesBuildingSocieties/19.svg`} alt={`images for ${slide.title}`} />
                                                        : slide.id === 4 ?
                                                            <img className='absolute left-1/2 transform -translate-x-1/2 bottom-0' src={`${basePath}/images/TailoredServicesBuildingSocieties/20.svg`} alt={`images for ${slide.title}`} />
                                                            : slide.id === 5 ?
                                                                <img className='absolute right-0 -bottom-0' src={`${basePath}/images/TailoredServicesBuildingSocieties/21.svg`} alt={`images for ${slide.title}`} />
                                                                : null}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>


                    <p className='text mt-5 sm:mt-20'>
                    <Speaker id="177" text="Partnering with Blukite Finance is not just about expanding your product offerings; it’s about embracing a future where Building Societies are seen as innovators, leaders in financial inclusion, and champions of member satisfaction. By leveraging Blukite’s technology, your society is not only equipped to meet the challenges of today but is also future proofed against the evolving demands of the financial world. In choosing Blukite, you choose a path of growth, innovation, and unparalleled service diversity, ensuring your Building Society remains a cornerstone of the community it serves, today and tomorrow. This expansion of product offerings is more than a strategy; it’s a commitment to the financial well-being and satisfaction of every member, ensuring your society remains an indispensable part of their financial lives."/>
                        Partnering with Blukite Finance is not just about expanding your product offerings; it’s about embracing a future where Building Societies are seen as innovators, leaders in financial
                        inclusion, and champions of member satisfaction. By leveraging Blukite’s technology, your society is not only equipped to meet the challenges of today but is also future proofed
                        against the evolving demands of the financial world. In choosing Blukite, you choose a path of growth, innovation, and unparalleled service diversity, ensuring your Building Society
                        remains a cornerstone of the community it serves, today and tomorrow. This expansion of product offerings is more than a strategy; it’s a commitment to the financial well-being and
                        satisfaction of every member, ensuring your society remains an indispensable part of their financial lives.</p>



                </section>
                <section id="section7" className='my-10 sm:my-20'>
                    <h2 className='pageTitle'>The Power of Advanced Analytics</h2>
                    <p className='text'>
                    <Speaker id="178" text="The Power of Advanced Analytics.
                        Empowering Building Societies with data-driven decision-making capabilities, Blukite Finance offers more than just a technological solution; it provides a strategic advantage. By understanding and anticipating member needs, societies can ensure their offerings remain relevant and competitive. Moreover, the ability to automate certain decisions frees up valuable resources, allowing societies to focus on innovation and member engagement. In partnering with Blukite, Building Societies gain access to a powerful toolset for navigating the complexities of the modern financial landscape, making informed decisions that drive growth, enhance member satisfaction, and ensure long-term success.
                        Understanding Market Trends and Customer Behaviour: Blukite's platform harnesses the vast amounts of data generated by daily transactions and member interactions, providing a comprehensive overview of market trends and customer behaviour.
                        Tailoring Services to Member Needs: The analytics provided by Blukite go beyond general trends, offering deep dives into the preferences and behaviours of individual members. This level of detail enables societies to tailor their services with unprecedented precision, enhancing member satisfaction and loyalty.
                        Informed Strategic Positioning: Data-driven insights facilitate informed strategic positioning, allowing Building Societies to identify growth opportunities, innovate product offerings, and refine service delivery models. This strategic advantage is invaluable in today’s competitive financial landscape.
                        Risk Management and Compliance: Analytics also play a pivotal role in risk management and regulatory compliance. By analysing data patterns, societies can identify potential risks before they materialise, adopting proactive measures to mitigate them. Similarly, compliance analytics ensure that societies adhere to regulatory requirements, avoiding penalties and reputational damage.
                        Automating Decision Processes: Our platform also offers the potential for automating certain decision-making processes. This automation can range from simple tasks, such as flagging accounts for review, to more complex algorithms predicting loan defaults or identifying cross-selling opportunities.
                        Understanding Market Trends and Customer Behaviour: Blukite's platform harnesses the vast amounts of data generated by daily transactions and member interactions, providing a comprehensive overview of market trends and customer behaviour.
                        Tailoring Services to Member Needs: The analytics provided by Blukite go beyond general trends, offering deep dives into the preferences and behaviours of individual members. This level of detail enables societies to tailor their services with unprecedented precision, enhancing member satisfaction and loyalty.
                        Informed Strategic Positioning: Data-driven insights facilitate informed strategic positioning, allowing Building Societies to identify growth opportunities, innovate product offerings, and refine service delivery models. This strategic advantage is invaluable in today’s competitive financial landscape.
                        Risk Management and Compliance: Analytics also play a pivotal role in risk management and regulatory compliance. By analysing data patterns, societies can identify potential risks before they materialise, adopting proactive measures to mitigate them. Similarly, compliance analytics ensure that societies adhere to regulatory requirements, avoiding penalties and reputational damage.
                        Automating Decision Processes: Our platform also offers the potential for automating certain decision-making processes. This automation can range from simple tasks, such as flagging accounts for review, to more complex algorithms predicting loan defaults or identifying cross-selling opportunities.
                        "/>
                        Empowering Building Societies with data-driven decision-making capabilities, Blukite Finance offers more than just a technological solution; it provides a strategic advantage. By
                        understanding and anticipating member needs, societies can ensure their offerings remain relevant and competitive. Moreover, the ability to automate certain decisions frees up
                        valuable resources, allowing societies to focus on innovation and member engagement. In partnering with Blukite, Building Societies gain access to a powerful toolset for navigating
                        the complexities of the modern financial landscape, making informed decisions that drive growth, enhance member satisfaction, and ensure long-term success.</p>


                    <div className='flex flex-col xl:flex-row justify-between xl:justify-center my-5 sm:my-10'>

                        <div className='w-full 2xl:w-6/12'>
                            <img className='mx-auto' src={`${basePath}images/TailoredServicesBuildingSocieties/22.svg`} alt="illustration for Debt Collection" />
                        </div>
                        <div className='w-full 2xl:w-6/12 2xl:text-base text-sm'>
                            <div className='flex items-start gap-5 mb-2' data-aos="fade-up" data-aos-duration="700">
                                <img src={`${basePath}images/ico/checkmark.svg`} alt="checkmark icon" />
                                <p><span className='font-medium'>Understanding Market Trends and Customer Behaviour:  </span> Blukite's platform harnesses the vast amounts of data generated by daily transactions and member interactions, providing a comprehensive overview of market trends and customer behaviour. </p>
                            </div>
                            <div className='flex items-start gap-5 mb-2' data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                                <img src={`${basePath}images/ico/checkmark.svg`} alt="checkmark icon" />
                                <p><span className='font-medium'>Tailoring Services to Member Needs: </span> The analytics provided by Blukite go beyond general trends, offering deep dives into the preferences and behaviours of individual members. This level of detail enables societies to tailor their services with unprecedented precision, enhancing member satisfaction and loyalty.</p>
                            </div>
                            <div className='flex items-start gap-5 mb-2' data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
                                <img src={`${basePath}images/ico/checkmark.svg`} alt="checkmark icon" />
                                <p><span className='font-medium'>Informed Strategic Positioning: </span>Data-driven insights facilitate informed strategic positioning, allowing Building Societies to identify growth opportunities, innovate product offerings, and refine service delivery models. This strategic advantage is invaluable in today’s competitive financial landscape.</p>
                            </div>
                            <div className='flex items-start gap-5 mb-2' data-aos="fade-up" data-aos-duration="700" data-aos-delay="200">
                                <img src={`${basePath}images/ico/checkmark.svg`} alt="checkmark icon" />
                                <p><span className='font-medium'>Risk Management and Compliance: </span>Analytics also play a pivotal role in risk management and regulatory compliance. By analysing data patterns, societies can identify potential risks before they materialise, adopting proactive measures to mitigate them. Similarly, compliance analytics ensure that societies adhere to regulatory requirements, avoiding penalties and reputational damage.</p>
                            </div>
                            <div className='flex items-start gap-5 mb-2' data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                                <img src={`${basePath}images/ico/checkmark.svg`} alt="checkmark icon" />
                                <p><span className='font-medium'>Automating Decision Processes: </span>Our platform also offers the potential for automating certain decision-making processes. This automation can range from simple tasks, such as flagging accounts for review, to more complex algorithms predicting loan defaults or identifying cross-selling opportunities.</p>
                            </div>

                        </div>
                    </div>
                    <p className='text'>
                    <Speaker id="179" text="Essential data includes member transaction histories and patterns, loan application outcomes and performance, digital engagement metrics, and feedback and satisfaction scores. Decisions that can be automated encompass credit risk assessments, personalised marketing and product recommendations, fraud detection and prevention, and the allocation of resources for customer service."/>
                        Essential data includes member transaction histories and patterns, loan application outcomes and performance, digital engagement metrics, and feedback and satisfaction scores.
                        Decisions that can be automated encompass credit risk assessments, personalised marketing and product recommendations, fraud detection and prevention, and the allocation of
                        resources for customer service.</p>
                </section>

                <section id="section8" className='my-10 sm:my-20'>
                    <h2 className='pageTitle'>Enhancing Member Experience with Blukite Finance</h2>
                    <p className='subtitle font-normal italic'>A Guide to Digital Transformation</p>
                    <p className='text'>
                    <Speaker id="180" text="Enhancing Member Experience with Blukite Finance. A Guide to Digital Transformation. 
                    In today’s digital era, enhancing the member experience is paramount for Building Societies to remain competitive and relevant. Blukite Finance's comprehensive solution is designed to elevate the end-user experience, merging the traditional values of Building Societies with the efficiencies and conveniences of modern technology.  Blukite Finance offers more than just a digital solution; it represents a partnership in enhancing the member experience. By embracing Blukite’s platform, Building Societies can provide a modern, engaging banking experience that resonates with today’s members, characterised by convenience, personalisation, and accessibility. This transformation not only strengthens member loyalty but also positions Building Societies as forward-thinking, member-centric institutions ready for the future of finance.
                    Simplifying the Process.
                    Blukite’s platform revolutionises the application process by making it quicker and more intuitive. By integrating smart forms that pre-populate member information and employ predictive text, we reduce the time and effort required to apply for financial products.
                    Reducing Friction Points.
                    Our solution identifies and eliminates common friction points in the application process, such as unnecessary steps or repetitive information requests, ensuring a smooth and hassle-free experience for members.
                    Empowering Members with Insights.
                    Blukite Finance equips members with personalised financial management tools that offer real-time insights into their finances, that help them track spending, set budgeting goals, and monitor savings progress, fostering a sense of empowerment and financial well-being.
                    Customisable Dashboards.
                    Members can customise their dashboard to highlight the information most important to them, whether it's upcoming bill payments, savings milestones, or investment performance, ensuring a tailored banking experience.
                    Omnichannel Access.
                    Blukite ensures members have consistent and seamless access across all digital channels, whether they're using a web browser, mobile app, or tablet. This omnichannel approach guarantees that members can manage their finances whenever and wherever they choose.
                    Interactive Communication Tools.
                    From chatbots that provide instant assistance to video banking options that offer a personal touch without the need to visit a branch, Blukite’s platform embraces the latest in communication technology to enhance member support and engagement.
                    Listening and Adapting.
                    Feedback loops are integrated into our platform, encouraging members to share their experiences and suggestions, which are invaluable for improvement, allowing Building Societies to adapt and evolve their services in alignment with member expectations.
                    Designing with Everyone in Mind.
                    Accessibility features are a core component of our solution, ensuring that financial services are usable for everyone, including those with disabilities. From screen reader compatibility to easy navigation for those with mobility challenges.
                    "/>
                        In today’s digital era, enhancing the member experience is paramount for Building Societies to remain competitive and relevant. Blukite Finance's comprehensive solution is designed
                        to elevate the end-user experience, merging the traditional values of Building Societies with the efficiencies and conveniences of modern technology.  Blukite Finance offers more than
                        just a digital solution; it represents a partnership in enhancing the member experience. By embracing Blukite’s platform, Building Societies can provide a modern, engaging banking
                        experience that resonates with today’s members, characterised by convenience, personalisation, and accessibility. This transformation not only strengthens member loyalty but also
                        positions Building Societies as forward-thinking, member-centric institutions ready for the future of finance.</p>


                    <div className="memberExperience__cards items-stretch gap-x-10 gap-y-5 sm:gap-x-8 gap-y-8 my-5 sm:my-14">
                        <div className='py-5 pb-10'>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_1.svg`} alt='icon for Simplifying the Process' />
                            <p className='mt-4 text-lg font-normal'>Simplifying the Process</p>
                            <p className='mt-2 leading-tight'>Blukite’s platform revolutionises the application process by making it quicker and more intuitive. By integrating smart forms that pre-populate member information and employ predictive text, we reduce the time and effort required to apply for financial products.</p>
                        </div>


                        <div className='py-5 pb-10'>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_5.svg`} alt='icon for Reducing Friction Points' />
                            <p className='mt-4 text-lg font-normal'>Reducing Friction Points</p>
                            <p className='mt-2 leading-tight'>Our solution identifies and eliminates common friction points in the application process, such as unnecessary steps or repetitive information requests, ensuring a smooth and hassle-free experience for members.</p>
                        </div>

                        <div className='py-5 pb-10'>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_2.svg`} alt='icon for Empowering Members with Insights' />
                            <p className='mt-4 text-lg font-normal'>Empowering Members with Insights</p>
                            <p className='mt-2 leading-tight'>Blukite Finance equips members with personalised financial management tools that offer real-time insights into their finances, that help them track spending, set budgeting goals, and monitor savings progress, fostering a sense of empowerment and financial well-being.</p>
                        </div>

                        <div className='py-5 pb-10'>
                            <img className='w-14' src={`${basePath}/images/ico/keyFeatureItem_9.svg`} alt='icon for Customisable Dashboards' />
                            <p className='mt-4 text-lg font-normal'>Customisable Dashboards</p>
                            <p className='mt-2 leading-tight'>Members can customise their dashboard to highlight the information most important to them, whether it's upcoming bill payments, savings milestones, or investment performance, ensuring a tailored banking experience.</p>
                        </div>


                        <div className='py-5 pb-10'>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_4.svg`} alt='icon for Omnichannel Access' />
                            <p className='mt-4 text-lg font-normal'>Omnichannel Access</p>
                            <p className='mt-2 leading-tight'>Blukite ensures members have consistent and seamless access across all digital channels, whether they're using a web browser, mobile app, or tablet. This omnichannel approach guarantees that members can manage their finances whenever and wherever they choose.</p>
                        </div>

                        <div className='py-5 pb-10'>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_10.svg`} alt='icon for Interactive Communication Tools' />
                            <p className='mt-4 text-lg font-normal'>Interactive Communication Tools</p>
                            <p className='mt-2 leading-tight'>From chatbots that provide instant assistance to video banking options that offer a personal touch without the need to visit a branch, Blukite’s platform embraces the latest in communication technology to enhance member support and engagement.</p>
                        </div>

                        <div className='py-5 pb-10'>
                            <img className='w-14' src={`${basePath}/images/ico/keyFeatureItem_11.svg`} alt='icon for Listening and Adapting' />
                            <p className='mt-4 text-lg font-normal'>Listening and Adapting</p>
                            <p className='mt-2 leading-tight'>Feedback loops are integrated into our platform, encouraging members to share their experiences and suggestions, which are invaluable for improvement, allowing Building Societies to adapt and evolve their services in alignment with member expectations.</p>
                        </div>
                        <div className='py-5 pb-10'>
                            <img className='w-14' src={`${basePath}/images/ico/keyFeatureItem_12.svg`} alt='icon for Designing with Everyone in Mind' />
                            <p className='mt-4 text-lg font-normal'>Designing with Everyone in Mind</p>
                            <p className='mt-2 leading-tight'>Accessibility features are a core component of our solution, ensuring that financial services are usable for everyone, including those with disabilities. From screen reader compatibility to easy navigation for those with mobility challenges.</p>
                        </div>
                    </div>

                </section>
                <section id="section9" className='my-10 sm:my-20'>
                    <h2 className='pageTitle'>Ensuring Security and Compliance</h2>
                    <p className='text'>
                    <Speaker id="180" text="Ensuring Security and Compliance. In the financial sector, particularly for Building Societies, navigating the labyrinth of regulatory requirements is a formidable challenge. These regulations, constantly evolving to address new risks and protect consumers, cover a broad spectrum from credit provisioning to anti-fraud measures and the fair treatment of consumers. Blukite Finance recognises the critical nature of these concerns and offers a robust solution that not only meets but exceeds these regulatory and security expectations.
                    Security and compliance are imperative for building societies, given the dynamic nature of the financial landscape. Regulations are frequently updated to reflect changes in financial crimes, technological advancements, and consumer protection needs. Therefore, building societies must remain vigilant, ensuring their operations comply with current laws and are ready to adapt to future changes.At the forefront of these efforts is consumer protection. The fair treatment of consumers is not just a regulatory requirement but a cornerstone of trust between building societies and their members. This includes transparent credit provisioning, safeguarding personal data, and ensuring that all consumer interactions are conducted with fairness and integrity.Additionally, as financial transactions increasingly move online, the threat of fraud and financial crime grows. Building societies must employ sophisticated measures to detect and prevent these activities to protect their members and their own operations."/>
                        In the financial sector, particularly for Building Societies, navigating the labyrinth of regulatory requirements is a formidable challenge. These regulations, constantly evolving to
                        address new risks and protect consumers, cover a broad spectrum from credit provisioning to anti-fraud measures and the fair treatment of consumers. Blukite Finance recognises
                        the critical nature of these concerns and offers a robust solution that not only meets but exceeds these regulatory and security expectations.</p>
                    <p className='text'>Security and compliance are imperative for building societies, given the dynamic nature of the financial landscape. Regulations are frequently updated to reflect changes in financial
                        crimes, technological advancements, and consumer protection needs. Therefore, building societies must remain vigilant, ensuring their operations comply with current laws and are
                        ready to adapt to future changes.At the forefront of these efforts is consumer protection. The fair treatment of consumers is not just a regulatory requirement but a cornerstone of
                        trust between building societies and their members. This includes transparent credit provisioning, safeguarding personal data, and ensuring that all consumer interactions are
                        conducted with fairness and integrity.Additionally, as financial transactions increasingly move online, the threat of fraud and financial crime grows. Building societies must employ
                        sophisticated measures to detect and prevent these activities to protect their members and their own operations.</p>

                    <p className='mt-5 sm:mt-10 italic font-normal subtitle text-center'>How Blukite Delivers on Security and Compliance</p>

                    <Select
                        defaultValue={1}
                        className='block md:hidden mt-5'
                        style={{
                            width: '100%',
                        }}
                        onChange={(value) => setActiveComplianceSlide(value)}
                        options={complianceOptions}
                    />
                    <div className='hidden md:block'>
                        <div className='coreSolutions__keyServicesNavItems flex-row justify-start xl:justify-between mt-10 w-full 2xl:w-full'>
                            {complianceSlides.map(slide => {
                                return (
                                    <div className={activeComplianceSlideData.id === slide.id ? 'coreSolutions__keyServicesNavItem--active' : 'coreSolutions__keyServicesNavItem'} onClick={() => setActiveComplianceSlide(slide.id)}>{slide.title}</div>
                                )
                            }
                            )}
                        </div>

                    </div>

                    <div className={`my-5 md:my-16 flex flex-col lg:flex-row gap-10 items-center sm:items-start w-full xl:w-9/12 mx-auto`}>
                        <div className='text-base xl:text-lg mt-0 sm:mt-5 xl:mt-14 w-full sm:w-9/12'>
                            <Speaker id={180 + activeComplianceSlideData.id} text={activeComplianceSlideData.textTitle + ". " + activeComplianceSlideData.text}/>
                            <p className='font-normal'>{activeComplianceSlideData.textTitle}</p>
                            <p className='text'>{activeComplianceSlideData.text}</p>
                        </div>
                        <img className='mt-0 xl:mt-5' src={`${basePath}images/TailoredServicesBuildingSocieties/${activeComplianceSlideData.imageId}.svg`} alt={`image for ${activeComplianceSlideData.title}`} />
                    </div>

                    {/* slider */}

                    <div className='bg-lightBlue p-5 lg:p-16 flex-col mb-20 my-10 sm:my-20 items-center gap-5 lg:gap-10 flex xl:flex-row rounded' data-aos="fade-up">
                        <div className=' flex-col gap-5 lg:gap-10 flex xl:flex-row items-center'>
                            <div className='max-w-full w-full mx-auto xl:max-w-64 uppercase italic font-normal text-xl lg:text-3xl text-center xl:text-left'>PARTNER WITH
                                BLUKITE Finance</div>

                            <div className='flex'>
                                <img className='w-8 lg:w-14 self-start' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                                <p className='font-light text-base lg:text-lg ml-5 lg:leading-tight'>
                                <Speaker id="186" text="Partnering with Blukite Finance offers Building Societies more than just a technological upgrade; it provides a comprehensive solution for security and compliance challenges. By choosing Blukite, societies gain a partner dedicated to ensuring their operations are secure, compliant, and prepared for the future. This peace of mind allows Building Societies to focus on what they do best: serving their members with integrity and innovation, secure in the knowledge that their operations are protected against both current and future regulatory and security challenges."/>
                                    Partnering with Blukite Finance offers Building Societies more than just a technological upgrade; it provides a comprehensive solution for security and compliance challenges. By choosing Blukite, societies gain a partner dedicated to ensuring their operations are secure, compliant, and prepared for the future. This peace of mind allows Building Societies to focus on what they do best: serving their members with integrity and innovation, secure in the knowledge that their operations are protected against both current and future regulatory and security challenges.
                                </p>
                                <img className='w-8 lg:w-14 rotate-180 self-end ml-5' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                            </div>
                        </div>

                    </div>

                </section>

                <section id="section10" className='my-10 sm:my-20'>
                    <h2 className='pageTitle'>Partnership for the Future</h2>
                    <p className='text'>
                    <Speaker id="187" text="Partnership for the Future.
Blukite Finance extends a comprehensive suite of services to support Building Societies in their strategic expansion. Our solutions encompass consumer lending facilitation, advanced regulatory compliance, innovative digital banking platforms, and a strong focus on enhancing accessibility for all members. As you consider the next steps towards a profitable and inclusive future, let Blukite be your partner in navigating this journey. With our support, your society can confidently explore new financial products and services, ensuring they are competitive, compliant, and accessible, thereby securing your place in the future of finance."/>
                        Blukite Finance extends a comprehensive suite of services to support Building Societies in their strategic expansion. Our solutions encompass consumer lending facilitation,
                        advanced regulatory compliance, innovative digital banking platforms, and a strong focus on enhancing accessibility for all members. As you consider the next steps towards a
                        profitable and inclusive future, let Blukite be your partner in navigating this journey. With our support, your society can confidently explore new financial products and services,
                        ensuring they are competitive, compliant, and accessible, thereby securing your place in the future of finance.</p>

                    <div className='flex flex-col lg:flex-row mt-7 gap-7 mt-10'>
                        <div className='customShadow rounded-3xl p-5 sm:p-8 flex-1' data-aos="fade-up-right" data-aos-duration="800">
                            <div>
                                <img src={`${basePath}/images/ico/TailoredServicesBuildingSocieties/7.svg`} alt="icons for Unlock Consumer Lending's Lucrative Potential " />

                                <p className='w-full xl:w-9/12 font-normal text-lg mt-5 leading-tight'>Unlock Consumer Lending's Lucrative Potential</p>
                                <div className='w-full'>
                                    <p className='mt-1'>
                                    <Speaker id="188" text="Unlock Consumer Lending's Lucrative Potential. Consumer lending offers an unprecedented opportunity to significantly
                                        boost your Building Society's profitability. Unlike traditional mortgage
                                        lending, consumer lending can achieve return-on-equity ratios above 70%,
                                        thanks to higher interest rates and lower refinancing costs. By partnering
                                        with Blukite Finance, you can tap into this potential, diversifying your
                                        portfolio to attract new market segments and enhance member benefits."/>
                                        Consumer lending offers an unprecedented opportunity to significantly
                                        boost your Building Society's profitability. Unlike traditional mortgage
                                        lending, consumer lending can achieve return-on-equity ratios above 70%,
                                        thanks to higher interest rates and lower refinancing costs. By partnering
                                        with Blukite Finance, you can tap into this potential, diversifying your
                                        portfolio to attract new market segments and enhance member benefits.</p>
                                </div>
                            </div>
                        </div>

                        <div className='customShadow rounded-3xl p-5 sm:p-8 flex-1' data-aos="fade-up-right" data-aos-duration="800">
                            <div>
                                <img src={`${basePath}/images/ico/TailoredServicesBuildingSocieties/8.svg`} alt="icons for Seamless Integration, Strategic Growth" />

                                <p className='w-full xl:w-9/12 font-normal text-lg mt-5 leading-tight'>Seamless Integration, Strategic Growth</p>
                                <div className='w-full'>
                                    <p className='mt-1'><Speaker id="189" text="Seamless Integration, Strategic Growth. Blukite Finance's technology seamlessly integrates with your current
                                        systems, facilitating expansion without disrupting your core operations.
                                        This approach enables Building Societies to explore new avenues for
                                        growth and diversification into consumer lending, promising enhanced
                                        returns and broader market engagement while maintaining operational
                                        stability and member trust. Our phased expansion strategy into consumer
                                        lending minimises risk, ensuring you can innovate confidently. Embrace
                                        our inclusive services, which promise to make financial products
                                        accessible to all, aligning with your commitment to community and
                                        societal values."/>Blukite Finance's technology seamlessly integrates with your current
                                        systems, facilitating expansion without disrupting your core operations.
                                        This approach enables Building Societies to explore new avenues for
                                        growth and diversification into consumer lending, promising enhanced
                                        returns and broader market engagement while maintaining operational
                                        stability and member trust. Our phased expansion strategy into consumer
                                        lending minimises risk, ensuring you can innovate confidently. Embrace
                                        our inclusive services, which promise to make financial products
                                        accessible to all, aligning with your commitment to community and
                                        societal values.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='customShadow rounded-3xl relative overflow-hidden flex flex-col xl:flex-row gap-0 xl:gap-5 mt-7' data-aos="fade-right" data-aos-duration="800">
                        <div className='flex-1 p-5 sm:p-8'>
                            <img src={`${basePath}/images/ico/TailoredServicesBuildingSocieties/9.svg`} alt="icons for Your Call to Action: Lead with Confidence" />
                            <p className='font-normal text-lg mt-5 leading-tight'>Your Call to Action: Lead with Confidence</p>
                            <p className='mt-1'>
                            <Speaker id="190" text="Your Call to Action: Lead with Confidence. We invite you to lead your Building Society into a profitable and inclusive
                                future. With Blukite Finance, you gain a partner equipped to navigate the
                                expansion into consumer lending, ensuring operational stability and opening
                                new avenues for growth. This partnership not only aligns with your heritage of
                                trust but also positions you to meet tomorrow's challenges head-on,
                                reinforcing your crucial role in the financial well-being of your communities."/>
                                We invite you to lead your Building Society into a profitable and inclusive
                                future. With Blukite Finance, you gain a partner equipped to navigate the
                                expansion into consumer lending, ensuring operational stability and opening
                                new avenues for growth. This partnership not only aligns with your heritage of
                                trust but also positions you to meet tomorrow's challenges head-on,
                                reinforcing your crucial role in the financial well-being of your communities.</p>
                        </div>
                        <div className='flex-1'>
                            <img className='hidden xl:block w-10/12 mx-auto' src={`${basePath}/images/TailoredServicesBuildingSocieties/28.svg`} alt="illustration for System Integration Solutions" />
                            <img className='block xl:hidden w-7/12 mx-auto mb-4' src={`${basePath}/images/TailoredServicesBuildingSocieties/29.svg`} alt="illustration for System Integration Solutions" />
                        </div>
                    </div>

                </section>


                <div>
                </div>

            </div>
        </div>
    );
}

export default StrategicTechnologyIntegrationForBuildingSocieties