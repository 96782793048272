import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';

const PaymentProcessing = () => {

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Our partners',
                        }
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Our partners',
                        }
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <h2 className='pageTitle'>Payment Processing</h2>
                    <div className='flex flex-col xl:flex-row items-center gap-10 2xl:gap-0 mb-10 sm:mb-20'>
                        <div className='flex-1 -mt-3 sm:mt-0'>
                            <p className='text'><Speaker id="66" text="Payment Processing. Blukite Finance recognises the critical importance of reliable and efficient payment processing in today's digital world. That's why we have partnered with leading providers, Clowd9 and Accomplish Financial, to bring our clients top-tier payment processing solutions. Our strategic partnerships ensure that our customers' processing requirements are met with the highest standards of robustness and redundancy.
Fully integrated with Clowd9 and Accomplish Financial, Blukite Finance offers a seamless payment experience, underscored by security, speed, and reliability. Our outsourcing model for payment processing means that we can focus on what we do best, delivering exceptional financial services, while ensuring that the backbone of your transactions is strong and unwavering."/>Blukite Finance recognises the critical importance of reliable and efficient payment
                                processing in today's digital world. That's why we have partnered with leading
                                providers, Clowd9 and Accomplish Financial, to bring our clients top-tier payment
                                processing solutions. Our strategic partnerships ensure that our customers'
                                processing requirements are met with the highest standards of robustness and
                                redundancy.</p>
                            <p className='text'>Fully integrated with Clowd9 and Accomplish Financial, Blukite Finance offers a
                                seamless payment experience, underscored by security, speed, and reliability. Our
                                outsourcing model for payment processing means that we can focus on what we do
                                best, delivering exceptional financial services, while ensuring that the backbone of
                                your transactions is strong and unwavering.</p>
                            <p className='text'><Speaker id="67" text="As part of our ongoing commitment to enhancing and expanding our financial services, Blukite Finance is actively developing capabilities to bring issuing processing in-house. This strategic move is aimed at further optimising our payment processing solutions, ensuring greater control, increased security, and even more reliable service for our clients.By transitioning to in-house issuing processing, we look forward to offering bespoke payment solutions tailored to the unique needs of our customers. This future development underscores our dedication to innovation and our continuous effort to deliver comprehensive, end-to-end financial services under one roof. Stay tuned for more updates as we pave the way for a more integrated, efficient, and secure financial ecosystem with Blukite Finance at its core."/>
                            As part of our ongoing commitment to enhancing and expanding our financial
                                services, Blukite Finance is actively developing capabilities to bring issuing
                                processing in-house. This strategic move is aimed at further optimising our payment
                                processing solutions, ensuring greater control, increased security, and even more
                                reliable service for our clients.By transitioning to in-house issuing processing, we
                                look forward to offering bespoke payment solutions tailored to the unique needs of
                                our customers. This future development underscores our dedication to innovation
                                and our continuous effort to deliver comprehensive, end-to-end financial services
                                under one roof. Stay tuned for more updates as we pave the way for a more
                                integrated, efficient, and secure financial ecosystem with Blukite Finance at its core.</p>
                        </div>
                        <div className='flex-1 h-min'>
                            <img src={`${basePath}/images/paymentProcessing.svg`} className='mx-auto' alt="illustration for payment processing" />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default PaymentProcessing