import React from 'react';
import { connect } from 'react-redux';
import { Anchor, Collapse } from 'antd';
import { basePath, baseURL } from '../../routes';
import { useNavigate } from "react-router-dom";
import { PlainButton } from '../../components/buttons/buttons';
import './regulatoryUmbrellaProgram.scss';
import { openPopup, closePopup } from '../../actions/popup';


const mapDispatchToProps = dispatch => ({
  openPopup: (payload) => {
     return dispatch(openPopup(payload));
  }
})

const RegulatoryUmbrellaFaq = ({openPopup}) => {

  let navigate = useNavigate(); 
  const faqs = [
    {
      key: '1',
      label: 'What is the Regulatory Umbrella Program?',
      children: <p>The Regulatory Umbrella Program allows fintech startups and financial service providers to operate under Blukite Finance's regulatory licenses, speeding up the process to market and ensuring compliance with financial regulations.</p>,
    },
    {
      key: '2',
      label: 'How does Blukite Finance ensure compliance with GDPR and PSD2?',
      children: <p>Blukite Finance employs advanced data protection measures, secure data handling practices, and regular audits to ensure full compliance with GDPR. For PSD2, we offer secure API interfaces and consent management tools that align with regulatory requirements.          </p>,
    },
    {
      key: '3',
      label: 'What upcoming regulatory changes is Blukite Finance preparing for?',
      children: <p>We are closely monitoring and preparing for the implementation of PSR1 and PSD3, ensuring our clients' operations remain compliant and forward-looking. Our team regularly updates our systems and processes in anticipation of these changes.          </p>,
    },
    {
      key: '4',
      label: 'Can Blukite Finance help with ISO 27001 certification?',
      children: <p>While Blukite Finance itself is built on ISO 27001 standards for information security, we guide and support our clients through the process of understanding and implementing practices that can help them achieve or maintain their ISO 27001 certification.</p>,
    },
    {
      key: '5',
      label: 'What kind of support does Blukite Finance offer for navigating regulatory compliance?',
      children: <p>We offer a dedicated compliance support team, tailored training sessions, detailed documentation, and a suite of technology solutions designed to simplify compliance management.</p>,
    },
    {
      key: '6',
      label: "How does technology drive Blukite Finance's compliance solutions?",
      children: <p>Our compliance solutions leverage AI and machine learning for risk identification and automated reporting, secure APIs for data sharing, and encryption for data security, enhancing operational efficiency and compliance accuracy.
      </p>,
    },
    {
      key: '7',
      label: 'How does the appointment as an Appointed Representative work?',
      children: <p>Clients can become Appointed Representatives under our Consumer Credit License, allowing them to offer credit products under our regulatory oversight. We handle compliance monitoring, reporting, and provide comprehensive support.</p>,
    },
    {
      key: '8',
      label: "What is the process for becoming a PSD Agent under Blukite's license?",
      children: <p>As a PSD Agent, clients can offer payment services using our Payment Institution license. We guide clients through the onboarding process, ensuring they meet all regulatory requirements for operation.</p>,
    },
    {
      key: '9',
      label: 'Are there tailored compliance solutions for different market needs?',
      children: <p>Yes, Blukite Finance offers bespoke compliance solutions tailored to the specific needs of our clients, considering their market, the nature of their services, and the regulatory landscape they operate in.</p>,
    },
    {
      key: '10',
      label: 'How can I stay updated on regulatory changes affecting my business?',
      children: <p>Blukite Finance provides regular updates, newsletters, and webinars focused on regulatory changes and compliance best practices, ensuring our clients are always informed and prepared.
      </p>,
    },
  ];

  return (
    <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage regulatoryUmbrellaProgram">
      <div className="hidden sm:block sidebar">
        <Anchor
          targetOffset={350}
          items={[
            {
              key: 'section1',
              href: '#section1',
              title: 'Empowering Compliance'
            },
            {
              key: 'section2',
              href: '#section2',
              title: 'FAQ',
            }
          ]}
        />
      </div>

      <div className='block sm:hidden sidebar'>
        <Anchor
          direction="horizontal"
          targetOffset={150}
          items={[
            {
              key: 'section1',
              href: '#section1',
              title: 'Empowering Compliance'
            },
            {
              key: 'section2',
              href: '#section2',
              title: 'FAQ',
            }
          ]}
        />
      </div>

      <div className="content faq">
        <section id="section1">
          <h2 className='pageTitle'>Empower Your Compliance Journey with Blukite Finance</h2>

          <p className='text'>Ready to navigate the complexities of regulatory compliance with ease and confidence? Blukite Finance invites you to explore our comprehensive suite of regulatory compliance
            solutions. Whether you are looking to stay ahead of current regulations or prepare for future changes, our dedicated team and state-of-the-art technology are here to support you
            every step of the way.</p>

          <div className='bg-lightBlue flex flex-col sm:flex-row my-10'>
            <img className='w-full sm:w-4/12 md:w-5/12 lg:w-3/12 object-cover' src={`${basePath}images/faq_moreInfoSection.png`} alt="icon for section: Downloadable Resources" />
            <div className='p-5 sm:p-10 flex-1'>
              <p className='font-normal text-lg'>Learn More</p>
              <p className='text'>Dive deeper into our regulatory compliance solutions and discover how we can tailor our services to meet
                your unique needs. Our expertise is your advantage in the ever-evolving world of financial regulations.</p>
              <div className='mt-5 w-full sm:w-fit font-normal'>
                <PlainButton onClick={() => navigate(`${baseURL}/integrated_compliance_solutions`)} text="More info" />
              </div>
            </div>
          </div>

          <div className='bg-lightBlue flex flex-col-reverse sm:flex-row my-10'>
            <div className='p-5 sm:p-10 flex-1'>
              <p className='font-normal text-lg'>Book a Consultation</p>
              <p className='text'>Connect with our experts for a personalised consultation. Discuss your specific compliance challenges and
                learn how Blukite Finance can help streamline your processes and ensure regulatory adherence.</p>
              <div className='mt-5 w-full sm:w-fit font-normal'>
                <PlainButton onClick={() => openPopup({currentPopup: 'BookConsultation'})} text="Book a consultation" />
              </div>
            </div>
            <img className='w-full sm:w-4/12 md:w-5/12 lg:w-3/12 object-cover' src={`${basePath}images/faq_bookConsultationSection.png`} alt="icon for section: Downloadable Resources" />
          </div>

          <p className='subtitle'>Enhance Your Compliance Strategy with<span className='font-medium'> Interactive Resources</span></p>
          <p className='text'>At Blukite Finance, we are committed to simplifying compliance, reducing risk, and enhancing operational efficiency for our clients. Let us be your partner in compliance,
            empowering you to focus on what you do best: driving growth and innovation in your financial services.</p>

          <div className='flex gap-10 lg:gap-20 my-10 sm:my-14 flex-wrap'>
            <div className='flex-1 min-w-44 sm:min-w-32'>
              <img className='w-12' src={`${basePath}images/ico/faq_1.svg`} alt="icon for section: FAQ Section" />
              <p className='mt-3 font-medium'>FAQ Section</p>
              <p className='mt-2'>Have questions? Our expandable FAQ section addresses common compliance queries, offering clear, concise answers to help you navigate your compliance journey.</p>
            </div>

            <div className='flex-1 min-w-44 sm:min-w-32'>
              <img className='w-12' src={`${basePath}images/ico/faq_2.svg`} alt="icon for section: Downloadable Resources" />
              <p className='mt-3 font-medium'>Downloadable Resources</p>
              <p className='mt-2'>Explore our downloadable whitepapers, case studies, and regulatory checklists which are designed to enhance your compliance knowledge and best practices.</p>
            </div>

            <div className='flex-1 min-w-44 m:min-w-32'>
              <img className='w-12' src={`${basePath}images/ico/faq_3.svg`} alt="icon for section: Interactive Timeline" />
              <p className='mt-3 font-medium'>Interactive Timeline</p>
              <p className='mt-2'>Stay informed with our interactive timeline detailing upcoming regulatory changes and how Blukite Finance's solutions are evolving to meet these new requirements.</p>
            </div>

            <div className='flex-1 min-w-44 sm:min-w-32'>
              <img className='w-12' src={`${basePath}images/ico/faq_4.svg`} alt="icon for section: Specialised Contact Form" />
              <p className='mt-3 font-medium'>Specialised Contact Form</p>
              <p className='mt-2'>Use our specialised contact form for targeted inquiries or discussing specific compliance needs. Our team is prepared to offer the insights and support you require for success.</p>
            </div>
          </div>
        </section>

            <section id="section2" className='py-2 md:py-5'>
              <h2 className='pageTitle'>FAQ – Blukite’s Regulatory Compliance Services</h2>

              <div className='my-7'>
                <Collapse items={faqs} defaultActiveKey={['1']} />
              </div>
        </section>
      </div>
    </div>
  );
}

export default connect(
  null,
  mapDispatchToProps
)(RegulatoryUmbrellaFaq);