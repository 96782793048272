import React, { useEffect, useState } from 'react';
import { Anchor, Select } from 'antd';
import { basePath } from '../../routes';
import { useLocation } from 'react-router-dom';
import Speaker from '../../components/speaker/speaker';
import './coreSolutions.scss';

const GridComponent = () => {
    const [expandedCard, setExpandedCard] = useState(null);

    const toggleCard = (id) => {
        if (expandedCard === id) {
            setExpandedCard(null);
        } else {
            setExpandedCard(id);
        }
    };

    const keyFeaturesItems = [
        {
            id: 1, title: 'Rapid Market Entry', desc: 'Accelerate your product launch with a platform designed for speed and efficiency.',
            text:
                <div>
                    <p className='text'>In today's fast-paced financial landscape, the ability to quickly bring new products to market can be a decisive competitive advantage. BluSys Core Solution is engineered with this principle at its core, offering financial institutions a streamlined pathway to rapid product launch and market penetration.</p>
                    <p className='text'>Key Advantages Include:</p>
                    <p className='mt-1.5'><span className='font-medium'>• Streamlined Development Process: </span>Leveraging our advanced platform, institutions can bypass the traditional complexities and delays associated with product development. BluSys simplifies the creation and deployment of new financial products through intuitive design tools and automated workflows.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Pre-Configured Templates: </span>To further expedite market entry, BluSys provides a variety of pre-configured product templates. These templates are based on industry best practices and can be customised to suit specific market needs, dramatically reducing the time from concept to launch.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>•  Regulatory Compliance: </span> One of the most time-consuming aspects of launching new financial products is ensuring regulatory compliance. BluSys comes pre-equipped with compliance mechanisms for current standards, including Open Banking and PSD2, and is adaptable to future regulations, removing a significant barrier to rapid market entry.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Integration Capabilities:  </span>Our solution's versatile integration capabilities mean that it can easily connect with existing banking systems, CRMs, and third-party services. This ensures that new products can be seamlessly introduced into the current ecosystem without disrupting existing operations.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Cloud-Based Infrastructure:  </span>By utilising a cloud-based infrastructure, BluSys facilitates not just swift deployment but also scalability and reliability. Financial institutions can scale their offerings according to demand without concerns about infrastructure limitations or downtime.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>Empowering Innovation: </span></p>
                    <p>By removing traditional obstacles to product development and launch, BluSys Core Solution empowers banks and financial institutions to innovate freely, respond to market demands with agility, and seize opportunities as they arise. Accelerate your journey to market leadership with BluSys, where efficiency meets excellence.</p>
                </div>,
                textSpeaker: `Rapid Market Entry.

                In today's fast-paced financial landscape, the ability to quickly bring new products to market can be a decisive competitive advantage. BluSys Core Solution is engineered with this principle at its core, offering financial institutions a streamlined pathway to rapid product launch and market penetration.
                
                Key Advantages Include:
                
                • Streamlined Development Process: Leveraging our advanced platform, institutions can bypass the traditional complexities and delays associated with product development. BluSys simplifies the creation and deployment of new financial products through intuitive design tools and automated workflows.
                
                • Pre-Configured Templates: To further expedite market entry, BluSys provides a variety of pre-configured product templates. These templates are based on industry best practices and can be customised to suit specific market needs, dramatically reducing the time from concept to launch.
                
                • Regulatory Compliance: One of the most time-consuming aspects of launching new financial products is ensuring regulatory compliance. BluSys comes pre-equipped with compliance mechanisms for current standards, including Open Banking and PSD2, and is adaptable to future regulations, removing a significant barrier to rapid market entry.
                
                • Integration Capabilities: Our solution's versatile integration capabilities mean that it can easily connect with existing banking systems, CRMs, and third-party services. This ensures that new products can be seamlessly introduced into the current ecosystem without disrupting existing operations.
                
                • Cloud-Based Infrastructure: By utilising a cloud-based infrastructure, BluSys facilitates not just swift deployment but also scalability and reliability. Financial institutions can scale their offerings according to demand without concerns about infrastructure limitations or downtime.
                
                Empowering Innovation:
                
                By removing traditional obstacles to product development and launch, BluSys Core Solution empowers banks and financial institutions to innovate freely, respond to market demands with agility, and seize opportunities as they arise. Accelerate your journey to market leadership with BluSys, where efficiency meets excellence.`
        },
        {
            id: 2, title: 'Adaptable Product Design', desc: 'Craft lending products that meet the exact needs of your market with our flexible design tools.',
            text:
                <div>
                    <p className='text'>Blukite Finance's BluSys Core Solution introduces unparalleled flexibility in financial product design, enabling institutions to tailor lending products precisely to the needs of their market. Our platform empowers you to innovate and adapt, ensuring your offerings remain relevant and competitive.</p>
                    <p className='text'>Features and Benefits:</p>
                    <p className='mt-1.5'><span className='font-medium'>• Comprehensive Design Tools: </span>Utilise our suite of flexible design tools that allow for the creation of a diverse range of lending products. From consumer loans to complex business financing solutions, our platform supports your creative vision.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Customisable Loan Features: </span>With BluSys, every aspect of your loan products can be customised. Adjust interest rates, repayment schedules, loan terms, and more to create products that align perfectly with your customers' expectations and financial realities.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>•  Responsive Product Adjustments: </span>Market needs evolve, and so should your products. Our platform enables quick adjustments to existing products, allowing you  to respond to changing market demands or regulatory environments efficiently.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Innovative Financial Solutions: </span>Beyond traditional loans, explore the creation of novel financial products. Whether it is integrating loyalty programs or offering multi-currency options, BluSys supports your drive for innovation.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• User-Centric Approach: </span>Design with the end-user in mind. Our tools support the development of user-friendly interfaces and processes, enhancing customer satisfaction and engagement.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>Empowering Your Vision: </span></p>
                    <p>Adaptable product design is more than a feature; it is a philosophy that underpins the BluSys Core Solution. We believe that the best financial products are those that are conceived with flexibility, designed with precision, and delivered with the customer at heart. Embrace the power to craft lending products that not only meet but exceed the exact needs of your market.</p>
                </div>,
                  textSpeaker: `Adaptable Product Design.

                  Blukite Finance's BluSys Core Solution introduces unparalleled flexibility in financial product design, enabling institutions to tailor lending products precisely to the needs of their market. Our platform empowers you to innovate and adapt, ensuring your offerings remain relevant and competitive.
                  
                  Features and Benefits:
                  
                  • Comprehensive Design Tools: Utilise our suite of flexible design tools that allow for the creation of a diverse range of lending products. From consumer loans to complex business financing solutions, our platform supports your creative vision.
                  
                  • Customisable Loan Features: With BluSys, every aspect of your loan products can be customised. Adjust interest rates, repayment schedules, loan terms, and more to create products that align perfectly with your customers' expectations and financial realities.
                  
                  • Responsive Product Adjustments: Market needs evolve, and so should your products. Our platform enables quick adjustments to existing products, allowing you to respond to changing market demands or regulatory environments efficiently.
                  
                  • Innovative Financial Solutions: Beyond traditional loans, explore the creation of novel financial products. Whether it is integrating loyalty programs or offering multi-currency options, BluSys supports your drive for innovation.
                  
                  • User-Centric Approach: Design with the end-user in mind. Our tools support the development of user-friendly interfaces and processes, enhancing customer satisfaction and engagement.
                  
                  Empowering Your Vision:
                  
                  Adaptable product design is more than a feature; it is a philosophy that underpins the BluSys Core Solution. We believe that the best financial products are those that are conceived with flexibility, designed with precision, and delivered with the customer at heart. Embrace the power to craft lending products that not only meet but exceed the exact needs of your market.`,
        },

        {
            id: 3, title: 'Bespoke Product Adjustments', desc: 'Fine-tune products to perfection with customisable rates, terms, and rewards.', text:

                <div>
                    <p className='text'>The BluSys Core Solution elevates the concept of financial product customisation, offering unparalleled flexibility to tailor your offerings down to the finest details. Our platform empowers financial institutions to fine-tune their products, ensuring they align perfectly with both market demands and individual consumer needs.</p>
                    <p className='text'>Customisation Features:</p>
                    <p className='mt-1.5'><span className='font-medium'>• Adjustable Rates: </span>Utilise our suite of flexible design tools that allow for the creation of a diverse range of lending products. From consumer loans to complex business financing solutions, our platform supports your creative vision.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Flexible Terms: </span>Whether short-term loans or long-duration financing, adjust the loan terms to fit the specific needs of different consumer groups or business models.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Reward Integration: </span>Enhance customer loyalty and retention by integrating rewards and bonus programs directly into your product offerings. Customise these incentives to encourage desired customer behaviours and increase product uptake.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Segmentation Tools: </span>Utilise advanced segmentation tools to create bespoke adjustments for different customer demographics, geographic locations, or even behavioural patterns, ensuring your products serve the right purpose for the right audience.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• User-The Impact of Customisation: </span>Customisable rates, terms, and rewards are not just features; they represent a new paradigm in financial service provision, one where products are as unique as the customers they serve. This level of customisation ensures that your institution can not only meet but anticipate the needs of your market, fostering deeper relationships and driving growth.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>Empowering Financial Innovation: </span></p>
                    <p>Bespoke product adjustments through the BluSys Core Solution signify our commitment to innovation and excellence in financial services. By offering these sophisticated customisation capabilities, we empower our clients to not only compete but lead in their respective markets.</p>
                </div>,
                  textSpeaker: `Bespoke Product Adjustments.

                  The BluSys Core Solution elevates the concept of financial product customisation, offering unparalleled flexibility to tailor your offerings down to the finest details. Our platform empowers financial institutions to fine-tune their products, ensuring they align perfectly with both market demands and individual consumer needs.
                  
                  Customisation Features:
                  
                  • Adjustable Rates: Utilise our suite of flexible design tools that allow for the creation of a diverse range of lending products. From consumer loans to complex business financing solutions, our platform supports your creative vision.
                  
                  • Flexible Terms: Whether short-term loans or long-duration financing, adjust the loan terms to fit the specific needs of different consumer groups or business models.
                  
                  • Reward Integration: Enhance customer loyalty and retention by integrating rewards and bonus programs directly into your product offerings. Customise these incentives to encourage desired customer behaviours and increase product uptake.
                  
                  • Segmentation Tools: Utilise advanced segmentation tools to create bespoke adjustments for different customer demographics, geographic locations, or even behavioural patterns, ensuring your products serve the right purpose for the right audience.
                  
                  • User-The Impact of Customisation: Customisable rates, terms, and rewards are not just features; they represent a new paradigm in financial service provision, one where products are as unique as the customers they serve. This level of customisation ensures that your institution can not only meet but anticipate the needs of your market, fostering deeper relationships and driving growth.
                  
                  Empowering Financial Innovation:
                  
                  Bespoke product adjustments through the BluSys Core Solution signify our commitment to innovation and excellence in financial services. By offering these sophisticated customisation capabilities, we empower our clients to not only compete but lead in their respective markets.`,
        },

        {
            id: 4, title: 'Versatile Integration', desc: 'Seamlessly connect with existing systems and third-party services for a unified operation.', text:
                <div>
                    <p className='text'>BluSys Core Solution by Blukite Finance is designed with versatility at its core, offering seamless integration capabilities that bridge your financial operations with existing systems and third-party services. Our platform ensures a cohesive and unified operation, enhancing efficiency and user experience.</p>
                    <p className='text'>Integration Capabilities:</p>
                    <p className='mt-1.5'><span className='font-medium'>• Wide-Ranging Compatibility: </span>Whether integrating with legacy banking systems, modern CRMs, or cutting-edge fintech services, BluSys provides the adaptability you need to create a connected financial ecosystem.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Plug-and-Play Simplicity: </span>Our integration process is streamlined to minimise disruption and technical challenges, enabling a plug-and-play solution that connects your operations quickly and efficiently.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• API-First Design: </span> BluSys employs an API-first approach, ensuring that all system functionalities can be accessed and integrated through well-documented and secure APIs, facilitating easy communication between different software platforms.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Custom Integration Support: </span>Understanding that every financial institution has unique needs, BluSys offers custom integration support to address specific challenges and requirements, ensuring a perfect fit within your existing technological framework.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Enhanced Data Flow: </span>With BluSys, data silos become outdated. Our solution enhances the flow of information between systems, improving accuracy, reducing redundancy, and enabling real-time insights into financial operations.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>Empowering Efficiency and Innovation:</span></p>
                    <p>The versatile integration capability of the BluSys Core Solution is more than just a technical feature, it is a strategic enabler for financial institutions looking to innovate and grow in a competitive landscape. By facilitating smooth interoperability between diverse systems and services, we empower our clients to leverage their existing investments while embracing new technologies and capabilities.</p>
                </div>,
                  textSpeaker: `Versatile Integration.

                  BluSys Core Solution by Blukite Finance is designed with versatility at its core, offering seamless integration capabilities that bridge your financial operations with existing systems and third-party services. Our platform ensures a cohesive and unified operation, enhancing efficiency and user experience.
                  
                  Integration Capabilities:
                  
                  • Wide-Ranging Compatibility: Whether integrating with legacy banking systems, modern CRMs, or cutting-edge fintech services, BluSys provides the adaptability you need to create a connected financial ecosystem.
                  
                  • Plug-and-Play Simplicity: Our integration process is streamlined to minimise disruption and technical challenges, enabling a plug-and-play solution that connects your operations quickly and efficiently.
                  
                  • API-First Design: BluSys employs an API-first approach, ensuring that all system functionalities can be accessed and integrated through well-documented and secure APIs, facilitating easy communication between different software platforms.
                  
                  • Custom Integration Support: Understanding that every financial institution has unique needs, BluSys offers custom integration support to address specific challenges and requirements, ensuring a perfect fit within your existing technological framework.
                  
                  • Enhanced Data Flow: With BluSys, data silos become outdated. Our solution enhances the flow of information between systems, improving accuracy, reducing redundancy, and enabling real-time insights into financial operations.
                  
                  Empowering Efficiency and Innovation:
                  
                  The versatile integration capability of the BluSys Core Solution is more than just a technical feature, it is a strategic enabler for financial institutions looking to innovate and grow in a competitive landscape. By facilitating smooth interoperability between diverse systems and services, we empower our clients to leverage their existing investments while embracing new technologies and capabilities.`,
        },
        {
            id: 5, title: 'Compliance with OB and PSD2', desc: 'Stay ahead of regulatory changes and leverage new opportunities with our compliant solutions.', text:
                <div>
                    <p className='text'>In an era of rapid regulatory evolution, Blukite Finance stands as your stalwart ally, ensuring that your financial services not only comply with today's regulations but are also primed for tomorrow's changes. Our commitment to compliance with Open Banking and PSD2 is unwavering, as these frameworks open new avenues for innovation and customer empowerment.</p>
                    <p className='text'>Navigating the Regulatory Landscape:</p>
                    <p className='mt-1.5'><span className='font-medium'>• Comprehensive Compliance: </span>Blukite is meticulously engineered to adhere to the stringent requirements of Open Banking and PSD2, ensuring your operations are fully compliant and secure
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Future-Ready Framework: </span>Beyond current standards, our platform is designed with an eye on the future, actively preparing for anticipated updates and new regulations such as PSR1 and PSD3.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Leveraging Opportunities: </span> Compliance is not just about meeting requirements but also about seizing opportunities. Blukite enables you to leverage the new data-sharing and payment initiation services facilitated by these regulations, unlocking innovative product and service offerings.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Streamlined Adaptation: </span>Our solution simplifies the complexity of regulatory compliance, providing tools and features that streamline the adaptation process. With BluSys, staying compliant does not have to be a burden.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>Building Trust and Opening Doors:</span></p>
                    <p>Adherence to Open Banking and PSD2 is not just a regulatory necessity it is a cornerstone of building customer trust and competitive advantage. By ensuring your services are compliant, secure, and innovative, Blukite opens the door to new business models and deeper customer relationships.</p>
                    <p className='mt-1.5'><span className='font-medium'>Future-Ready Compliance:</span></p>
                    <p>Dive deeper into how Blukite prepares for regulatory shifts with our "Future-Ready Compliance" feature. Learn about our proactive approach to staying ahead of the curve, safeguarding your operations, and enhancing consumer trust in an ever-evolving regulatory landscape.</p>
                </div>,
                  textSpeaker: `Compliance with OB and PSD2.

                  In an era of rapid regulatory evolution, Blukite Finance stands as your stalwart ally, ensuring that your financial services not only comply with today's regulations but are also primed for tomorrow's changes. Our commitment to compliance with Open Banking and PSD2 is unwavering, as these frameworks open new avenues for innovation and customer empowerment.
                  
                  Navigating the Regulatory Landscape:
                  
                  • Comprehensive Compliance: Blukite is meticulously engineered to adhere to the stringent requirements of Open Banking and PSD2, ensuring your operations are fully compliant and secure
                  
                  • Future-Ready Framework: Beyond current standards, our platform is designed with an eye on the future, actively preparing for anticipated updates and new regulations such as PSR1 and PSD3.
                  
                  • Leveraging Opportunities: Compliance is not just about meeting requirements but also about seizing opportunities. Blukite enables you to leverage the new data-sharing and payment initiation services facilitated by these regulations, unlocking innovative product and service offerings.
                  
                  • Streamlined Adaptation: Our solution simplifies the complexity of regulatory compliance, providing tools and features that streamline the adaptation process. With BluSys, staying compliant does not have to be a burden.
                  
                  Building Trust and Opening Doors:
                  
                  Adherence to Open Banking and PSD2 is not just a regulatory necessity it is a cornerstone of building customer trust and competitive advantage. By ensuring your services are compliant, secure, and innovative, Blukite opens the door to new business models and deeper customer relationships.
                  
                  Future-Ready Compliance:
                  
                  Dive deeper into how Blukite prepares for regulatory shifts with our "Future-Ready Compliance" feature. Learn about our proactive approach to staying ahead of the curve, safeguarding your operations, and enhancing consumer trust in an ever-evolving regulatory landscape.`,
        },
        {
            id: 6, title: 'Uncompromised Security', desc: 'Protect your operations and your customers with top-tier security standards.', text:
                <div>
                    <p className='text'>In the digital age, security is paramount for financial institutions. The BluSys Core Solution by Blukite Finance is built on the foundation of uncompromised security, employing top-tier standards to protect your operations and, importantly, your customers. Our commitment to security is not just a feature it is an integral part of our identity.</p>
                    <p className='text'>Core Security Measures:</p>
                    <p className='mt-1.5'><span className='font-medium'>• End-to-End Encryption: </span>Ensuring that all data, both in transit and at rest, is encrypted with the latest cryptographic standards, safeguarding against unauthorised access and data breaches.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Multi-Factor Authentication (MFA): </span>Implementing MFA to add an extra layer of security for user access, significantly reducing the risk of unauthorised account use.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Continuous Monitoring and Threat Detection: </span>Leveraging advanced monitoring tools and AI-driven threat detection systems to identify and neutralise threats in real-time before they can impact your business.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Regular Security Audits: </span> Conducting comprehensive audits and penetration testing to rigorously evaluate and enhance the security posture of our platform.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Compliance with PCI DSS and GDPR: </span>Adhering strictly to the Payment Card Industry Data Security Standard (PCI DSS) and General Data Protection Regulation (GDPR), ensuring that our platform meets global security and privacy requirements.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>A Commitment to Trust:</span></p>
                    <p>At Blukite Finance, we understand that trust is the cornerstone of the financial services industry. That is why our BluSys Core Solution embodies a security-first approach, ensuring that every transaction and customer interaction is protected by the highest security standards. Our unwavering commitment to security empowers you to focus on what you do best: serving your customers and growing your business, secure in the knowledge that your operations are safeguarded at every turn.</p>
                </div>,
                  textSpeaker: `Uncompromised Security.

                  In the digital age, security is paramount for financial institutions. The BluSys Core Solution by Blukite Finance is built on the foundation of uncompromised security, employing top-tier standards to protect your operations and, importantly, your customers. Our commitment to security is not just a feature it is an integral part of our identity.
                  
                  Core Security Measures:
                  
                  • End-to-End Encryption: Ensuring that all data, both in transit and at rest, is encrypted with the latest cryptographic standards, safeguarding against unauthorised access and data breaches.
                  
                  • Multi-Factor Authentication (MFA): Implementing MFA to add an extra layer of security for user access, significantly reducing the risk of unauthorised account use.
                  
                  • Continuous Monitoring and Threat Detection: Leveraging advanced monitoring tools and AI-driven threat detection systems to identify and neutralise threats in real-time before they can impact your business.
                  
                  • Regular Security Audits: Conducting comprehensive audits and penetration testing to rigorously evaluate and enhance the security posture of our platform.
                  
                  • Compliance with PCI DSS and GDPR: Adhering strictly to the Payment Card Industry Data Security Standard (PCI DSS) and General Data Protection Regulation (GDPR), ensuring that our platform meets global security and privacy requirements.
                  
                  A Commitment to Trust:
                  
                  At Blukite Finance, we understand that trust is the cornerstone of the financial services industry. That is why our BluSys Core Solution embodies a security-first approach, ensuring that every transaction and customer interaction is protected by the highest security standards. Our unwavering commitment to security empowers you to focus on what you do best: serving your customers and growing your business, secure in the knowledge that your operations are safeguarded at every turn.`,
        },
        {
            id: 7, title: 'On-premises or Cloud Deployment', desc: 'Choose the deployment method that suits your needs, with options for both on-premises and cloud.', text:
                <div>
                    <p className='text'>Blukite Finance's BluSys Core Solution offers unparalleled flexibility in deployment, catering to the unique needs and preferences of every financial institution. Understanding that one size does not fit all in the realm of financial services, we provide both on-premises and cloud deployment options, allowing you to choose the path that best aligns with your operational strategies and security requirements.</p>
                    <p className='text'>Deployment Options Explained:</p>
                    <p className='mt-1.5'><span className='font-medium'>• On-Premises Deployment: </span>For institutions prioritising complete control over their IT environment and data, our on-premises deployment offers the security and autonomy of hosting the BluSys platform within your own infrastructure. This option is ideal for organizations with specific compliance or regulatory needs that dictate data residency.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Cloud Deployment: </span>Embrace the scalability, flexibility, and cost-efficiency of cloud-based deployment with BluSys. Our cloud option reduces the need for extensive on-site hardware and maintenance, offering a streamlined, secure, and resilient infrastructure that grows with your business.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>Key Benefits:</span></p>
                    <p className='mt-1.5'><span className='font-medium'>• Tailored to Your Needs: </span>Whether you prefer the control of on-premises deployment or the agility of cloud services, BluSys adapts to your business model and operational requirements.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Scalability: </span>Easily scale your operations up or down with cloud deployment, or maintain a steady, controlled expansion with on-premises solutions.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Security and Compliance: </span>Both deployment options are designed with uncompromised security measures, ensuring your data is protected according to the highest industry standards.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Cost Efficiency: </span>Cloud deployment can significantly reduce upfront and ongoing IT costs, while on-premises deployment offers predictable, fixed costs associated with maintaining your own infrastructure.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>Empowering Your Choice:</span></p>
                    <p>At Blukite Finance, we believe in empowering our clients with choices that reflect their operational needs and future visions. The BluSys Core Solution’s flexible deployment options underscore our commitment to providing solutions that are not just powerful and secure but also adaptable to the diverse landscapes of financial institutions worldwide.</p>
                </div>,
                  textSpeaker: `On-premises or Cloud Deployment.

                  Blukite Finance's BluSys Core Solution offers unparalleled flexibility in deployment, catering to the unique needs and preferences of every financial institution. Understanding that one size does not fit all in the realm of financial services, we provide both on-premises and cloud deployment options, allowing you to choose the path that best aligns with your operational strategies and security requirements.
                  
                  Deployment Options Explained:
                  
                  • On-Premises Deployment: For institutions prioritising complete control over their IT environment and data, our on-premises deployment offers the security and autonomy of hosting the BluSys platform within your own infrastructure. This option is ideal for organizations with specific compliance or regulatory needs that dictate data residency.
                  
                  • Cloud Deployment: Embrace the scalability, flexibility, and cost-efficiency of cloud-based deployment with BluSys. Our cloud option reduces the need for extensive on-site hardware and maintenance, offering a streamlined, secure, and resilient infrastructure that grows with your business.
                  
                  Key Benefits:
                  
                  • Tailored to Your Needs: Whether you prefer the control of on-premises deployment or the agility of cloud services, BluSys adapts to your business model and operational requirements.
                  
                  • Scalability: Easily scale your operations up or down with cloud deployment, or maintain a steady, controlled expansion with on-premises solutions.
                  
                  • Security and Compliance: Both deployment options are designed with uncompromised security measures, ensuring your data is protected according to the highest industry standards.
                  
                  • Cost Efficiency: Cloud deployment can significantly reduce upfront and ongoing IT costs, while on-premises deployment offers predictable, fixed costs associated with maintaining your own infrastructure.
                  
                  Empowering Your Choice:
                  
                  At Blukite Finance, we believe in empowering our clients with choices that reflect their operational needs and future visions. The BluSys Core Solution’s flexible deployment options underscore our commitment to providing solutions that are not just powerful and secure but also adaptable to the diverse landscapes of financial institutions worldwide.`,
        },
        {
            id: 8, title: 'Future-Ready Compliance', desc: 'We stay ahead of regulatory changes and prepare for PSR1 and PSD3.', text:
                <div>
                    <p className='text'>At Blukite Finance, we understand the importance of not just keeping pace with regulatory changes but staying ahead of them. Our "Future-Ready Compliance" initiative is a testament to this commitment, especially as we prepare for the implications of PSR1 and PSD3.</p>
                    <p className='text'>Proactive Measures for Seamless Adherence:</p>
                    <p className='mt-1.5'><span className='font-medium'>• Regulatory Monitoring: </span>Our team continuously monitors the evolving regulatory landscape, ensuring that BluSys Core Solution is not only compliant today but also ready for tomorrow's requirements.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• System Upgrades and Testing: </span>We proactively implement system upgrades and conduct rigorous testing scenarios to align with PSR1 and PSD3 mandates well ahead of their effective dates.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Stakeholder Engagement: </span>By engaging with regulators, financial institutions, and technology partners, we gain insights and feedback that shape our compliance strategies, ensuring they are robust and comprehensive.
                    </p>
                    <p className='mt-1.5'><span className='font-medium'>• Training and Support: </span>We equip our clients and their teams with the knowledge and tools necessary to navigate these regulatory changes confidently, offering workshops, documentation, and dedicated support.
                    </p>
                </div>,
                  textSpeaker: `Future-Ready Compliance.

                  At Blukite Finance, we understand the importance of not just keeping pace with regulatory changes but staying ahead of them. Our "Future-Ready Compliance" initiative is a testament to this commitment, especially as we prepare for the implications of PSR1 and PSD3.
                  
                  Proactive Measures for Seamless Adherence:
                  
                  • Regulatory Monitoring: Our team continuously monitors the evolving regulatory landscape, ensuring that BluSys Core Solution is not only compliant today but also ready for tomorrow's requirements.
                  
                  • System Upgrades and Testing: We proactively implement system upgrades and conduct rigorous testing scenarios to align with PSR1 and PSD3 mandates well ahead of their effective dates.
                  
                  • Stakeholder Engagement: By engaging with regulators, financial institutions, and technology partners, we gain insights and feedback that shape our compliance strategies, ensuring they are robust and comprehensive.
                  
                  • Training and Support: We equip our clients and their teams with the knowledge and tools necessary to navigate these regulatory changes confidently, offering workshops, documentation, and dedicated support.`,
        },
    ]

    return (
        <main className='mt-10'>
            <ul className="coreSolutions__keyFeaturesItems">
                {keyFeaturesItems.map(x => {
                    const isExpanded = expandedCard === x.id;
                    return (
                        <React.Fragment key={x.id}>
                            <li className={isExpanded ? 'coreSolutions__keyFeaturesItem--active' : ''} onClick={() => toggleCard(x.id)}>
                                <img className='w-16' src={`${basePath}images/ico/keyFeatureItem_${x.id}.svg`} alt={`icon for ${x.title}`} />
                                <p className='mt-3 text-lg font-normal'>{x.title}</p>
                                <p className='mt-3'>{x.desc}</p>
                                <button
                                    type="button"
                                    onClick={() => toggleCard(x.id)}
                                    data-quick-view
                                    className='text-blue font-medium  mt-auto flex items-center -ml-5'
                                >
                                    Read more
                                    <img className='w-4 ml-3 coreSolutions__keyFeaturesReadMoreArrow' src={`${basePath}images/ico/keyFeatureArrow.svg`} alt="Arrow icon" />
                                </button>

                            </li>
                            {isExpanded && (
                                <div className={`coreSolutions__keyFeaturesItem--fullwidth coreSolutions__keyFeaturesItem--activeExpanded`}>
                                    <div className='flex justify-between'>
                                        <img className='w-16' src={`${basePath}images/ico/keyFeatureItem_${x.id}.svg`} alt={`icon for ${x.title}`} />
                                        <img className='w-16 ml-3 cursor-pointer' onClick={() => toggleCard(x.id)} src={`${basePath}images/ico/keyFeatureCloseArrow.svg`} alt="Arrow icon" />
                                    </div>
                                    <p className='mt-3 text-lg font-normal'>{x.title}</p>
                                    <p><Speaker id={28 + x.id} text={x.textSpeaker}/> {x.text}</p>
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
            </ul>
        </main>
    );
};


const CoreSolutions = () => {

    const location = useLocation();
    const [animate, setAnimate] = useState(false);
    const [activeSlide, setActiveSlide] = useState(1);
    const [keyServicesSlides, setKeyServicesSlides] = useState([
        { id: 1, title: "Revolving credit", text: "BluSys Core Solution brings unparalleled flexibility to credit card operations, allowing financial institutions to offer consumers adaptable credit limits that adjust to their spending and repayment behaviours. This dynamic approach ensures customers can manage their finances more effectively, enhancing satisfaction and loyalty." },
        { id: 2, title: "Instalment Loans", text: "Our platform excels in structuring instalment loans with both fixed and flexible repayment schedules. Special features, such as balloon payments and unbalanced loans, allow for customised repayment terms, catering to the unique financial situations of borrowers. This flexibility aids in providing tailored financial solutions that meet individual customer needs." },
        { id: 3, title: "Debit Overdraft", text: "BluSys extends the functionality of traditional banking by offering debit overdraft services, granting customers the ability to overdraw their accounts up to a pre-defined limit. This service is instrumental in providing a safety net for users, ensuring they have access to funds during unexpected financial shortfalls." },
        { id: 4, title: "Multi-Currency", text: "Recognising the global nature of today’s financial landscape, our solution facilitates transactions in multiple currencies, making it an ideal choice for institutions with international operations. This capability simplifies currency management for both the provider and the end-user, enhancing transaction efficiency across borders." },
        { id: 5, title: "Loyalty & Bonus", text: "With BluSys, institutions can easily implement and manage loyalty and bonus programs, incentivising continued service usage. These programs are designed to boost customer retention by rewarding users for their loyalty, fostering a positive and long-lasting customer relationship." },
        { id: 6, title: "Accounts", text: "BluSys Core Solution is designed with the foresight to address accounts that may fall into financial difficulty. Our platform provides mechanisms for proactively managing such situations, ensuring that accounts receive the necessary support and adjustments to navigate through challenging times without immediately resorting to stringent measures like account quarantine. This approach not only aids in customer retention but also aligns with ethical banking practices and regulatory guidelines." },
    ])
    const activeSlideData = keyServicesSlides.find(slide => slide.id === activeSlide);
    // const [activeKeyServiceTab, setActiveKeyServiceTab] = useState('1');

    useEffect(() => {
        const scrollToHash = () => {
            const hash = location.hash;
            if (hash) {
                const targetElement = document.querySelector(hash);
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        scrollToHash();
    }, [location.hash]);

    useEffect(() => {
        setAnimate(true);
        const timeout = setTimeout(() => {
            setAnimate(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, [activeSlideData]);
    
    useEffect(() => {
        console.log('animate', animate)
    }, [animate])

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Core Solution'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Key Services',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Key Features',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'Benefits',
                        }
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Core Solution'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Key Services',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Key Features',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'Benefits',
                        }
                    ]}
                />
            </div>
            <div className="content fadeIn">
                <section id="section1">
                    <h2 className='pageTitle'>Comprehensive Credit and Lending Services</h2>
                    <p className='subtitle'>Innovating for Accessibility: <span className='font-medium'>VIA Image-to-Audio Technology</span></p>

                    <p className='text'><Speaker id="21" text="Comprehensive Credit and Lending Services. Innovating for Accessibility: VIA Image-to-Audio Technology. enhanced accessibility
                        enhanced accessibilityDiscover the BluSys Core Solution by Blukite Finance, our revolutionary platform tailored for the evolving needs of banks, consumer lending institutions, and merchants. Designed to enhance operational efficiency and profitability, BluSys enables the delivery of bespoke credit offerings with unmatched precision. Embrace the future of finance with solutions that are as dynamic as the industries we serve, all underpinned by our commitment to innovation and excellence.
                        At its core, BluSys is engineered to catapult your operations into new realms of efficiency and profitability. Our platform does more than streamline processes; it opens a world of possibilities, enabling the delivery of custom-tailored credit solutions that resonate with the diverse needs of various consumer-facing industries. With BluSys, you are not just adapting to the market; you are setting the pace, offering innovative financial products that truly make a difference.
                        Join us as we embark on this journey to transform financial services, where every transaction is an opportunity for growth, every solution a step towards greater success."/> Discover the BluSys<sup>®</sup> Core Solution by Blukite Finance, our revolutionary platform tailored for the evolving needs of banks, consumer lending institutions, and merchants.
                        Designed to enhance operational efficiency and profitability, BluSys enables the delivery of bespoke credit offerings with unmatched precision. Embrace the future of finance with
                        solutions that are as dynamic as the industries we serve, all underpinned by our commitment to innovation and excellence.</p>

                    <p className='text'>At its core, BluSys is engineered to catapult your operations into new realms of efficiency and profitability. Our platform does more than streamline processes; it opens a world of
                        possibilities, enabling the delivery of custom-tailored credit solutions that resonate with the diverse needs of various consumer-facing industries. With BluSys, you are not just
                        adapting to the market; you are setting the pace, offering innovative financial products that truly make a difference.</p>

                    <p className='text'>Join us as we embark on this journey to transform financial services, where every transaction is an opportunity for growth, every solution a step towards greater success. </p>

                </section>

                <section id="section2" className='my-14'>
                    <p className='subtitle text-center'><span className='font-medium'>Key Services</span> of the BluSys Core Solution</p>
                    <Select
                        defaultValue={1}
                        className='block lg:hidden mt-3'
                        style={{
                            width: '100%',
                        }}
                        onChange={(value) => setActiveSlide(value)}
                        options={[
                            {
                                value: 1,
                                label: 'Revolving credit',
                            },
                            {
                                value: 2,
                                label: 'Instalment Loans',
                            },
                            {
                                value: 3,
                                label: 'Debit Overdraft',
                            },
                            {
                                value: 4,
                                label: 'Multi-Currency',
                            },
                            {
                                value: 5,
                                label: 'Loyalty & Bonus Programs',
                            },
                            {
                                value: 6,
                                label: 'Accounts handling',
                            },
                        ]}
                    />

                    <div className='hidden lg:block'>

                        <div className='coreSolutions__keyServicesNavItems flex-row justify-start xl:justify-between mt-10 w-full 2xl:w-full'>
                            {keyServicesSlides.map(slide => {
                                return (
                                    <div className={activeSlide === slide.id ? 'coreSolutions__keyServicesNavItem--active' : 'coreSolutions__keyServicesNavItem'} onClick={() => setActiveSlide(slide.id)}>{slide.title}</div>
                                )
                            }
                            )}
                        </div>

                    </div>

                    <div className={`my-5 md:my-10 flex flex-col xl:flex-row gap-0 items-start w-full xl:w-10/12 mx-auto items-center ${animate ? 'animate-slideLeft' : ''}`}>
                        <div className='text-base sm:text-lg w-full xl:w-7/12'>
                            <p className='font-normal'>{activeSlideData.id === 5 ? `${activeSlideData.title} Programs` : activeSlideData.id === 6 ? `${activeSlideData.title} in Financial Difficulty` : activeSlideData.title}</p>
                            <p className='text'><Speaker id={21 + activeSlideData.id} text={activeSlideData.title + activeSlideData.text}/> {activeSlideData.text}</p>
                        </div>
                        <img className={activeSlideData.id === 5 ? 'coreSolutions__keyServicesItemImage--small' : activeSlideData.id === 6 ? 'coreSolutions__keyServicesItemImage--small' : 'coreSolutions__keyServicesItemImage'} src={`${basePath}images/keyServicesSlides_${activeSlideData.id}.png`} alt="User-Centric Design" />
                    </div>

                    <div className='bg-lightBlue p-5 lg:p-16 mt-10 sm:mt-24 rounded'>
                        <div className='flex flex-col md:flex-row gap-1 md:gap-10 justify-between'>
                            <div className='uppercase italic font-normal text-xl lg:text-3xl text-left '>TRANSFORMING FINANCIAL SERVICES</div>
                            <p className='italic font-light text-base lg:text-lg lg:text-xl text-left md:text-right'>Chris Hamilton, Founder of Blukite Finance</p>
                        </div>
                        <p className='font-light text-base sm:text-lg mt-3 md:mt-5'>
                        <Speaker id="28" text="TRANSFORMING FINANCIAL SERVICES. Blusys represents not just our vision for the future of finance but our promise to deliver solutions that empower our clients to lead, innovate, and excel. We're not just creating technology but also crafting a new financial ecosystem where efficiency, security, and innovation intersect to meet the demands of tomorrow. Chris Hamilton, Founder of Blukite Finance."/>
                            Blusys represents not just our vision for the future of finance but our promise to deliver solutions that empower our clients to lead, innovate, and excel. We're not just creating technology but also crafting a new financial ecosystem where efficiency, security, and innovation intersect to meet the demands of tomorrow.
                        </p>
                    </div>

                </section>

                <section id="section3" className='my-14'>
                    <p className='subtitle text-center'><span className='font-medium'>Key Features</span> of BluSys Core Solution</p>
                    <GridComponent />
                </section>

                <section id="section4" className='my-20'>
                    <p className='subtitle text-center'><span className='font-medium'>Exclusive Benefits</span> of Choosing BluSys Core Solution</p>
                    <div className='flex flex-col xl:flex-row gap-0 xl:gap-10 mt-5 sm:mt-10'>
                        <div>
                        <Speaker id="37" text="Exclusive Benefits of Choosing BluSys Core Solution. 1. Quicker Time-to-Market: Leverage the agility of BluSys Core Solution to launch your financial products faster than ever before. Our streamlined processes and automated workflows cut down development time, allowing you to respond swiftly to market demands.
                            2. Reduced Operational Costs:
                            With BluSys, experience significant cost savings through efficient operational practices. Our cloud-based or on-premises deployment options minimise the need for extensive hardware investments and reduce ongoing maintenance expenses.
                            3. Enhanced Security:
                            Security is paramount in the financial sector. BluSys Core Solution employs state-of-the-art security measures, including end-to-end encryption and multi-factor authentication, to protect your data and your customers' information from threats."/>
                            <p className='mt-1.5'><span className='font-medium'>1. Quicker Time-to-Market:</span></p>
                            <p>Leverage the agility of BluSys Core Solution to launch your financial products faster than ever before. Our streamlined processes and automated workflows cut down development time, allowing you to respond swiftly to market demands.</p>

                            <p className='mt-1.5'><span className='font-medium'>2. Reduced Operational Costs:</span></p>
                            <p>With BluSys, experience significant cost savings through efficient operational practices. Our cloud-based or on-premises deployment options minimise the need for extensive hardware investments and reduce ongoing maintenance expenses.</p>

                            <p className='mt-1.5'><span className='font-medium'>3. Enhanced Security:</span></p>
                            <p>Security is paramount in the financial sector. BluSys Core Solution employs state-of-the-art security measures, including end-to-end encryption and multi-factor authentication, to protect your data and your customers' information from threats.</p>
                        </div>
                        <div>

                        <Speaker id="38" text="4. Customised Solutions:
                        Our platform is designed for flexibility, enabling you to tailor financial products and services to fit the unique needs of your customers. From interest rates to repayment schedules, BluSys offers the tools to create truly bespoke solutions.
                        5. Advanced Analytics:
                        Make informed decisions with the help of advanced analytics. BluSys provides deep insights into customer behaviour, product performance, and market trends, empowering you to strategise with precision.
                        6. Dedicated Support:
                        Blukite Finance is committed to your success. Our dedicated support team is available to assist you with any queries, ensuring smooth operation and optimal use of the BluSys Core Solution.
                        Each benefit not only showcases the value proposition of the BluSys Core Solution but also aligns with the strategic needs of modern financial institutions looking for competitive advantage, operational efficiency, and innovation in their product offerings.
                        "/>
                            <p className='mt-1.5'><span className='font-medium'>4. Customised Solutions:</span></p>
                            <p>Our platform is designed for flexibility, enabling you to tailor financial products and services to fit the unique needs of your customers. From interest rates to repayment schedules, BluSys offers the tools to create truly bespoke solutions.</p>

                            <p className='mt-1.5'><span className='font-medium'>5. Advanced Analytics:</span></p>
                            <p>Make informed decisions with the help of advanced analytics. BluSys provides deep insights into customer behaviour, product performance, and market trends, empowering you to strategise with precision.</p>

                            <p className='mt-1.5'><span className='font-medium'>6. Dedicated Support:</span></p>
                            <p>Blukite Finance is committed to your success. Our dedicated support team is available to assist you with any queries, ensuring smooth operation and optimal use of the BluSys Core Solution.</p>
                        </div>
                    </div>
                    <p className='mt-5'>Each benefit not only showcases the value proposition of the BluSys Core Solution but also aligns with the strategic needs of modern financial institutions looking for competitive advantage, operational efficiency, and innovation in their product offerings.</p>
                </section>

            </div>
        </div>
    )
}

export default CoreSolutions