import HomePage from './pages/homePage/homePage';
import CompanyOverview from './pages/companyOverview/companyOverview';
import OurMission from './pages/ourMissions/ourMissions';
import LeadershipTeam from './pages/leadershipTeam/leadershipTeam';
import Careers from './pages/careers/careers';

import RegulatoryUmbrellaProgramOverview from './pages/regulatoryUmbrella Program/overview';
import IntegratedComplianceSolutions from './pages/regulatoryUmbrella Program/integratedComplianceSolutions';
import RegulatoryUmbrellaFaq from './pages/regulatoryUmbrella Program//faq';

import EnhancedAccessibility from './pages/diversityAndInclusion/enhancedAccessibility';
import VisualImpairedAccess from './pages/diversityAndInclusion/visualImpairedAccess';
import BiometricCards from './pages/diversityAndInclusion/biometricCards';

import CoreSolutions from './pages/coreSolutions/coreSolutions';
import CustomizedModularServices from './pages/bespokeFinancialServices/customizedModularServices';
import RiskAssessment from './pages/bespokeFinancialServices/riskAssessment';
import DataSecurityAndPrivacy from './pages/bespokeFinancialServices/dataSecurityAndPrivacy';
import ProductDevelopmentAndMarketAnalysis from './pages/bespokeFinancialServices/productDevelopmentAndMarketAnalysis';
import WhiteLabelConsumerApp from './pages/bespokeFinancialServices/whiteLabelConsumerApp';
import CardIssuingAndFulfilment from './pages/bespokeFinancialServices/cardIssuingAndFulfilment';
import UnderwritingAndLoanOrigination from './pages/bespokeFinancialServices/underwritingAndLoanOrigination';
import MarketingAndCustomerAcquisition from './pages/bespokeFinancialServices/marketingAndCustomerAcquisition';
import LoyaltyAndRewardsProgram from './pages/bespokeFinancialServices/loyaltyAndRewardsProgram';
import FinancialManagement from './pages/bespokeFinancialServices/financialManagement';
import CustomerMonitoring from './pages/bespokeFinancialServices/customerMonitoring';
import DebtCollection from './pages/bespokeFinancialServices/debtCollection';
import PaymentProcessing from './pages/bespokeFinancialServices/paymentProcessing';
import TailoredServicesOverview from './pages/tailoredServices/tailoredServicesOverview';
import TailoredServicesBanks from './pages/tailoredServices/tailoredServicesBanks';
import StrategicTechnologyIntegrationForBuildingSocieties from './pages/tailoredServices/strategicTechnologyIntegrationForBuildingSocieties';
import CreditUnions from './pages/tailoredServices/creditUnions';
import FinTechsCreditSolutions from './pages/tailoredServices/finTechsCreditSolutions';
import LiquidityProvidersCreditSolutions from './pages/tailoredServices/liquidityProvidersCreditSolutions';
import TailoredServicesRetailers from './pages/tailoredServices/tailoredServicesRetailers';
import DiversityAndInclusion from './pages/diversityAndInclusion/diversityAndInclusion';
import ComplianceAndEthics from './pages/complianceAndEthics/complianceAndEthics';

import PrivacyPolicy from './pages/policies/privacyPolicy';
import TermsOfUse from './pages/policies/termsOfUse';
import CookiePolicy from './pages/policies/cookiePolicy';
import Disclaimer from './pages/policies/disclaimer';
import KnowledgeHub from './pages/knowledgeHub/knowledgeHub';
import KnowledgeHubPost1 from './pages/knowledgeHub/knowledgeHubPost1';
import EsgFramework from './pages/esgFramework/esgFramework';
import ContactUs from './pages/contactUs/contactUs';

// export const baseURL = '/blukite-finance';
// export const basePath = '';

export const baseURL = '';
export const basePath = '../';

export const routes = [
    {pageName: 'Home', path: baseURL, element: <HomePage/>},
    {pageName: 'book_consultation', path: `${baseURL}/book_consultation`, element: <HomePage/>},
    {pageName: 'Company overview', path: `${baseURL}/company_overview`, element: <CompanyOverview/>},
    {pageName: 'The missions & values', path: `${baseURL}/our_mission`, element: <OurMission/>},
    {pageName: 'Leadership team', path: `${baseURL}/leadership_team`, element: <LeadershipTeam/>},
    {pageName: 'Compliance & Ethics', path: `${baseURL}/compliance_and_ethics`, element: <ComplianceAndEthics/>},
    {pageName: 'Careers', path: `${baseURL}/careers`, element: <Careers/>},
    // {pageName: 'Enhanced accessibility', path: `${baseURL}/enhanced_accessibility`, element: <EnhancedAccessibility/>},
    // {pageName: 'Visual impaired access', path: `${baseURL}/visual_impaired_access`, element: <VisualImpairedAccess/>},
    // {pageName: 'Biometric cards', path: `${baseURL}/biometric_cards`, element: <BiometricCards/>},
    {pageName: 'Core Solutions', path: `${baseURL}/core_solutions`, element: <CoreSolutions/>},
    // {pageName: 'Regulatory Umbrella Program Overview', path: `${baseURL}/regulatory_umbrella_program_overview`, element: <RegulatoryUmbrellaProgramOverview/>},
    // {pageName: 'Integrated Compliance Solutions', path: `${baseURL}/integrated_compliance_solutions`, element: <IntegratedComplianceSolutions/>},
    // {pageName: 'Regulatory Umbrella Program FAQ', path: `${baseURL}/regulatory_umbrella_program_faq`, element: <RegulatoryUmbrellaFaq/>},
    {pageName: 'Customized Modular Services', path: `${baseURL}/customised_modular_services`, element: <CustomizedModularServices/>},
    {pageName: 'Risk Assessment & Credit Scoring', path: `${baseURL}/risk_assessment_and_credit_scoring`, element: <RiskAssessment/>},
    {pageName: 'Underwriting & Loan Origination', path: `${baseURL}/underwriting&loan_origination`, element: <UnderwritingAndLoanOrigination/>},
    {pageName: 'Data Security & Privacy', path: `${baseURL}/data_security&privacy`, element: <DataSecurityAndPrivacy/>},
    {pageName: 'Marketing & Customer Acquisition', path: `${baseURL}/marketing&customer_acquisition`, element: <MarketingAndCustomerAcquisition/>},
    {pageName: 'Financial Management', path: `${baseURL}/financial_management`, element: <FinancialManagement/>},
    {pageName: 'Product Development & Market Analysis', path: `${baseURL}/product_development&market_analysis`, element: <ProductDevelopmentAndMarketAnalysis/>},
    {pageName: 'Card Issuing & Fulfilment', path: `${baseURL}/card_issuing&fulfilment`, element: <CardIssuingAndFulfilment/>},
    {pageName: 'White Label Payment App', path: `${baseURL}/white_label_consumer_app`, element: <WhiteLabelConsumerApp/>},
    {pageName: 'Loyalty & Rewards Program', path: `${baseURL}/loyalty&rewards_program`, element: <LoyaltyAndRewardsProgram/>},
    {pageName: 'Customer Monitoring', path: `${baseURL}/custmomer_monitoring`, element: <CustomerMonitoring/>},
    {pageName: 'Debt Collection', path: `${baseURL}/debt_collection`, element: <DebtCollection/>},
    {pageName: 'Payment Processing', path: `${baseURL}/payment_processing`, element: <PaymentProcessing/>},
    {pageName: 'TailoredServices Overview', path: `${baseURL}/tailored_services_overview`, element: <TailoredServicesOverview/>},
    {pageName: 'TailoredServices Banks', path: `${baseURL}/tailored_services_banks`, element: <TailoredServicesBanks/>},
    {pageName: 'Strategic Technology Integration for Building Societies', path: `${baseURL}/strategic_technology_integration_for_building_societies`, element: <StrategicTechnologyIntegrationForBuildingSocieties/>},
    {pageName: 'Unlocking Growth and Innovation for Credit Unions with Blukite Finance', path: `${baseURL}/growth_and_innovation_for_credit_unions`, element: <CreditUnions/>},
    {pageName: 'Empowering FinTech Innovation: Credit Solutions by Blukite Finance', path: `${baseURL}/fin_techs_credit_solutions`, element: <FinTechsCreditSolutions/>},
    {pageName: 'Optimising Returns for Liquidity Providers: Credit Solutions by Blukite Finance', path: `${baseURL}/liquidity_providers_credit_solutions`, element: <LiquidityProvidersCreditSolutions/>},
    {pageName: 'Optimising Retail Expansion: Innovative Credit Solutions by Blukite Finance', path: `${baseURL}/tailored_services_retailers`, element: <TailoredServicesRetailers/>},
    {pageName: 'Diversity and Inclusion', path: `${baseURL}/diversity_and_inclusion`, element: <DiversityAndInclusion/>},
    {pageName: 'Esg', path: `${baseURL}/esg`, element: <EsgFramework/>},
    {pageName: 'Contact Us', path: `${baseURL}/contact_us`, element: <ContactUs/>},

    {pageName: 'Privacy Policy', path: `${baseURL}/privacy_policy`, element: <PrivacyPolicy/>},
    {pageName: 'Terms of use', path: `${baseURL}/terms_of_use`, element: <TermsOfUse/>},
    {pageName: 'Cookie Policy', path: `${baseURL}/cookie_policy`, element: <CookiePolicy/>},
    {pageName: 'Disclaimer', path: `${baseURL}/disclaimer`, element: <Disclaimer/>},

    // {pageName: 'Knowledge Hub', path: `${baseURL}/knowledge_hub`, element: <KnowledgeHub/>},
    // {pageName: 'Knowledge Hub', path: `${baseURL}/knowledge_hub/1`, element: <KnowledgeHubPost1/>},
]