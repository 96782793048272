import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Anchor } from 'antd';
import { basePath } from '../../routes';
import { useLocation } from 'react-router-dom';
import Speaker from '../../components/speaker/speaker';
import './companyOverview.scss';
import '../tailoredServices/tailoredServices.scss';
import { PlainButton } from '../../components/buttons/buttons';
import { openPopup } from '../../actions/popup';


const mapDispatchToProps = dispatch => ({
    openPopup: (payload) => {
      return dispatch(openPopup(payload));
    }
  })
  

const CompanyOverview = ({openPopup}) => {
    const location = useLocation();
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [currentProductOfferIndex, setCurrentProductOfferIndex] = useState(0);
    const productOfferingsSlides = [
        {
            title: "Expanding from the UK to the World",
            description: `Blukite Finance, headquartered in the UK, 
            delivers our innovative solutions to clients 
            worldwide. Our extensive reach enhances 
            the capabilities and market presence of our 
            clients, allowing them to excel in the global 
            marketplace.`,
            id: 1,
        },
        {
            title: "Commitment to Accessibility",
            description: `Blukite Finance is committed to developing 
            accessible technology that serves users with 
            disabilities, ensuring greater autonomy, 
            convenience, and security. We strive to 
            bridge the gap and provide inclusive financial
             services for all demographics, including 
            the aging population.`,
            id: 2,
        },
        {
            title: "Innovative Payment Solutions",
            description: `We provide state-of-the-art payment 
            infrastructure for instant, secure payments 
            between accounts in real-time. Our solutions 
            encompass a variety of transaction types, 
            including person-to-person, consumer-to-
            merchant, in-store, and online, all while 
            ensuring the security of sensitive data.`,
            id: 3,
        },
        {
            title: "Future-Ready Technology",
            description: `Our dedication to overcoming the limitations of
            traditional payment systems is evident in our 
           use of open data to enable real-time account 
           settlements. This innovation paves the way for
            scalable solutions and future advancements
            in financial technology.`,
            id: 4,
        },
        {
            title: "Partner with Us",
            description: `We invite businesses worldwide to partner 
            with us to design and implement their credit 
            solution architecture. By integrating with 
            Blukite Finance, you can offer your customers
             a seamless, efficient payment experience, 
            supported by our robust infrastructure
             and commitment to inclusivity.`,
            id: 5,
        }
    ];

    useEffect(() => {
        const scrollToHash = () => {
            const hash = location.hash;
            if (hash) {
                const targetElement = document.querySelector(hash);
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        scrollToHash();
    }, [location.hash]);

    function applyStyles(currentProductOfferIndex, isNext) {
        let styles = [
            'transform: translateX(0%) scale(1);',
            'transform: translateX(100%) scale(1);',
            'transform: translateX(200%) scale(1);',
            'transform: translateX(300%) scale(1);',
            'transform: translateX(400%) scale(1);',
        ];

        for (let i = 0; i < styles.length; i++) {
            let slideIndex = (currentProductOfferIndex + i) % styles.length;
            let slideElement = document.getElementById(`slide-${slideIndex}`);
            slideElement.style = styles[i];

            if (isNext) {
                if (i !== styles.length - 1) {
                    slideElement.classList.add('slide');
                } else {
                    slideElement.classList.remove('slide');
                }

            } else {
                if (slideIndex !== 0) {
                    slideElement.classList.add('slide');
                } else {
                    slideElement.classList.remove('slide');
                }
            }
        }
    }

    const updateSlides = (isNext) => {
        productOfferingsSlides.forEach((slide, index) => {
            applyStyles(currentProductOfferIndex, isNext)
        });
    };

    const handleNext = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        const currentSlide = document.getElementById(`slide-${currentProductOfferIndex}`);
        currentSlide.classList.add('moving-out');

        setTimeout(() => {
            setCurrentProductOfferIndex((currentProductOfferIndex + 1) % productOfferingsSlides.length);
            updateSlides(true);
            setIsTransitioning(false);
        }, 400); // Match this timeout to the CSS transition duration
    };

    const handlePrevious = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        setCurrentProductOfferIndex(currentProductOfferIndex === 0 ? productOfferingsSlides.length - 1 : currentProductOfferIndex - 1);
        updateSlides(false);

        const currentSlide = document.getElementById(`slide-${currentProductOfferIndex}`);
        currentSlide.classList.add('moving-in');
        setIsTransitioning(false);
    };

    // swipes
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {

        if (touchStart - touchEnd > 150) {
            handleNext();
        }

        if (touchStart - touchEnd < -150) {
            handlePrevious();
        }
    }


    useEffect(() => {
        updateSlides(true);
    }, [currentProductOfferIndex]);


    return (

        <div className="flex flex-col md:flex-row container gap-16 mt-0 sm:mt-24 lg:mt-24 infoPage companyOverview">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Company Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Our Mission & Values',
                        }
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Company Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Our Mission & Values',
                        }
                    ]}
                />
            </div>
            <div className="content fadeIn">
                <section id="section1">
                    <h2 className='pageTitle'>Building Innovative Financial Solutions to Accelerate Business Growth</h2>
                    <div className='text'>
                        <p><Speaker id="7" text="Building Innovative Financial Solutions to Accelerate Business Growth. Blukite Finance, a UK-based fintech leader, is at the forefront of financial technology, providing inclusive solutions designed for progressive credit providers. Our flagship BluSys Core 
Solution is the cornerstone of our comprehensive suite of services, empowering banks, credit unions, and lending institutions to exceed the expectations of today's digital customers."/>
            Blukite Finance, a UK-based fintech leader, is at the forefront of financial technology, providing inclusive solutions designed for progressive credit providers. Our flagship BluSys<sup>®</sup> Core 
Solution is the cornerstone of our comprehensive suite of services, empowering banks, credit unions, and lending institutions to exceed the expectations of today's digital customers.</p>
                    </div>
                </section>

                <section id="section2" className='py-5 md:py-10'>

                <div className='customerMonitoring__offers mb-5 sm:mb-20'>
                        <div className='customerMonitoring__offerDashedLine hidden lg:block'>
                            <img src={`${basePath}images/dashedLine_3.svg`} alt="dashed line" />
                        </div>



                        {/* <img className='customerMonitoring__offerDashedLine hidden lg:block' src={`${basePath}images/CustomerMonitoring/dashedLine.svg`} alt="dashed line" /> */}
                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerImgWrapper justify-end'>
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/CompanyOverview/1.svg`} alt="image for WHO WE ARE" />
                            </div>
                            {/* <div className='customerMonitoring__offerNumber self-start'>1</div> */}
                            <div className='customerMonitoring__offerNumber'>1</div>
                            {/* <div className='customerMonitoring__offerItem self-start' data-aos="fade-left" data-aos-duration="700" data-aos-delay="300"> */}
                            <div className='customerMonitoring__offerItem' data-aos="fade-left" data-aos-duration="700" data-aos-delay="300">
                                <p className='font-medium'>WHO WE ARE</p>
                                <p className='mt-2'><Speaker id="231" text="Who we are?. As a key part of the Blukite Group, we are dedicated to creating 
                                equitable and accessible financial solutions. Our commitment to 
                                social governance and community impact ensures that our efforts
                                foster a financial ecosystem that benefits everyone."/>
                               As a key part of the Blukite Group, we are dedicated to creating 
                                equitable and accessible financial solutions. Our commitment to 
                                social governance and community impact ensures that our efforts
                                foster a financial ecosystem that benefits everyone.</p>
                            </div>
                        </div>

                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerItem' data-aos="fade-right" data-aos-duration="700" data-aos-delay="300">
                                <p className='font-medium text-right'>WHAT WE DO</p>
                                <p className='mt-2 text-right'><Speaker isRight id="232" text="What we do? Blukite Finance offers an extensive ecosystem tailored to the needs of
                                credit providers, anchored by our innovative BluSys Core Solution.
                                This platform delivers unparalleled flexibility, scalability, and efficiency,
                                allowing our clients to stay competitive and responsive in a rapidly
                                changing market. Our solutions support rapid market entry and
                                adaptability, helping businesses thrive in a dynamic financial landscape.
                                "/>
                                    Blukite Finance offers an extensive ecosystem tailored to the needs of
                                    credit providers, anchored by our innovative BluSys<sup>®</sup> Core Solution.
                                    This platform delivers unparalleled flexibility, scalability, and efficiency,
                                    allowing our clients to stay competitive and responsive in a rapidly
                                    changing market. Our solutions support rapid market entry and
                                    adaptability, helping businesses thrive in a dynamic financial landscape.
                                    </p>
                            </div>
                            <div className='customerMonitoring__offerNumber'>2</div>
                            <div className='customerMonitoring__offerImgWrapper'>
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/CompanyOverview/2.svg`} alt="image for WHAT WE DO" />
                            </div>
                        </div>

                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerImgWrapper justify-end'>
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/CompanyOverview/3.svg`} alt="image for WHY WE DO IT" />
                            </div>
                            <div className='customerMonitoring__offerNumber'>3</div>
                            <div className='customerMonitoring__offerItem' data-aos="fade-left" data-aos-duration="700" data-aos-delay="300">
                                <p className='font-medium'>WHY WE DO IT</p>
                                <p className='mt-2'><Speaker id="233" text="Why we do it?. Our mission is to enable financial institutions to deliver exceptional
                                customer experiences through integrated, cutting-edge solutions. 
                                By leveraging our advanced technology, we help clients meet 
                                evolving demands effortlessly, ensuring they can offer innovative, 
                                inclusive services that drive growth and customer satisfaction.
                                "/>Our mission is to enable financial institutions to deliver exceptional
                                customer experiences through integrated, cutting-edge solutions. 
                                By leveraging our advanced technology, we help clients meet 
                                evolving demands effortlessly, ensuring they can offer innovative, 
                                inclusive services that drive growth and customer satisfaction.
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className='flex xl:flex-row flex-col justify-between gap-5 mt-5'>
                        <p className='font-normal subtitle italic leading-tight sm:w-auto'>Expanding Horizons with Blukite Finance</p>
                        <div className='mt-0 xl:mt-7 gap-10 flex justify-between'>
                            <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handlePrevious()} onClick={handlePrevious} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderLeftArrow.svg`} alt="slider left arrow" />
                            <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handleNext()} onClick={handleNext} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderRightArrow.svg`} alt="slider right arrow" />
                        </div>
                    </div>

                    <div className='border-r border-separateLight mb-10 tailoredServicesBuildingSocieties'>
                        <div id="slider" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                            <div className="cards mt-2 xl:mt-10">
                                {productOfferingsSlides.map((slide, index) => (
                                    <div id={`slide-${index}`} key={index} className={`cards__card ${index === currentProductOfferIndex ? 'activeCard' : index === (currentProductOfferIndex + 1) % productOfferingsSlides.length ? 'next' : 'sliderHidden'}`}>
                                        <div className="cards__content flex flex-col">
                                            <p className="font-normal text-lg italic">{slide.title}</p>
                                            <p className="text-sm sm:text-base leading-tight font-light mt-3">
                                            <Speaker id={234 + index} text={slide.title + ". " + slide.description}/>
                                                {slide.description}</p>
                                            {slide.id === 1 ?
                                                <img className='absolute left-0 bottom-0 w-full' src={`${basePath}/images/CompanyOverview/4.svg`} alt={`images for ${slide.title}`} />
                                                    : slide.id === 3 ?
                                                        <img className='absolute right-0 bottom-0' src={`${basePath}/images/CompanyOverview/5.svg`} alt={`images for ${slide.title}`} />
                                                            : slide.id === 5 ?
                                                            <div className='mt-auto'>
                                                            <PlainButton text="Connect" onClick={() => openPopup({ currentPopup: 'BookConsultation' })}/>
                                                            </div>
                                                                : null}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section id="section2" className='py-5 md:py-10'>
                    <div className='my-5 sm:my-10 companyOverview__cards'>
                        <div className='companyOverview__card flex flex-col items-center text-center'>
                            <img className='mb-3' src={`${basePath}images/ico/company_1.svg`} alt="our mission icon" />
                            <p className='mt-2 text-lg'>Our Mission</p>
                            <p className='font-normal text-lg'>Empowering Financial Innovation</p>
                            <p className='mt-4 w-full font-light'><Speaker id="8" text="Our Mission. Empowering Financial Innovation. Blukite Finance is committed to empowering every player in the financial sector, from time-honoured banks to cutting-edge FinTechs. We are set on breaking new ground, using our modular solutions to redefine lending services, fostering an environment where innovation leads to tangible advancements in financial accessibility and inclusion.
"/>Blukite Finance is committed to empowering every player in the financial sector, from time-honoured banks to cutting-edge FinTechs. We are set on breaking new ground, using our modular solutions to redefine lending services, fostering an environment where innovation leads to tangible advancements in financial accessibility and inclusion.</p>
                        </div>
                        <div className='companyOverview__card  flex flex-col items-center text-center'>
                            <img className='mb-3' src={`${basePath}images/ico/company_2.svg`} alt="our mission icon" />
                            <p className='mt-2 text-lg'>Our Approach</p>
                            <p className='font-normal text-lg'>Championing Responsible Lending</p>
                            <p className='mt-4 w-full font-light'><Speaker id="9" text="Our Approach. Championing Responsible Lending. We stand at the vanguard of responsible lending, enhancing accessibility to financial services for all customers. Our mission extends beyond mere compliance; we strive to exceed standards of consumer equity, advocating for practices that ensure fair treatment and ethical engagement across the financial spectrum."/>We stand at the vanguard of responsible lending, enhancing accessibility to financial services for all customers. Our mission extends beyond mere compliance; we strive to exceed standards of consumer equity, advocating for practices that ensure fair treatment and ethical engagement across the financial spectrum.</p>
                        </div>
                        <div className='companyOverview__card flex flex-col items-center text-center'>
                            <img className='mb-3' src={`${basePath}images/ico/company_3.svg`} alt="our mission icon" />
                            <p className='mt-2 text-lg'>Our Values</p>
                            <p className='font-normal text-lg'>Partnership and Ethical Profitability</p>
                            <p className='mt-4 w-full font-light'><Speaker id="10" text="Our Values. Partnership and Ethical Profitability. Our core values revolve around partnership, innovation, and adaptability. These principles guide us in delivering equitable solutions that are symbiotic with our partners' growth. By aligning business expansion with ethical standards and regulatory frameworks, we foster profitability rooted in transparency and fairness."/>Our core values revolve around partnership, innovation, and adaptability. These principles guide us in delivering equitable solutions that are symbiotic with our partners' growth. By aligning business expansion with ethical standards and regulatory frameworks, we foster profitability rooted in transparency and fairness.</p>
                        </div>
                        <div className='companyOverview__card  flex flex-col items-center text-center'>
                            <img className='mb-3' src={`${basePath}images/ico/company_4.svg`} alt="our mission icon" />
                            <p className='mt-2 text-lg'>Our Promise</p>
                            <p className='font-normal text-lg'>Growth with Integrity</p>
                            <p className='mt-4 w-full font-light'><Speaker id="11" text="Our Promise. Growth with Integrity. We promise to facilitate our partners' journey towards brand recognition and profitability with utmost integrity. Our flexible suite of products is designed for efficiency and scalability, ensuring that our partners can achieve their business objectives while adhering to their social governance commitments and driving positive change in the financial industry."/> We promise to facilitate our partners' journey towards brand recognition and profitability with utmost integrity. Our flexible suite of products is designed for efficiency and scalability, ensuring that our partners can achieve their business objectives while adhering to their social governance commitments and driving positive change in the financial industry.</p>
                        </div>
                    </div>
                </section> */}
            </div>
        </div>
    )
}

export default connect(
    null,
    mapDispatchToProps
  )(CompanyOverview);