import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { basePath } from '../../routes';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';

const FinancialManagement = () => {

    const slides = [
        {
            id: 1,
            title: 'Transaction Monitoring.',
            text: `Gain real-time insights into your financial 
            transactions with our advanced monitoring 
            tools. Designed to provide a clear overview of
            cash flow, identify potential fraud early, and 
            ensure compliance with financial regulations, 
            our system puts control back in your hands.`
        },
        {
            id: 2,
            title: 'Liquidity Management.',
            text: `Effectively manage your liquidity to ensure 
            that you always have the necessary funds to 
            meet your obligations. Our service offers 
            predictive analytics and forecasting tools, 
            enabling you to make informed decisions 
            about cash flow and asset allocation.`
        },
        {
            id: 3,
            title: 'Client Financial Health Management.',
            text: `Beyond transactions and liquidity, we provide a 
            holistic view of your financial health. Our platform
            offers budgeting tools, financial planning, and 
            performance analytics to guide your business 
            towards financial stability and growth.`
        },
        {
            id: 4,
            title: 'Seamless Ecosystem Integration.',
            text: `Blukite’s Financial Management Service is designed 
            for seamless integration with your existing financial 
            ecosystems. Whether it's accounting software, 
            banking platforms, or payment processors, our 
            service ensures a cohesive and efficient financial 
            management experience.`
        }
    ]
    const [activeSlide, setActiveSlide] = useState(1);
    const activeSlideData = slides.find(slide => slide.id === activeSlide);
    const [animate, setAnimate] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        setAnimate(true);
        const timeout = setTimeout(() => {
            setAnimate(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, [activeSlide]);


    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                setActiveSlide(activeSlide === slides.length ? 1 : activeSlide + 1);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [activeSlide, isHovered]);

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage productDevelopmentAndMarketAnalysis">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Our Services',
                        }
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Our Services',
                        }
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <h2 className='pageTitle'>Financial Management</h2>
                    <p className='text'><Speaker id="103" text="Financial Management. Maintaining optimal financial health is paramount for businesses seeking sustainable growth and stability. Recognising this need, Blukite Finance introduces a comprehensive
                        Financial Management Service designed to empower your business with advanced tools and insights for managing your financial operations effectively. Our Financial
                        Management Service encompasses a wide array of functionalities tailored to enhance your financial operations, from day-to-day transaction monitoring to strategic liquidity
                        management."/>Maintaining optimal financial health is paramount for businesses seeking sustainable growth and stability. Recognising this need, Blukite Finance introduces a comprehensive
                        Financial Management Service designed to empower your business with advanced tools and insights for managing your financial operations effectively. Our Financial
                        Management Service encompasses a wide array of functionalities tailored to enhance your financial operations, from day-to-day transaction monitoring to strategic liquidity
                        management.</p>
                    <p className='subtitle font-normal text-center mt-10 italic'>What We Offer</p>
                    <Select
                        defaultValue={1}
                        className='block lg:hidden mt-5'
                        style={{
                            width: '100%',
                        }}
                        onChange={(value) => setActiveSlide(value)}
                        options={[
                            {
                                value: 1,
                                label: 'Transaction Monitoring',
                            },
                            {
                                value: 2,
                                label: 'Liquidity Management',
                            },
                            {
                                value: 3,
                                label: 'Client Financial Health Management',
                            },
                            {
                                value: 4,
                                label: 'Seamless Ecosystem Integration',
                            }
                        ]}
                    />
                    <div className='flex-row flex xl:flex-col gap-10 xl:gap-0' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        <div className='timeline hidden lg:flex mt-5 xl:mt-32'>
                            <div onClick={() => setActiveSlide(1)} className={activeSlide === 1 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Transaction <br/> Monitoring</p></div>
                            <div onClick={() => setActiveSlide(2)} className={activeSlide === 2 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Liquidity <br/> Management</p></div>
                            <div onClick={() => setActiveSlide(3)} className={activeSlide === 3 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Client Financial Health Management</p></div>
                            <div onClick={() => setActiveSlide(4)} className={activeSlide === 4 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Seamless Ecosystem Integration</p></div>
                        </div>

                        <div data-aos="fade-up" data-aos-duration="700" className='my-6 lg:my-12 flex flex-col xl:flex-row w-full xl:w-9/12 mx-auto items-center gap-5 lg:gap-10'>
                            <p className='flex-1 text-base sm:text-lg'><Speaker id={103 + activeSlideData.id}  text={activeSlideData.title + '.' + activeSlideData.text}/>{activeSlideData.text}</p>
                            <div className='flex-1 w-full'>
                                <object className='rounded object-fit h-64 xl:h-72 w-full'
                                    data={`${basePath}images/FinancialManagement/${activeSlideData.id}.svg`} alt="Comprehensive Market Analysis iamge">
                                </object>
                                </div>
                        </div>
                    </div>
                </section>
                <section id="section2" className=''>
                    <div className='bg-lightBlue p-5 lg:p-16 my-5 sm:my-16 rounded'>

                        <div className=' flex-col gap-5 lg:gap-10 flex xl:flex-row items-center'>
                            <div className='max-w-full w-full mx-auto xl:max-w-64 uppercase italic font-normal text-xl lg:text-3xl text-center xl:text-left'>WHY CHOOSE BLUKITE FINANCE?</div>
                            <p className='font-light text-base lg:text-lg ml-5 lg:leading-tight'>
                            <Speaker id="108" text="WHY CHOOSE BLUKITE FINANCE? At Blukite Finance, we understand that managing financial operations can be complex and time-consuming. Our Financial Management Service is designed to alleviate these challenges, providing you with the tools and insights needed to navigate the financial landscape with confidence. Informed Decision-Making
                            Leverage comprehensive financial data and analytics to guide your strategic and operational decisions. Operational Excellence
                            Automate your financial operations to focus more on core business activities and less on manual tasks.
                            Sustainable Growth
                            With insights into your financial health and performance, position your business for success in competitive markets.
                            Regulatory Compliance
                            Stay ahead of regulatory obligations with tools that monitor and manage financial transactions and practices." />

                                At Blukite Finance, we understand that managing financial operations can be complex and time-consuming. Our
                                Financial Management Service is designed to alleviate these challenges, providing you with the tools and insights
                                needed to navigate the financial landscape with confidence.
                            </p>
                        </div>

                        <div className='cards mt-10 mb-5'>
                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/1.svg`} alt="icon for Expertise card" />
                                <p className='text-xl font-normal mt-3'>Informed Decision-Making</p>
                                <p className='mt-2'>Leverage comprehensive financial data and analytics to guide your strategic and operational decisions.</p>
                            </div>

                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/2.svg`} alt="icon for Expertise card" />
                                <p className='text-xl font-normal mt-3'>Operational Excellence</p>
                                <p className='mt-2'>Automate your financial operations to focus more on core business activities and less on manual tasks.</p>
                            </div>

                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/3.svg`} alt="icon for Expertise card" />
                                <p className='text-xl font-normal mt-3'>Sustainable Growth</p>
                                <p className='mt-2'>With insights into your financial health and performance,
                                    position your business for
                                    success in competitive markets.</p>
                            </div>

                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/6.svg`} alt="icon for Expertise card" />
                                <p className='text-xl font-normal mt-3'>Regulatory Compliance</p>
                                <p className='mt-2'>Stay ahead of regulatory obligations with tools that monitor and manage financial transactions and practices.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default FinancialManagement