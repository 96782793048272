import React from 'react';
import './bespokeFinancialServices.scss';
import { basePath, baseURL } from '../../routes';
import { NavLink } from "react-router-dom";
import { PlainButton } from '../../components/buttons/buttons';
import { useNavigate } from "react-router-dom";
import Speaker from '../../components/speaker/speaker';

const CustomizedModularServices = () => {

  let navigate = useNavigate();
  const cards = [
    { id: '1', title: 'Regulatory Umbrella Program', link: `${baseURL}/integrated_compliance_solutions`, class: 'four-grid-cells' },
    { id: '2', title: 'Risk Assessment & Credit Scoring Service', link: '', class: '' },
    { id: '3', title: 'Underwriting & Loan Origination Service', link: `${baseURL}/underwriting&loan_origination`, class: 'wide-image' },
    { id: '4', title: 'Payment Processing Service', link: '', class: '' },
    { id: '5', title: 'Customer Monitoring Service', link: '', class: '' },
    { id: '6', title: 'Debt Collection Service', link: '', class: '' },
    { id: '7', title: 'Data Security & Privacy Service', link: `${baseURL}/data_security&privacy`, class: 'four-grid-cells' },
    { id: '8', title: 'Marketing & Customer Acquisition Service', link: `${baseURL}/marketing&customer_acquisition`, class: 'wide-image' },
    { id: '9', title: 'Financial Management Service', link: '', class: 'four-grid-cells' },
    { id: '10', title: 'Product Development & Market Analysis Service', link: `${baseURL}/product_development&market_analysis`, class: '' },
    { id: '11', title: 'Card Issuing & Fulfilment Service', link: '', class: '' },
    { id: '12', title: 'White Label Payment App', link: `${baseURL}/white_label_consumer_app`, class: '' },
    { id: '13', title: 'Loyalty & Rewards Program', link: '', class: '' },
  ]
  
  return (
    <div className="container gap-16 sm:gap-5 md:gap-16 mt-20 sm:mt-24 infoPage w-11/12 lg:w-11/12 xl:w-10/12 customizedModularServices">
      <div className='mx-0 md:mx-14'>
      <div className='flex-col sm:flex-row flex items-start justify-between gap-0 sm:gap-10'>
        <h2 className='pageTitle'>Shaping the Future of Lending with Customised Modular Services</h2>
        <div className='w-max flex-none mt-0 sm:mt-4'> 
          <PlainButton onClick={() => navigate(`${baseURL}/disclaimer`)} text="Disclaimer" />
        </div>
      </div>


      <p className='subtitle'><span className='font-medium'>Unleashing Potential</span> with Blukite Finance’s Comprehensive<span className='font-medium'> Financial Services</span></p>
      <p className='text'>
      <Speaker id="39" text="Shaping the Future of Lending with Customised Modular Services
Unleashing Potential with Blukite Finance’s Comprehensive Financial Services. At the heart of the financial services transformation, Blukite Finance introduces a suite of bespoke solutions designed to elevate the operational efficiency and profitability of credit and lending activities. Serving a diverse clientele, including banks, consumer lending institutions, and merchants seeking to implement Point of Sale finance options, we provide an extensive array of customised credit solutions. Our offerings are meticulously tailored to meet the intricate demands of various consumer-focused industries, empowering our partners to deliver precisely what their customers need. With Blukite Finance, experience the synergy of innovation and customisation, as we pave the way for a new era in financial services."/>
        At the heart of the financial services transformation, Blukite Finance introduces a suite of bespoke solutions designed to elevate the operational efficiency and profitability of credit
        and lending activities. Serving a diverse clientele, including banks, consumer lending institutions, and merchants seeking to implement Point of Sale finance options, we provide an
        extensive array of customised credit solutions. Our offerings are meticulously tailored to meet the intricate demands of various consumer-focused industries, empowering our
        partners to deliver precisely what their customers need. With Blukite Finance, experience the synergy of innovation and customisation, as we pave the way for a new era in
        financial services.
      </p>
      </div>
      <div className='my-10 mb-20'>
        <p className='uppercase italic text-xl sm:text-2xl text-center'>Technology & Digital Platform</p>

        <div className="cards my-10">
          {cards.map(x => {
            return (
              <NavLink to={x.link} className={`card ${x.class}`} key={x.id}>
                <div className='card__iconWrapper'>
                  <img className='card__icon' src={`${basePath}/images/ico/customizedModularServicesCard_${x.id}.svg`} />
                </div>
                <p className='mt-3'>{x.title}</p>
               
                <div className='card__arrow'>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.20447 12.9611L13.2343 4.47873L13.2344 13.2097" stroke="#0E2A38" stroke-width="0.9" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4.27703 4.4782H13.1214" stroke="#0E2A38" stroke-width="0.9" stroke-miterlimit="10" stroke-linecap="round" />
                  </svg>
                </div>
              </NavLink>
            )
          })}
        </div>
      </div>

    </div>
  )
}

export default CustomizedModularServices