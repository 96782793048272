import React, {useState, useEffect} from 'react';
import { Anchor } from 'antd';

const TermsOfUse = () => {

    const [activeLink, setActiveLink] = useState('#section1');

    useEffect(() => {
        const sidebar = document.querySelector('.sidebar');
        if (sidebar) {
            const sectionNumber = parseInt(activeLink.replace('#section', ''), 10);
            if (sectionNumber >= 17) {
                sidebar.scrollTo({
                    top: sidebar.scrollHeight,
                    behavior: 'smooth'
                });
            } else {
                sidebar.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    }, [activeLink]);


    return (
        <div className="flex flex-col lg:flex-row container mb-20 gap-16 sm:gap-5 lg:gap-16 my-0 sm:my-12 lg:my-24 policiesPage">
            <div className="hidden lg:block sidebar">
                <Anchor
                    affix={false}
                    showInkInFixed={true}
                    targetOffset={200}
                    onChange={setActiveLink}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: '1. Introduction'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: '2. Acceptance of Terms'
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: '3. User Rights and Responsibilities'
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: '4. Modification of Terms'
                        },
                        {
                            key: 'section5',
                            href: '#section5',
                            title: '5. Privacy and Data Protection'
                        },
                        {
                            key: 'section6',
                            href: '#section6',
                            title: '6. Intellectual Property Rights'
                        },
                        {
                            key: 'section7',
                            href: '#section7',
                            title: '7. User Conduct and Restrictions'
                        },
                        {
                            key: 'section8',
                            href: '#section8',
                            title: '8. Links to Third-Party Websites'
                        },
                        {
                            key: 'section9',
                            href: '#section9',
                            title: '9. Disclaimers and Limitation of Liability'
                        },
                        {
                            key: 'section10',
                            href: '#section10',
                            title: '10. Indemnification'
                        },
                        {
                            key: 'section11',
                            href: '#section11',
                            title: '11. Governing Law and Jurisdiction'
                        },
                        {
                            key: 'section12',
                            href: '#section12',
                            title: '12. Dispute Resolution'
                        },
                        {
                            key: 'section13',
                            href: '#section13',
                            title: '13. Miscellaneous Provisions'
                        },
                        {
                            key: 'section14',
                            href: '#section14',
                            title: '14. Contact Information'
                        },
                        {
                            key: 'section15',
                            href: '#section15',
                            title: '15. Accessibility Statement'
                        },
                        {
                            key: 'section16',
                            href: '#section16',
                            title: '16. Effective Date'
                        },
                        {
                            key: 'section17',
                            href: '#section17',
                            title: '17. Update Processes'
                        },
                        {
                            key: 'section18',
                            href: '#section18',
                            title: 'Closing Statement: Commitment to Fairness and Equity'
                        },
                    ]}
                />
            </div>

            <div className='block lg:hidden sidebar'>
                <Anchor
                    affix={false}
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: '1. Introduction'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: '2. Acceptance of Terms'
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: '3. User Rights and Responsibilities'
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: '4. Modification of Terms'
                        },
                        {
                            key: 'section5',
                            href: '#section5',
                            title: '5. Privacy and Data Protection'
                        },
                        {
                            key: 'section6',
                            href: '#section6',
                            title: '6. Intellectual Property Rights'
                        },
                        {
                            key: 'section7',
                            href: '#section7',
                            title: '7. User Conduct and Restrictions'
                        },
                        {
                            key: 'section8',
                            href: '#section8',
                            title: '8. Links to Third-Party Websites'
                        },
                        {
                            key: 'section9',
                            href: '#section9',
                            title: '9. Disclaimers and Limitation of Liability'
                        },
                        {
                            key: 'section10',
                            href: '#section10',
                            title: '10. Indemnification'
                        },
                        {
                            key: 'section11',
                            href: '#section11',
                            title: '11. Governing Law and Jurisdiction'
                        },
                        {
                            key: 'section12',
                            href: '#section12',
                            title: '12. Dispute Resolution'
                        },
                        {
                            key: 'section13',
                            href: '#section13',
                            title: '13. Miscellaneous Provisions'
                        },
                        {
                            key: 'section14',
                            href: '#section14',
                            title: '14. Contact Information'
                        },
                        {
                            key: 'section15',
                            href: '#section15',
                            title: '15. Accessibility Statement'
                        },
                        {
                            key: 'section16',
                            href: '#section16',
                            title: '16. Effective Date'
                        },
                        {
                            key: 'section17',
                            href: '#section17',
                            title: '17. Update Processes'
                        },
                        {
                            key: 'section18',
                            href: '#section18',
                            title: 'Closing Statement: Commitment to Fairness and Equity'
                        },
                    ]}
                />
            </div>

            <div className="content">

                <section id="section1" className='mt-10'>
                    <h2 className='pageTitle'>Terms and Conditions of Use for Blukite Finance Limited Websites</h2>
                    <h2 className='pageTitle'>1. Introduction</h2>
                    <p className='subtitle'>1.1 Purpose of the Document: </p>
                    <p>This document ("Terms and Conditions") sets out the terms and conditions under which users ("you") may access and use the websites owned and operated by Blukite Finance Limited (Blukite) ("we," "our," or "us"). These terms are designed to ensure a clear understanding of the legal agreement between you and Blukite when you use our websites.
                    </p>
                    <p className='subtitle'>1.2 Applicability to Websites: </p>
                    <p>These Terms and Conditions apply to all websites operated by Blukite. This includes, but is not limited to, the following websites:
                    </p>
                    <div className='flex flex-col text-blue underline'>
                        <a href='#' target='_blank'>www.blukite.group</a>
                        <a href='#' target='_blank'>www.blukite.finance</a>
                        <a href='#' target='_blank'>www.blukite.net</a>
                        <a href='#' target='_blank'>www.blusys</a>
                        <a href='#' target='_blank'>www.blusys</a>
                        <a href='#' target='_blank'>www.blukitemerchant.com</a>
                        <a href='#' target='_blank'>www.talucard.com</a>
                    </div>
                    <p>By accessing any of these websites, you agree to comply with and be bound by these terms. It is important that you read this document carefully as it affects your legal rights and obligations.
                    </p>



                </section>

                <section id="section2" className='mt-10'>
                    <h2 className='pageTitle'>2. Acceptance of Terms</h2>
                    <p className='subtitle'>2.1 Agreement to Terms:</p>
                    <p>By accessing, browsing, or otherwise using any of the websites operated by Blukite, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use our websites.
                    </p>
                    <p>By accessing, browsing, or otherwise using any of the websites operated by Blukite, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use our websites.</p>
                    <p className='subtitle'>2.2 Legal Capacity:</p>
                    <p>In agreeing to these Terms and Conditions, you represent and warrant that you have the legal capacity to enter into a binding contract. If you are using the websites on behalf of an organisation or entity, you confirm that you have the authority to bind such organisation or entity to these terms.
                    </p>
                    <p className='subtitle'>2.3 Changes to Terms:</p>
                    <p>We reserve the right to modify or replace these Terms and Conditions at any time as we deem necessary. It is your responsibility to review these terms periodically for changes. Your continued use of the websites following the posting of any changes to these Terms and Conditions constitutes acceptance of those changes.
                    </p>


                </section>


                <section id="section3" className='mt-10'>
                    <h2 className='pageTitle'>3. User Rights and Responsibilities</h2>
                    {/* <p><span className='font-normal'>2.1 Agreement to Terms: </span></p> */}
                    <p className='subtitle'>3.1 Acceptable Use of the Websites: </p>
                    <p>You agree to use our websites only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the websites. Prohibited behaviour includes, but is not limited to:
                    </p>

                    <ul>
                        <li>Harassing or causing distress or inconvenience to any other user.
                        </li>
                        <li>Transmitting obscene or offensive content.
                        </li>
                        <li>Disrupting the normal flow of dialogue on the websites.
                        </li>
                        <li>Engaging in conduct that affects the operational performance of the websites.
                        </li>
                    </ul>

                    <p className='subtitle'>3.2 User-Generated Content: </p><p>If our websites allow you to post content, such as comments, reviews, or articles:</p>
                    <ul>
                        <li>You retain all of your ownership rights in your content, but you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, store, and copy that content and to distribute and make it available to third parties.
                        </li>
                        <li>You must ensure that your content does not violate any applicable laws or rights of others, including intellectual property rights and privacy rights.
                        </li>
                        <li>We have the right to remove any posting you make on our websites if, in our opinion, your post does not comply with our content standards.
                        </li>
                    </ul>

                    <p className='subtitle'>3.3 Responsibility for Account Security:</p><p>Where applicable, you are responsible for maintaining the confidentiality of your account details and for all activities that occur under your account. You agree to immediately notify us of any unauthorised use of your account or any other breach of security.
                    </p>
                    <p className='subtitle'>3.4 Reporting Infringements:</p><p>I If you believe that any content on our websites infringes upon your rights or the rights of others, please contact us with detailed information about the infringement.
                    </p>

                </section>

                <section id="section4" className='mt-10'>
                    <h2 className='pageTitle'>4. Modification of Terms</h2>
                    <p className='subtitle'>4.1 Right to Modify Terms: </p><p>Blukite reserves the right, at our discretion, to change, modify, add, or remove portions of these Terms and Conditions at any time. Such changes shall be effective immediately upon posting unless otherwise specified. We may update our Terms and Conditions to reflect changes in our practices, technologies, legal requirements, or other factors.</p>
                    <p className='subtitle'>4.2 Responsibility to Review Changes: </p><p>It is your responsibility to check these Terms and Conditions periodically for changes. Your continued use of the websites following the posting of changes will mean that you accept and agree to the changes. If you do not agree to the updated terms, you should discontinue your use of the websites.
                    </p>
                    <p className='subtitle'>4.3 Notification of Changes: </p><p>Whenever significant changes are made to these Terms and Conditions; we will endeavour to notify you by prominent notice on the websites or by sending an email to the address you have provided to us. However, it remains your responsibility to review the terms regularly to stay informed of any changes.
                    </p>
                    <p className='subtitle'>4.4 Effective Date of Changes: </p><p>The date of the last modification of these Terms and Conditions will be indicated at the top of this document, making it easy for users to ascertain when the last update was made.</p>

                </section>

                <section id="section5" className='mt-10'>
                    <h2 className='pageTitle'>5. Privacy and Data Protection
                    </h2>
                    <p className='subtitle'>5.1 Commitment to Data Protection: </p><p>Blukite is committed to protecting the privacy and security of our users' personal information. Our practices are in strict compliance with the General Data Protection Regulation (GDPR) and other relevant data protection laws.
                    </p>
                    <p className='subtitle'>5.2 Data Collection and Usage:
                    </p>
                    <ul>
                        <li><span className='font-normal'>Personal Data Collection: </span>We collect personal data when you use our websites, such as name, email address, and contact information. The collection of this data is essential for providing our services, processing transactions, and for internal analytical purposes.
                        </li>
                        <li><span className='font-normal'>Purpose of Data Collection:</span>he data collected is used to improve our websites, tailor the user experience, communicate with users, and comply with legal obligations.
                        </li>
                        <li><span className='font-normal'>Cookies and Tracking Technologies: </span>Our websites use cookies and similar tracking technologies to monitor user interactions and preferences. Users have the choice to accept or decline cookies.
                        </li>
                        <p className='subtitle'>5.3 Data Sharing and Disclosure:
                        </p>

                        <li>We do not sell, trade, or otherwise transfer your personal data to outside parties except when necessary for providing a service you have requested, for legal compliance, or in the event of a business transfer such as a merger or acquisition.
                        </li>
                        <li>Third-party service providers used by us will only receive the necessary information to perform their services and are obligated not to disclose or use the information for other purposes.
                        </li>
                    </ul>
                    <p className='subtitle'>5.4 User Rights:
                    </p>
                    <ul>
                        <li>Under GDPR and other data protection laws, users have rights regarding their personal data, including the right to access, rectify, erase, restrict processing, and object to processing, as well as the right to data portability.
                        </li>
                        <li>Users can exercise these rights by contacting us using the information provided in the Contact section of the websites.
                        </li>
                    </ul>
                    <p className='subtitle'>5.5 Data Security:
                    </p>
                    <ul>
                        <li>We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.
                        </li>
                    </ul>
                    <p className='subtitle'>5.6 Changes to Privacy Practices:
                    </p>
                    <ul>
                        <li>If there are any changes to our privacy practices, they will be communicated through our websites and, where appropriate, through direct communication with users.
                        </li>
                    </ul>
                    <p className='subtitle'>5.7 Contact Information:
                    </p>
                    <ul>
                        <li>For any questions or concerns regarding privacy and data protection, users can contact us through the designated channels provided on our websites.
                        </li>
                    </ul>

                </section>

                <section id="section6" className='mt-10'>
                    <h2 className='pageTitle'>6. Intellectual Property Rights
                    </h2>
                    <p className='subtitle'>6.1 Ownership of Website Content:
                    </p>
                    <ul>
                        <li>Ownership: All content present on the websites of Blukite, including but not limited to text, graphics, logos, images, audio clips, digital downloads, and software, is the property of Blukite or its content suppliers and is protected by international copyright and intellectual property laws.
                        </li>
                        <li>Use of Content: The compilation of all content on these websites is the exclusive property of Blukite. Unauthorized use of any content or materials on the websites for any purpose is strictly prohibited and may result in legal action.
                        </li>
                    </ul>
                    <p className='subtitle'>6.2 Trademarks:</p>
                    <ul>
                        <li>Blukite Trademarks: The trademarks, logos, and service marks displayed on the websites, including the name 'Blukite' and its logo, are registered and unregistered trademarks of Blukite. Nothing contained on the websites should be construed as granting any license or right to use any trademark without the prior written permission of Blukite.
                        </li>
                        <li>Use of Trademarks: Any use of Blukite trademarks on any other website or network computer environment, for example, the storage or reproduction of the website in any external internet site or the creation of links, hypertext, links, or deep links between the website and any other internet site, without the written permission of Blukite, is prohibited.
                        </li>
                    </ul>
                    <p className='subtitle'>6.3 Copyrighted Material:
                    </p>
                    <ul>
                        <li>Respect for Copyright: Users of the websites must respect the intellectual property rights of others. The use of copyrighted material on the websites without the express permission of the copyright owner is not permitted.
                        </li>
                        <li>Infringement Notifications: If you believe that your work has been copied in a way that constitutes copyright infringement, or if you are aware of any infringing material on the websites, please contact us with detailed information about the infringement.
                        </li>
                    </ul>



                </section>

                <section id="section7" className='mt-10'>
                    <h2 className='pageTitle'>7. User Conduct and Restrictions</h2>
                    <p className='subtitle'>7.1 Acceptable User Conduct:
                    </p>
                    <ul>
                        <li>Respectful Interaction: Users must engage with the websites and other users respectfully and responsibly. This includes refraining from using offensive language, harassment, or other disruptive behaviours.
                        </li>
                        <li>Legal Compliance: Users must comply with all applicable local, national, and international laws and regulations while using the websites.
                        </li>
                    </ul>
                    <p className='subtitle'>7.2 Prohibited Behaviours:</p>
                    <ul>
                        <li>No Illegal Activities: Users must not engage in any illegal activities on the websites, including but not limited to fraud, money laundering, or the distribution of malicious software.
                        </li>
                        <li>No Harmful Content: Users must not upload, post, or otherwise transmit any content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.
                        </li>
                        <li>No Interference with Operations: Users must not interfere with or disrupt the websites or servers or networks connected to the websites, nor disobey any requirements, procedures, policies, or regulations of networks connected to the websites.
                        </li>
                    </ul>
                    <p className='subtitle'>7.3 User-Generated Content Guidelines:
                    </p>
                    <ul>
                        <li>Content Ownership: Users retain ownership of any content they submit, post, display, or otherwise make available on the websites.
                        </li>
                        <li>Content Responsibility: Users are responsible for their content, including its legality, reliability, and appropriateness.
                        </li>
                        <li>License to Use User Content: By posting content, users grant Blukite a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the content in connection with the websites and Blukite's business.
                        </li>
                    </ul>
                    <p className='subtitle'>7.4 Reporting Violations:</p>

                </section>

                <section id="section8" className='mt-10'>
                    <h2 className='pageTitle'>8. Links to Third-Party Websites
                    </h2>
                    <p className='subtitle'>8.1 External Links Disclaimer:
                    </p>
                    <ul>
                        <li>Presence of Third-Party Links: The websites operated by Blukite may contain links to third-party websites that are not owned or controlled by Blukite. These links are provided for your convenience to provide further information.
                        </li>
                        <li>No Endorsement: The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. Blukite has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites.
                        </li>
                    </ul>

                    <p className='subtitle'>8.2 User Responsibility:
                    </p>
                    <ul>
                        <li>Due Diligence: We encourage our users to be aware when they leave our websites and to read the terms and conditions and privacy policy of any other website that they visit.
                        </li>
                        <li>Risks and Liabilities: You acknowledge and agree that Blukite shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such third-party websites.
                        </li>
                    </ul>
                    <p className='subtitle'>8.3 No Affiliation:
                    </p>
                    <ul>
                        <li>Independent Entities: Any dealings with third parties (including advertisers) included within the Blukite websites or participation in promotions, including the delivery of and the payment for goods and services, and any other terms, conditions, warranties, or representations associated with such dealings or promotions, are solely between you and the advertiser or other third party. Blukite is not responsible or liable for any part of any such dealings or promotions.
                        </li>
                    </ul>



                </section>
                <section id="section9" className='mt-10'>
                    <h2 className='pageTitle'>9. Disclaimers and Limitation of Liability</h2>
                    <p className='subtitle'>9.1 Accuracy of Information:
                    </p>
                    <ul>
                        <li>No Warranty for Accuracy: While Blukite strives to ensure that the information on our websites is accurate and up to date, we do not warrant or make any representations regarding the accuracy, reliability, or completeness of the information, content, materials, or products included on our sites.
                        </li>
                        <li>Use at Your Own Risk: Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to our websites, or by anyone who may be informed of any of its contents.
                        </li>
                    </ul>

                    <p className='subtitle'>9.2 Website Availability:</p>
                    <ul>
                        <li>Service Interruptions: Blukite does not guarantee that our websites, or any content on them, will always be available or be uninterrupted. Access to our websites is permitted on a temporary basis, and we reserve the right to withdraw, suspend, discontinue, or change all or any part of our websites without notice.
                        </li>
                        <li>No Liability for Unavailability: We will not be liable to you if for any reason our websites are unavailable at any time or for any period.
                        </li>
                    </ul>
                    <p className='subtitle'>9.3 Limitation of Liability:
                    </p>
                    <ul>
                        <li>Limitation for Direct Damages: In no event will Blukite, its directors, employees, partners, agents, suppliers, or affiliates, be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the websites; (ii) any conduct or content of any third party on the websites; (iii) any content obtained from the websites; and (iv) unauthorised access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.
                        </li>
                        <li>Acknowledgement of Terms: Your use of our websites is at your sole risk. The services and products provided on our websites are provided on an "as is" and "as available" basis.
                        </li>
                    </ul>
                </section>
                <section id="section10" className='mt-10'>
                    <h2 className='pageTitle'>10. Indemnification</h2>
                    <p className='subtitle'>10.1 User Agreement to Indemnify:
                    </p>
                    <ul>
                        <li>Scope of Indemnification: You agree to indemnify, defend, and hold harmless Blukite, its directors, officers, employees, consultants, agents, and affiliates, from any and all third-party claims, liability, damages, and/or costs (including, but not limited to, legal fees) arising from your use of our websites, your breach of these Terms and Conditions, or your infringement of any intellectual property or other rights of any person or entity.
                        </li>
                    </ul>
                    <p className='subtitle'>10.2 Defence and Control of Claims:
                    </p>
                    <ul>
                        <li>Cooperation in Defence: In the event of any claim for which we are entitled to indemnification under this clause, we will (i) provide you with reasonable written notice of the claim; (ii) give you sole control of the defence and settlement of the claim (provided that you may not settle or defend any claim unless you unconditionally release us of all liability and such settlement does not affect our business or service); and (iii) provide to you all reasonable assistance, at your expense.
                        </li>
                    </ul>
                    <p className='subtitle'>10.3 Limitation on Indemnification:</p>
                    <ul><li>Exclusions: The indemnification obligation does not apply if the claim for damages result from our own negligence, fraud, wilful misconduct, or any other actions by us that are not in compliance with these Terms and Conditions.
                    </li></ul>
                </section>
                <section id="section11" className='mt-10'>
                    <h2 className='pageTitle'>11. Governing Law and Jurisdiction
                    </h2>
                    <p className='subtitle'>11.1 Governing Law:
                    </p>
                    <ul>
                        <li>Applicable Law: These Terms and Conditions and any separate agreements whereby we provide you services shall be governed by and construed in accordance with the laws of England and Wales, without regard to its conflict of law provisions.
                        </li>
                        <li>Reference to Jurisdiction: It is important to specify the jurisdiction, such as the country or state, whose laws will govern the terms and any disputes that might arise. This should be a jurisdiction relevant to Blukite's operations.
                        </li>
                    </ul>
                    <p className='subtitle'>11.2 Jurisdiction for Disputes:
                    </p>
                    <ul>
                        <li>Resolution of Disputes: Any disputes or claims arising out of or in connection with these Terms and Conditions, including disputes relating to its validity, breach, termination, or nullity, shall be subject to the exclusive jurisdiction of the courts of England and Wales.
                        </li>
                        <li>User Acknowledgement: By using our websites, you agree that the courts of [Jurisdiction] will have exclusive jurisdiction to resolve any disputes or claims.
                        </li>
                    </ul>
                    <p className='subtitle'>11.3 International Use:
                    </p>
                    <ul>
                        <li>International Application: We make no representation that our websites are appropriate or available for use in locations outside of England and Wales and accessing them from territories where their contents are illegal is prohibited.
                        </li>
                        <li>Compliance with Local Laws: Users who choose to access our websites from other locations do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable.
                        </li>
                    </ul>

                </section>

                <section id="section12" className='mt-10'>
                    <h2 className='pageTitle'>12. Dispute Resolution</h2>
                    <p className='subtitle'>12.1 Initial Attempt to Resolve Disputes:
                    </p>
                    <ul>
                        <li>
                            Informal Resolution: Before filing a claim against Blukite, you agree to try to resolve the dispute informally by contacting us. We'll try to resolve the dispute informally by contacting you via email.

                        </li>
                    </ul>
                    <p className='subtitle'>12.2 Formal Dispute Resolution:
                    </p>
                    <ul><li>Arbitration Agreement: If the dispute is not resolved within a specified period (e.g., 30 days) after the initial contact, either you or Blukite may initiate formal proceedings. You agree that any unresolved disputes will be resolved by final and binding arbitration, except as otherwise provided in these Terms and Conditions.
                    </li></ul>
                    <p className='subtitle'>12.3 Arbitration Process:
                    </p>
                    <ul>
                        <li>Arbitration Rules: The arbitration will be conducted by a neutral arbitrator, in accordance with the rules of an established arbitration institution (e.g., [Name of Arbitration Institution]), which are hereby incorporated by reference.
                        </li>
                        <li>Arbitration Venue: The arbitration will take place in [Venue Location], or another mutually agreed location.
                        </li>
                    </ul>

                    <p className='subtitle'>12.4 Exceptions to Arbitration:
                    </p>
                    <ul>
                        <li>Small Claims Court: Either party may bring a claim in a small claims court for disputes or claims within the scope of that court’s jurisdiction.
                        </li>
                        <li>No Class Actions: You may only resolve disputes with us on an individual basis and may not bring a claim as a plaintiff or a class member in a class, consolidated, or representative action.
                        </li>
                    </ul>
                    <p className='subtitle'>12.5 Governing Law in Arbitration:
                    </p>
                    <ul><li>Applicability of Jurisdiction Law: The arbitration shall be governed by the same governing law as mentioned in Section 11.
                    </li></ul>

                    <p className='subtitle'>12.6 Costs of Arbitration:
                    </p>
                    <ul><li>Arbitration Fees: The payment of filing, administration, and arbitrator fees will be governed by the arbitration institution's rules, unless otherwise stated in this agreement.
                    </li></ul>
                </section>

                <section id="section13" className='mt-10'>
                    <h2 className='pageTitle'>13. Miscellaneous Provisions
                    </h2>
                    <p className='subtitle'>13.1 Severability:
                    </p>
                    <ul>
                        <li>Validity of Remaining Provisions: If any provision of these Terms and Conditions is found by a court or other binding authority to be invalid, you agree that every attempt shall be made to give effect to the parties' intentions as reflected in that provision, and the remaining provisions contained in these Terms and Conditions shall continue in full force and effect.
                        </li>
                    </ul>
                    <p className='subtitle'>13.2 Entire Agreement:
                    </p>
                    <ul>
                        <li>Comprehensiveness of Terms: These Terms and Conditions constitute the entire agreement between you and Blukite regarding the use of the websites, superseding any prior agreements between you and Blukite relating to your use of the websites.
                        </li>
                        <li>Exclusion of Other Agreements: The failure of Blukite to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision unless acknowledged and agreed to by Blukite in writing.
                        </li>
                    </ul>
                    <p className='subtitle'>13.3 Non-Waiver of Rights:
                    </p>
                    <ul>
                        <li>Continued Effectiveness of Rights: The failure of Blukite to insist upon or enforce strict performance of any provision of these Terms and Conditions or to exercise any right under them shall not be construed as a waiver or relinquishment of Blukite's right to assert or rely upon any such provision or right in that or any other instance.
                        </li>
                        <li>Ongoing Right to Enforce Terms: The express waiver by Blukite of any provision, condition, or requirement of these Terms and Conditions shall not constitute a waiver of any future obligation to comply with such provision, condition, or requirement.
                        </li>
                    </ul>
                </section>

                <section id="section14" className='mt-10'>
                    <h2 className='pageTitle'>14. Contact Information</h2>
                    <p className='subtitle'>14.1 Contacting Blukite:
                    </p>
                    <ul>
                        <li>Queries and Concerns: If you have any questions, concerns, or comments about these Terms and Conditions, please feel free to contact us. We value your feedback and are committed to addressing any issues or queries you may have.
                        </li>
                        <li>Contact Methods: You can reach us in the following ways:
                        </li>
                        <ul>
                            <li>Email: contact@blukite.finance
                            </li>
                            <li>Postal Address: Tondu Enterprise Centre, Bryn Road, Aberkenfig, Bridgend, CF32 9BS
                            </li>
                            <li>Online Contact Form: Online contact tabs are available on all our websites for your convenience. Simply visit any of our websites and look for the contact tab to send us your queries or feedback.
                            </li>
                            <li>In-App Chat Facilities: For users of our apps, we also offer in-app chat facilities, allowing for real-time communication and support.
                            </li>
                        </ul>
                    </ul>

                    <p className='subtitle'>14.2 Response Time:
                    </p>
                    <ul>
                        <li>Acknowledgement of Queries: We strive to respond to all queries in a timely manner. Please allow us a reasonable time frame, we aim to respond within 2 business days to your enquiries.
                        </li>
                    </ul>

                </section>

                <section id="section15" className='mt-10'>
                    <h2 className='pageTitle'>15. Accessibility Statement</h2>
                    <p className='subtitle'>15.1 Commitment to Accessibility:
                    </p>
                    <ul>
                        <li>Inclusivity: Blukite is committed to making its websites accessible to the widest possible audience, regardless of technology or ability. We are continually working to increase the accessibility and usability of our websites and in doing so, adhere to many of the available standards and guidelines.
                        </li>
                        <li>Conformity with Standards: Our goal is to conform to the guidelines provided by the Web Content Accessibility Guidelines (WCAG) 2.1, Level AA, which set forth how to make web content more accessible for people with disabilities.
                        </li>
                    </ul>
                    <p className='subtitle'>15.2 Ongoing Effort:
                    </p>
                    <ul>
                        <li>Continuous Improvement: We view accessibility as an ongoing effort and are continuously seeking solutions that will bring all areas of our websites up to the same level of overall web accessibility.
                        </li>
                        <li>Feedback and Suggestions: Should you experience any difficulty in accessing any part of our websites, or if you have suggestions on how we could improve our services in terms of accessibility, please feel free to contact us. Your feedback is invaluable to us.
                        </li>
                    </ul>
                    <p className='subtitle'>
                        15.3 Director of Diversity and Inclusion:

                    </p>
                    <ul>
                        <li>DDI's Role: Our Director of Diversity and Inclusion (DDI) plays a key role in ensuring our commitment to accessibility and inclusion across all our platforms.
                        </li>
                        <li>Contacting the DDI: For further assistance, or to offer suggestions regarding our accessibility services, please contact our DDI at DDI@blukite.net.
                        </li>
                    </ul>
                    <p className='subtitle'>15.4 Assistance and Resources:
                    </p>
                    <ul>
                        <li>Providing Support: For assistance with any accessibility issues, please reach out using the contact information provided in Section 14. Our team, including the DDI, is ready to provide support and ensure that you can access all content and features on our websites.
                        </li>
                    </ul>

                </section>
                <section id="section16" className='mt-10'>
                    <h2 className='pageTitle'>16. Effective Date</h2>
                    <p className='subtitle'>16.1 Date of Effectiveness:
                    </p>
                    <ul>
                        <li>Commencement of Terms: These Terms and Conditions are effective as of December 5th, 2023.
                        </li>
                        <li>Binding from Effective Date: From the above date, these terms will be binding and applicable to all users of the websites operated by Blukite.
                        </li>
                    </ul>
                    <p className='subtitle'>16.2 Acknowledgement of Users:
                    </p>
                    <ul>
                        <li>User Awareness: By using our websites from the Effective Date onwards, you acknowledge that you are bound by these updated Terms and Conditions and agree to comply with all its provisions.
                        </li>
                    </ul>

                </section>

                <section id="section17" className='mt-10'>
                    <h2 className='pageTitle'>17. Update Processes</h2>
                    <p className='subtitle'>17.1 Notification of Updates:
                    </p>
                    <ul>
                        <li>Automatic Notification: Customers of Blukite will be automatically notified of any changes to our legal documents, including but not limited to these Terms of Use, our Privacy Policy, and any other relevant legal agreements or notices.
                        </li>
                        <li>Method of Notification: Notifications will be provided through a method deemed most effective, which may include email communication, notifications within our apps, or a prominent notice on our websites.
                        </li>
                    </ul>
                    <p className='subtitle'>17.2 Clarity on Changes:
                    </p>
                    <ul>
                        <li>Details of Updates: Each notification will include clear information regarding the nature of the changes, highlighting any significant amendments made to the documents.
                        </li>
                        <li>Effective Date of Changes: The notification will also specify the date from which such changes will become effective, allowing users adequate time to review and understand the implications of the updates.
                        </li>
                    </ul>
                    <p className='subtitle'>
                        17.3 User Acknowledgement:
                    </p>
                    <ul>
                        <li>Continued Use as Acceptance: By continuing to use our websites, apps, or services after the effective date of these changes, users indicate their acceptance of the updated terms.
                        </li>
                        <li>Encouragement to Review Updates: We encourage all users to carefully review any updates to our legal documents, as continued use of our services constitutes agreement to be bound by these terms.
                        </li>
                    </ul>
                </section>

                <section id="section18" className='mt-10'>
                    <h2 className='pageTitle'>Closing Statement: Commitment to Fairness and Equity
                    </h2>
                    <p>Blukite is steadfastly committed to ensuring that all our customers, suppliers, and team members experience fairness and accessibility in their interactions with our products and services. We believe that providing equitable service is not just a responsibility, but a cornerstone of our business ethos.
                    </p>
                    <p>Our dedication to creating an inclusive environment extends beyond the mere provision of services; it is woven into the fabric of our corporate identity. We constantly strive to uphold these values in every aspect of our operations, fostering a culture of respect, inclusivity, and equal opportunity for all.
                    </p>
                    <p>We recognise that our strength lies in our diversity and in our commitment to accommodating the varied needs and expectations of our community. Blukite is devoted to continuously enhancing our practices, ensuring that we remain a leader in providing equitable, accessible, and fair services to everyone we serve.
                    </p>
                </section>



            </div>
        </div>
    );
}

export default TermsOfUse