import React from 'react';
import Speaker from '../../components/speaker/speaker';

const Disclaimer = () => {

    return (
        <div className="container my-16 md:my-24 infoPage">
            <div className="content">
                <h2 className='pageTitle'>Blukite Finance Limited Disclaimer</h2>
                <p>
                <Speaker id="240" text="Blukite Finance Limited Disclaimer. Blukite Finance Limited currently does not directly offer regulated financial services or engage in regulated activities. Our BluSys® Core Solution, however, is fully operational and designed to support regulated financial service providers. The content shared on this website is intended for informational purposes only and should not be construed as an offer or invitation to avail of financial services directly. We are excited about the active role Blukite Finance plays in the financial industry with our BluSys® Core Solution, which serves banks, credit unions, and lending institutions. Our technology enables these regulated entities to deliver innovative, integrated financial solutions to meet the digital demands of today's customers. Our offerings ensure flexibility, scalability, and efficiency, helping clients stay competitive and adaptable in a rapidly evolving market.
                    Details regarding the services, features, and offerings of Blukite Finance are accurate as of now but are subject to change as we continue to develop and expand our solutions. We are committed to providing our business clients and partners with precise and up-to-date information."/>
                    Blukite Finance Limited currently does not directly offer regulated financial services or engage in regulated activities. Our BluSys<sup>®</sup> Core Solution, however, is fully operational and designed to support regulated financial service providers. The content shared on this website is intended for informational purposes only and should not be construed as an offer or invitation to avail of financial services directly.
                </p>
                <p className='mt-2'>We are excited about the active role Blukite Finance plays in the financial industry with our BluSys<sup>®</sup> Core Solution, which serves banks, credit unions, and lending institutions. Our technology enables these regulated entities to deliver innovative, integrated financial solutions to meet the digital demands of today's customers. Our offerings ensure flexibility, scalability, and efficiency, helping clients stay competitive and adaptable in a rapidly evolving market.</p>
                <p className='mt-2'>Details regarding the services, features, and offerings of Blukite Finance are accurate as of now but are subject to change as we continue to develop and expand our solutions. We are committed to providing our business clients and partners with precise and up-to-date information.</p>
            </div>
        </div>
    );
}

export default Disclaimer