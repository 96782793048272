import React from 'react'

const ourMissions = () => {
    return (
        <section className='fadeIn pt-10 sm:pt-32'>
            <div className='container py-10'>

                <div className=''>
                        {/* <div className='font-bold text-xl uppercase'><span className='text-blue'>blu</span>kite <span className='text-blue'>finance</span></div> */}
                        <h1 className='uppercase text-2xl sm:text-4xl'><span className='font-bold'>Forging</span> the <span className='font-bold'>path</span> to <span className='font-bold'>inclusive financilal excellence</span></h1>
                </div>

                <div className='mt-5 text-lg'>
                    <p className='font-semibold'>Our Mission: Empowering Financial Innovation</p>
                    <p>Blukite Finance is committed to empowering every player in the financial sector, from time-honoured banks to cutting-edge FinTechs. We are set on breaking new ground, using our modular solutions to redefine lending services, fostering an environment where innovation leads to tangible advancements in financial accessibility and inclusion.</p>

                    <p className='font-semibold mt-10'>Our Approach: Championing Responsible Lending</p>
                    <p>We stand at the vanguard of responsible lending, enhancing accessibility to financial services for all customers. Our mission extends beyond mere compliance; we strive to exceed standards of consumer equity, advocating for practices that ensure fair treatment and ethical engagement across the financial spectrum.</p>

                    <p className='font-semibold mt-10'>Our Values: Partnership and Ethical Profitability</p>
                    <p>Our core values revolve around partnership, innovation, and adaptability. These principles guide us in delivering equitable solutions that are symbiotic with our partners' growth and risk management strategies. By aligning business expansion with ethical standards and regulatory frameworks, we foster profitability rooted in transparency and fairness.</p>

                    <p className='font-semibold mt-10'>Our Promise: Growth with Integrity</p>
                    <p>At Blukite Finance, we promise to facilitate our partners' journey towards brand recognition and profitability with utmost integrity. Our flexible suite of products is designed for efficiency and scalability, ensuring that our partners can achieve their business objectives while adhering to their social governance commitments and driving positive change in the financial industry. </p>
                </div>

            </div>
            <div className='mt-20 bg-blue p-5 md:p-10 lg:p-14'>
                <div className='container'>
                    {/* <div className='flex flex-col mx-auto w-fit uppercase'>
                        <div className='ml-0 sm:ml-14 flex w-fit gap-5'>
                            <img className='w-7 sm:w-10 lg:w-16' src="./images/ico/quote.svg" alt="quote mark" />
                            <p className='mt-5 lg:mt-8 text-white font-bold text-sm sm:text-lg md:text-2xl lg:text-4xl'>We strive <span className='font-light'>to</span> exceed</p>
                        </div>
                        <div className='flex  w-fit gap-5 mt-2'>
                            <p className='  text-white font-bold text-sm sm:text-lg md:text-2xl lg:text-4xl'>standards <span className='font-light'>of</span> consumer equity</p>
                            <img className='mt-0 w-7 sm:w-10 lg:w-16 ml-auto rotate-180' src="./images/ico/quote.svg" alt="quote mark" />
                        </div>
                    </div> */}

                    <div className='flex flex-col  w-fit mx-auto uppercase'>
                        <img className='w-7 sm:w-10 lg:w-16' src="./images/ico/quote.svg" alt="quote mark" />
                        <p className=' w-fit text-white font-bold text-base sm:text-lg lg:text-4xl py-2 sm:py-5'>We strive <span className='font-light'>to</span> exceed<br /> standards <span className='font-light'>of</span> consumer equity</p>
                        <img className='w-7 sm:w-10 lg:w-16 ml-auto rotate-180' src="./images/ico/quote.svg" alt="quote mark" />

                        <div className='font-bold'><span className='text-darkBlue'>blu</span>kite finance</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ourMissions