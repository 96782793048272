import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';
import { PlainButton } from '../../components/buttons/buttons';
import { useNavigate } from "react-router-dom";

const WhiteLabelConsumerApp = () => {

    let navigate = useNavigate();

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage productDevelopmentAndMarketAnalysis">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Accessibility Features',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Integrating VIA',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'Biometric cards',
                        },
                        {
                            key: 'section5',
                            href: '#section5',
                            title: 'TGM Technology',
                        }
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Accessibility Features',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Integrating VIA',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'Biometric cards',
                        },
                        {
                            key: 'section5',
                            href: '#section5',
                            title: 'TGM Technology',
                        }
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <div className='flex gap-10 xl:gap-20 justify-between'>
                        <div className='w-full xl:w-7/12'>
                            <h2 className='pageTitle'>White Label Payment App by Blukite Finance: Empowering Inclusivity</h2>
                            <div className='mt-5 lg:mt-10 xl:mt-0'>
                                <img className='block xl:hidden float-right w-44 ml-5 -mt-0 lg:-mt-10' src={`${baseURL}/images/WhiteLabelConsumerApp/1.png`} alt="mobile phones" />
                                <p className='text text-justify xl:text-left'><Speaker id="122" text="White Label Payment App by Blukite Finance: Empowering Inclusivity. Blukite Finance revolutionises the digital financial landscape with our White Label 
                                    Payment App, now with enhanced focus on accessibility and cutting-edge technology. 
                                    We have streamlined the extensive investment of time and financial resources required 
                                    for developing bespoke consumer apps into a fully customisable mobile application 
                                    solution. Our platform enables businesses to rapidly deploy consumer-facing apps that 
                                    ensure an inclusive user experience, catering to all customers regardless of their 
                                    physical or cognitive abilities. Partnering with Blukite Finance for your White Label 
                                    Payment App positions your business as a technology-forward entity and an inclusive 
                                    brand that values all customers. Our commitment to accessibility ensures that your app
                                    is compliant with global accessibility standards, enhancing user satisfaction and 
                                    broadening your market reach."/>
                                    Blukite Finance revolutionises the digital financial landscape with our White Label
                                    Payment App, now with enhanced focus on accessibility and cutting-edge technology.
                                    We have streamlined the extensive investment of time and financial resources required
                                    for developing bespoke consumer apps into a fully customisable mobile application
                                    solution. Our platform enables businesses to rapidly deploy consumer-facing apps that
                                    ensure an inclusive user experience, catering to all customers regardless of their
                                    physical or cognitive abilities. Partnering with Blukite Finance for your White Label
                                    Payment App positions your business as a technology-forward entity and an inclusive
                                    brand that values all customers. Our commitment to accessibility ensures that your app
                                    is compliant with global accessibility standards, enhancing user satisfaction and
                                    broadening your market reach.</p>
                            </div>
                            <p className='subtitle font-normal mt-10 lg:mt-20 italic'>Accessibility at the Forefront</p>
                            <p className='text italic'>
                                <Speaker id="123" text="Accessibility at the Forefront. Understanding the diverse needs of all users, we've ingrained accessibility features into the core of our White Label Payment App: Screen Reader Compatibility.
                        Ensuring that visually impaired users can navigate the app effectively with text-to-speech software.
                        Easy Navigation.
                        Designing a user interface that is intuitive and easy to navigate for users with physical or cognitive disabilities, including those who use assistive technologies.
                        Subtitles and Transcripts.
                        Including subtitles and transcripts for audio and video content to assist users who are deaf or hard of hearing.
                        Adjustable Contrast & Font.
                        Catering to users with visual impairments by providing options to adjust the visual aspects of the app for better readability - High Contrast Modes & Adjustable Font Sizes."/>
                                Understanding the diverse needs of all users, we've ingrained accessibility features into the core of our White Label Payment App:</p>
                        </div>
                        <div className='w-72 mr-20 xl:mr-32 hidden xl:block'>
                            <img className='' src={`${baseURL}/images/WhiteLabelConsumerApp/1.png`} alt="mobile phones" />
                        </div>
                    </div>


                </section>
                <section id="section2" className=''>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-0 sm:gap-10 lg:gap-20 relative my-10 text-left sm:text-center'>
                        <img className='hidden xl:block absolute top-10 w-9/12 -z-10  left-1/2 transform -translate-x-1/2' src={`${baseURL}/images/ico/WhiteLabelConsumerApp/dashedLine.svg`} alt="dashed arrow illustration" />
                        <div className='flex flex-row sm:flex-col items-center'>
                            <img src={`${baseURL}/images/ico/WhiteLabelConsumerApp/1.svg`} className='bg-white p-5' alt="icon for Screen Reader Compatibility" />
                            <div className=''>
                                <p className='font-normal mt-3'>Screen Reader Compatibility</p>
                                <p className='mt-1 leading-tight'>Ensuring that visually impaired users can navigate the app effectively with text-to-speech software.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center'>
                            <img src={`${baseURL}/images/ico/WhiteLabelConsumerApp/2.svg`} className='bg-white p-5' alt="icon for Easy Navigation" />
                            <div className=''>
                                <p className='font-normal mt-3'>Easy Navigation</p>
                                <p className='mt-1 leading-tight'>Designing a user interface that is
                                    intuitive and easy to navigate for
                                    users with physical or cognitive
                                    disabilities, including those who
                                    use assistive technologies.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center'>
                            <img src={`${baseURL}/images/ico/WhiteLabelConsumerApp/3.svg`} className='bg-white p-5' alt="icon for Subtitles and Transcripts" />
                            <div className=''>
                                <p className='font-normal mt-3'>Subtitles and Transcripts</p>
                                <p className='mt-1 leading-tight'>Including subtitles and transcripts
                                    for audio and video content to
                                    assist users who are deaf or
                                    hard of hearing.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center'>
                            <img src={`${baseURL}/images/ico/WhiteLabelConsumerApp/4.svg`} className='bg-white p-5' alt="icon for Adjustable Contrast & Font" />

                            <div className=''>
                                <p className='font-normal mt-3'>Adjustable Contrast & Font</p>
                                <p className='mt-1 leading-tight'>Catering to users with visual impairments by providing options to adjust the visual aspects of the app for better readability - High Contrast Modes & Adjustable Font Sizes.</p>
                            </div>
                        </div>
                    </div>

                    <div className='my-10 sm:my-16 bodrer w-full 2xl:w-9/12 mx-auto flex gap-10 xl:gap-20'>
                        <div className='hidden lg:block w-64 xl:w-72 flex-none'><img className='' src={`${baseURL}/images/WhiteLabelConsumerApp/2.png`} alt="image of the phone front view with setting sscreen on it" /></div>
                        <div>
                            <p className='subtitle font-normal italic'>Commitment to Universal Design</p>
                            <p className='mt-5 text w-full'><Speaker id="124" text="Commitment to Universal Design. Our approach to integrating accessibility features is guided by the principles of universal design, ensuring that the app is:
                            Equitable.
                            Providing the same means of use for all users: identical whenever possible, equivalent when not.
                            Flexible.
                            Accommodating a diverse array of individual preferences and abilities, ensuring that the app caters to a broad spectrum of users.
                            Simple and Intuitive.
                            Making the app understandable regardless of the user's experience, knowledge, language skills, or current concentration level.
                            "/>
                                Our approach to integrating accessibility features is guided by the
                                principles of universal design, ensuring that the app is:</p>

                            <div className='mt-5 flex flex-col gap-5'>
                                <div className='py-3 px-7 rounded-xl customShadow flex gap-6'>
                                    <img src={`${baseURL}/images/ico/WhiteLabelConsumerApp/5.svg`} alt="icon for Equitable" />
                                    <div>
                                        <p className='font-normal'>Equitable</p>
                                        <p className='mt-1'>Providing the same means of use for all users: identical whenever possible, equivalent when not.</p>
                                    </div>
                                </div>

                                <div className='py-3 px-7 rounded-xl customShadow flex gap-6'>
                                    <img src={`${baseURL}/images/ico/WhiteLabelConsumerApp/6.svg`} alt="icon for Flexible" />
                                    <div>
                                        <p className='font-normal'>Flexible</p>
                                        <p className='mt-1'>Accommodating a diverse array of individual preferences and abilities, ensuring that the app caters to a broad spectrum of users.</p>
                                    </div>
                                </div>

                                <div className='py-3 px-7 rounded-xl customShadow flex gap-6'>
                                    <img src={`${baseURL}/images/ico/WhiteLabelConsumerApp/7.svg`} alt="icon for Simple and Intuitive" />
                                    <div>
                                        <p className='font-normal'>Simple and Intuitive</p>
                                        <p className='mt-1'>Making the app understandable regardless of the user's experience, knowledge, language skills, or current concentration level.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <p className='text'>
                        <Speaker id="125" text="Additionally, integrating our solutions aligns with your Environmental, Social, and Governance (ESG) goals, particularly in social governance. By prioritising inclusivity and 
                    accessibility, you demonstrate a commitment to consumer equitability, expanding your reach to a more diverse customer base and promoting social responsibility. This approach 
                    not only benefits your business ethically but also fosters loyalty and trust among consumers who value equitable access to financial services. Choose Blukite Finance's White 
                    Label Payment App to elevate your brand's digital presence with an accessible, engaging, and customisable mobile experience. Our platform empowers you to connect with your 
                    customers inclusively, fostering loyalty and driving growth.Explore the possibilities with Blukite Finance. Contact us today to learn how our accessible and customisable app 
                    solution can make a difference for your business and your customers." />
                        Additionally, integrating our solutions aligns with your Environmental, Social, and Governance (ESG) goals, particularly in social governance. By prioritising inclusivity and
                        accessibility, you demonstrate a commitment to consumer equitability, expanding your reach to a more diverse customer base and promoting social responsibility. This approach
                        not only benefits your business ethically but also fosters loyalty and trust among consumers who value equitable access to financial services. Choose Blukite Finance's White
                        Label Payment App to elevate your brand's digital presence with an accessible, engaging, and customisable mobile experience. Our platform empowers you to connect with your
                        customers inclusively, fostering loyalty and driving growth.Explore the possibilities with Blukite Finance. Contact us today to learn how our accessible and customisable app
                        solution can make a difference for your business and your customers.</p>

                    <div className="relative w-full my-10">
                        <video className='w-full' loop controls={true} poster={`${basePath}/images/videoThubnail.webp`}>
                            <source src={`${basePath}/videos/the_accessible_payment_network.mp4`} type="video/mp4" />
                        </video>
                    </div>
                </section>
                <section id="section3" className=''>


                    <p className='subtitle font-normal mt-10 lg:mt-20 italic'>Integrating VIA: Advanced Analytics and Insights</p>
                    <p className='text italic w-full lg:w-7/12'>
                        <Speaker id="223" text="ntegrating VIA: Advanced Analytics and Insights. 
                        Blukite Finance's White Label Payment App now includes VIA, our advanced analytics and insights platform. VIA provides businesses with detailed consumer behaviour analytics, helping you make data-driven decisions to enhance customer engagement and satisfaction. 
                        1.
                        Real-Time Data
                        Access up-to-the-minute analytics to monitor app performance and user interactions.
                        2.
                        Consumer Insights
                        Gain a deep understanding of consumer behaviour, preferences, and trends.
                        3. 
                        Custom Reports
                        Generate tailored reports to meet your specific business needs.
                        4. 
                        Predictive Analytics
                        Utilise AI-driven insights to forecast trends and optimise your offerings.
                        "/>
                        Blukite Finance's White Label Payment App now includes VIA, our advanced analytics and insights platform.
                        VIA provides businesses with detailed consumer behaviour analytics, helping you make data-driven decisions to
                        enhance customer engagement and satisfaction.</p>


                    <div className='block lg:hidden'>
                        <div className='numberItems my-5 sm:my-10 gap-3 sm:gap-5 '>
                            <div className='numberItem'>
                                <div className='number'>1</div>
                                <div className='numberItemContent'>
                                    <p className='font-normal'>Real-Time Data</p>
                                    <p className='mt-2'>Access up-to-the-minute analytics
                                        to monitor app performance and
                                        user interactions.</p>
                                </div>
                            </div>

                            <div className='numberItem'>
                                <div className='number'>2</div>
                                <div className='numberItemContent'>
                                    <p className='font-normal'>Consumer Insights</p>
                                    <p className='mt-2'>Gain a deep understanding
                                        of consumer behaviour,
                                        preferences, and trends.</p>
                                </div>
                            </div>

                            <div className='numberItem'>
                                <div className='number'>3</div>
                                <div className='numberItemContent'>
                                    <p className='font-normal'>Custom Reports</p>
                                    <p className='mt-2'>Generate tailored reports
                                        to meet your specific
                                        business needs.</p>
                                </div>
                            </div>

                            <div className='numberItem'>
                                <div className='number'>4</div>
                                <div className='numberItemContent'>
                                    <p className='font-normal'>Predictive Analytics</p>
                                    <p className='mt-2'>Utilise AI-driven insights
                                        to forecast trends and
                                        optimise your offerings.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src={`${baseURL}/images/WhiteLabelConsumerApp/3.svg`} className='hidden lg:block w-full -mt-16' alt='Integrating VIA: Advanced Analytics and Insights' />
                </section>
                <section id="section4" className=''>

                    <p className='subtitle font-normal mt-10 lg:mt-20 italic text-center'>Enhancing Security with Biometric Cards and Patent Pending TGM</p>
                    <p className='text italic text-center'>
                        <Speaker id="224" text="Enhancing Security with Biometric Cards and Patent Pending TGM. 
Blukite Finance enhances security and user experience through the integration of biometric cards with patent pending TGM (Tactile Guidance Markers). We are dedicated to leveraging advanced technology to enhance security, convenience, and accessibility for its consumers, supporting a safer and more inclusive financial ecosystem. By focusing on inclusive design and innovative security solutions, we ensure that all users, regardless of their physical abilities or technological proficiency, have full access to our services and can manage their finances with autonomy and ease."/>
                        Blukite Finance enhances security and user experience through the integration of biometric cards with patent pending TGM (Tactile Guidance Markers). We are dedicated to leveraging advanced technology to enhance security, convenience, and accessibility for its consumers, supporting a safer and more inclusive financial ecosystem. By focusing on inclusive design and innovative security solutions, we ensure that all users, regardless of their physical abilities or technological proficiency, have full access to our services and can manage their finances with autonomy and ease.</p>
                    <div className='mt-20'>

                        <div className='w-full customShadow rounded-3xl relative overflow-hidden w-full xl:w-auto flex flex-col-reverse lg:flex-row mt-7 gap-5 xl:gap-7'>
                            <div className='w-full lg:w-6/12 xl:w-4/12 p-5 sm:p-10'>
                                <img src={`${basePath}/images/ico/blueCircles/3.svg`} alt="icons for TGM Technology" />
                                <p className='font-normal text-lg mt-5 leading-tight'>Seamless User Experience</p>
                                <p className='mt-2 leading-tight'>
                                    <Speaker id="225" text="Seamless User Experience. The biometric authentication process is 
quick and intuitive, streamlining transactions 
without the need for PINs or signatures."/>
                                    The biometric authentication process is
                                    quick and intuitive, streamlining transactions
                                    without the need for PINs or signatures.
                                </p>
                            </div>
                            <div className='w-full lg:w-6/12 xl:w-8/12'>
                                <video className='w-full h-full object-cover' autoPlay muted loop playsInline>
                                    <source src={`${basePath}/videos/TaluDemo.mp4`} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                        <div className='flex flex-col xl:flex-row mt-7 gap-7'>
                            <div className='flex-1 customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-auto'>
                                <img src={`${basePath}/images/ico/blueCircles/4.svg`} alt="icons for Security & Data Protection" />
                                <p className='font-normal text-lg mt-5 leading-tight'>Security & Data Protection</p>
                                <div className='w-full'>
                                    <p className='mt-2 leading-tight'>
                                        <Speaker id="226" text="Security & Data Protection. Enhanced Security: The biometric chip introduces a new level of security 
for financial transactions, significantly reducing the risk of unauthorised 
access and fraudulent activities. Privacy and Data Protection: Biometric data is stored securely on the card 
itself, minimising privacy concerns."/>
                                        <span className='font-normal'>Enhanced Security: </span>
                                        The biometric chip introduces a new level of security
                                        for financial transactions, significantly reducing the risk of unauthorised
                                        access and fraudulent activities. </p>
                                    <p className='mt-2 leading-tight'><span className='font-normal'>Privacy and Data Protection: </span>Biometric data is stored securely on the card
                                        itself, minimising privacy concerns.</p>
                                </div>
                            </div>

                            <div className='flex-1 customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-auto'>
                                <img src={`${basePath}/images/ico/blueCircles/5.svg`} alt="icons for Accessibility & Innovation" />
                                <p className='font-normal text-lg mt-5 leading-tight'>Accessibility & Innovation</p>
                                <div className='w-full'>
                                    <p className='mt-2 leading-tight'>
                                        <Speaker id="227" text="Accessibility & Innovation. Increased Accessibility: For individuals with disabilities or those who find 
traditional security measures challenging, biometric verification offers an 
accessible alternative. Future-Proof Technology: Adopting biometric technology positions Blukite 
Finance at the forefront of innovation, readying consumers for the future of 
secure contactless payments."/>
                                        <span className='font-normal'>Increased Accessibility: </span>
                                        For individuals with disabilities or those who find
                                        traditional security measures challenging, biometric verification offers an
                                        accessible alternative. </p>
                                    <p className='mt-2 leading-tight'><span className='font-normal'>Future-Proof Technology: </span>Adopting biometric technology positions Blukite
                                        Finance at the forefront of innovation, readying consumers for the future of
                                        secure contactless payments.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="section5" className=''>

                    <div className='mb-20'>
                        <div className='w-full customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-auto flex flex-col xl:flex-row mt-7 gap-7 items-center'>
                            <div className='w-10/12 sm:w-6/12 xl:w-3/12'>
                                <img className='w-full' src={`${baseURL}/images/WhiteLabelConsumerApp/4.svg`} alt='icon of TGM (Tactile Guidance Markers) - Patent Pending' />
                            </div>
                            <div className='w-full xl:w-9/12'>
                                <img src={`${basePath}/images/ico/blueCircles/6.svg`} alt="icons for TGM Technology" />
                                <p className='font-normal text-lg mt-5 leading-tight'>TGM (Tactile Guidance Markers) - Patent Pending</p>
                                <p className='mt-2 leading-tight'>
                                    <Speaker id="228" text="TGM (Tactile Guidance Markers) - Patent Pending. Our innovation combines the security benefits of biometric fingerprint recognition with a universal tactile 
guidance system, facilitating easier and more secure card transactions for all users. The use of raised 
tactile sensory aids, such as Braille or similar markers, provides a physical guide to the required location for 
the biometric sensor. This system enhances the card's accessibility by ensuring that users can accurately 
position their finger on the sensor, thus eliminating positioning errors during the payment cycle."/>
                                    Our innovation combines the security benefits of biometric fingerprint recognition with a universal tactile
                                    guidance system, facilitating easier and more secure card transactions for all users. The use of raised
                                    tactile sensory aids, such as Braille or similar markers, provides a physical guide to the required location for
                                    the biometric sensor. This system enhances the card's accessibility by ensuring that users can accurately
                                    position their finger on the sensor, thus eliminating positioning errors during the payment cycle.
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col xl:flex-row mt-7 gap-7'>
                            <div className='flex-1 customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-auto'>
                                <img src={`${basePath}/images/ico/blueCircles/7.svg`} alt="icons for TGM Technology" />
                                <p className='font-normal text-lg mt-5 leading-tight'>TGM Technology</p>
                                <div className='w-full'>
                                    <p className='mt-2 leading-tight'>
                                        <Speaker id="229" text="TGM Technology. Braille Markers: Raised Braille dots offer visually impaired 
users a tactile method to locate the sensor. Drop on Demand: Raised dots or patterns that can be 
easily felt, guiding users to the correct finger placement. Tactile Differentiation: Various textures or shapes on the 
card surface help users identify the biometric sensor 
location quickly and accurately."/>
                                        <span className='font-normal'>Braille Markers: </span>
                                        Raised Braille dots offer visually impaired
                                        users a tactile method to locate the sensor. </p>
                                    <p className='mt-2 leading-tight'><span className='font-normal'>Drop on Demand: </span>Raised dots or patterns that can be
                                        easily felt, guiding users to the correct finger placement.</p>
                                    <p className='mt-2 leading-tight'><span className='font-normal'>Tactile Differentiation: </span>Various textures or shapes on the
                                        card surface help users identify the biometric sensor
                                        location quickly and accurately.</p>
                                </div>
                            </div>

                            <div className='flex-1 customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-auto'>
                                <div className='w-full'>
                                    <p className='mt-2 leading-tight'>
                                        <Speaker id="230" text="This tactile guidance system significantly improves the user experience, making 
biometric authentication more reliable and accessible. Our TGM technology is 
currently patent pending, underscoring our commitment to pioneering advancements
in financial accessibility and security."/>
                                        This tactile guidance system significantly improves the user experience, making
                                        biometric authentication more reliable and accessible. Our TGM technology is
                                        currently patent pending, underscoring our commitment to pioneering advancements
                                        in financial accessibility and security.
                                    </p>
                                    <div className='w-full sm:w-5/12 mt-10'>
                                        <PlainButton onClick={() => navigate(`${baseURL}/`)} text="More info" />
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </section>
            </div>
        </div>
    );
}

export default WhiteLabelConsumerApp