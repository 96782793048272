import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Anchor } from 'antd';
import { basePath, baseURL } from '../../routes';
import { PlainButton } from '../../components/buttons/buttons';
import Speaker from '../../components/speaker/speaker';
import { useNavigate } from "react-router-dom";
import { openPopup } from '../../actions/popup';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';
import './tailoredServices.scss'

const mapDispatchToProps = dispatch => ({
    openPopup: (payload) => {
        return dispatch(openPopup(payload));
    }
})

const CreditUnions = ({ openPopup }) => {

    let navigate = useNavigate();

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage creditUnions">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Consumer Lending '
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Forging a Future Together',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Seamless Integration',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'Enhancing Consumer Lending',
                        },
                        {
                            key: 'section5',
                            href: '#section5',
                            title: 'Advancing Financial Inclusion',
                        },
                        {
                            key: 'section6',
                            href: '#section6',
                            title: 'Vision for Success',
                        },
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Consumer Lending '
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Forging a Future Together',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Seamless Integration',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'Enhancing Consumer Lending',
                        },
                        {
                            key: 'section5',
                            href: '#section5',
                            title: 'Advancing Financial Inclusion',
                        },
                        {
                            key: 'section6',
                            href: '#section6',
                            title: 'Vision for Success',
                        }
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <h2 className='pageTitle'>Unlocking Growth and Innovation for Credit Unions with Blukite Finance</h2>
                    <p className='text'>
                        <Speaker id="191" text="Unlocking Growth and Innovation for Credit Unions with Blukite Finance. Blukite Finance introduces a suite of Tailored Services for Credit Unions, designed to support their unique needs and drive growth without significant upfront investment. Recognising
                        the diversity and financial capabilities across credit unions, Blukite's offerings are crafted to boost operational efficiency, broaden financial product ranges, and elevate member
                        experiences. This partnership ensures credit unions can seamlessly integrate innovative consumer lending options, adhere to regulatory compliance, leverage digital banking
                        advancements, and enhance accessibility, aligning with their community-focused ethos while securing a competitive edge in the evolving financial landscape." />
                        Blukite Finance introduces a suite of Tailored Services for Credit Unions, designed to support their unique needs and drive growth without significant upfront investment. Recognising
                        the diversity and financial capabilities across credit unions, Blukite's offerings are crafted to boost operational efficiency, broaden financial product ranges, and elevate member
                        experiences. This partnership ensures credit unions can seamlessly integrate innovative consumer lending options, adhere to regulatory compliance, leverage digital banking
                        advancements, and enhance accessibility, aligning with their community-focused ethos while securing a competitive edge in the evolving financial landscape.</p>

                    <div className='customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden flex gap-10 xl:gap-20 mt-10' data-aos="fade-down-right" data-aos-duration="800">
                        <img className='hidden xl:block flex-1 ml-0 2xl:ml-10' src={`${basePath}/images/CreditUnions/1.svg`} alt="illustration for System Integration Solutions" />

                        <div className='w-full flex-2'>
                            <img src={`${basePath}/images/ico/planetWithArrows.svg`} alt="icons for Expanding Horizons in Consumer Lending" />
                            <p className='font-normal text-lg mt-5 italic'>Expanding Horizons in Consumer Lending</p>
                            <img className='block xl:hidden w-5/12 lg:w-4/12 float-left mr-5 mt-5' src={`${basePath}/images/CreditUnions/1.svg`} alt="illustration for System Integration Solutions" />
                            <p className='mt-2'>
                            <Speaker id="192" text="Expanding Horizons in Consumer Lending. Credit unions stand at the cusp of a transformative opportunity in consumer lending, a domain
                                that promises not only to amplify their profitability but also to broaden their impact within the
                                communities they serve. By venturing into consumer lending, credit unions can unlock new
                                market segments, catering to a wider array of financial needs and preferences. This strategic
                                move not only diversifies their portfolio but also significantly enhances member benefits,
                                positioning credit unions as comprehensive financial service providers. The shift towards
                                consumer lending is not merely an expansion of services; it's an evolution that aligns with
                                the changing dynamics of financial consumption, inviting credit unions to explore innovative
                                lending solutions that resonate with the modern member's lifestyle and financial aspirations."/>
                                Credit unions stand at the cusp of a transformative opportunity in consumer lending, a domain
                                that promises not only to amplify their profitability but also to broaden their impact within the
                                communities they serve. By venturing into consumer lending, credit unions can unlock new
                                market segments, catering to a wider array of financial needs and preferences. This strategic
                                move not only diversifies their portfolio but also significantly enhances member benefits,
                                positioning credit unions as comprehensive financial service providers. The shift towards
                                consumer lending is not merely an expansion of services; it's an evolution that aligns with
                                the changing dynamics of financial consumption, inviting credit unions to explore innovative
                                lending solutions that resonate with the modern member's lifestyle and financial aspirations.</p>
                        </div>
                    </div>
                </section>

                <section id="section2">
                    <div className='flex flex-col xl:flex-row mt-7 gap-7'>
                        <div className='customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-7/12' data-aos="fade-up-right" data-aos-duration="800">
                            <img className='absolute -bottom-5 right-10' src={`${basePath}/images/CreditUnions/2.svg`} alt="illustration for System Integration Solutions" />
                            <div>
                                <img src={`${basePath}/images/ico/TailoredServicesBuildingSocieties/9.svg`} alt="icons for Forging a Future Together" />
                                <p className='w-7/12 xl:w-9/12 font-normal text-lg mt-5 italic'>Forging a Future Together</p>
                                <div className='w-full'>
                                    <p className='mt-2 mb-28'>
                                    <Speaker id="193" text="Forging a Future Together. Embarking on a strategic partnership with Blukite Finance enables credit unions to
                                        undertake a significant transformation. This alliance is meticulously crafted to address
                                        the unique challenges and opportunities facing credit unions today. By modernising
                                        operations, Blukite Finance helps credit unions to streamline their processes, enhance
                                        their technological capabilities, and position themselves at the forefront of financial
                                        innovation. Diversifying financial products through this partnership not only expands the
                                        range of services offered to members but also opens upnew avenues for growth and
                                        engagement. Furthermore, Blukite's commitment to inclusivity ensures that these
                                        services reach a wider audience, making financial health accessible to all members of the
                                        community. This comprehensive approach reinforces the crucial role of credit unions in
                                        their members' financial well-being, marking a pivotal step towards a future where credit
                                        unions continue to thrive and lead in the financial sector."/>
                                        Embarking on a strategic partnership with Blukite Finance enables credit unions to
                                        undertake a significant transformation. This alliance is meticulously crafted to address
                                        the unique challenges and opportunities facing credit unions today. By modernising
                                        operations, Blukite Finance helps credit unions to streamline their processes, enhance
                                        their technological capabilities, and position themselves at the forefront of financial
                                        innovation. Diversifying financial products through this partnership not only expands the
                                        range of services offered to members but also opens upnew avenues for growth and
                                        engagement. Furthermore, Blukite's commitment to inclusivity ensures that these
                                        services reach a wider audience, making financial health accessible to all members of the
                                        community. This comprehensive approach reinforces the crucial role of credit unions in
                                        their members' financial well-being, marking a pivotal step towards a future where credit
                                        unions continue to thrive and lead in the financial sector.</p>
                                </div>
                            </div>
                        </div>
                        <div className='customShadow rounded-3xl p-5 sm:p-10 w-full xl:w-5/12' data-aos="fade-up" data-aos-duration="800">
                            <img src={`${basePath}/images/ico/TailoredServicesBanks/2.svg`} alt="icons for Seamless Integration & Steady Growth " />
                            <p className='font-normal text-lg mt-5 italic'>Seamless Integration & Steady Growth</p>
                            <p className='mt-2'>
                            <Speaker id="194" text="Seamless Integration & Steady Growth. Integrating with Blukite's technology enables credit
                                unions to access a broad spectrum of financial
                                services seamlessly. This process is designed to be
                                straightforward, involving initial consultations to
                                understand specific needs, followed by a phased
                                implementation strategy that ensures compatibility
                                with existing systems. Key steps include system
                                compatibility assessment, customisation of services
                                like credit issuing, debt management, and risk
                                management to fit the credit union's requirements,
                                followed by rigorous testing phases to ensure
                                seamless functionality. Training sessions are
                                provided to ensure staff are fully equipped to
                                leverage the new capabilities. This approach allows
                                credit unions to expand their offerings and enhance
                                operational efficiency without disrupting their core
                                services, ensuring a smooth transition to more
                                advanced, member-focused services."/>
                                Integrating with Blukite's technology enables credit
                                unions to access a broad spectrum of financial
                                services seamlessly. This process is designed to be
                                straightforward, involving initial consultations to
                                understand specific needs, followed by a phased
                                implementation strategy that ensures compatibility
                                with existing systems. Key steps include system
                                compatibility assessment, customisation of services
                                like credit issuing, debt management, and risk
                                management to fit the credit union's requirements,
                                followed by rigorous testing phases to ensure
                                seamless functionality. Training sessions are
                                provided to ensure staff are fully equipped to
                                leverage the new capabilities. This approach allows
                                credit unions to expand their offerings and enhance
                                operational efficiency without disrupting their core
                                services, ensuring a smooth transition to more
                                advanced, member-focused services.</p>
                        </div>
                    </div>
                </section>
                <section id="section4">
                    <div className='customShadow rounded-3xl p-5 sm:p-10 mt-7 relative overflow-hidden' data-aos="fade-up" data-aos-duration="800">
                        <img className='absolute -top-16 sm:-top-10 xl:top-0 right-0' src={`${basePath}/images/CreditUnions/3.svg`} alt="illustration for System Integration Solutions" />

                        <img src={`${basePath}/images/ico/TailoredServicesBanks/3.svg`} alt="icons for Enhancing Consumer Lending with Blukite's Innovative Solutions" />
                        <p className='font-normal text-lg mt-5 italic'>Enhancing Consumer Lending with Blukite's Innovative Solutions</p>
                        <p className='mt-2'>
                        <Speaker id="195" text="Enhancing Consumer Lending with Blukite's Innovative Solutions. Blukite Finance specialises in elevating the consumer lending capabilities of credit unions through advanced technological solutions and strategic support. Our goal is
                            to empower credit unions to not just maintain but significantly enhance their consumer lending services. With Blukite, credit unions can access cutting-edge tools for
                            risk assessment, loan management, and digital service delivery, ensuring they can meet and exceed the evolving expectations of their members. This partnership is
                            designed to solidify credit unions' positions as leading financial providers in their communities, offering both traditional and innovative lending solutions that cater
                            comprehensively to member needs."/>
                            Blukite Finance specialises in elevating the consumer lending capabilities of credit unions through advanced technological solutions and strategic support. Our goal is
                            to empower credit unions to not just maintain but significantly enhance their consumer lending services. With Blukite, credit unions can access cutting-edge tools for
                            risk assessment, loan management, and digital service delivery, ensuring they can meet and exceed the evolving expectations of their members. This partnership is
                            designed to solidify credit unions' positions as leading financial providers in their communities, offering both traditional and innovative lending solutions that cater
                            comprehensively to member needs.</p>
                    </div>
                </section>

                <section id="section5" className='my-10 sm:my-20'>
                    <h2 className='pageTitle'>Advancing Financial Inclusion: Blukite’s Commitment to Accessibility</h2>
                    <p className='text'>
                    <Speaker id="196" text="Advancing Financial Inclusion: Blukite’s Commitment to Accessibility. Blukite Finance's commitment to accessibility and inclusion ensures that credit unions can offer financial services that are accessible to all members, including those with
                        disabilities. This commitment is fundamental to fostering financial inclusion, perfectly aligning with the core values of credit unions that prioritise community welfare and
                        member service."/>
                        Blukite Finance's commitment to accessibility and inclusion ensures that credit unions can offer financial services that are accessible to all members, including those with
                        disabilities. This commitment is fundamental to fostering financial inclusion, perfectly aligning with the core values of credit unions that prioritise community welfare and
                        member service. </p>

                    <div className='w-full xl:w-10/12 2xl:w-8/12 mx-auto flex flex-col-reverse lg:flex-row mt-10 xl:mt-20 items-center gap-10 2xl:gap-20'>
                        <div className=' flex-1'><p className='text'>
                        <Speaker id="197" text="By partnering with Blukite, credit unions can leverage innovative
                            solutions designed to remove barriers, making financial products
                            and services more inclusive and ensuring that every member has
                            equal access to the financial support they need. Credit unions
                            interested in leveraging these innovative solutions to enhance their
                            service offerings are invited to explore the Enhanced Accessibility
                            tab on our website for more information."/>
                            By partnering with Blukite, credit unions can leverage innovative
                            solutions designed to remove barriers, making financial products
                            and services more inclusive and ensuring that every member has
                            equal access to the financial support they need. Credit unions
                            interested in leveraging these innovative solutions to enhance their
                            service offerings are invited to explore the Enhanced Accessibility
                            tab on our website for more information.</p>
                            <div className='mt-5 w-full sm:w-64'>
                                <PlainButton text="More info" onClick={() => navigate(`${baseURL}/enhanced_accessibility`)} />
                            </div>
                        </div>
                        <div className=''><img src={`${basePath}/images/CreditUnions/4.svg`} alt="illustaration for Advancing Financial Inclusion: Blukite’s Commitment to Accessibility" /></div>
                    </div>

                </section>

                <section id="section6" className='my-10 sm:my-20'>
                    <h2 className='pageTitle'>Vision for Success</h2>
                    <p className='text'>
                    <Speaker id="198" text="Vision for Success. The partnership between credit unions and Blukite Finance is envisioned to bring transformative benefits, enhancing member satisfaction through more inclusive services and
                        bolstering profitability and competitiveness in the market. This collaboration is set to provide a comprehensive growth path, aligning with future-ready strategies to ensure credit
                        unions not only meet but exceed the evolving needs of their communities. Together, we aim to redefine the landscape of financial services, making them more accessible, efficient,
                        and responsive to member needs, setting a new standard for success in the credit union sector."/>
                    The partnership between credit unions and Blukite Finance is envisioned to bring transformative benefits, enhancing member satisfaction through more inclusive services and
                        bolstering profitability and competitiveness in the market. This collaboration is set to provide a comprehensive growth path, aligning with future-ready strategies to ensure credit
                        unions not only meet but exceed the evolving needs of their communities. Together, we aim to redefine the landscape of financial services, making them more accessible, efficient,
                        and responsive to member needs, setting a new standard for success in the credit union sector.</p>

                    <div className='w-full xl:w-10/12 2xl:w-8/12 mx-auto flex flex-col lg:flex-row mt-10 xl:mt-20 items-center gap-10 2xl:gap-20'>
                        <div className=''><img src={`${basePath}/images/CreditUnions/5.svg`} alt="illustaration for Advancing Financial Inclusion: Blukite’s Commitment to Accessibility" /></div>
                        <div className=' flex-1'><p className='text'>
                        <Speaker id="199" text="Blukite Finance invites credit unions to embrace a future where growth
                            and inclusivity converge seamlessly. This call to action encourages a
                            partnership that extends beyond mere financial transactions, offering
                            support and innovation for credit unions ready to explore new realms
                            of financial services. With Blukite, expand confidently into untapped
                            markets and enhance your offerings, all while upholding the values of
                            community and inclusivity that define your mission. Join us in taking a
                            significant step toward a prosperous, inclusive future for your members."/>
                        Blukite Finance invites credit unions to embrace a future where growth
                            and inclusivity converge seamlessly. This call to action encourages a
                            partnership that extends beyond mere financial transactions, offering
                            support and innovation for credit unions ready to explore new realms
                            of financial services. With Blukite, expand confidently into untapped
                            markets and enhance your offerings, all while upholding the values of
                            community and inclusivity that define your mission. Join us in taking a
                            significant step toward a prosperous, inclusive future for your members.</p>
                            <div className='mt-5 w-full sm:w-64'>
                                <PlainButton text="Book a consultation" onClick={() => openPopup({ currentPopup: 'BookConsultation' })} />
                            </div>
                        </div>
                    </div>

                </section>


                <div>
                </div>

            </div>
        </div>
    );
}

export default connect(
    null,
    mapDispatchToProps
)(CreditUnions);