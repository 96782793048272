import React, { useEffect, useState, useRef } from 'react';
import { Anchor } from 'antd';
import { basePath } from '../../routes';
import Speaker from '../../components/speaker/speaker';
import './esgFramework.scss'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EsgFramework = () => {

    const [isTransitioning, setIsTransitioning] = useState(false);
    const [currentProductOfferIndex, setCurrentProductOfferIndex] = useState(0);
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);
    const productOfferingsSlides = [
        {
            title: "Shared Reporting",
            description: `We will report collectively under governance guidelines, showcasing
            the collective impact of our network of businesses. This shared 
           reporting approach not only highlights individual achievements but
            also demonstrates the significant progress made through our 
           collective efforts. The Blukite Friendship Community Dashboard 
           features aggregated data, providing a comprehensive overview of
            our environmental, social, and governance impacts. This 
           transparency enhances credibility and accountability, allowing all
            stakeholders to see the tangible results of our collaborative 
           sustainability initiatives.`,
            id: 1,
        },
        {
            title: "Unified Standards",
            descriptionSmall: `Adhere to a unified set of ESG standards, ensuring consistency and
            credibility across the network. By following Blukite’s comprehensive
            ESG framework, all members can maintain high standards of 
           sustainability and ethical practices. The Community Dashboard will 
           provide clear guidelines, templates, and tools to help businesses 
           implement these standards effectively, ensuring that our collective
           efforts are credible, impactful, and aligned with global best practices. Blukite Friendship is more than an initiative; it’s a movement 
           towards a sustainable and ethical business ecosystem.`,
            description: `Adhere to a unified set of ESG standards, ensuring consistency and
            credibility across the network. By following Blukite’s comprehensive
            ESG framework, all members can maintain high standards of 
           sustainability and ethical practices. The Community Dashboard will 
           provide clear guidelines, templates, and tools to help businesses 
           implement these standards effectively, ensuring that our collective
           efforts are credible, impactful, and aligned with global best practices. Blukite Friendship is more than an initiative; it’s a movement 
           towards a sustainable and ethical business ecosystem. By joining, 
           businesses can lead by example, prepare for future regulations, and 
           collectively make a significant positive impact on the environment 
           and society. Our network of like-minded companies is dedicated to 
           driving meaningful change and fostering a culture of sustainability 
           and responsibility.`,
            id: 2,
        },
        {
            title: "Collaborative Efforts",
            description: `Work with Blukite and other partners to achieve common 
            sustainability goals. By joining forces, we can leverage shared 
            knowledge, resources, and innovations to tackle ESG challenges 
            more effectively. The Blukite Friendship Community Dashboard will 
            facilitate collaboration through project management tools, discussion
             forums, and resource sharing, enabling members to work together 
            on initiatives that drive significant environmental and social impact.`,
            id: 3,
        },
        {
            title: "Blukite Friendship Recognition",
            description: ` Blukite Friendship recognition signifies your business’s 
            dedication to sustainable and ethical practices. Benefits include:
            Visibility on Our Platform: Highlighting your achievements on our 
            website and social media channels.
            Networking Opportunities: Connecting with other like-minded 
            businesses and industry leaders.
            Support and Resources: Access to additional resources and 
            guidance to further enhance your ESG practices.
            Blukite Friendship is more than an initiative; it’s a movement 
            towards a sustainable and ethical business ecosystem. By joining,
            businesses can lead by example, prepare for future regulations, 
            and collectively make a significant positive impact on the 
            environment and society. `,
            id: 4,
        }
    ]
    const aboutEsgSlides = [
        {
            title: "ABOUT ESG",
            desc: `Environmental, Social, and Governance (ESG) refers to the three key factors 
            used to measure the sustainability and ethical impact of a company. These 
            criteria help investors and stakeholders evaluate how a company manages its 
            responsibilities to the environment, its relationships with employees, customers,
            and the broader community, and its governance structures and practices.
            `,
            id: 1
        },
        {
            title: "BLUKITE`S COMMITMENT TO ESG",
            desc: `Blukite is a forward-thinking company committed to integrating
            sustainable and ethical practices into our core operations. As a growing
            enterprise, we recognise the importance of adopting an ESG framework
            to meet regulatory requirements and lead by example in our industry.
            `,
            id: 2
        },
        {
            title: "FORWARD THINKING",
            desc: `Blukite has developed its ESG framework and policies to support 
            our clients in fulfilling their own ESG compliance requirements and 
            to prudently prepare for broader ESG mandates. By adopting these
            early steps, we ensure that Blukite is compliant and a leader in 
            sustainable business practices.
            `,
            id: 3
        },
    ]

    const [activeAboutEsgSlideId, setActiveAboutEsgSlideId] = useState(1);
    const slider = useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        afterChange: (current) => {
            setActiveAboutEsgSlideId(current + 1)
        },
        responsive: [{
            breakpoint: 1500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        }]
    };

    useEffect(() => {
        updateSlides(true);
    }, [currentProductOfferIndex]);


    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {

        if (touchStart - touchEnd > 150) {
            handleNext();
        }

        if (touchStart - touchEnd < -150) {
            handlePrevious();
        }
    }

    const handleNext = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        const currentSlide = document.getElementById(`slide-${currentProductOfferIndex}`);
        currentSlide.classList.add('moving-out');

        setTimeout(() => {
            setCurrentProductOfferIndex((currentProductOfferIndex + 1) % productOfferingsSlides.length);
            updateSlides(true);
            setIsTransitioning(false);
        }, 400); // Match this timeout to the CSS transition duration
    };

    const handlePrevious = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        setCurrentProductOfferIndex(currentProductOfferIndex === 0 ? productOfferingsSlides.length - 1 : currentProductOfferIndex - 1);
        updateSlides(false);

        const currentSlide = document.getElementById(`slide-${currentProductOfferIndex}`);
        currentSlide.classList.add('moving-in');
        setIsTransitioning(false);
    };

    function applyStyles(currentProductOfferIndex, isNext) {
        let styles = [
            'transform: translateX(0%) scale(1);',
            'transform: translateX(100%) scale(1);',
            'transform: translateX(200%) scale(1);',
            'transform: translateX(300%) scale(1);',
        ];

        for (let i = 0; i < styles.length; i++) {
            let slideIndex = (currentProductOfferIndex + i) % styles.length;
            let slideElement = document.getElementById(`slide-${slideIndex}`);
            slideElement.style = styles[i];

            if (isNext) {
                if (i !== styles.length - 1) {
                    slideElement.classList.add('slide');
                } else {
                    slideElement.classList.remove('slide');
                }

            } else {
                if (slideIndex !== 0) {
                    slideElement.classList.add('slide');
                } else {
                    slideElement.classList.remove('slide');
                }
            }
        }
    }

    const updateSlides = (isNext) => {
        productOfferingsSlides.forEach((slide, index) => {
            applyStyles(currentProductOfferIndex, isNext)
        });
    };

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage tailoredServicesRetailers">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Blukite Friendship',
                        }
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Blukite Friendship',
                        }
                    ]}
                />
            </div>

            <div className="">
                <section id="section1">
                    <h2 className='pageTitle'>ESG framework</h2>
                    <p className='text'>
                        <Speaker id="554" text="ESG framework. Welcome to Blukite's Commitment to Sustainability and Social Responsibility. At Blukite, we recognise the critical importance of environmental, social, and governance (ESG) practices 
for regulatory compliance and the overall health and sustainability of our planet. We have developed a comprehensive ESG framework and set of policies to support our clients in 
meeting their own ESG compliance requirements while prudently preparing for broader ESG mandates. Our approach ensures that we and our clients remain at the forefront of 
sustainability and ethical governance." />
                        Welcome to Blukite's Commitment to Sustainability and Social Responsibility. At Blukite, we recognise the critical importance of environmental, social, and governance (ESG) practices
                        for regulatory compliance and the overall health and sustainability of our planet. We have developed a comprehensive ESG framework and set of policies to support our clients in
                        meeting their own ESG compliance requirements while prudently preparing for broader ESG mandates. Our approach ensures that we and our clients remain at the forefront of
                        sustainability and ethical governance. </p>

                    <div className='my-20'>

                        <p className='font-normal not-italic subtitle uppercase leading-tight'>
                            {aboutEsgSlides.find(x => x.id === activeAboutEsgSlideId).title}
                        </p>

                        <div className='mt-2'>
                            <div className="esgFramework__sliderWrapper">
                                <div className="esgFramework__slider">
                                    <Slider {...settings} ref={slider}>
                                        <img className='object-cover w-full h-full' src={`${basePath}images/esg/6.jpg`} alt="illustration" />
                                        <video className='object-cover w-full h-full' autoPlay muted loop playsInline>
                                            <source src={`${basePath}/videos/windmills.mp4`} type="video/mp4" />
                                        </video>
                                        <video className='object-cover w-full h-full' autoPlay muted loop playsInline>
                                            <source src={`${basePath}/videos/forest.mp4`} type="video/mp4" />
                                        </video>
                                    </Slider>
                                </div>
                            </div>
                            <div className='esgFramework__sliderContent ml-0 xl:ml-12 h-auto xl:h-64 w-full xl:w-11/12'>
                                <p className='flex-1'>
                                    <Speaker id={554 + activeAboutEsgSlideId} text={ aboutEsgSlides.find(x => x.id === activeAboutEsgSlideId).title + ". " + aboutEsgSlides.find(x => x.id === activeAboutEsgSlideId).desc}/>
                                    {aboutEsgSlides.find(x => x.id === activeAboutEsgSlideId).desc}
                                </p>
                                <div className='gap-7 flex justify-between'>
                                    <img onKeyDown={() => { slider.current.slickPrev(); setActiveAboutEsgSlideId(activeAboutEsgSlideId === 1 ? aboutEsgSlides.length : activeAboutEsgSlideId - 1); }} onClick={() => { slider.current.slickPrev(); setActiveAboutEsgSlideId(activeAboutEsgSlideId === 1 ? aboutEsgSlides.length : activeAboutEsgSlideId - 1); }} tabIndex={0} className='w-4 cursor-pointer transform rotate-180 hover:scale-110 transition-transform' src={`${basePath}images/ico/esg/sliderArrow-right.svg`} alt="slider left arrow" />
                                    <img onKeyDown={() => { slider.current.slickNext(); setActiveAboutEsgSlideId(activeAboutEsgSlideId === aboutEsgSlides.length ? 1 : activeAboutEsgSlideId + 1); }} onClick={() => { slider.current.slickNext(); setActiveAboutEsgSlideId(activeAboutEsgSlideId === aboutEsgSlides.length ? 1 : activeAboutEsgSlideId + 1); }} tabIndex={0} className='w-4 cursor-pointer hover:scale-110 transition-transform' src={`${basePath}images/ico/esg/sliderArrow-right.svg`} alt="slider right arrow" />
                                </div>

                            </div>
                        </div>


                    </div>

                    <p className='text'>
                    <Speaker id="558" text={`Twenty-five years ago, as I embarked on a new business project, a dear friend of mine who excelled in marketing shared a piece of wisdom that has resonated with me ever since.
                        He quoted from the Dao De Jing: "A journey of a thousand miles begins with a single step." This simple yet profound truth has guided my approach to every endeavour. Today, I am
                        reminded of that wisdom as we introduce our latest initiative: "One Planet, One Step at a Time - Blukite Friendship: Together for a Greener Planet." This initiative embodies the spirit of
                        collective action and the belief that even the smallest steps can lead to significant change. Together, let's make a difference. Together, let's walk the path of sustainability and social
                        responsibility. Together, let's create a greener planet. Chris Hamilton, Founder of Blukite Finance`}/>
                        Twenty-five years ago, as I embarked on a new business project, a dear friend of mine who excelled in marketing shared a piece of wisdom that has resonated with me ever since.
                        He quoted from the Dao De Jing: "A journey of a thousand miles begins with a single step." This simple yet profound truth has guided my approach to every endeavour. Today, I am
                        reminded of that wisdom as we introduce our latest initiative: "One Planet, One Step at a Time - Blukite Friendship: Together for a Greener Planet." This initiative embodies the spirit of
                        collective action and the belief that even the smallest steps can lead to significant change. Together, let's make a difference. Together, let's walk the path of sustainability and social
                        responsibility. Together, let's create a greener planet.</p>

                    <p className='text text-right italic text-lg'>Chris Hamilton, Founder of Blukite Finance</p>


                    <div className='my-20 sm:my-32'>
                        <div className='esgFramework__infoBoxes items-center'>
                            <div className='esgFramework__infoBox--long'>
                                <img className='w-44' src={`${basePath}images/logoBlukiteFriendship.svg`} alt='Logo Blukite Friendship' />
                                <p className='mt-10 text'>
                                <Speaker id="559" text="Blukite Friendship is an initiative aimed at encouraging small and medium-sized enterprises (SMEs) to join us in our 
                                commitment to environmental sustainability, social responsibility, and strong governance. We provide a comprehensive 
                                framework and best practices that smaller businesses can adopt to start their ESG journey."/>
                                Blukite Friendship is an initiative aimed at encouraging small and medium-sized enterprises (SMEs) to join us in our 
                                commitment to environmental sustainability, social responsibility, and strong governance. We provide a comprehensive 
                                framework and best practices that smaller businesses can adopt to start their ESG journey.
                                </p>
                            </div>

                            <div className='esgFramework__infoBoxHighlighted flex-1 hidden xl:flex'>
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIconsGreen/1.svg`} alt='icon for Lead by Example' />
                                <p className='mt-2 text-lg font-normal'>Lead by Example</p>
                                <p className='mt-2'>Join Blukite Friendship to position your
                                    business as a sustainability leader,
                                    demonstrating your commitment to ethical practices and environmental stewardship.</p>
                            </div>
                        </div>
                        <div className='mt-10 esgFramework__infoBoxes'>

                            <div className='esgFramework__infoBoxHighlighted flex xl:hidden'>
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIconsGreen/1.svg`} alt='icon for Lead by Example' />
                                <p className='mt-2 text-lg font-normal'>Lead by Example</p>
                                <p className='mt-2'>Join Blukite Friendship to position your
                                    business as a sustainability leader,
                                    demonstrating your commitment to ethical practices and environmental stewardship.</p>
                            </div>

                            <div className='esgFramework__infoBoxHighlighted'>
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIconsGreen/2.svg`} alt='icon for Future-Proof Your Business' />
                                <p className='mt-2 text-lg font-normal'>Future-Proof Your Business</p>
                                <p className='mt-2'>Prepare for upcoming ESG regulations
                                    and ensure long-term compliance <br className='block 2xl:hidden'/>
                                    and resilience.</p>
                            </div>
                            <div className='esgFramework__infoBoxHighlighted'>
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIconsGreen/3.svg`} alt='icon for Enhance Brand Reputation' />
                                <p className='mt-2 text-lg font-normal'>Enhance Brand Reputation</p>
                                <p className='mt-2'>Improve your market position, enhance
                                    your brand reputation, and open up new
                                    opportunities for growth and collaboration.</p>
                            </div>
                            <div className='esgFramework__infoBoxHighlighted'>
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIconsGreen/4.svg`} alt='icon for Collective Impact' />
                                <p className='mt-2 text-lg font-normal'>Collective Impact</p>
                                <p className='mt-2'>Collaborate with Blukite and other like-minded
                                    businesses to create a significant positive
                                    impact on the environment and society.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="section2" className='mt-10 mb-10 sm:mt-20 xl:mt-20 sm:mb-20 liquidityProvidersCreditSolutions'>


                    <div className='my-40 flex flex-col xl:flex-row gap-20 w-10/12 mx-auto'>
                        <div className='flex-1'>
                            <div class="circle-container">
                                <div class="orbit">
                                    <div class="center-image">
                                        <img className='mainImg' src={`${basePath}images/ico/greenCircles/9.svg`} alt="" />
                                        <img className='dottedCircle' src={`${basePath}images/ico/greenCircles/dottedCircle.svg`} alt="" />
                                    </div>
                                    <ul>
                                        <li>
                                            <div><img src={`${basePath}images/ico/greenCircles/1.svg`} alt="" /></div>
                                        </li>
                                        <li>
                                            <div><img src={`${basePath}images/ico/greenCircles/2.svg`} alt="" /></div>
                                        </li>
                                        <li>
                                            <div><img src={`${basePath}images/ico/greenCircles/3.svg`} alt="" /></div>
                                        </li>
                                        <li>
                                            <div><img src={`${basePath}images/ico/greenCircles/4.svg`} alt="" /></div>
                                        </li>
                                        <li>
                                            <div><img src={`${basePath}images/ico/greenCircles/5.svg`} alt="" /></div>
                                        </li>
                                        <li>
                                            <div><img src={`${basePath}images/ico/greenCircles/6.svg`} alt="" /></div>
                                        </li>
                                        <li>
                                            <div><img src={`${basePath}images/ico/greenCircles/7.svg`} alt="" /></div>
                                        </li>
                                        <li>
                                            <div><img src={`${basePath}images/ico/greenCircles/8.svg`} alt="" /></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* <img className='' src={`${basePath}/images/esg/3.svg`} alt="illustration" /> */}
                        </div>

                        <div className='flex-1'>
                            <Speaker id="560" text="HOW TO JOIN BLUKITE FRIENDSHIP. 
                            1.
                            Adopt Our ESG Framework: Implement the practices and guidelines provided in our comprehensive ESG framework.
                            2.
                            Commit to Continuous Improvement: Regularly review and improve your ESG practices, aiming for greater sustainability and social impact.
                            3.
                            Engage with the Community: Share your progress and successes with the Blukite Friendship community, inspiring others to join the movement.
                            4.s
                            Earn Recognition: Earn Recognition: Businesses that demonstrate a strong commitment to our ESG principles will be awarded the Blukite Friendship recognition.
                            "/>
                            <p className='font-normal not-italic subtitle uppercase leading-tight text-center mt-10 xl:mt-0'>
                                HOW TO JOIN BLUKITE FRIENDSHIP
                                </p>

                            <div className='relative flex gap-8 flex-col mt-7'>
                                <img className='esgFramework__dashedLineNmbers' src={`${basePath}/images/tailoredServicesRetailers/dashedLine-green.svg`} alt="dashed line image" />

                                <div className='flex items-center gap-5'>
                                    <div className='rounded-full w-12 h-12 p-5 bg-white customShadow text-green text-2xl font-medium flex-none flex items-center justify-center'>1</div>
                                    <p className='leading-tight'><span className='font-normal'>
                                        Adopt Our ESG Framework: </span>Implement the practices and guidelines provided
                                        in our comprehensive ESG framework.</p>
                                </div>
                                <div className='flex items-center gap-5'>
                                    <div className='rounded-full w-12 h-12 p-5 bg-white customShadow text-green text-2xl font-medium flex-none flex items-center justify-center'>2</div>
                                    <p className='leading-tight'><span className='font-normal'>Commit to Continuous Improvement: </span>Regularly review and improve your ESG
                                        practices, aiming for greater sustainability and social impact.</p>
                                </div>
                                <div className='flex items-center gap-5'>
                                    <div className='rounded-full w-12 h-12 p-5 bg-white customShadow text-green text-2xl font-medium flex-none flex items-center justify-center'>3</div>
                                    <p className='leading-tight'><span className='font-normal'>Engage with the Community: </span>Share your progress and successes with the Blukite
                                        Friendship community, inspiring others to join the movement.</p>
                                </div>
                                <div className='flex items-center gap-5'>
                                    <div className='rounded-full w-12 h-12 p-5 bg-white customShadow text-green text-2xl font-medium flex-none flex items-center justify-center'>4</div>
                                    <p className='leading-tight'><span className='font-normal'>Earn Recognition: </span>Earn Recognition: Businesses that demonstrate a strong commitment to our
                                        ESG principles will be awarded the Blukite Friendship recognition.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex xl:flex-row flex-col justify-between gap-5 mt-10'>
                        <p className='font-normal not-italic subtitle uppercase leading-tight'>Integration with Blukite’s Framework</p>
                        <div className='mt-0 xl:mt-7 gap-10 flex justify-between'>
                            <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handlePrevious()} onClick={handlePrevious} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderLeftArrow.svg`} alt="slider left arrow" />
                            <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handleNext()} onClick={handleNext} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderRightArrow.svg`} alt="slider right arrow" />
                        </div>
                    </div>

                    <div className='border-r border-separateLight'>
                        <div id="slider" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                            <div className="cards mt-2 xl:mt-10">
                                {productOfferingsSlides.map((slide, index) => (
                                    <div id={`slide-${index}`} key={index} className={`cards__card ${index === currentProductOfferIndex ? 'activeCard' : index === (currentProductOfferIndex + 1) % productOfferingsSlides.length ? 'next' : 'sliderHidden'}`}>
                                        <div className="cards__content">
                                            <p className="font-normal text-base sm:text-lg leading-tight italic">{slide.title}</p>
                                            <p className="text-sm sm:text-base font-light mt-3">
                                                <Speaker id={561 + slide.id} text={slide.title + "./" + slide.description} />
                                                {/* {slide.description} */}
                                                {slide.id === 4 ?
                                                    <>
                                                        <p> Blukite Friendship recognition signifies your business’s
                                                            dedication to sustainable and ethical practices. Benefits include:</p>
                                                        <p className='mt-2 text-left'><span className='font-normal'>Visibility on Our Platform: </span>Highlighting your achievements on our
                                                            website and social media channels.</p>
                                                        <p className='mt-2 text-left'><span className='font-normal'>Networking Opportunities: </span>Connecting with other like-minded
                                                            businesses and industry leaders.</p>
                                                        <p className='mt-2 text-left'><span className='font-normal'>Support and Resources: </span>Access to additional resources and
                                                            guidance to further enhance your ESG practices.</p>
                                                        <p className='mt-2 hidden sm:block'>Blukite Friendship is more than an initiative; it’s a movement
                                                            towards a sustainable and ethical business ecosystem. By joining,
                                                            businesses can lead by example, prepare for future regulations,
                                                            and collectively make a significant positive impact on the
                                                            environment and society. </p>
                                                        <p className='mt-2 block sm:hidden'>
                                                            Blukite Friendship promotes a sustainable, ethical business ecosystem. Joining enables businesses to lead, prepare for future regulations, and positively impact the environment and society.
                                                        </p>
                                                    </>

                                                    : slide.id === 2 ?
                                                        <>
                                                            <p className="hidden sm:block text-sm sm:text-base font-light mt-3">{slide.description}</p>
                                                            <p className="block sm:hidden text-sm sm:text-base font-light mt-3">{slide.descriptionSmall}</p>
                                                        </>

                                                    : slide.description
                                                }

                                            </p>

                                            {slide.id === 1 ?
                                                <object className='absolute left-1/2 -bottom-22  sm:-bottom-10 transform -translate-x-1/2' data={`${basePath}/images/esg/4.svg`}></object>
                                                : slide.id === 3 ?
                                                    <object className='absolute left-1/2 transform -translate-x-1/2 -bottom-7' data={`${basePath}/images/esg/5.svg`}></object>
                                                    : null}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                <div>
                </div>

            </div>
        </div>
    );
}

export default EsgFramework;