import React, { useState, useEffect } from 'react';
import { Anchor } from 'antd';

const CookiePolicy = () => {
    const { Link } = Anchor;

    return (
        <div className="flex flex-col lg:flex-row container gap-16 sm:gap-5 lg:gap-16 mt-0 sm:my-12 lg:my-24 policiesPage">
            <div className="hidden lg:block sidebar">
                <Anchor targetOffset={100} affix={false}>
                    <Link
                        href="#section1"
                        title="1. Cookie Policy of Blukite Finance Limited"
                    ></Link>

                    <Link
                        href="#section2"
                        title="2. Types and Purposes of Cookies Used at Blukite"
                    >
                        {/* <Link
                            href="#section2.1"
                            title="2.1 Necessary Cookies"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section2.2"
                            title="2.2 Performance Cookies"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section2.3"
                            title="2.3 Functionality Cookies"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section2.4"
                            title="2.4 Targeting/Advertising Cookies"
                            style={{ marginLeft: 20 }}
                        /> */}
                    </Link>
                    <Link
                        href="#section3"
                        title="3. Accessibility & Cookies: Supporting Users with Disabilities and the Ageing Community"
                    >
                        {/* <Link
                            href="#section3.1"
                            title="3.1 Accessibility Support via Chatbot"
                            style={{ marginLeft: 20 }}
                        /> */}
                    </Link>
                    <Link
                        href="#section4"
                        title="4. Cookie Duration"
                    >
                        {/* <Link
                            href="#section4.1"
                            title="4.1 Session Cookies"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section4.2"
                            title="4.2 Persistent Cookies"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section4.3"
                            title="4.3 Cookie Renewal"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section4.4"
                            title="4.4 User Control"
                            style={{ marginLeft: 20 }}
                        /> */}
                    </Link>
                    <Link
                        href="#section5"
                        title="5. Third-Party Cookies"
                    >
                    </Link>
                    <Link
                        href="#section6"
                        title="6. User Consent and Accessibility Assistance"
                    >
                        {/* <Link
                            href="#section6"
                            title="6.1 Consent Mechanism"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section6.2"
                            title="6.2 Accessibility in Consent Process"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section6.3"
                            title="6.3 Managing Preferences"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section6.4"
                            title="6.4 Consent Records and Updates"
                            style={{ marginLeft: 20 }}
                        /> */}
                    </Link>
                    <Link
                        href="#section7"
                        title="7. How to Control and Delete Cookies"
                    >
                        {/* <Link
                            href="#section7.1"
                            title="7.1 Google Chrome"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section7.2"
                            title="7.2 Mozilla Firefox"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section7.3"
                            title="7.3 Safari (for Mac)"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section7.4"
                            title="7.4 Microsoft Edge"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section7.5"
                            title="7.5 Mobile Devices"
                            style={{ marginLeft: 20 }}
                        /> */}
                    </Link>
                    <Link
                        href="#section8"
                        title="8. Policy Updates"
                    >
                        {/* <Link
                            href="#section8.1"
                            title="8.1 Update Frequency"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section8.2"
                            title="8.2 Notification of Changes"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section8.3"
                            title="8.3 Version and Date"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section8.4"
                            title="8.4 Ongoing Accessibility"
                            style={{ marginLeft: 20 }}
                        /> */}
                    </Link>
                    <Link
                        href="#section9"
                        title="9. Contact Information"
                    ></Link>
                    <Link
                        href="#section10"
                        title="10. Legal Compliance and Framework"
                    ></Link>
                </Anchor>

            </div>

            <div className='block lg:hidden sidebar'>
                <Anchor targetOffset={100}  direction="horizontal">
                    <Link
                        href="#section1"
                        title="1. Cookie Policy of Blukite Finance Limited"
                    ></Link>

                    <Link
                        href="#section2"
                        title="2. Types and Purposes of Cookies Used at Blukite"
                    >
                        {/* <Link
                            href="#section2.1"
                            title="2.1 Necessary Cookies"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section2.2"
                            title="2.2 Performance Cookies"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section2.3"
                            title="2.3 Functionality Cookies"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section2.4"
                            title="2.4 Targeting/Advertising Cookies"
                            style={{ marginLeft: 20 }}
                        /> */}
                    </Link>
                    <Link
                        href="#section3"
                        title="3. Accessibility & Cookies: Supporting Users with Disabilities and the Ageing Community"
                    >
                        {/* <Link
                            href="#section3.1"
                            title="3.1 Accessibility Support via Chatbot"
                            style={{ marginLeft: 20 }}
                        /> */}
                    </Link>
                    <Link
                        href="#section4"
                        title="4. Cookie Duration"
                    >
                        {/* <Link
                            href="#section4.1"
                            title="4.1 Session Cookies"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section4.2"
                            title="4.2 Persistent Cookies"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section4.3"
                            title="4.3 Cookie Renewal"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section4.4"
                            title="4.4 User Control"
                            style={{ marginLeft: 20 }}
                        /> */}
                    </Link>
                    <Link
                        href="#section5"
                        title="5. Third-Party Cookies"
                    >
                    </Link>
                    <Link
                        href="#section6"
                        title="6. User Consent and Accessibility Assistance"
                    >
                        {/* <Link
                            href="#section6"
                            title="6.1 Consent Mechanism"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section6.2"
                            title="6.2 Accessibility in Consent Process"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section6.3"
                            title="6.3 Managing Preferences"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section6.4"
                            title="6.4 Consent Records and Updates"
                            style={{ marginLeft: 20 }}
                        /> */}
                    </Link>
                    <Link
                        href="#section7"
                        title="7. How to Control and Delete Cookies"
                    >
                        {/* <Link
                            href="#section7.1"
                            title="7.1 Google Chrome"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section7.2"
                            title="7.2 Mozilla Firefox"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section7.3"
                            title="7.3 Safari (for Mac)"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section7.4"
                            title="7.4 Microsoft Edge"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section7.5"
                            title="7.5 Mobile Devices"
                            style={{ marginLeft: 20 }}
                        /> */}
                    </Link>
                    <Link
                        href="#section8"
                        title="8. Policy Updates"
                    >
                        {/* <Link
                            href="#section8.1"
                            title="8.1 Update Frequency"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section8.2"
                            title="8.2 Notification of Changes"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section8.3"
                            title="8.3 Version and Date"
                            style={{ marginLeft: 20 }}
                        />
                        <Link
                            href="#section8.4"
                            title="8.4 Ongoing Accessibility"
                            style={{ marginLeft: 20 }}
                        /> */}
                    </Link>
                    <Link
                        href="#section9"
                        title="9. Contact Information"
                    ></Link>
                    <Link
                        href="#section10"
                        title="10. Legal Compliance and Framework"
                    ></Link>
                </Anchor>
            </div>

            <div className="content">

                <section id="section1" className='mt-10'>
                    <h2 className='pageTitle'>1. Cookie Policy of Blukite Finance Limited</h2>
                    <p>Welcome to Blukite Finance Limited (Blukite). This Cookie Policy applies universally to all digital platforms and services offered by Blukite, including our website, mobile and web-based applications, and our range of credit and payment initiation services, as well as account information services. Our use of cookies extends across all these platforms to ensure a seamless and efficient user experience.
                    </p>
                    <p>Cookies are small text files stored on your device (be it a computer, mobile phone, tablet, or any other device) when you interact with our digital services. They play a crucial role in enhancing website functionality, enabling ease of navigation, and ensuring the efficiency of our web and mobile applications. Additionally, cookies assist us in gathering insights about the usage patterns and preferences of our users, allowing us to tailor our services and content more effectively.
                    </p>
                    <p>By engaging with any of Blukite's services or platforms, you agree to the use of cookies as described in this policy. We provide detailed information on the types of cookies we use, their specific purposes, and guidance on how you can manage or remove them, ensuring you have control over your privacy and data.
                    </p>
                </section>
                <section id="section2" className='mt-10'>
                    <h2 className='pageTitle'>2. Types and Purposes of Cookies Used at Blukite
                    </h2>
                    <p>At Blukite, our use of cookies is designed to enhance and streamline your experience on our website and applications. Here, we detail the types of cookies we use, their specific functions, and the impact of disabling them:
                    </p>


                </section>
                <section id="section2.1">
                    <p className='subtitle'>2.1 Necessary Cookies:
                    </p>

                    <ul>
                        <li><span className='font-normal'>Purpose: </span>These are fundamental to the operational integrity of our platforms. They facilitate core functions such as securing user data, managing network traffic, and maintaining user session continuity.
                        </li>
                        <li><span className='font-normal'>Examples: </span>Authentication cookies that keep you logged in, security cookies that protect against malicious activities, and session cookies that ensure consistent user experience.
                        </li>
                        <li><span className='font-normal'>Impact of Disabling: </span>Without these cookies, our platforms may not function properly or securely. For instance, you might encounter frequent disconnections or inability to access certain features.
                        </li>
                    </ul>
                </section>
                <section id="section2.2">
                    <p className='subtitle'>2.2 Performance Cookies:</p>
                    <ul>
                        <li><span className='font-normal'>Purpose: </span>These cookies collect anonymised data about user interactions, providing us with insights into usage patterns, popular content, and potential issues like error messages or slow loading pages.
                        </li>
                        <li><span className='font-normal'>Examples: </span>Cookies that track page visits, user engagement time, and interaction with site features.
                        </li>
                        <li><span className='font-normal'>Impact of Disabling: </span>Opting out of these cookies won't hinder your ability to use our services, but it will limit our capacity to enhance user experience and platform efficiency based on user behaviour analysis.
                        </li>
                    </ul>

                </section>
                <section id="section2.3">
                    <p className='subtitle'>2.3 Functionality Cookies:</p>
                    <ul>
                        <li><span className='font-normal'>Purpose: </span>They remember your individual preferences and settings, offering a more personalised and convenient experience each time you visit.
                        </li>
                        <li><span className='font-normal'>Examples: </span>Cookies that store your language preferences, region selection, or customisations in layout or content display.
                        </li>
                        <li><span className='font-normal'>Impact of Disabling: </span>If these cookies are disabled, you may find that your experience becomes more generic, lacking personalisation. Settings like language or region may need to be reselected on each visit.
                        </li>
                    </ul>
                </section>

                <section id="section2.4">
                    <p className='subtitle'>2.3 Targeting/Advertising Cookies:</p>
                    <ul>
                        <li><span className='font-normal'>Purpose: </span>These cookies are essential for delivering advertising content that is relevant to your interests. They also help us track the effectiveness of our advertising campaigns and control the frequency of ad exposure.
                        </li>
                        <li><span className='font-normal'>Examples: </span>Cookies that analyse which adverts you have seen, how often you have seen them, and how you interact with them.

                        </li>
                        <li><span className='font-normal'>Impact of Disabling: </span>Disabling these cookies means the advertisements you see may be less relevant to your interests. However, it will not reduce the number of ads you see, just their relevance.</li>
                    </ul>
                    <p>Please be aware that disabling cookies, particularly Necessary and Functionality cookies, may significantly affect your user experience and the efficiency of the services we offer.
                    </p>
                </section>

                <section id="section3" className='mt-10'>
                    <h2 className='pageTitle'>3. Accessibility & Cookies: Supporting Users with Disabilities and the Ageing Community
                    </h2>
                    <p>At Blukite, we are committed to ensuring that our digital platforms are accessible and user-friendly for everyone, including people living with disabilities and members of the ageing community. Cookies play a vital role in this commitment by enabling us to optimise our website and applications to suit the diverse needs of all our users.
                    </p>
                    <ul>
                        <li><span className='font-normal'>Enhanced Navigation and Readability: </span>Cookies can store user preferences like text size, font preferences, and contrast settings, making our platforms easier to read and navigate for users with visual impairments.
                        </li>
                        <li><span className='font-normal'>Remembering Accessibility Preferences: </span>For users who require specific accessibility settings, functionality cookies remember these preferences. This means that once a user has set their accessibility preferences, our platforms will maintain these settings during subsequent visits.
                        </li>
                        <li><span className='font-normal'>Assistive Technologies Compatibility: </span>We use cookies to ensure that our website and applications function seamlessly with assistive technologies such as screen readers and keyboard-only navigation, offering an inclusive experience for users with mobility or dexterity challenges.
                        </li>
                        <li><span className='font-normal'>User Feedback and Continuous Improvement: </span>By analysing how all our users, including those with disabilities and ageing users, interact with our platforms, we can continuously refine and enhance accessibility features.
                        </li>
                    </ul>
                    <p>We encourage users to enable cookies that support these accessibility functions. However, we also understand the importance of choice and control over personal data. If you choose to disable certain types of cookies, we strive to maintain as much of the accessibility functionality as possible, but some personalisation features may be affected.
                    </p>
                    <p>Blukite is dedicated to ongoing improvement in accessibility and inclusivity, and we welcome feedback from our users to help us in this endeavour.
                    </p>


                </section>

                <section id="section3.1">
                    <p className='subtitle'>3.1 Accessibility Support via Chatbot
                    </p>
                    <p>To further support our commitment to accessibility and user assistance, Blukite is pleased to introduce a chatbot feature on our cookie policy page. This feature is designed to provide real-time support and clarification regarding our cookie policy, especially beneficial for users who might have specific questions or need additional guidance.
                    </p>
                    <ul>
                        <li><span className='font-normal'>How to Access: </span>The chatbot can be accessed by clicking on the easily identifiable 'Accessibility Assistance' (EA) logo located on our cookie policy page.
                        </li>
                        <li><span className='font-normal'>User-Friendly Interaction: </span>The chatbot is designed to be intuitive and user-friendly, offering clear, concise answers to your questions regarding our cookie use, and how it pertains to accessibility and personal preferences.
                        </li>
                        <li><span className='font-normal'>Personalised Assistance: </span>Whether you need more information about a specific type of cookie, help in managing your cookie preferences, or have questions about accessibility options, our chatbot is here to provide tailored assistance.
                        </li>
                        <li><span className='font-normal'>Feedback and Improvement: </span>Your interactions with the chatbot will also help us to continually improve our services and ensure that our cookie policy and practices remain transparent and user centric.
                        </li>
                    </ul>
                    <p>We believe that this chatbot feature will make understanding and navigating our cookie policy more accessible and straightforward, aligning with our goal to create an inclusive digital environment for all our users.
                    </p>
                </section>

                <section id="section4" className='mt-10'>
                    <h2 className='pageTitle'>4. Cookie Duration
                    </h2>
                    <p>At Blukite, we use different types of cookies, each with its own duration on your device. Understanding these durations helps you better manage your data and privacy preferences:
                    </p>
                </section>
                <section id="section4.1">
                    <p className='subtitle'>4.1 Session Cookies:
                    </p>
                    <p>These cookies are temporary and are designed to last only while your browser is open. They are automatically deleted once you close your browser. Session cookies are typically used to maintain your session as you navigate through our website or app, ensuring a consistent and seamless experience.
                    </p>
                </section>
                <section id="section4.2">
                    <p className='subtitle'>4.2 Persistent Cookies:
                    </p>
                    <p>Unlike session cookies, persistent cookies remain on your device for a pre-defined period, even after you close your browser. The duration of these cookies can vary depending on their purpose but is usually set to a standard timeframe that best serves the intended functionality. For example:
                    </p>
                    <ul>
                        <li><span className='font-normal'>Functionality Cookies: </span>Might last for several months to remember your preferences and settings between visits.
                        </li>
                        <li><span className='font-normal'>Performance Cookies: </span>Could remain for a year or more to help us understand long-term patterns and improvements.
                        </li>
                        <li><span className='font-normal'>Targeting/Advertising Cookies: </span>These may have varying durations based on the advertising campaigns and are often set by third-party advertising partners.
                        </li>
                    </ul>
                </section>
                <section id="section4.3">
                    <p className='subtitle'>4.3 Cookie Renewal:
                    </p>
                    <p>It’s important to note that the duration of cookies can be reset. For instance, every time you visit our website or use our app, some persistent cookies may renew, extending their lifespan from that point.
                    </p>
                </section>
                <section id="section4.4">
                    <p className='subtitle'>4.4 User Control:
                    </p>
                    <p>You have control over cookie management on your device. Most web browsers allow you to view, manage, and delete cookies. Remember, deleting or blocking certain types of cookies, especially necessary and functionality cookies, might affect your user experience on our platforms.
                    </p>
                    <p>At Blukite, we regularly review and update our cookie practices to ensure they align with best practices and regulatory requirements, keeping the privacy and preferences of our users at the forefront.
                    </p>
                </section>

                <section id="section5" className='mt-10'>
                    <h2 className='pageTitle'>5. Third-Party Cookies
                    </h2>
                    <p>In addition to the cookies that we set directly (first-party cookies), Blukite also utilises certain third-party cookies on our website and applications. These cookies are set by external companies or services, and their purposes vary from enhancing user experience to providing us with valuable insights or advertising content. Here is an overview of how these third-party cookies operate and their purpose:
                    </p>

                    <ol>
                        <li><span className='font-normal'>Analytics Services: </span>We use third-party analytics services, such as Google Analytics, to gain insights into how our services are being used and how we can improve them. These cookies help us understand user behaviour, such as which pages are most frequently visited, how much time users spend on our site, and how they navigate through it.
                        </li>
                        <li><span className='font-normal'>Advertising Partners: </span>Some cookies are set by our advertising partners. They collect data about your browsing habits and interests, allowing us to show you advertising that is more relevant and interesting to you. These cookies also help in limiting the number of times you see an ad and measuring the effectiveness of advertising campaigns.
                        </li>
                        <li><span className='font-normal'>Social Media Plugins: </span>Our website and applications may include social media features, such as the Facebook "like" button or the Twitter "tweet" button. These features may set cookies on your device by these third-party sites to track your use of their services.
                        </li>
                        <li><span className='font-normal'>How They Operate: </span>Third-party cookies are managed by external organisations, and their use of cookies is governed by their respective privacy and cookie policies. We do not have direct control over the information that is collected by these cookies.
                        </li>
                        <li><span className='font-normal'>User Consent and Control: </span>Your consent and preferences regarding third-party cookies can be managed through our cookie consent tool. Additionally, you have the option to adjust your browser settings to block third-party cookies.
                        </li>
                    </ol>
                    <p>Please be aware that if you choose to block or disable third-party cookies, it may impact certain features and functionalities provided by external services on our platforms.
                    </p>
                    <p>Blukite is committed to ensuring transparency in the use of third-party cookies and we encourage users to review the privacy policies of these third-party services for more detailed information.
                    </p>
                </section>

                <section id="section6" className='mt-10'>
                    <h2 className='pageTitle'>6. User Consent and Accessibility Assistance
                    </h2>
                    <p>At Blukite, we are dedicated to making our cookie consent process both legally compliant and accessible to all users, including those with disabilities and the ageing community.
                    </p>
                </section>

                <section id="section6.1">
                    <p className='subtitle'>6.1 Consent Mechanism:
                    </p>
                    <p>Our website and applications feature a cookie consent banner or pop-up upon your first visit. This tool informs you about our cookie usage and allows you to give explicit consent for different cookie categories (Necessary, Performance, Functionality, Targeting/Advertising), along with the option to customise your preferences.
                    </p>
                </section>

                <section id="section6.2">
                    <p className='subtitle'>6.2 Accessibility in Consent Process:
                    </p>

                    <ol>
                        <li><span className='font-normal'>EA Icon for Additional Assistance: </span>For users who require extra help, clicking on the 'Easy Accessibility' (EA) icon within the consent banner provides tailored assistance. This can include guidance through the consent process, explanations of cookie functions, and help in setting preferences.
                        </li>
                        <li><span className='font-normal'>Screen Reader and Keyboard Navigation: </span>The consent banner is fully accessible via screen readers and can be navigated using a keyboard, accommodating users with visual or mobility impairments.
                        </li>
                        <li><span className='font-normal'>Design for Visibility and Clarity: </span>High-contrast colours and clear fonts are used for better readability, aiding users with visual impairments.
                        </li>
                        <li><span className='font-normal'>Simple Language: </span>We use straightforward language to make the information easily understandable for everyone, including the ageing population and those not familiar with technical jargon.
                        </li>
                    </ol>
                </section>
                <section id="section6.3">
                    <p className='subtitle'>6.3 Managing Preferences:
                    </p>
                    <p>You have complete control over your cookie preferences and can modify them anytime. Detailed instructions are provided in the consent banner and our cookie settings tool.
                    </p>
                </section>
                <section id="section6.4">
                    <p className='subtitle'>6.4 Consent Records and Updates:
                    </p>
                    <p>We maintain a record of your preferences and periodically prompt you to review and confirm your settings to ensure they remain current.
                    </p>
                    <p>Blukite's commitment to accessibility is reflected in our user-centric approach to cookie consent. By integrating features like the EA icon, we ensure that our cookie consent process is accessible and easy to navigate for all our users.
                    </p>
                </section>
                <section id="section7" className='mt-10'>
                    <h2 className='pageTitle'>7. How to Control and Delete Cookies
                    </h2>
                    <p>At Blukite, we believe in empowering you to manage your data privacy. You have the ability to control and delete cookies through your web browser settings. Here’s how you can do this in some of the most commonly used browsers:
                    </p>
                </section>
                <section id="section7.1">
                    <p className='subtitle'>7.1 Google Chrome:
                    </p>
                    <ul>
                        <li>To control cookies: Click the three dots in the top right corner > 'Settings' > 'Privacy and security' > 'Cookies and other site data'. Here, you can choose your preferred settings.
                        </li>
                        <li>To delete cookies: Go to 'Cookies and other site data' > 'See all cookies and site data' > 'Remove all'.
                        </li>
                    </ul>
                </section>
                <section id="section7.2">
                    <p className='subtitle'>7.2 Mozilla Firefox:
                    </p>
                    <ul>
                        <li>To control cookies: Click the three horizontal lines in the top right corner > 'Options' > 'Privacy & Security'. Under the 'Cookies and Site Data' section, adjust your settings.
                        </li>
                        <li>To delete cookies: In the 'Privacy & Security' section, go to 'Cookies and Site Data' > 'Clear Data'.
                        </li>
                    </ul>
                </section>
                <section id="section7.3">
                    <p className='subtitle'>7.3 Safari (for Mac):
                    </p>
                    <ul>
                        <li>To control cookies: Go to 'Safari' in the menu bar > 'Preferences' > 'Privacy'. Here, you can manage your cookie preferences.
                        </li>
                        <li>To delete cookies: In the 'Privacy' tab, click 'Manage Website Data' and then 'Remove All'.
                        </li>
                    </ul>
                </section>
                <section id="section7.4">
                    <p className='subtitle'>7.4 Microsoft Edge:
                    </p>
                    <ul>
                        <li>To control cookies: Click the three dots in the top right corner > 'Settings' > 'Cookies and site permissions' > 'Manage and delete cookies and site data'.
                        </li>
                        <li>To delete cookies: Under 'Manage and delete cookies and site data', select 'See all cookies and site data' > 'Remove all'.
                        </li>
                    </ul>
                </section>
                <section id="section7.5">
                    <p className='subtitle'>7.5 Mobile Devices:
                    </p>
                    <ul>
                        <li>On mobile devices, you can usually find cookie settings in the browser's app settings, under 'Privacy' or a similar section.
                        </li>
                    </ul>
                    <p>Please note that if you choose to block or delete cookies, it might affect the functionality of our website and the services we offer. Some preferences and settings might be lost, and you may need to re-enter information that was previously stored in cookies.
                    </p>
                    <p>For more detailed guidance, we recommend referring to the 'Help' section of your browser or visiting the browser's official website.
                    </p>
                </section>
                <section id="section8" className='mt-10'>
                    <h2 className='pageTitle'>8. Policy Updates
                    </h2>
                    <p>At Blukite, we are committed to staying up to date with the latest data protection practices and legal requirements. To reflect this commitment, our cookie policy may be updated periodically.
                    </p>
                </section>
                <section id="section8.1">
                    <p className='subtitle'>8.1 Update Frequency:
                    </p>
                    <p>We review our cookie policy annually to ensure it aligns with any new legal requirements, technological advancements, and best practices in data privacy and protection. Additionally, we may perform more frequent reviews and updates as necessary, especially in response to significant changes in legislation or our own data processing activities.
                    </p>
                </section>
                <section id="section8.2">
                    <p className='subtitle'>8.2 Notification of Changes:
                    </p>
                    <p>Whenever we make changes to our cookie policy, we will take steps to ensure you are informed:
                    </p>
                    <ul>
                        <li><span className='font-normal'>Notification on Website/App: </span>A notification will be displayed on our website and within our applications informing you of any significant changes to our cookie policy.
                        </li>
                        <li><span className='font-normal'>Email Communication: </span> For registered users, we may also send an email notification outlining the key changes and how they may affect you.
                        </li>
                        <li><span className='font-normal'>Clear and Accessible Updates: </span>All updates will be clearly indicated in the policy document, with major changes summarised at the beginning for ease of understanding.</li>
                    </ul>
                </section>
                <section id="section8.3">
                    <p className='subtitle'>8.3 Version and Date:
                    </p>
                    <p>Each update to our cookie policy will include the version number and the date of the most recent revision, so you can easily track any changes made.
                    </p>
                </section>
                <section id="section8.4">
                    <p className='subtitle'>8.4 Ongoing Accessibility:
                    </p>
                    <p>We are committed to ensuring that notifications of policy updates are accessible to all users, including those with disabilities or those part of the ageing community. Notifications will be compatible with screen readers and other assistive technologies, and presented in clear, easy-to-understand language.
                    </p>
                    <p>We encourage you to review our cookie policy periodically to stay informed about how we use cookies and protect your data. Your continued use of our website and services after any changes indicates your acceptance of these updates.
                    </p>
                </section>
                <section id="section9" className='mt-10'>
                    <h2 className='pageTitle'>9. Contact Information
                    </h2>
                    <p>We at Blukite value open communication with our users. If you have any questions, concerns, or need further clarification about our cookie policy, please feel free to contact us. Our team is ready to assist you with any inquiries you may have.
                    </p>
                    <ol>
                        <li><span className='font-normal'>Email: </span>For direct communication, you can email us at contact@blukite.finance. We aim to respond to all email inquiries within 48 hours.
                        </li>
                        <li><span className='font-normal'>Online Contact Form: </span>You can also reach us through the contact form available on our website. Just fill in your details and query, and we will get back to you as promptly as possible.
                        </li>
                        <li><span className='font-normal'>Mailing Address: </span>For written correspondence, feel free to send your letters to Unit 36-37 Tondu Enterprise Centre Bryn Road, Aberkenfig, Bridgend, Wales, CF32 9BS.</li>
                        <li><span className='font-normal'>Accessibility Support: </span>If you require any assistance due to a disability or need an accessible format of our cookie policy, please let us know, and we will be happy to accommodate your needs.
                        </li>
                    </ol>
                    <p>We encourage you to reach out with any feedback or inquiries regarding our cookie usage. Your input is valuable to us and helps in enhancing our services and policies.
                    </p>
                </section>
                <section id="section10" className='mt-10'>
                    <h2 className='pageTitle'>10. Legal Compliance and Framework
                    </h2>
                    <p>Blukite is committed to upholding the highest standards of data privacy and protection, ensuring our cookie policy and practices comply with all applicable laws and regulations. This includes adherence to major data protection regulations such as the General Data Protection Regulation (GDPR) and the ePrivacy Directive, among other regional laws.
                    </p>
                    <ol>
                        <li><span className='font-normal'>GDPR Compliance (Regulation (EU) 2016/679): </span>Our use of cookies aligns with the GDPR’s principles, focusing on lawfulness, fairness, transparency, and the safeguarding of individual rights. We obtain clear and affirmative consent for any cookies that are not strictly necessary for the operation of our platforms. For more details, users can refer to the official GDPR text.
                        </li>
                        <li><span className='font-normal'>ePrivacy Directive (2002/58/EC): </span>In line with this Directive, we ensure that our users are fully informed about the use of cookies and similar technologies on our platforms, and that their consent is obtained where required. This Directive’s provisions guide our approach to obtaining and managing user consent.</li>
                        <li><span className='font-normal'>Adaptability to Regional Laws: </span>We recognise that data protection laws can vary by region. Thus, our policies and practices are regularly reviewed and adjusted to conform to any new legal requirements or changes in existing laws.
                        </li>
                        <li><span className='font-normal'>User Rights and Data Protection: </span>Respecting and facilitating user rights, such as the right to access, correct, or delete personal data, is a cornerstone of our policy. We are proactive in ensuring our practices enable these rights effectively.
                        </li>
                        <li><span className='font-normal'>Continuous Monitoring and Training: </span>Our team is continuously educated on the latest in data protection laws and regularly monitors our compliance with these laws to ensure our practices are up to date.
                        </li>
                    </ol>
                    <p>Blukite’s dedication to legal compliance in data privacy is unwavering. For any concerns or inquiries regarding our compliance with data protection laws, please feel free to contact us. We are committed to addressing all queries and ensuring our users’ data is handled responsibly and in line with all legal standards.
                    </p>
                    <p className='font-normal'>Closing Statement
                    </p>
                    <p>Thank you for taking the time to understand our Cookie Policy at Blukite. We are deeply committed to respecting your privacy and ensuring the highest standards of data protection. Our use of cookies is designed to enhance your experience on our platforms, always keeping in mind the importance of your privacy and the trust you place in us.
                    </p>
                    <p>We encourage you to revisit this policy periodically for any updates, as we continually strive to improve our practices and maintain transparency. Your continued use of our website and services signifies your understanding and acceptance of this policy.
                    </p>
                    <p>If you have any questions, concerns, or feedback about this policy or how we handle your data, please do not hesitate to contact us. We value your opinions and are always here to assist you.
                    </p>
                </section>
            </div>
        </div>
    );
}

export default CookiePolicy