import React, { useState, useEffect, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { htmlCode } from './utils/code';
import './index.scss'


const PrivacyPolicy = () => {

    const [activeLink, setActiveLink] = useState('#section1');
    const { Link } = Anchor;

    useEffect(() => {
        const sidebar = document.querySelector('.sidebar');
        if (sidebar) {
            const sectionNumber = parseInt(activeLink.replace('#section', ''), 10);
            if (sectionNumber >= 17) {
                sidebar.scrollTo({
                    top: sidebar.scrollHeight,
                    behavior: 'smooth'
                });
            } else {
                sidebar.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    }, [activeLink]);

    return (
        <div className="flex flex-col md:flex-row container mb-20 gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 policiesPage">
            <div className="hidden sm:block sidebar">
                <Anchor affix={false} targetOffset={200} onChange={setActiveLink}>
                    <Link href="#section1" title="1. Introduction to Blukite Finance Limited’s Privacy Policy"></Link>
                    <Link href="#section2" title="2. How We Use Your Personal Information"></Link>
                    <Link href="#section3" title="3. Personal Information and the Law"></Link>
                    <Link href="#section4" title="4. Legal Basis for Using Your Personal Information"></Link>
                    <Link href="#section5" title="5. Groups of Personal Information"></Link>
                    <Link href="#section6" title="6. Where we collect personal information from"></Link>
                    <Link href="#section7" title="7. How Long We Keep Your Personal Information"></Link>
                    <Link href="#section8" title="8. If You Choose Not to Give Personal Information"></Link>
                    <Link href="#section9" title="9. Cookies and Similar Tracking Technologies"></Link>
                    <Link href="#section10" title="10. How to Complain"></Link>
                    <Link href="#section11" title="11. How to Withdraw Your Consent"></Link>
                    <Link href="#section12" title="12. Letting Us Know if Your Personal Information Is Incorrect"></Link>
                    <Link href="#section13" title="13. How to Get a Copy of Your Personal Information"></Link>
                    <Link href="#section14" title="14. Your Rights"></Link>
                    <Link href="#section15" title="15. How Personal Information is Used"></Link>
                    <Link href="#section16" title="16. How We Work Out What Marketing You Receive"></Link>
                    <Link href="#section17" title="17. How We Use Your Information to Make Automated Decisions"></Link>
                    <Link href="#section18" title="18. Credit Reference Agencies (CRAs)"></Link>
                    <Link href="#section19" title="19. Fraud Prevention Agencies (FPAs)"></Link>
                    <Link href="#section20" title="20. Sending Data Outside the UK and EEA"></Link>
                    <Link href="#section21" title="21. Data Security Measures"></Link>
                    <Link href="#section22" title="22. Third-Party Links"></Link>
                    <Link href="#section23" title="23. User Rights in Detail"></Link>
                    <Link href="#section24" title="24. Policy Updates and Changes"></Link>
                </Anchor>
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor affix={false} targetOffset={200} direction="horizontal">
                    <Link href="#section1" title="1. Introduction to Blukite Finance Limited’s Privacy Policy"></Link>

                    <Link href="#section2" title="2. How We Use Your Personal Information"></Link>
                    <Link href="#section3" title="3. Personal Information and the Law"></Link>
                    <Link href="#section4" title="4. Legal Basis for Using Your Personal Information"></Link>
                    <Link href="#section5" title="5. Groups of Personal Information"></Link>
                    <Link href="#section6" title="6. Where we collect personal information from"></Link>
                    <Link href="#section7" title="7. How Long We Keep Your Personal Information"></Link>
                    <Link href="#section8" title="8. If You Choose Not to Give Personal Information"></Link>
                    <Link href="#section9" title="9. Cookies and Similar Tracking Technologies"></Link>
                    <Link href="#section10" title="10. How to Complain"></Link>
                    <Link href="#section10" title="11. How to Withdraw Your Consent"></Link>
                    <Link href="#section10" title="12. Letting Us Know if Your Personal Information Is Incorrect"></Link>
                    <Link href="#section10" title="13. How to Get a Copy of Your Personal Information"></Link>
                    <Link href="#section10" title="14. Your Rights"></Link>
                    <Link href="#section10" title="15. How Personal Information is Used"></Link>
                    <Link href="#section10" title="16. How We Work Out What Marketing You Receive"></Link>
                    <Link href="#section10" title="17. How We Use Your Information to Make Automated Decisions"></Link>
                    <Link href="#section10" title="18. Credit Reference Agencies (CRAs)"></Link>
                    <Link href="#section10" title="19. Fraud Prevention Agencies (FPAs)"></Link>
                    <Link href="#section10" title="20. Sending Data Outside the UK and EEA"></Link>
                    <Link href="#section10" title="21. Data Security Measures"></Link>
                    <Link href="#section10" title="22. Third-Party Links"></Link>
                    <Link href="#section10" title="23. User Rights in Detail"></Link>
                    <Link href="#section10" title="24. Policy Updates and Changes"></Link>
                </Anchor>
            </div>

            <div className="content pr-4">

                <section id="section1">
                    {/*<h2 className='pageTitle'>1. Introduction to Blukite Finance Limited’s Privacy Policy</h2>
                    <p className='text'>Welcome to BluKite Finance Limited’s (‘the Company’) Data Privacy Policy. This document is designed as an internal document to articulate our organisation's approach to managing data privacy. As a credit card provider regulated by the Financial Conduct Authority (FCA) and committed to compliance with the General Data Protection Regulation (GDPR), this policy establishes the framework through which we safeguard the privacy and security of personal data within our operations. It outlines the internal guidelines and procedures that govern the collection, processing, and storage of customer and employee data. It is crucial for all staff members to familiarise themselves with this policy, as it forms the cornerstone of our commitment to maintaining the highest standards of data privacy. This internal policy is complemented by Bluekite’s external Privacy Statement, accessible on our website, which serves as a transparent communication tool for our customers, detailing how their data is handled and their privacy rights. By aligning our internal practices with external transparency, we strive to build and maintain trust in our commitment to safeguarding the privacy of individuals' data.1.1 What is a Privacy Policy?
                    </p>
                    <p className='text'>A privacy policy is a comprehensive document that outlines how the Company manages and protects the personal information it collects about customers and employees. It serves as a guiding framework for internal operations, defining the principles and procedures that govern data privacy practices. Bluekite’s Data Privacy Policy not only establishes our commitment to compliance with regulatory standards such as GDPR but also reflects our dedication to transparency and accountability. This Policy is an essential tool for our employees to understand and adhere to the standards set forth in our data privacy practices, ensuring that customer and employee information is handled responsibly and ethically.
                    </p>
                    <p className='subtitle'>1.2 Why is a Privacy Policy Necessary?
                    </p>
                    <p className='text'>A Privacy Policy is essential for several reasons:
                    </p>*/}
                    {/*<LetterList letterList={list12}/>*/}
                    <div className='privacy-policy'
                        dangerouslySetInnerHTML={
                            { __html: htmlCode }
                        } />
                </section>

            </div>
        </div>
    );
}

export default PrivacyPolicy
