import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';

const CustomerMonitoring = () => {

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage customerMonitoring">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Key Offerings',
                        },
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Key Offerings',
                        },
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <h2 className='pageTitle'>Customer Monitoring</h2>
                    <p className='text'>
                    <Speaker id="68" text="Customer Monitoring. Blukite Finance recognises the critical role of customer monitoring in maintaining a healthy financial ecosystem. Our comprehensive Customer Monitoring service is designed to
                        support businesses in implementing consumer best practices and effective debt management. By leveraging advanced analytics and real-time data processing, we offer insights
                        that help in identifying, understanding, and managing customer behaviour and risk."/>
                        Blukite Finance recognises the critical role of customer monitoring in maintaining a healthy financial ecosystem. Our comprehensive Customer Monitoring service is designed to
                        support businesses in implementing consumer best practices and effective debt management. By leveraging advanced analytics and real-time data processing, we offer insights
                        that help in identifying, understanding, and managing customer behaviour and risk.
                    </p>
                </section>

                <section id="section2" className='my-16 sm:my-20 relative'>
                    <p className='subtitle font-normal italic text-center'>Key Offerings in Customer Monitoring</p>

                    <div className='customerMonitoring__offers mt-10 mb-20'>
                        {/* <div className='customerMonitoring__offerDashedLine hidden lg:block'> */}
                        {/* </div> */}

                        {/* <svg className='customerMonitoring__offerDashedLine'> */}
                        {/* <line x1="0" y1="30" x2="500" y2="30" stroke-color="#f8a100" /> */}
                        {/* </svg> */}

                        {/* <svg width="1" height="947" viewBox="0 0 1 947" fill="none" xmlns="http://www.w3.org/2000/svg" className='customerMonitoring__offerDashedLine'> */}
                        {/* <path d="M0.5 1L0.499959 946" stroke="#82C7E8" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="14 14"/> */}
                        {/* </svg> */}

                        <div className='customerMonitoring__offerDashedLine hidden lg:block'>
                            <img src={`${basePath}images/CustomerMonitoring/dashedLine_2.svg`} alt="dashed line" />

                        </div>



                        {/* <img className='customerMonitoring__offerDashedLine hidden lg:block' src={`${basePath}images/CustomerMonitoring/dashedLine.svg`} alt="dashed line" /> */}
                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerImgWrapper justify-end' data-aos="fade-right" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/CustomerMonitoring/1.svg`} alt="image for Real-Time Behavioural Analysis" />
                            </div>
                            <div className='customerMonitoring__offerNumber self-start'>1</div>
                            <div className='customerMonitoring__offerItem self-start' data-aos="fade-left" data-aos-duration="700" data-aos-delay="300">
                                <p className='font-medium'>Real-Time Behavioural Analysis</p>
                                <p className='mt-2'><Speaker id="69" text="Real-Time Behavioural Analysis. Utilise cutting-edge technology to analyse customer transactions and behaviours in real-time. This allows for early detection of potential financial distress or changes in spending patterns, enabling proactive engagement and support."/>Utilise cutting-edge technology to analyse customer transactions
                                    and behaviours in real-time. This allows for early detection of
                                    potential financial distress or changes in spending patterns,
                                    enabling proactive engagement and support.</p>
                            </div>
                        </div>

                        <div className='customerMonitoring__offer flex-col-reverse lg:flex-row'>
                            <div className='customerMonitoring__offerItem' data-aos="fade-right" data-aos-duration="700" data-aos-delay="300">
                                <p className='font-medium text-right'>Debt Management Solutions</p>
                                <p className='mt-2 text-right'><Speaker isRight id="70" text="Debt Management Solutions. Our platform integrates sophisticated debt management tools
                                    that assist in identifying at-risk customers early. By doing so, we
                                    enable businesses to offer tailored solutions, such as flexible repayment plans or financial counselling, thereby fostering
                                    responsible lending and borrowing practices."/>
                                    Our platform integrates sophisticated debt management tools
                                    that assist in identifying at-risk customers early. By doing so, we
                                    enable businesses to offer tailored solutions, such as flexible repayment plans or financial counselling, thereby fostering
                                    responsible lending and borrowing practices.</p>
                            </div>
                            <div className='customerMonitoring__offerNumber'>2</div>
                            <div className='customerMonitoring__offerImgWrapper' data-aos="fade-left" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/CustomerMonitoring/2.svg`} alt="image for Debt Management Solutions" />
                            </div>
                        </div>

                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerImgWrapper justify-end' data-aos="fade-right" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/CustomerMonitoring/3.svg`} alt="image for Custom Alerts and Notifications" />
                            </div>
                            <div className='customerMonitoring__offerNumber'>3</div>
                            <div className='customerMonitoring__offerItem' data-aos="fade-left" data-aos-duration="700" data-aos-delay="300">
                                <p className='font-medium'>Custom Alerts and Notifications</p>
                                <p className='mt-2'><Speaker id="71" text="Custom Alerts and Notifications. Set up customised alerts for specific events or behaviours that
                                    may indicate a customer is facing financial difficulties. This
                                    feature ensures businesses can quickly take appropriate
                                    action to mitigate risks and support their customers."/>Set up customised alerts for specific events or behaviours that
                                    may indicate a customer is facing financial difficulties. This
                                    feature ensures businesses can quickly take appropriate
                                    action to mitigate risks and support their customers.</p>
                            </div>
                        </div>

                        <div className='customerMonitoring__offer flex-col-reverse lg:flex-row'>
                            <div className='customerMonitoring__offerItem' data-aos="fade-right" data-aos-duration="700" data-aos-delay="300">
                                <p className='font-medium text-right'>Compliance with Best Practices</p>
                                <p className='mt-2 text-right'><Speaker isRight id="72" text="Compliance with Best Practices. Blukite Finance's Customer Monitoring service is designed to align
                                    with industry best practices and regulatory requirements. Ensuring
                                    compliance not only protects businesses from potential legal and
                                    financial repercussions but also builds trust with customers."/>Blukite Finance's Customer Monitoring service is designed to align
                                    with industry best practices and regulatory requirements. Ensuring
                                    compliance not only protects businesses from potential legal and
                                    financial repercussions but also builds trust with customers.</p>
                            </div>
                            <div className='customerMonitoring__offerNumber'>4</div>
                            <div className='customerMonitoring__offerImgWrapper' data-aos="fade-left" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/CustomerMonitoring/4.svg`} alt="image for Compliance with Best Practices" />
                            </div>
                        </div>

                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerImgWrapper justify-end' data-aos="fade-right" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/CustomerMonitoring/5.svg`} alt="image for Comprehensive Reporting" />
                            </div>
                            <div className='customerMonitoring__offerNumber'>5</div>
                            <div className='customerMonitoring__offerItem' data-aos="fade-left" data-aos-duration="700" data-aos-delay="300">
                                <p className='font-medium'>Comprehensive Reporting</p>
                                <p className='mt-2'><Speaker id="73" text="Comprehensive Reporting. Gain access to detailed reports and insights into customer
                                    behaviour, financial health, and risk profiles. These reports are
                                    invaluable for strategic decision-making and for enhancing
                                    customer relationship management."/>Gain access to detailed reports and insights into customer
                                    behaviour, financial health, and risk profiles. These reports are
                                    invaluable for strategic decision-making and for enhancing
                                    customer relationship management.</p>
                            </div>
                        </div>

                    </div>



                    {/* line */}

                    <p className='subtitle font-normal italic text-center'>The Importance of Consumer Best Practices and Debt Management</p>
                    <p className='text'><Speaker id="74" text="The Importance of Consumer Best Practices and Debt Management. Implementing consumer best practices and effective debt management is not just about compliance or risk mitigation, it's about building a sustainable business that values and
                        protects its customers. Blukite Finance's Customer Monitoring service empowers businesses to:"/>Implementing consumer best practices and effective debt management is not just about compliance or risk mitigation, it's about building a sustainable business that values and
                        protects its customers. Blukite Finance's Customer Monitoring service empowers businesses to:</p>

                    <div className="customerMonitoring__cards gap-10 sm:gap-20 mt-14">
                        <div>
                            <img className='w-16' src={`${basePath}images/ico/keyFeatureItem_4.svg`} alt='icon for Enhance Customer Experience' />
                            <p className='mt-4 text-lg font-normal'>Enhance Customer Experience</p>
                            <p className='mt-2'><Speaker id="75" text="Enhance Customer Experience. Early detection of potential issues allows businesses to guide customers towards healthier financial habits, benefiting both parties in the long term."/>Early detection of potential issues
                                allows businesses to guide customers
                                towards healthier financial habits,
                                benefiting both parties in the long term.</p>
                        </div>


                        <div>
                            <img className='w-16' src={`${basePath}images/ico/keyFeatureItem_5.svg`} alt='icon for Promote Financial Health' />
                            <p className='mt-4 text-lg font-normal'>Promote Financial Health</p>
                            <p className='mt-2'><Speaker id="76" text="Promote Financial Health. By understanding customer needs and behaviours, businesses can personalise their offerings, improving satisfaction and loyalty."/>By understanding customer needs
                                and behaviours, businesses can
                                personalise their offerings, improving
                                satisfaction and loyalty.</p>
                        </div>


                        <div>
                            <img className='w-16' src={`${basePath}images/ico/keyFeatureItem_1.svg`} alt='icon for Sustain Growth' />
                            <p className='mt-4 text-lg font-normal'>Sustain Growth</p>
                            <p className='mt-2'><Speaker id="77" text="Sustain Growth. A focus on best practices and debt management contributes to a more robust financial ecosystem, supporting sustainable growth and innovation."/>A focus on best practices and debt
                                management contributes to a more
                                robust financial ecosystem, supporting
                                sustainable growth and innovation.</p>
                        </div>
                    </div>

                    <p className='text mt-14'><Speaker id="78" text="Blukite Finance is committed to advancing financial services through technology and insight. Our Customer Monitoring service exemplifies this commitment, providing businesses with the tools they need to manage customer relationships effectively, responsibly, and profitably. Contact us to learn how we can help you integrate these best practices into your operations, ensuring you are not just compliant, but also a leader in customer care and financial management."/>Blukite Finance is committed to advancing financial services through technology and insight. Our Customer Monitoring service exemplifies this commitment, providing businesses
                        with the tools they need to manage customer relationships effectively, responsibly, and profitably. Contact us to learn how we can help you integrate these best practices into your
                        operations, ensuring you are not just compliant, but also a leader in customer care and financial management.</p>

                </section>


            </div>
        </div>
    );
}

export default CustomerMonitoring