import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import { useNavigate } from "react-router-dom";
import { PlainButton } from '../../components/buttons/buttons';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';
import './tailoredServices.scss'

const TailoredServicesOverview = () => {

    const [activeSlide, setActiveSlide] = useState(1);
    const [animate, setAnimate] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    let navigate = useNavigate();


    useEffect(() => {
        setAnimate(true);
        const timeout = setTimeout(() => {
            setAnimate(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, [activeSlide]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                setActiveSlide(activeSlide === 4 ? 1 : activeSlide + 1);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [activeSlide, 4, isHovered]);

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage tailoredServicesOverview">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Partnership',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Our Benefits',
                        },
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Partnership',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Our Benefits',
                        },
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <div className='flex-col sm:flex-row flex items-start justify-between gap-0 sm:gap-10'>
                        <h2 className='pageTitle'>Tailored Services by Blukite Finance</h2>
                        <div className='w-max flex-none mt-0 sm:mt-4'>
                            <PlainButton onClick={() => navigate(`${baseURL}/disclaimer`)} text="Disclaimer" />
                        </div>
                    </div>

                    <p className='text'>
                        <Speaker id="130" text="Tailored Services by Blukite Finance. Businesses across various sectors are continuously seeking innovative solutions to enhance their operations, engage with customers, and navigate the complexities of the market. Recognising this universal need, Blukite Finance proudly introduces our suite of Tailored Services, meticulously designed to meet the specific requirements of diverse industry players.
                        From traditional banking institutions and credit unions to dynamic fintech startups, from liquidity providers to retailers, our services offer a bespoke approach to financial solutions. Whether you are looking to enter the consumer credit market, seeking operational program support, or aiming to integrate pay-over-time product options, Blukite Finance is your partner in achieving your strategic goals. As we embark on this journey together, let us be the architects of your success, transforming challenges into opportunities and visions into realities. Explore our Tailored Services to discover how Blukite Finance can be the catalyst for your business’s growth and innovation."/>
                        
                        Businesses across various sectors are continuously seeking innovative solutions to enhance their operations, engage with customers, and navigate the complexities of the market.
                        Recognising this universal need, Blukite Finance proudly introduces our suite of Tailored Services, meticulously designed to meet the specific requirements of diverse industry players.</p>
                    <p className='text'>From traditional banking institutions and credit unions to dynamic fintech startups, from liquidity providers to retailers, our services offer a bespoke approach to financial solutions.
                        Whether you are looking to enter the consumer credit market, seeking operational program support, or aiming to integrate pay-over-time product options, Blukite Finance is your partner
                        in achieving your strategic goals. As we embark on this journey together, let us be the architects of your success, transforming challenges into opportunities and visions into realities.
                        Explore our Tailored Services to discover how Blukite Finance can be the catalyst for your business’s growth and innovation.</p>
                </section>

                <section id="section2" className='my-10 sm:my-20'>
                    <div className='flex items-start'>
                        <Speaker id="131" text="Why Partner with Blukite Finance? 
                        Expertise Across the Board.
                        Our deep understanding of the financial services ecosystem enables us to provide targeted solutions that address the unique challenges and opportunities within your sector.
                        Customisation at Its Core.
                        We believe in the power of personalised service. Our offerings are tailored to align with your business model, market objectives, and customer engagement strategies.
                        Innovative Technology.
                        Leveraging cutting-edge technology, we ensure that our solutions not only meet current industry standards but also anticipate future trends, keeping you ahead of the curve.
                        Confidential and Trustworthy.
                        We uphold the highest standards of confidentiality and integrity, ensuring that your business’s and your customers' information is handled with utmost care and professionalism.
                        "/>
                        <p className='subtitle font-normal italic'>Why Partner with Blukite Finance?</p>
                    </div>
                    <div className='tailoredServicesOverview__sliderWrapper flex-col xl:flex-row' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        <div className='tailoredServicesOverview__slider'>
                            <div className={activeSlide === 1 ? 'tailoredServicesOverview__sliderItem--active' : 'tailoredServicesOverview__sliderItem'} onClick={() => setActiveSlide(1)}>
                                <div className='flex gap-2'>
                                    <img src={`${basePath}/images/ico/TailoredServicesOverview/1.svg`} alt="icon for the text with title Expertise Across the Board" />
                                    <p className='font-normal'>Expertise Across the Board</p>
                                </div>
                                <p className='mt-1'>Our deep understanding of the financial services ecosystem enables us to provide targeted solutions that address the unique challenges and  opportunities within your sector.</p>
                            </div>

                            <div className={activeSlide === 2 ? 'tailoredServicesOverview__sliderItem--active' : 'tailoredServicesOverview__sliderItem'} onClick={() => setActiveSlide(2)}>
                                <div className='flex gap-2'>
                                    <img src={`${basePath}/images/ico/TailoredServicesOverview/2.svg`} alt="icon for the text with title Customisation at Its Core" />
                                    <p className='font-normal'>Customisation at Its Core</p>
                                </div>
                                <p className='mt-1'>We believe in the power of personalised service. Our offerings are tailored to align with your business model, market objectives, and customer engagement strategies.</p>
                            </div>
                            <div className={activeSlide === 3 ? 'tailoredServicesOverview__sliderItem--active' : 'tailoredServicesOverview__sliderItem'} onClick={() => setActiveSlide(3)}>
                                <div className='flex gap-2'>
                                    <img src={`${basePath}/images/ico/TailoredServicesOverview/3.svg`} alt="icon for the text with title Innovative Technology" />
                                    <p className='font-normal'>Innovative Technology</p>
                                </div>
                                <p className='mt-1'>Leveraging cutting-edge technology, we ensure that our solutions not only meet current industry standards but also anticipate future trends, keeping you ahead of the curve.</p>
                            </div>
                            <div className={activeSlide === 4 ? 'tailoredServicesOverview__sliderItem--active' : 'tailoredServicesOverview__sliderItem'} onClick={() => setActiveSlide(4)}>
                                <div className='flex gap-2'>
                                    <img src={`${basePath}/images/ico/TailoredServicesOverview/4.svg`} alt="icon for the text with title Confidential and Trustworthy" />
                                    <p className='font-normal'>Confidential and Trustworthy</p>
                                </div>
                                <p className='mt-1'>We uphold the highest standards of confidentiality and integrity, ensuring that your business’s and your customers' information is handled with utmost care and professionalism.</p>
                            </div>
                        </div>
                        <div className={`tailoredServicesOverview__sliderImgWrapper hidden sm:flex ${animate ? 'fadeIn' : ''}`}>
                            <img src={`${basePath}/images/ico/TailoredServicesOverview/partner_${activeSlide}.svg`} className='tailoredServicesOverview__sliderImg' alt="" />
                        </div>
                    </div>

                </section>


                <section id="section3" className='my-10 sm:my-32'>
                    
                    <p className='subtitle font-normal italic text-center w-fit mx-auto'>
                    <Speaker id="132" text="Unlock the Power of BluSys: Exclusive Benefits for the Financial Sector
                    BluSys by Blukite Finance offers a comprehensive suite of advantages designed to elevate financial services for professionals, banks, lenders, and leaders. Here is what our platform delivers:
                    Quicker Time-to-Market.
                    Cloud computing and open API architecture speed up product launches.
                    Reduced Operational Costs.
                    Achieve significant savings through efficient technology and operations.
                    Greater Flexibility.
                    A platform that evolves with the financial landscape to meet changing demands.
                    Enhanced Security.
                    Top-tier measures safeguard your financial data against threats.
                    Seamless Integration.
                    Smooth interoperability with existing systems and third-party services.
                    Streamlined Operations.
                    A platform that evolves with the financial landscape to meet changing demands.
                    Advanced Analytics.
                    Cutting-edge insights for informed decision-making and trend analysis.
                    Customised Solutions.
                    Tailored financial products and services to match each client's unique needs.
                    Dedicated Support.
                    Expert assistance available every step of the way for your convenience.
                    "/>

Unlock the Power of BluSys: Exclusive Benefits for the Financial Sector</p>
                    <p className='subtitle italic text-center text-base w-full lg:w-8/12 2xl:w-6/12 mx-auto'>BluSys by Blukite Finance offers a comprehensive suite of advantages designed to elevate financial services for professionals, banks, lenders, and leaders. Here is what our platform delivers:</p>


                    <div className="tailoredServicesOverview__cards sm:gap-x-14 sm:gap-y-10 my-7 sm:my-14">
                        <div className=''>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_4.svg`} alt='icon for Quicker Time-to-Market' />
                            <p className='mt-4 text-lg font-normal'>Quicker Time-to-Market</p>
                            <p className='mt-2 leading-relaxed'>Cloud computing and open API architecture speed up product launches.</p>
                        </div>


                        <div>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_5.svg`} alt='icon for Reduced Operational Costs' />
                            <p className='mt-4 text-lg font-normal'>Reduced Operational Costs</p>
                            <p className='mt-2 leading-relaxed'>Achieve significant savings through efficient technology and operations.</p>
                        </div>

                        <div>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_1.svg`} alt='icon for Greater Flexibility' />
                            <p className='mt-4 text-lg font-normal'>Greater Flexibility</p>
                            <p className='mt-2 leading-relaxed'>A platform that evolves with the financial landscape to meet changing demands.</p>
                        </div>

                        <div>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_4.svg`} alt='icon for Enhanced Security' />
                            <p className='mt-4 text-lg font-normal'>Enhanced Security</p>
                            <p className='mt-2 leading-relaxed'>Top-tier measures safeguard your financial data against threats.</p>
                        </div>


                        <div>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_5.svg`} alt='icon for Seamless Integration' />
                            <p className='mt-4 text-lg font-normal'>Seamless Integration</p>
                            <p className='mt-2 leading-relaxed'>Smooth interoperability with existing systems and third-party services.</p>
                        </div>

                        <div>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_1.svg`} alt='icon for Streamlined Operations' />
                            <p className='mt-4 text-lg font-normal'>Streamlined Operations</p>
                            <p className='mt-2 leading-relaxed'>A platform that evolves with the financial landscape to meet changing demands.</p>
                        </div>

                        <div>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_4.svg`} alt='icon for Advanced Analytics' />
                            <p className='mt-4 text-lg font-normal'>Advanced Analytics</p>
                            <p className='mt-2 leading-relaxed'>Cutting-edge insights for informed decision-making and trend analysis.</p>
                        </div>


                        <div>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_5.svg`} alt='icon for Customised Solutions' />
                            <p className='mt-4 text-lg font-normal'>Customised Solutions</p>
                            <p className='mt-2 leading-relaxed'>Tailored financial products and services to match each client's unique needs.</p>
                        </div>

                        <div>
                            <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_1.svg`} alt='icon for Dedicated Support' />
                            <p className='mt-4 text-lg font-normal'>Dedicated Support</p>
                            <p className='mt-2 leading-relaxed'>Expert assistance available every step of the way for your convenience.</p>
                        </div>
                    </div>

                </section>

            </div>
        </div>
    );
}

export default TailoredServicesOverview