import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import Speaker from '../../components/speaker/speaker';

const UnderwritingAndLoanOrigination = () => {

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage productDevelopmentAndMarketAnalysis">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Key features',
                        }
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Key features',
                        }
                    ]}
                />
            </div>

            <div className="content cardIssuingAndFulfilment">
                <section id="section1">
                    <h2 className='pageTitle'>Underwriting & Loan Origination</h2>
                    <p className='text'>
                    <Speaker id="60" text="Underwriting & Loan Origination. At Blukite Finance, our Underwriting & Loan Origination services are designed with a deep understanding of the delicate balance between customer access to credit and the risk management requirements of our liquidity providers. We position ourselves at the forefront of this process, ensuring that every credit decision upholds the highest standards of financial integrity and responsibility. A Commitment to Excellence
                    Our approach is built on sophisticated analytics and a comprehensive evaluation framework that allows us to assess the creditworthiness of applicants accurately. This rigorous process benefits our customers by facilitating access to credit under fair, transparent terms, while providing our liquidity providers with the confidence that the loans originated are of high quality and within acceptable risk parameters."/>
                        At Blukite Finance, our Underwriting & Loan Origination services are designed with a deep understanding of the delicate balance between customer access to credit and the risk
                        management requirements of our liquidity providers. We position ourselves at the forefront of this process, ensuring that every credit decision upholds the highest standards of
                        financial integrity and responsibility.
                    </p>
                </section>

                <section id="section2" className='my-5 mb-20'>
                    <p className='subtitle italic'>A Commitment to <span className='font-normal'>Excellence</span></p>
                    <p className='text'>
                        Our approach is built on sophisticated analytics and a comprehensive evaluation framework that allows us to assess the creditworthiness of applicants accurately. This rigorous
                        process benefits our customers by facilitating access to credit under fair, transparent terms, while providing our liquidity providers with the confidence that the loans originated
                        are of high quality and within acceptable risk parameters.
                    </p>
                    <div
                        className="animatedInfo__cards my-10"
                    >
                        <div
                            className="animatedInfo__card animatedInfo__card--big relative"
                        >
                            <div className='absolute right-3 top-5'>
                            <Speaker id="61" text="Advanced Risk Analysis. Leveraging the latest in credit risk technology, we ensure a
                                detailed assessment of each loan application, balancing the
                                needs of our customers with the risk appetites of our investors."/>
                            </div>
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/underwritingAndLoanOrigination/1.svg`} alt="icon for Advanced Risk Analysis card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Advanced Risk Analysis</div>
                            <div className="animatedInfo__cardText">Leveraging the latest in credit risk technology, we ensure a
                                detailed assessment of each loan application, balancing the
                                needs of our customers with the risk appetites of our investors.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card animatedInfo__card--big"
                        >
                            <div className='absolute right-3 top-5'>
                                <Speaker id="62" text="Efficient Origination Process. Our platform streamlines the loan origination process, making it
                                more efficient for customers to access the financial products,
                                without compromising the diligence required by our liquidity providers."/>
                            </div>
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/underwritingAndLoanOrigination/2.svg`} alt="icon for Efficient Origination Process card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Efficient Origination Process</div>
                            <div className="animatedInfo__cardText">Our platform streamlines the loan origination process, making it
                                more efficient for customers to access the financial products,
                                without compromising the diligence required by our liquidity providers.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card animatedInfo__card--big"
                        >
                            <div className='absolute right-3 top-5'>
                                <Speaker id="63" text="Transparency and Compliance. We operate with a commitment to transparency and compliance,
                                ensuring that all parties are informed and that every transaction
                                meets regulatory standards."/>
                            </div>
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/underwritingAndLoanOrigination/3.svg`} alt="icon for Transparency and Compliance card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Transparency and Compliance</div>
                            <div className="animatedInfo__cardText">We operate with a commitment to transparency and compliance,
                                ensuring that all parties are informed and that every transaction
                                meets regulatory standards.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card animatedInfo__card--big"
                        >
                             <div className='absolute right-3 top-5'>
                                <Speaker id="64" text="Dynamic Monitoring. Continuous monitoring of loan performance and risk exposure allows
                                us to promptly respond to market changes, safeguarding the interests
                                of both customers and liquidity providers."/>
                            </div>
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/underwritingAndLoanOrigination/4.svg`} alt="icon for Dynamic Monitoring card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Dynamic Monitoring</div>
                            <div className="animatedInfo__cardText">Continuous monitoring of loan performance and risk exposure allows
                                us to promptly respond to market changes, safeguarding the interests
                                of both customers and liquidity providers.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>
                    </div>

                    <p className='text'>
                    <Speaker id="65" text="At Blukite Finance, our Underwriting & Loan Origination services are designed with a deep understanding of the delicate balance between customer access to credit and the risk management requirements of our liquidity providers. We position ourselves at the forefront of this process, ensuring that every credit decision upholds the highest standards of financial integrity and responsibility."/>
                        At Blukite Finance, our Underwriting & Loan Origination services are designed with a deep understanding of the delicate balance between customer access to credit and the risk
                        management requirements of our liquidity providers. We position ourselves at the forefront of this process, ensuring that every credit decision upholds the highest standards of
                        financial integrity and responsibility.
                    </p>
                </section>

            </div>
        </div>
    );
}

export default UnderwritingAndLoanOrigination