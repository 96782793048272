import React, { useEffect } from 'react';
import { Anchor } from 'antd';
import { basePath } from '../../routes';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';
import './tailoredServices.scss'

const TailoredServicesBanks = () => {

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage tailoredServicesBanks">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Tailored Services for Banks'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Consumer Credit',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Seamless Integration',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'New Opportunities',
                        },
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Tailored Services for Banks'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Consumer Credit',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Seamless Integration',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'New Opportunities',
                        },
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <h2 className='pageTitle'>Tailored Services for Banks by Blukite Finance</h2>
                    <p className='text'><Speaker id="133" text="Tailored Services for Banks by Blukite Finance. In a financial ecosystem that's rapidly evolving, banks face the dual challenge of modernising legacy systems and expanding their product offerings to meet changing consumer demands. Blukite Finance recognises these challenges and offers a suite of tailored services designed to empower banks with the agility and innovation needed to thrive."/>In a financial ecosystem that's rapidly evolving, banks face the dual challenge of modernising legacy systems and expanding their product offerings to meet changing consumer
                        demands. Blukite Finance recognises these challenges and offers a suite of tailored services designed to empower banks with the agility and innovation needed to thrive.</p>

                    <p className='subtitle italic font-normal mt-14 text-center'>Modernising Legacy Systems</p>
                    <p className='text italic text-center mt-2'>For banks operating on legacy systems, the need for agility and efficiency has never been more critical. Blukite Finance offers:</p>

                    <div className='mt-10'>
                        <div className='customShadow rounded-3xl p-5 sm:p-7 xl:p-10 relative overflow-hidden' data-aos="fade-down-right" data-aos-duration="800">
                            <img className='hidden sm:block absolute -bottom-2 -right-24 lg:-right-10 xl:right-20 w-72' src={`${basePath}/images/TailoredServicesBanks/2.svg`} alt="illustration for System Integration Solutions" />
                            <div className='w-full sm:w-7/12'>
                                <img src={`${basePath}/images/ico/TailoredServicesBanks/2.svg`} alt="icons for System Integration Solutions" />
                                <p className='font-normal text-lg mt-5'>System Integration Solutions</p>
                                <p className='mt-1'>
                                <Speaker id="134" text="Modernising Legacy Systems. For banks operating on legacy systems, the need for agility and efficiency has never been more critical. Blukite Finance offers: System Integration Solutions
Seamlessly integrate modern financial technologies with your existing legacy systems, enhancing operational efficiency without the need for complete system overhauls."/>
                                Seamlessly integrate modern financial technologies with your existing legacy systems,
                                    enhancing operational efficiency without the need for complete system overhauls.</p>
                            </div>
                        </div>

                        <div className='flex flex-col lg:flex-row mt-7 gap-7'>
                            <div className='customShadow rounded-3xl p-5 sm:p-7 xl:p-10 relative overflow-hidden' data-aos="fade-up-right" data-aos-duration="800">
                                <object className='hidden sm:block absolute top-0 -right-10 xl:right-0 w-min lg:w-4/12 xl:w-36' data={`${basePath}/images/TailoredServicesBanks/3.svg`}></object>
                                <div>
                                    <img src={`${basePath}/images/ico/TailoredServicesBanks/3.svg`} alt="icons for Digital Transformation Advisory" />
                                    <p className='w-7/12 xl:w-9/12 font-normal text-lg mt-5'>Digital Transformation Advisory</p>
                                    <div className='w-10/12 lg:w-full xl:w-9/12'>
                                        <p className='mt-1'>
                                        <Speaker id="135" text="Digital Transformation Advisory. Leverage our expertise to strategically plan and implement digital transformation initiatives, ensuring your bank remains competitive and responsive to market demands."/>
                                        Leverage our expertise to strategically plan and
                                            implement digital transformation initiatives,
                                            ensuring your bank remains competitive and
                                            responsive to market demands.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='customShadow rounded-3xl p-5 sm:p-7 xl:p-10' data-aos="fade-up" data-aos-duration="800">
                                <img src={`${basePath}/images/ico/TailoredServicesBanks/4.svg`} alt="icons for Custom Software Development" />
                                <p className='font-normal text-lg mt-5'>Custom Software Development</p>
                                <p className='mt-1'>
                                <Speaker id="136" text="Custom Software Development. Develop custom solutions that address specific operational
                                    challenges, from improving transaction processing speeds
                                    to enhancing customer data analytics."/>
                                Develop custom solutions that address specific operational
                                    challenges, from improving transaction processing speeds
                                    to enhancing customer data analytics.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="section2" className='my-10 sm:my-20'>
                    <Speaker id="137" text="Expanding into Consumer Credit. For banks primarily focused on debit products looking to expand into consumer credit, Blukite Finance provides: 1. Market Entry Strategy. Comprehensive analysis and strategic planning services to navigate the consumer credit market, identifying opportunities for differentiation and success. 2. Credit Product Development. Assistance in developing a range of credit products tailored to your target customer base, from credit cards to personal loans and lines of credit. 3. Regulatory Compliance Guidance. Ensure your new credit offerings comply with all relevant regulations, leveraging Blukite Finance's deep understanding of the regulatory landscape. 4. Technology and Platform Support. Utilise our technology solutions for credit assessment, risk management, and loan origination, streamlining the launch and management of your credit products."/>
                    <p className='subtitle'>Expanding into <span className='font-normal'>Consumer Credit</span></p>
                    <p className='text'>For banks primarily focused on debit products looking to expand into consumer credit, Blukite Finance provides:</p>

                    <img className='hidden lg:block w-full' src={`${basePath}/images/TailoredServicesBanks/4.svg`} alt="Consumer Credit illustration" />
                    <div className='block lg:hidden'>
                        <div className='numberItems my-5 sm:my-10 gap-3 sm:gap-5 '>
                            <div className='numberItem'>
                                <div className='number'>1</div>
                                <div className='numberItemContent'>
                                    <p className='font-normal'>Market Entry Strategy</p>
                                    <p className='mt-2'>Comprehensive analysis and strategic planning services to navigate the consumer credit market, identifying opportunities for differentiation and success.</p>
                                </div>
                            </div>

                            <div className='numberItem'>
                                <div className='number'>2</div>
                                <div className='numberItemContent'>
                                    <p className='font-normal'>Credit Product Development</p>
                                    <p className='mt-2'>Assistance in developing a range of credit products tailored to your target customer base, from credit cards to personal loans and lines of credit.</p>
                                </div>
                            </div>

                            <div className='numberItem'>
                                <div className='number'>3</div>
                                <div className='numberItemContent'>
                                    <p className='font-normal'>Regulatory Compliance Guidance</p>
                                    <p className='mt-2'>Ensure your new credit offerings comply with all relevant regulations, leveraging Blukite Finance's deep understanding of the regulatory landscape.</p>
                                </div>
                            </div>

                            <div className='numberItem'>
                                <div className='number'>4</div>
                                <div className='numberItemContent'>
                                    <p className='font-normal'>Technology and Platform Support</p>
                                    <p className='mt-2'>Utilise our technology solutions for credit assessment, risk management, and loan origination, streamlining the launch and management of your credit products.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='my-10 2xl:my-32 w-full flex flex-col xl:flex-row gap-5 sm:gap-10 xl:gap-20 items-center'>
                        <div className='w-10/12 sm:w-6/12 xl:w-4/12'>
                            <img className='w-full' src={`${basePath}/images/TailoredServicesBanks/1.svg`} alt="image of the world map" />
                        </div>
                        <div className='flex-1'>
                            <p className='subtitle font-normal italic'>Why Choose Blukite Finance?</p>
                            <p className='mt-5 text w-full'>
                            <Speaker id="138" text="Why Choose Blukite Finance? With our support, you can enhance your operational agility, enter new markets with confidence, and deliver the innovative financial products your customer’s demand.
                            Dedicated Expertise.
                            Our team specialises in financial services innovation, providing insights into technology integration and market expansion strategies.
                            Commitment to Partnership.
                            We view ourselves as more than a service provider; we are your strategic partner, invested in your success.
                            Customised Solutions.
                            Recognising that no two banks are the same, we tailor our services to meet your specific needs and objectives.
                            "/>
                                With our support, you can enhance your operational agility, enter new markets with confidence, and deliver the innovative financial products your customer’s demand.</p>

                            <div className='mt-5 flex flex-col gap-5'>
                                <div className='py-3 px-7 rounded-xl customShadow flex gap-6' data-aos="fade-up" data-aos-duration="500">
                                    <img src={`${basePath}/images/ico/MarketingAndCustomerAcquisition/5.svg`} className='w-10 flex-none' alt="icon for Dedicated Expertise" />
                                    <div>
                                        <p className='font-normal'>Dedicated Expertise</p>
                                        <p className='mt-1'>Our team specialises in financial services innovation, providing insights into technology integration and market expansion strategies.</p>
                                    </div>
                                </div>

                                <div className='py-3 px-7 rounded-xl customShadow flex gap-6 items-center' data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
                                    <div className='w-10 flex-none h-min'>
                                        <img src={`${basePath}/images/ico/MarketingAndCustomerAcquisition/6.svg`} className='w-8 mx-auto' alt="icon for Commitment to Partnership" />
                                    </div>
                                    <div>
                                        <p className='font-normal'>Commitment to Partnership</p>
                                        <p className='mt-1'>We view ourselves as more than a service provider; we are your strategic partner, invested in your success.</p>
                                    </div>
                                </div>

                                <div className='py-3 px-7 rounded-xl customShadow flex gap-6' data-aos="fade-up" data-aos-duration="500" data-aos-delay="600">
                                    <img src={`${basePath}/images/ico/MarketingAndCustomerAcquisition/7.svg`} className='w-10 flex-none' alt="icon for Customised Solutions" />
                                    <div>
                                        <p className='font-normal'>Customised Solutions</p>
                                        <p className='mt-1'>Recognising that no two banks are the same, we tailor our services to meet your specific needs and objectives.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </section>


                <section id="section3" className='my-10 sm:my-32'>
                    <h2 className='pageTitle'>Seamless Integration: The API-Driven Advantage with Blukite's Platform</h2>
                    <p className='text'>
                    <Speaker id="139" text="Seamless Integration: The API-Driven Advantage with Blukite's Platform. Seamless integration of new technologies with existing systems represents a pivotal challenge for financial institutions seeking to enhance their services and operational efficiency. Blukite's platform, leveraging an API-driven architecture, emerges as a transformative solution in this context. By employing Application Programming Interfaces (APIs), our platform facilitates effortless communication and integration with diverse software systems, thereby addressing the complexities traditionally associated with technological upgrades and expansions. Here's an exploration of how an API-centric approach effectively mitigates common integration obstacles, paving the way for a harmonious blend of innovation and legacy infrastructure."/>
                        Seamless integration of new technologies with existing systems represents a pivotal challenge for financial institutions seeking to enhance their services and operational efficiency.
                        Blukite's platform, leveraging an API-driven architecture, emerges as a transformative solution in this context. By employing Application Programming Interfaces (APIs), our platform
                        facilitates effortless communication and integration with diverse software systems, thereby addressing the complexities traditionally associated with technological upgrades and
                        expansions. Here's an exploration of how an API-centric approach effectively mitigates common integration obstacles, paving the way for a harmonious blend of innovation and legacy
                        infrastructure.</p>
                    <p className='subtitle'><span className='font-normal'>Benefits</span> of API-Driven Integration with Blukite Platform</p>
                    <p className='text'>
                    <Speaker id="140" text="Benefits of API-Driven Integration with Blukite Platform. Given these advantages, integrating the Blukite platform with an existing technology stack via an API-driven approach not only mitigates common integration challenges but also unlocks significant opportunities for banks to enhance their services and operational efficiency.
                    Seamless Integration.
                    With Blukite's API-driven solution, banks can enhance their existing systems without significant disruptions, ensuring continuity and stability in their operations.
                    Minimised Impact on Existing Technology.
                    APIs allow for the addition of new functionalities without altering the existing infrastructure. This means that integrating Blukite's platform with a bank’s current technology stack can be done with minimal disruption.
                    Enhanced Compatibility.
                    APIs serve as a bridge between different technologies, ensuring that systems can interact regardless of underlying differences in architecture or design.
                    Simplified Data Exchange.
                    An API-driven approach facilitates smooth and secure data exchange between Blukite’s platform and the bank's internal systems, enhancing data integrity and operational efficiency.
                    Scalability.
                    APIs support scalable integration, allowing banks to start with MVP integration and expand functionalities as needed, aligning with growth and evolving customer demands.
                    Speed to Market.
                    By reducing the complexities of integration, API-driven solutions accelerate the deployment of new services, helping banks to quickly deliver innovative products to the market.
                    "/>
                        Given these advantages, integrating the Blukite platform with an existing technology stack via an API-driven approach not only mitigates common integration challenges but also
                        unlocks significant opportunities for banks to enhance their services and operational efficiency.</p>

                    <div
                        className="animatedInfo__cards my-16"
                    >
                        <div
                            className="animatedInfo__card"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/keyFeatureItem_7.svg`} alt="Seamless Integration" />
                            </div>
                            <div className="animatedInfo__cardTitle">Seamless Integration</div>
                            <div className="animatedInfo__cardText">With Blukite's API-driven solution, banks can enhance their existing systems without significant disruptions, ensuring continuity and stability in their operations.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/TailoredServicesBanks/1.svg`} alt="Minimised Impact on Existing Technology" />
                            </div>
                            <div className="animatedInfo__cardTitle">Minimised Impact on Existing Technology</div>
                            <div className="animatedInfo__cardText">APIs allow for the addition of new functionalities without altering the existing infrastructure. This means that integrating Blukite's platform with a bank’s current technology stack can be done with minimal disruption.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/integratedComplianceCard_2.svg`} alt="Enhanced Compatibility" />
                            </div>
                            <div className="animatedInfo__cardTitle">Enhanced Compatibility</div>
                            <div className="animatedInfo__cardText">APIs serve as a bridge between different technologies, ensuring that systems can interact regardless of underlying differences in architecture or design. </div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/keyFeatureItem_4.svg`} alt="Simplified Data Exchange" />
                            </div>
                            <div className="animatedInfo__cardTitle">Simplified Data Exchange</div>
                            <div className="animatedInfo__cardText">An API-driven approach facilitates smooth and secure data exchange between Blukite’s platform and the bank's internal systems, enhancing data integrity and operational efficiency.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/integratedComplianceCard_5.svg`} alt="Scalability" />
                            </div>
                            <div className="animatedInfo__cardTitle">Scalability</div>
                            <div className="animatedInfo__cardText">APIs support scalable integration, allowing banks to start with MVP integration and expand functionalities as needed, aligning with growth and evolving customer demands.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card"
                        >
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/keyFeatureItem_1.svg`} alt="Speed to Market" />
                            </div>
                            <div className="animatedInfo__cardTitle">Speed to Market</div>
                            <div className="animatedInfo__cardText">By reducing the complexities of integration, API-driven solutions accelerate the deployment of new services, helping banks to quickly deliver innovative products to the market.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>
                    </div>

                    <div className='bg-lightBlue p-5 lg:p-16 flex-col mb-20 my-0 sm:my-20 items-center gap-5 lg:gap-10 flex xl:flex-row rounded' data-aos="fade-up" data-aos-delay="300">
                        <div className=' flex-col gap-5 lg:gap-10 flex xl:flex-row items-center'>
                            <div className='max-w-full w-full mx-auto xl:max-w-64 uppercase italic font-normal text-xl lg:text-3xl text-center xl:text-left'>PARTNER WITH
                                BLUKITE Finance</div>

                            <div className='flex'>
                                <img className='w-8 lg:w-14 self-start' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                                <p className='font-light text-base lg:text-lg ml-5 lg:leading-tight'>
                                <Speaker id="141" text="PARTNER WITH BLUKITE FINANCE. Choosing Blukite means embracing a partnership that extends beyond technology. It's about innovating confidently, knowing that your infrastructure is robust, flexible, and future-ready. Discover how integrating the Blukite platform can elevate your bank's digital strategy, delivering exceptional value to your customers and stakeholders."/>
                                    Choosing Blukite means embracing a partnership that extends beyond technology. It's about innovating confidently, knowing that your infrastructure is robust, flexible, and future-ready. Discover how integrating the Blukite platform can elevate your bank's digital strategy, delivering exceptional value to your customers and stakeholders.
                                </p>
                                <img className='w-8 lg:w-14 rotate-180 self-end ml-5' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                            </div>
                        </div>

                    </div>

                </section>

                <section id="section4" className='my-10 sm:my-32'>
                    <p className='subtitle'>Crafting Informative Content:<span className='font-normal'> Integration Challenges and Opportunities</span></p>
                    <p className='text'>
                    <Speaker id="142" text="Crafting Informative Content: Integration Challenges and Opportunities. In crafting informative content for readers about the challenges and opportunities of integrating the Blukite platform, it's essential to highlight how the platform's API-driven nature addresses common integration concerns, thereby facilitating a smoother, more efficient process. In the journey to digital transformation, banks often face the daunting task of integrating new technology solutions with their existing technology stacks. The Blukite platform, powered by an advanced API-driven architecture, is designed to turn these challenges into opportunities for innovation and growth. Also integrating the Blukite platform not only addresses technical challenges but also opens a world of opportunities.
                    Seamless Integration.
                    With our API-driven solution, banks can enhance their existing systems without disruptions, ensuring continuity and stability in their operations.
                    Compatibility and Flexibility.
                    Our APIs bridge new functionalities with legacy systems, resolving compatibility issues and enhancing operational flexibility.
                    Rapid Deployment.
                    The simplicity of API integration accelerates the implementation of new services, allowing banks to respond swiftly to market changes and customer needs.
                    Efficient Data Management.
                    Blukite’s APIs facilitate efficient data exchanges, enhancing accuracy and enabling real-time insights that drive informed decision-making.
                    Innovative Product Offerings.
                    Quickly launch and scale new financial products, from credit solutions to personalised banking services, meeting evolving customer expectations.
                    Operational Excellence.
                    Achieve streamlined operations with automated processes, reducing costs and freeing resources to focus on strategic growth initiatives.
                    Data-Driven Insights.
                    Leverage the power of analytics for a deeper understanding of customer behaviour, optimising your services and marketing strategies for maximum impact.
                    "/>
                        In crafting informative content for readers about the challenges and opportunities of integrating the Blukite platform, it's essential to highlight how the platform's API-driven nature
                        addresses common integration concerns, thereby facilitating a smoother, more efficient process. In the journey to digital transformation, banks often face the daunting task of
                        integrating new technology solutions with their existing technology stacks. The Blukite platform, powered by an advanced API-driven architecture, is designed to turn these
                        challenges into opportunities for innovation and growth. Also integrating the Blukite platform not only addresses technical challenges but also opens a world of opportunities.</p>
                
                <div className='flex gap-10 sm:gap-20 flex-wrap justify-center my-10'>
                    <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                        <img src={`${basePath}/images/ico/TailoredServicesBanks/5.svg`} alt="icon for Seamless Integration"/>
                        <p className='mt-2 font-normal text-lg'>Seamless Integration</p>
                        <p>With our API-driven solution, banks can enhance their existing systems without disruptions, ensuring continuity and stability in their operations.</p>
                    </div>
                    <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                        <img src={`${basePath}/images/ico/TailoredServicesBanks/6.svg`} alt="icon for Compatibility and Flexibility"/>
                        <p className='mt-2 font-normal text-lg'>Compatibility and Flexibility</p>
                        <p>Our APIs bridge new functionalities with legacy systems, resolving compatibility issues and enhancing operational flexibility.</p>
                    </div>
                    <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                        <img src={`${basePath}/images/ico/TailoredServicesBanks/7.svg`} alt="icon for Rapid Deployment"/>
                        <p className='mt-2 font-normal text-lg'>Rapid Deployment</p>
                        <p>The simplicity of API integration accelerates the implementation of new services, allowing banks to respond swiftly to market changes and customer needs.</p>
                    </div>
                    <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                        <img src={`${basePath}/images/ico/TailoredServicesBanks/8.svg`} alt="icon for Efficient Data Management"/>
                        <p className='mt-2 font-normal text-lg'>Efficient Data Management</p>
                        <p> Blukite’s APIs facilitate efficient data exchanges, enhancing accuracy and enabling real-time insights that drive informed decision-making.</p>
                    </div>
                    <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                        <img src={`${basePath}/images/ico/TailoredServicesBanks/9.svg`} alt="icon for Innovative Product Offerings"/>
                        <p className='mt-2 font-normal text-lg'>Innovative Product Offerings</p>
                        <p>Quickly launch and scale new financial products, from credit solutions to personalised banking services, meeting evolving customer expectations.</p>
                    </div>
                    <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                        <img src={`${basePath}/images/ico/TailoredServicesBanks/10.svg`} alt="icon for Operational Excellence"/>
                        <p className='mt-2 font-normal text-lg'>Operational Excellence</p>
                        <p>Achieve streamlined operations with automated processes, reducing costs and freeing resources to focus on strategic growth initiatives.</p>
                    </div>
                    <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                        <img src={`${basePath}/images/ico/TailoredServicesBanks/11.svg`} alt="icon for Data-Driven Insights"/>
                        <p className='mt-2 font-normal text-lg'>Data-Driven Insights</p>
                        <p>Leverage the power of analytics for a deeper understanding of customer behaviour, optimising your services and marketing strategies for maximum impact.</p>
                    </div>
                </div>
                </section>

                <div>
                </div>

            </div>
        </div>
    );
}

export default TailoredServicesBanks