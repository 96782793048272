import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';
import './tailoredServices.scss'

const GridComponent = () => {
    const [expandedCard, setExpandedCard] = useState(null);

    const toggleCard = (id) => {
        if (expandedCard === id) {
            setExpandedCard(null);
        } else {
            setExpandedCard(id);
        }
    };

    const advantagesItems = [
        {
            id: 1, title: 'Enhanced Accessibility', desc: 'Leveraging Compliance with Enhanced Accessibility Certification.',
            text:
                <div>
                    <p className='text text-lg font-normal'>Leveraging Compliance with Enhanced Accessibility Certification</p>
                    <p className='text mt-2'>Blukite Finance is at the forefront of revolutionising retail financing, embodying the principle that financial services should be inclusive and accessible to all. Our
                        platform’s full compliance with Enhanced Accessibility (EA) certification is a testament to our unwavering commitment to inclusivity, ensuring that our services are
                        designed to cater to the broadest possible customer base, including those with disabilities.</p>
                </div>,
            textSpeaker: `Leveraging Compliance with Enhanced Accessibility Certification. Blukite Finance is at the forefront of revolutionising retail financing, embodying the principle that financial services should be inclusive and accessible to all. Our 
            platform’s full compliance with Enhanced Accessibility (EA) certification is a testament to our unwavering commitment to inclusivity, ensuring that our services are 
            designed to cater to the broadest possible customer base, including those with disabilities.`

        },
        {
            id: 2, title: 'Inclusivity', desc: 'Enhancing Retailer Offerings through Inclusivity.',
            text:
                <div>
                    <p className='text text-lg font-normal'>Enhancing Retailer Offerings through Inclusivity</p>
                    <p className='text mt-2'>Our compliance with EA standards means that when retailers partner with Blukite, they significantly expand their reach. The Blukite platform enables retailers to
                        circumnavigate the traditional boundaries to providing their customers with flexible deferred payment options (DPOs). This inclusivity ensures that all customers,
                        regardless of their physical or cognitive abilities, can access, understand, and use our financial products with ease.</p>
                </div>,
            textSpeaker: `Enhancing Retailer Offerings through Inclusivity. Our compliance with EA standards means that when retailers partner with Blukite, they significantly expand their reach. The Blukite platform enables retailers to 
              circumnavigate the traditional boundaries to providing their customers with flexible deferred payment options (DPOs). This inclusivity ensures that all customers, 
              regardless of their physical or cognitive abilities, can access, understand, and use our financial products with ease.`
        },
        {
            id: 3, title: 'Comprehensive Solutions', desc: 'Empowering Retailers with Comprehensive Solutions.',
            text:
                <div>
                    <p className='text text-lg font-normal'>Empowering Retailers with Comprehensive Solutions</p>
                    <p className='text mt-2'>Blukite Finance empowers retailers by providing a comprehensive suite of tools designed to facilitate seamless financial transactions. Retailers are given the
                        flexibility to fund all or part of the DPOs. Alternatively, Blukite can arrange for third-party liquidity providers to underwrite the required facilities, ensuring that
                        financial solutions are tailored to meet the specific needs of each retailer and their customer base.</p>
                </div>,
            textSpeaker: `Empowering Retailers with Comprehensive Solutions. Blukite Finance empowers retailers by providing a comprehensive suite of tools designed to facilitate seamless financial transactions. Retailers are given the 
              flexibility to fund all or part of the DPOs. Alternatively, Blukite can arrange for third-party liquidity providers to underwrite the required facilities, ensuring that 
              financial solutions are tailored to meet the specific needs of each retailer and their customer base.`
        },
        {
            id: 4, title: 'Customised Payment App and Cards', desc: 'Certified Accessible, Versatile, and User-Friendly for All.',
            text:
                <div>
                    <p className='text text-lg font-normal'>Customised Payment App and Cards</p>
                    <p className='text mt-2'> To further enhance accessibility and convenience for both retailers and their customers, Blukite provides a customised payment app, certified by the EA Association
                        for its accessibility features. This app is complemented by payment cards, offering a versatile and user-friendly payment solution. The app’s design adheres to the
                        highest standards of accessibility, ensuring that customers with various disabilities can navigate and manage their finances with ease.</p>
                </div>,
            textSpeaker: `Customised Payment App and Cards.  To further enhance accessibility and convenience for both retailers and their customers, Blukite provides a customised payment app, certified by the EA Association 
              for its accessibility features. This app is complemented by payment cards, offering a versatile and user-friendly payment solution. The app’s design adheres to the 
              highest standards of accessibility, ensuring that customers with various disabilities can navigate and manage their finances with ease.`
        },
        {
            id: 5, title: 'Merchant Portal ', desc: 'Detailed Transactions, KPIs, and Strategic Insights for Retailers.',
            text:
                <div>
                    <p className='text text-lg font-normal'>Merchant Portal for Enhanced Business Insights</p>
                    <p className='text mt-2'>Retailers partnering with Blukite also gain access to a comprehensive merchant portal. This portal provides detailed accounts of all transactions and other key
                        performance indicators (KPIs) that support business operations and strategic decision-making. The merchant portal is an invaluable tool for retailers, offering
                        insights into consumer behaviour, payment trends, and financial performance, enabling them to tailor their offerings to meet market demands more effectively.</p>
                </div>,
            textSpeaker: `Merchant Portal for Enhanced Business Insights. Retailers partnering with Blukite also gain access to a comprehensive merchant portal. This portal provides detailed accounts of all transactions and other key 
              performance indicators (KPIs) that support business operations and strategic decision-making. The merchant portal is an invaluable tool for retailers, offering 
              insights into consumer behaviour, payment trends, and financial performance, enabling them to tailor their offerings to meet market demands more effectively.`
        },
    ]
    return (
        <main className='mt-10'>
            <ul className="coreSolutions__keyFeaturesItems">
                {advantagesItems.map(x => {
                    const isExpanded = expandedCard === x.id;
                    return (
                        <React.Fragment key={x.id}>
                            <li className={isExpanded ? 'coreSolutions__keyFeaturesItem--active' : ''} onClick={() => toggleCard(x.id)}>
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/${x.id}.svg`} alt={`icon for ${x.title}`} />
                                <p className='mt-3 text-lg font-normal'>{x.title}</p>
                                <p className='mt-3'>{x.desc}</p>
                                <button
                                    type="button"
                                    onClick={() => toggleCard(x.id)}
                                    data-quick-view
                                    className='text-blue font-medium  mt-auto flex items-center -ml-5'
                                >
                                    Read more
                                    <img className='w-4 ml-3 coreSolutions__keyFeaturesReadMoreArrow' src={`${basePath}images/ico/keyFeatureArrow.svg`} alt="Arrow icon" />
                                </button>

                            </li>
                            {isExpanded && (
                                <div className={`coreSolutions__keyFeaturesItem--fullwidth coreSolutions__keyFeaturesItem--activeExpanded`}>
                                    <div className='flex justify-between'>
                                        <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/${x.id}.svg`} alt={`icon for ${x.title}`} />
                                        <img className='w-16 ml-3 cursor-pointer' onClick={() => toggleCard(x.id)} src={`${basePath}images/ico/keyFeatureCloseArrow.svg`} alt="Arrow icon" />
                                    </div>
                                    <p><Speaker id={482 + x.id} text={x.textSpeaker} /> {x.text}</p>
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
            </ul>
        </main>
    );
};


const TailoredServicesRetailers = () => {

    const productOfferingsSlides = [
        {
            title: "Aligning with Consumer Rights",
            description: `At Blukite Finance, we understand the critical importance of fair 
          consumer treatment and the assurance of affordability in financial 
          services. Our commitment to these principles is not just a 
          regulatory obligation; it is a cornerstone of our ethos, driving the 
          development of our consumer financing solutions. Through our 
          innovative approach, we ensure that our offerings not only comply
           with these critical principles but also enhance customer trust and 
          elevate our partners' brand reputations.`,
            id: 1,
        },
        {
            title: "Affordability and Compliance",
            descriptionSmall: `Blukite’s platform is engineered to prioritise affordability, ensuring 
          that financial solutions are accessible to consumers without leading
           to over indebtedness. Our comprehensive affordability assessments
           are embedded into the application process, ensuring that consumers
           are only offered financing options that align with their financial 
          capacity. This approach not only aligns with regulatory requirements 
          but also protects consumers from financial distress, fostering a 
          healthier financial ecosystem.`,
            description: `Blukite’s platform is engineered to prioritise affordability, ensuring 
          that financial solutions are accessible to consumers without leading
           to over indebtedness. Our comprehensive affordability assessments
           are embedded into the application process, ensuring that consumers
           are only offered financing options that align with their financial 
          capacity. This approach not only aligns with regulatory requirements 
          but also protects consumers from financial distress, fostering a 
          healthier financial ecosystem. Our compliance with these principles 
          is underpinned by rigorous adherence to regulatory standards, 
          including those set forth by the Financial Conduct Authority (FCA) in
           the UK. Blukite’s commitment to compliance is unwavering, ensuring
           that all our services are provided within the framework of the latest
           financial regulations. This commitment extends to continuous 
          monitoring and adaptation to regulatory changes, ensuring that 
          our partners and their consumers are always afforded the highest 
          level of protection and ethical treatment.`,
            id: 2,
        },
        {
            title: "Ensuring Fair Consumer Treatment",
            description: `Fair treatment of consumers is at the heart of everything we do at 
          Blukite. Our solutions are designed to offer transparency, fairness, 
          and respect in every transaction. We believe that financial services 
          should be accessible, easy to understand, and fair for everyone, a 
          belief that guides our product development and operational practices.
           By incorporating features such as clear terms and conditions, no 
          hidden fees, and straightforward repayment options, we ensure that
           consumers are fully informed and treated fairly throughout their 
          financing journey.`,
            id: 3,
        },
        {
            title: "Building Trust and Enhancing Brand Reputation",
            description: `In today’s market, consumer trust is paramount. By partnering with 
          Blukite, retailers and businesses can leverage our commitment to 
          fair consumer treatment and affordability to their advantage. Our
           solutions not only meet but exceed consumer expectations for 
          ethical financial services, fostering trust and loyalty towards your 
          brand. This alignment with consumer rights is more than a regulatory
           requirement; it is a strategic advantage, enhancing your brand's 
          reputation as a consumer-centric and socially responsible business.`,
            id: 4,
        }
    ]
    const [currentProductOfferIndex, setCurrentProductOfferIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    // swipes
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {

        if (touchStart - touchEnd > 150) {
            handleNext();
        }

        if (touchStart - touchEnd < -150) {
            handlePrevious();
        }
    }

    const handleNext = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        const currentSlide = document.getElementById(`slide-${currentProductOfferIndex}`);
        currentSlide.classList.add('moving-out');

        setTimeout(() => {
            setCurrentProductOfferIndex((currentProductOfferIndex + 1) % productOfferingsSlides.length);
            updateSlides(true);
            setIsTransitioning(false);
        }, 400); // Match this timeout to the CSS transition duration
    };

    const handlePrevious = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        setCurrentProductOfferIndex(currentProductOfferIndex === 0 ? productOfferingsSlides.length - 1 : currentProductOfferIndex - 1);
        updateSlides(false);

        const currentSlide = document.getElementById(`slide-${currentProductOfferIndex}`);
        currentSlide.classList.add('moving-in');
        setIsTransitioning(false);
    };

    function applyStyles(currentProductOfferIndex, isNext) {
        let styles = [
            'transform: translateX(0%) scale(1);',
            'transform: translateX(100%) scale(1);',
            'transform: translateX(200%) scale(1);',
            'transform: translateX(300%) scale(1);',
        ];

        for (let i = 0; i < styles.length; i++) {
            let slideIndex = (currentProductOfferIndex + i) % styles.length;
            let slideElement = document.getElementById(`slide-${slideIndex}`);
            slideElement.style = styles[i];

            if (isNext) {
                if (i !== styles.length - 1) {
                    slideElement.classList.add('slide');
                } else {
                    slideElement.classList.remove('slide');
                }

            } else {
                if (slideIndex !== 0) {
                    slideElement.classList.add('slide');
                } else {
                    slideElement.classList.remove('slide');
                }
            }
        }
    }

    const updateSlides = (isNext) => {
        productOfferingsSlides.forEach((slide, index) => {
            applyStyles(currentProductOfferIndex, isNext)
        });
    };

    useEffect(() => {
        updateSlides(true);
    }, [currentProductOfferIndex]);


    // numbers section 

    const [currentNumberIndex, setCurrentNumberIndex] = useState(1);

    function applyNumberStyles(currentNumberIndex) {
        let styles = [
            'top: 0;',
            'top: -68px;',
            'top: -136px;',
            'top: -204px;',
        ];

        let numberSliderEelement = document.getElementById(`numberSliderEelement`);

        if (numberSliderEelement) {
            numberSliderEelement.style = styles[currentNumberIndex - 1];
            console.log('currentNumberIndex', currentNumberIndex)
        }
    }

    const handleNextNumber = () => {
        if (isTransitioning) return;
        applyNumberStyles(currentNumberIndex + 1)
        setCurrentNumberIndex(currentNumberIndex + 1);
    };

    const handlePreviousNumber = () => {
        if (isTransitioning) return;
        applyNumberStyles(currentNumberIndex - 1)
        setCurrentNumberIndex(currentNumberIndex - 1);
    };

    // --------------


    const scenariosSlides = [
        {
            id: 1,
            imageName: 10,
            title: 'Background',
            text: `Imagine a mid-sized retailer specialising in home goods, facing 
            challenges in managing returns and offering flexible payment 
            solutions to their customers. The retailer seeks to enhance 
            customer satisfaction and loyalty by simplifying the returns 
            process and providing more accessible payment options.`
        },
        {
            id: 2,
            imageName: 11,
            title: 'Implementation',
            text: `The retailer partners with Blukite Finance, integrating the 
            customised payment app and leveraging the comprehensive 
            merchant portal. The integration process is seamless, supported 
            by Blukite's dedicated customer success team ensuring a smooth
            transition with minimal disruption to the retailer's operations.`
        },
        {
            id: 3,
            imageName: 12,
            title: 'Outcome',
            text: `Post-implementation, the retailer notices a significant uptick in 
            customer satisfaction. The ease of use of the Blukite payment 
            app leads to an increase in sales, as customers appreciate the 
            flexibility to manage their purchases and returns efficiently. The 
            merchant portal offers the retailer insights into customer 
            behaviour, enabling data-driven decisions to further enhance 
            the customer experience.`
        },
        {
            id: 4,
            imageName: 13,
            title: 'Feedback',
            text: `Though this scenario is hypothetical, it captures the essence of 
            feedback anticipated from retailers:
            "Integrating Blukite’s solution was straightforward, and the 
            impact on our operations was immediate. Offering flexible 
            payment options while managing returns more efficiently has 
            given us a competitive edge."
            "Our customers love the simplicity and flexibility of the Blukite 
            app. It's not just a payment tool; it is a significant enhancement 
            to the shopping experience we offer."
            `
        }
    ]
    const [activeScenario, setActiveScenario] = useState(1);
    const activeScenarioData = scenariosSlides.find(slide => slide.id === activeScenario);
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                setActiveScenario(activeScenario === scenariosSlides.length ? 1 : activeScenario + 1);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [activeScenario, isHovered]);


    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage tailoredServicesRetailers">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Tailored Services'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Risk Profiles & Categorisation',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Regulatory Umbrella Program',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'Returns Management',
                        },
                        {
                            key: 'section5',
                            href: '#section5',
                            title: 'Maximise Your Impact',
                        }
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Tailored Services'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Risk Profiles & Categorisation',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'Regulatory Umbrella Program',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'Returns Management',
                        },
                        {
                            key: 'section5',
                            href: '#section5',
                            title: 'Maximise Your Impact',
                        }
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <h2 className='pageTitle'>Optimising Retail Expansion: Innovative Credit Solutions by Blukite Finance</h2>
                    <p className='text'>
                        <Speaker id="241" text="Optimising Retail Expansion: Innovative Credit Solutions by Blukite Finance. For retailers looking to enhance their financial service offerings, Blukite Finance provides innovative credit solutions that integrate seamlessly with retail operations. Our technology
is designed to extend the capabilities of retailers, allowing them to offer consumer credit options directly at the point of sale. This not only improves customer retention but also 
boosts sales by providing flexible payment options. " />
                        For retailers looking to enhance their financial service offerings, Blukite Finance provides innovative credit solutions that integrate seamlessly with retail operations. Our technology
                        is designed to extend the capabilities of retailers, allowing them to offer consumer credit options directly at the point of sale. This not only improves customer retention but also
                        boosts sales by providing flexible payment options. </p>

                    <div className='customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden flex gap-10 xl:gap-20 mt-10' data-aos="fade-down-right" data-aos-duration="800">

                        <div className='w-full flex-2'>
                            <img src={`${basePath}/images/ico/blueCircles/8.svg`} alt="icons for Empower Your Retail Business with Flexible Consumer Financing" />
                            <p className='font-normal text-lg mt-5 italic'>Empower Your Retail Business with Flexible Consumer Financing</p>
                            <img className='block xl:hidden w-6/12 lg:w-4/12 float-left mr-2 mt-5' src={`${basePath}/images/tailoredServicesRetailers/1.svg`} alt="illustration for System Integration Solutions" />
                            <p className='mt-2'>
                                <Speaker id="242" text="Empower Your Retail Business with Flexible Consumer Financing. Unlock the full potential of your retail operations with Blukite's customised consumer financing 
solutions. Our innovative offerings, including our exclusive Deferred Payment Option (DPO), are 
designed to resonate with your commitment to responsible lending and the fair treatment of 
consumers. As an EA (Enhanced Accessibility) certified platform, we ensure inclusivity, granting 
access to a wider customer base and elevating the shopping experience to unprecedented heights."/>
                                Unlock the full potential of your retail operations with Blukite's customised consumer financing
                                solutions. Our innovative offerings, including our exclusive Deferred Payment Option (DPO), are
                                designed to resonate with your commitment to responsible lending and the fair treatment of
                                consumers. As an EA (Enhanced Accessibility) certified platform, we ensure inclusivity, granting
                                access to a wider customer base and elevating the shopping experience to unprecedented heights.</p>
                        </div>
                        <img className='hidden xl:block flex-1 -mb-10 -mr-10' src={`${basePath}/images/tailoredServicesRetailers/1.svg`} alt="illustration for System Integration Solutions" />

                    </div>

                    <div className='flex flex-col xl:flex-row mt-7 gap-7'>
                        <div className='customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden flex-1' data-aos="fade-up-right" data-aos-duration="800">
                            <img className='absolute w-5/12 sm:w-auto top-0 right-10' src={`${basePath}/images/tailoredServicesRetailers/2.svg`} alt="illustration for System Integration Solutions" />
                            <div>
                                <img src={`${basePath}/images/ico/blueCircles/9.svg`} alt="icons for Boosting Retail Growth" />
                                <p className='w-7/12 xl:w-9/12 font-normal text-lg mt-5 italic'>Boosting Retail Growth</p>
                                <div className='w-full'>
                                    <p className='mt-2'>
                                        <Speaker id="243" text="Boosting Retail Growth. Our Deferred Payment Options, tailored to align with ethical and fair 
service standards, pave the way for retailers to augment sales volumes, 
attract a diverse clientele, and establish a reputation for customer-centric 
financing. The integration of these solutions is streamlined, ensuring that 
your business is bolstered by a suite of options regulated for peace of 
mind and structured for success."/>
                                        Our Deferred Payment Options, tailored to align with ethical and fair
                                        service standards, pave the way for retailers to augment sales volumes,
                                        attract a diverse clientele, and establish a reputation for customer-centric
                                        financing. The integration of these solutions is streamlined, ensuring that
                                        your business is bolstered by a suite of options regulated for peace of
                                        mind and structured for success.</p>
                                </div>
                            </div>
                        </div>
                        <div className='customShadow rounded-3xl p-5 sm:p-10 flex-1' data-aos="fade-up" data-aos-duration="800">
                            <img src={`${basePath}/images/ico/blueCircles/10.svg`} alt="icons for The Power of Deferred Payment Options " />
                            <p className='font-normal text-lg mt-5 italic'>The Power of Deferred Payment Options</p>
                            <p className='mt-2'>
                                <Speaker id="244" text="The Power of Deferred Payment Options. In the dynamic world of retail finance, 'Deferred Payment Options' have 
become a cornerstone for forward-thinking businesses across the UK. 
DPOs epitomise the essence of flexibility, providing your customers with 
the breathing room they need to manage their finances without forgoing 
their purchasing power. This spectrum of payment solutions, ranging from
interest-free periods to structured monthly instalments, is not just a 
financial tool but a catalyst for enhancing customer satisfaction and loyalty.
"/>
                                In the dynamic world of retail finance, 'Deferred Payment Options' have
                                become a cornerstone for forward-thinking businesses across the UK.
                                DPOs epitomise the essence of flexibility, providing your customers with
                                the breathing room they need to manage their finances without forgoing
                                their purchasing power. This spectrum of payment solutions, ranging from
                                interest-free periods to structured monthly instalments, is not just a
                                financial tool but a catalyst for enhancing customer satisfaction and loyalty.
                            </p>
                        </div>
                    </div>

                    <div className='bg-lightBlue p-5 lg:p-16 flex-col mb-20 my-10 sm:my-20 items-center gap-5 lg:gap-10 flex xl:flex-row rounded' data-aos="fade-up">
                        <div className=' flex-col gap-5 lg:gap-10 flex xl:flex-row items-center'>
                            <div className='max-w-full w-full mx-auto xl:max-w-64 uppercase italic font-normal text-xl lg:text-3xl text-center xl:text-left'>PARTNER WITH
                                BLUKITE Finance</div>

                            <div className='flex'>
                                <img className='w-8 lg:w-14 self-start' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                                <p className='font-light text-base lg:text-lg ml-5 lg:leading-tight'>

                                    <Speaker id="245" text="PARTNER WITH BLUKITE FINANCE. Partnering with Blukite means accessing a suite of services that includes dynamic pricing models, 
secure payment processing, and comprehensive regulatory support, all tailored to the unique 
landscape of retail commerce. By leveraging our expertise, retailers can confidently navigate the 
consumer credit space, drive growth, and enhance their competitive edge in the marketplace."/>
                                    Partnering with Blukite means accessing a suite of services that includes dynamic pricing models,
                                    secure payment processing, and comprehensive regulatory support, all tailored to the unique
                                    landscape of retail commerce. By leveraging our expertise, retailers can confidently navigate the
                                    consumer credit space, drive growth, and enhance their competitive edge in the marketplace.
                                </p>
                                <img className='w-8 lg:w-14 rotate-180 self-end ml-5' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                            </div>
                        </div>

                    </div>



                    <p className='subtitle font-normal mt-10 lg:mt-20 italic'>For consumers, DPOs offer an array of financial freedoms, including:</p>
                    <Speaker id="246" text="For consumers, DPOs offer an array of financial freedoms, including:. 1. Budget-Friendly Planning. 
                        The ability to spread costs allows 
                        for better financial management 
                        and planning for consumers... 2. Instant Ownership. 
                        The appeal of owning a product 
                        immediately, with the convenience 
                        of paying later, is a significant draw
                        for online shoppers... 3. Purchase Power. DPOs empower consumers to 
                        access higher-value goods that 
                        may otherwise be outside of an 
                        immediate purchase range. 
                    "/>

                    <div className='block lg:hidden'>
                        <div className='numberItems my-5 sm:my-10 gap-3 sm:gap-5 '>
                            <div className='numberItem'>
                                <div className='number'>1</div>
                                <div className='numberItemContent'>
                                    <p className='font-normal'>The ability to spread costs allows
                                        for better financial management
                                        and planning for consumers.
                                    </p>
                                </div>
                            </div>

                            <div className='numberItem'>
                                <div className='number'>2</div>
                                <div className='numberItemContent'>
                                    <p className='font-normal'>Instant Ownership</p>
                                    <p className='mt-2'>The appeal of owning a product
                                        immediately, with the convenience
                                        of paying later, is a significant draw
                                        for online shoppers.</p>
                                </div>
                            </div>

                            <div className='numberItem'>
                                <div className='number'>3</div>
                                <div className='numberItemContent'>
                                    <p className='font-normal'>Purchase Power</p>
                                    <p className='mt-2'>DPOs empower consumers to
                                        access higher-value goods that
                                        may otherwise be outside of an
                                        immediate purchase range.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src={`${baseURL}/images/tailoredServicesRetailers/3.svg`} className='hidden lg:block w-full' alt='Integrating VIA: Advanced Analytics and Insights' />

                    <p className='font-normal subtitle italic leading-tight mt-10 sm:mt-20'>
                        <Speaker id="497" text="For merchants, DPOs signify an avenue for growth: 1. Increased Conversion Rates: By offering manageable payment structures, you can convert window shoppers into buyers, positively impacting your bottom line...
2. 
Boosted Transaction Values: BThe flexibility of DPOs often encourages customers to indulge in higher-value purchases, directly enhancing the average transaction size...
3. 
Enhanced Cash Flow: More sales coupled with higher-value transactions equate to improved liquidity for your retail operation...
4. 
Cultivating Loyalty: In a market saturated with options, DPOs serve as a loyalty magnet, drawing customers back to your business time and again...
5. 
Millennial and Gen Z Engagement: Adapt to the purchasing preferences of younger demographics who favour alternative financing over traditional credit lines...
6. 
Promotional Leverage: DPOs can be a compelling part of your promotional narrative, attracting customers with the allure of financial flexibility...
7. 
Risk Mitigation: Partnering with Blukite means the complexities of financing, from credit checks to fraud prevention, are managed expertly, minimising your exposure to risks."/>
                        For merchants, DPOs signify an avenue for growth:
                    </p>

                    <div className='flex flex-col xl:flex-row gap-5 xl:gap-14 justify-between mt-10 mb-10 xl:mb-32'>
                        <div className='flex-1 bg-lightBlue h-36 xl:h-auto relative overflow-hidden rounded-xl'>
                            <img data-aos="fade-up" data-aos-offset="0" data-aos-duration="700" className='relative xl:absolute bottom-0 left-5 mx-auto w-11/12 p-7 sm:p-10 xl:p-0 xl:w-10/12 2xl:w-9/12' src={`${basePath}/images/LiquidityProvidersCreditSolutions/1.svg`} alt="illustration laptop with arrow" />
                        </div>
                        <div className='flex-1 relative'>

                            {currentNumberIndex > 1 ?

                                <div onClick={() => handlePreviousNumber()} className='rounded-full w-12 h-12 p-3 bg-lightBlue text-blue text-2xl font-medium flex-none flex items-center justify-center cursor-pointer transition border border-transparent hover:border-blue'>
                                    <img src={`${basePath}/images/ico/topArrow-blue.svg`} alt="illustration laptop with arrow" />
                                </div>
                                :
                                <div className='pointer-events-none rounded-full w-12 h-12 p-3 bg-lightBlue text-blue text-2xl font-medium flex-none flex items-center justify-center'>
                                    <img src={`${basePath}/images/ico/topArrow-blue.svg`} alt="illustration laptop with arrow" />
                                </div>
                            }

                            <img className='absolute left-6 -z-10' src={`${basePath}/images/tailoredServicesRetailers/dashedLine.svg`} alt="illustration laptop with arrow" />

                            <div className='tailoredServicesRetailers__numberSliderWrapper'>
                                <div className='tailoredServicesRetailers__numberSlider' id="numberSliderEelement">

                                    <div className='flex items-center gap-5'>
                                        <div className='rounded-full w-12 h-12 p-5 bg-lightBlue text-blue text-2xl font-medium flex-none flex items-center justify-center'>1</div>
                                        <p className='leading-tight'><span className='font-normal'>Increased Conversion Rates: </span>By offering manageable payment structures, you can
                                            convert window shoppers into buyers, positively impacting your bottom line.</p>
                                    </div>
                                    <div className='flex items-center gap-5'>
                                        <div className='rounded-full w-12 h-12 p-5 bg-lightBlue text-blue text-2xl font-medium flex-none flex items-center justify-center'>2</div>
                                        <p className='leading-tight'><span className='font-normal'>Boosted Transaction Values: </span>The flexibility of DPOs often encourages customers to
                                            indulge in higher-value purchases, directly enhancing the average transaction size.</p>
                                    </div>
                                    <div className='flex items-center gap-5'>
                                        <div className='rounded-full w-12 h-12 p-5 bg-lightBlue text-blue text-2xl font-medium flex-none flex items-center justify-center'>3</div>
                                        <p className='leading-tight'><span className='font-normal'>Enhanced Cash Flow: </span>More sales coupled with higher-value transactions equate to
                                            improved liquidity for your retail operation.</p>
                                    </div>
                                    <div className='flex items-center gap-5'>
                                        <div className='rounded-full w-12 h-12 p-5 bg-lightBlue text-blue text-2xl font-medium flex-none flex items-center justify-center'>4</div>
                                        <p className='leading-tight'><span className='font-normal'>Cultivating Loyalty: </span> In a market saturated with options, DPOs serve as a loyalty magnet,
                                            drawing customers back to your business time and again.</p>
                                    </div>
                                    <div className='flex items-center gap-5'>
                                        <div className='rounded-full w-12 h-12 p-5 bg-lightBlue text-blue text-2xl font-medium flex-none flex items-center justify-center'>5</div>
                                        <p className='leading-tight'><span className='font-normal'>Millennial and Gen Z Engagement: </span> Adapt to the purchasing preferences of younger
                                            demographics who favour alternative financing over traditional credit lines.</p>
                                    </div>
                                    <div className='flex items-center gap-5'>
                                        <div className='rounded-full w-12 h-12 p-5 bg-lightBlue text-blue text-2xl font-medium flex-none flex items-center justify-center'>6</div>
                                        <p className='leading-tight'><span className='font-normal'>Promotional Leverage: </span>DPOs can be a compelling part of your promotional narrative,
                                            attracting customers with the allure of financial flexibility.</p>
                                    </div>
                                    <div className='flex items-center gap-5'>
                                        <div className='rounded-full w-12 h-12 p-5 bg-lightBlue text-blue text-2xl font-medium flex-none flex items-center justify-center'>7</div>
                                        <p className='leading-tight'><span className='font-normal'>Risk Mitigation: </span>Partnering with Blukite means the complexities of financing, from credit
                                            checks to fraud prevention, are managed expertly, minimising your exposure to risks.</p>
                                    </div>

                                </div>
                            </div>

                            {currentNumberIndex <= 3 ?
                                <div onClick={() => handleNextNumber()} className='rounded-full w-12 h-12 p-3 bg-lightBlue text-blue text-2xl font-medium flex-none flex items-center justify-center cursor-pointer transition border border-transparent hover:border-blue'>
                                    <img src={`${basePath}/images/ico/downArrow-blue.svg`} alt="illustration laptop with arrow" />
                                </div>
                                :
                                <div className='rounded-full w-12 h-12 p-3 bg-lightBlue text-blue text-2xl font-medium flex-none flex items-center justify-center'>
                                    <img src={`${basePath}/images/ico/downArrow-blue.svg`} alt="illustration laptop with arrow" />
                                </div>
                            }

                        </div>
                    </div>
                </section>

                <section id="section2" className='mt-10 mb-10 sm:mt-20 xl:mt-20 sm:mb-20 liquidityProvidersCreditSolutions'>

                    <h2 className='pageTitle'>Risk Profiles & Categorisation</h2>
                    <p className='text'>
                        <Speaker id="247" text="Risk Profiles & Categorisation. Blukite Finance’s approach to consumer financing is deeply rooted in the principles of fairness, transparency, and affordability. By ensuring compliance with these principles, we not
only protect consumers but also empower our partners to build stronger, more trusting relationships with their customers. In an era where consumer rights and ethical business 
practices are increasingly under the spotlight, Blukite stands as a beacon of responsible lending and financial inclusivity." />
                        Blukite Finance’s approach to consumer financing is deeply rooted in the principles of fairness, transparency, and affordability. By ensuring compliance with these principles, we not
                        only protect consumers but also empower our partners to build stronger, more trusting relationships with their customers. In an era where consumer rights and ethical business
                        practices are increasingly under the spotlight, Blukite stands as a beacon of responsible lending and financial inclusivity.</p>

                    <div className='flex xl:flex-row flex-col justify-between gap-5 mt-10'>
                        <p className='font-normal subtitle italic leading-tight'>Empowering Consumers with Fair Financial Solutions</p>
                        <div className='mt-0 xl:mt-7 gap-10 flex justify-between'>
                            <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handlePrevious()} onClick={handlePrevious} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderLeftArrow.svg`} alt="slider left arrow" />
                            <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handleNext()} onClick={handleNext} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderRightArrow.svg`} alt="slider right arrow" />
                        </div>
                    </div>

                    <div className='border-r border-separateLight'>
                        <div id="slider" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                            <div className="cards mt-2 xl:mt-10">
                                {productOfferingsSlides.map((slide, index) => (
                                    <div id={`slide-${index}`} key={index} className={`cards__card ${index === currentProductOfferIndex ? 'activeCard' : index === (currentProductOfferIndex + 1) % productOfferingsSlides.length ? 'next' : 'sliderHidden'}`}>
                                        <div className="cards__content">
                                            <p className="font-normal text-base sm:text-lg leading-tight italic">{slide.title}</p>

                                            {slide.id === 2 ?
                                                <>
                                                    <p className="hidden sm:block text-sm sm:text-base font-light mt-3"><Speaker id={219 + slide.id} text={slide.title + "./" + slide.description} /> {slide.description}</p>
                                                    <p className="block sm:hidden text-sm sm:text-base font-light mt-3"><Speaker id={219 + slide.id} text={slide.title + "./" + slide.description} /> {slide.descriptionSmall}</p>
                                                </>

                                                : <p className="text-sm sm:text-base font-light mt-3">
                                                    <Speaker id={247 + slide.id} text={slide.title + "./" + slide.description} />
                                                    {slide.description}</p>
                                            }
                                            {slide.id === 1 ?
                                                <object className='absolute left-1/2 -bottom-1 transform -translate-x-1/2' data={`${basePath}/images/tailoredServicesRetailers/4.svg`}></object>
                                                : slide.id === 3 ?
                                                    <object className='absolute left-1/2 transform -translate-x-1/2 bottom-0' data={`${basePath}/images/tailoredServicesRetailers/5.svg`}></object>
                                                    : slide.id === 4 ?
                                                        <object className='absolute bottom-0 right-0' data={`${basePath}/images/tailoredServicesRetailers/6.svg`}></object>
                                                        : null}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                <section id="section3" className='mt-10 mb-10 sm:mt-20 xl:mt-20 sm:mb-20'>

                    <h2 className='pageTitle'>Regulatory Umbrella Program Advantages</h2>
                    <p className='text'>
                        <Speaker id="482" text="Regulatory Umbrella Program Advantages. By partnering with Blukite Finance, retailers not only ensure compliance with Enhanced Accessibility certification but also embrace a philosophy of inclusivity. This approach not 
only broadens the customer base but also enhances customer satisfaction and loyalty. Blukite’s comprehensive, accessible financial solutions empower retailers to offer their 
customers a more flexible and inclusive payment experience, positioning them as leaders in ethical and socially responsible business practices." />
                        By partnering with Blukite Finance, retailers not only ensure compliance with Enhanced Accessibility certification but also embrace a philosophy of inclusivity. This approach not
                        only broadens the customer base but also enhances customer satisfaction and loyalty. Blukite’s comprehensive, accessible financial solutions empower retailers to offer their
                        customers a more flexible and inclusive payment experience, positioning them as leaders in ethical and socially responsible business practices.</p>

                    <GridComponent />

                </section>

                <section id="section4" className='mt-10 mb-10 sm:mt-20 xl:mt-20 sm:mb-20'>
                    <h2 className='pageTitle'>Strategic Returns Management</h2>
                    <p className='text'><Speaker id="488" text="Strategic Returns Management. Demonstrating Ease of Use: Hypothetical Retailer Success Story. While actual case studies and testimonials are in the process of being compiled, it is important for potential retail 
partners to understand the transformative impact and simplicity of the Blukite solution. Below is a hypothetical scenario reflecting the ease of integration and operational efficiency
 that retailers can expect." />Demonstrating Ease of Use: Hypothetical Retailer Success Story. While actual case studies and testimonials are in the process of being compiled, it is important for potential retail
                        partners to understand the transformative impact and simplicity of the Blukite solution. Below is a hypothetical scenario reflecting the ease of integration and operational efficiency
                        that retailers can expect.</p>

                    <p className='subtitle font-normal text-center mt-10 sm:mt-20'>Scenario: A Retailer's Journey with Blukite Finance</p>
                    <Select
                        defaultValue={1}
                        className='block lg:hidden mt-5'
                        style={{
                            width: '100%',
                        }}
                        onChange={(value) => setActiveScenario(value)}
                        options={[
                            {
                                value: 1,
                                label: 'Background',
                            },
                            {
                                value: 2,
                                label: 'Implementation',
                            },
                            {
                                value: 3,
                                label: 'Outcome',
                            },
                            {
                                value: 4,
                                label: 'Feedback',
                            }
                        ]}
                    />
                    <div className='flex-row flex xl:flex-col gap-10 xl:gap-6' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        <div className='timeline hidden lg:flex mt-5 xl:mt-24'>
                            <div onClick={() => setActiveScenario(1)} className={activeScenario === 1 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Background</p></div>
                            <div onClick={() => setActiveScenario(2)} className={activeScenario === 2 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Implementation</p></div>
                            <div onClick={() => setActiveScenario(3)} className={activeScenario === 3 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Outcome</p></div>
                            <div onClick={() => setActiveScenario(4)} className={activeScenario === 4 ? 'timeline__item--active' : 'timeline__item'}><p className='timeline__text'>Feedback</p></div>
                        </div>

                        <div data-aos="fade-up" data-aos-duration="700" className='my-6 lg:my-12 flex flex-col xl:flex-row w-full xl:w-9/12 mx-auto items-center gap-5 lg:gap-10'>
                            <div className='flex-1 w-full'><img className='rounded object-contain h-64 xl:h-72 w-full' src={`${basePath}images/tailoredServicesRetailers/${activeScenarioData.imageName}.svg`} alt="Comprehensive Market Analysis iamge" /></div>
                            <p className='flex-1 text-base sm:text-lg'><Speaker id={488 + activeScenarioData.id} text={activeScenarioData.title + ". " + activeScenarioData.text} />{activeScenarioData.text}</p>
                        </div>
                    </div>

                    <p>
                    <Speaker id="493" text="This illustrative scenario underscores the strategic advantages of partnering with Blukite Finance. The ease of use of our platform, combined with the strategic returns management capability, is designed to enhance both the retailer's operational efficiency and the customer's shopping experience. As we compile actual case studies and testimonials, we look forward to sharing real-world examples of success stories that mirror this hypothetical scenario, showcasing the tangible benefits of our comprehensive financial solutions."/>
                        This illustrative scenario underscores the strategic advantages of partnering with Blukite Finance. The ease of use of our platform, combined with the strategic returns
                        management capability, is designed to enhance both the retailer's operational efficiency and the customer's shopping experience. As we compile actual case studies and
                        testimonials, we look forward to sharing real-world examples of success stories that mirror this hypothetical scenario, showcasing the tangible benefits of our comprehensive
                        financial solutions.</p>
                </section>

                <section id="section5" className='mt-10 mb-10 sm:mt-20 xl:mt-20 sm:mb-20'>
                    <h2 className='pageTitle'>Exclusive Invitation: Experience the Blukite Difference</h2>
                    <p className='text'>
                    <Speaker id="494" text="Exclusive Invitation: Experience the Blukite Difference. At Blukite Finance, we are not just offering a solution; we are inviting you to be a part of the future of retail. We warmly extend an exclusive invitation to you for a personalised demonstration or consultation session. Experience firsthand how our tailored solutions, including the innovative Deferred Payment Option (DPO), can revolutionise your business operations and elevate your customer experience. Experience how our platform can be customized to meet your unique business requirements, enhancing your payment systems and strategic returns management. By understanding the benefits of the Deferred Payment Option (DPO), you can drive sales, enhance customer satisfaction, and foster loyalty through flexible payment solutions. Additionally, integrating an EA-certified product into your operations reflects our commitment to ethical and regulatory excellence. Our dedication to Enhanced Accessibility aligns with ethical and statutory ESG standards, ensuring your services are accessible to a broader customer base, including those with specific accessibility needs."/>
                        At Blukite Finance, we are not just offering a solution; we are inviting you to be a part of the future of retail. We warmly extend an exclusive invitation to you for a personalised
                        demonstration or consultation session. Experience firsthand how our tailored solutions, including the innovative Deferred Payment Option (DPO), can revolutionise your business
                        operations and elevate your customer experience. Experience how our platform can be customized to meet your unique business requirements, enhancing your payment systems
                        and strategic returns management. By understanding the benefits of the Deferred Payment Option (DPO), you can drive sales, enhance customer satisfaction, and foster loyalty
                        through flexible payment solutions. Additionally, integrating an EA-certified product into your operations reflects our commitment to ethical and regulatory excellence. Our
                        dedication to Enhanced Accessibility aligns with ethical and statutory ESG standards, ensuring your services are accessible to a broader customer base, including those with
                        specific accessibility needs.</p>
                    <p className='subtitle font-normal'>The Blukite Commitment to ESG</p>
                    <p className='text'>
                    <Speaker id="495" text="The Blukite Commitment to ESG. Adopting Blukite’s EA-certified solutions signifies more than a strategic financial decision; it represents a commitment to ethical and regulatory excellence. Our products are designed to be inclusive, ensuring your services are accessible to all customers and align with best practices in corporate social responsibility. Advantages of an EA-Certified Product: Inclusivity at the Core. 
                    By offering a platform accessible to everyone, you not only expand your market but also build a brand that champions inclusivity.
                    Aligning with ESG Standards. 
                    Implementing solutions that adhere to high ethical and sustainability criteria underscores your dedication to responsible business practices, potentially enhancing your company’s reputation and customer trust.
                    A Strategic Edge. 
                    In a marketplace where consumers increasingly make choices based on corporate ethics and sustainability, adopting Blukite’s EA-certified solutions positions your brand as a leader in responsible retailing.
                    "/>
                        Adopting Blukite’s EA-certified solutions signifies more than a strategic financial decision; it represents a commitment to ethical and regulatory excellence. Our products are designed
                        to be inclusive, ensuring your services are accessible to all customers and align with best practices in corporate social responsibility.</p>
                    
                    <p className='subtitle font-normal text-center mt-10 sm:mt-20'>Advantages of an EA-Certified Product:</p>


                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-0 sm:gap-10 lg:gap-20 relative my-5 sm:my-16 text-left sm:text-center'>
                        <img className='hidden xl:block absolute top-10 w-9/12 -z-10  left-1/2 transform -translate-x-1/2' src={`${basePath}/images/ico/WhiteLabelConsumerApp/dashedLine.svg`} alt="dashed arrow illustration" />

                        <div className='flex flex-row sm:flex-col items-center gap-2 text-sm sm:text-base sm:gap-0'>
                            <img src={`${basePath}/images/tailoredServicesRetailers/7.svg`} className='h-32 sm:h-44 bg-white p-0 w-24 sm:w-44' alt="icon for Inclusivity at the Core" />
                            <div className=''>
                                <p className='font-normal mt-3'>Inclusivity at the Core</p>
                                <p className='mt-1 leading-tight'>By offering a platform accessible to
                                    everyone, you not only expand your
                                    market but also build a brand that
                                    champions inclusivity.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center gap-2 text-sm sm:text-base sm:gap-0'>
                            <img src={`${basePath}/images/tailoredServicesRetailers/8.svg`} className='h-32 sm:h-44 bg-white p-0 w-24 sm:w-44' alt="icon for Aligning with ESG Standards" />
                            <div className=''>
                                <p className='font-normal mt-3'>Aligning with ESG Standards</p>
                                <p className='mt-1 leading-tight'>Implementing solutions that adhere to high
                                    ethical and sustainability criteria underscores
                                    your dedication to responsible business
                                    practices, potentially enhancing your
                                    company’s reputation and customer trust.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center gap-2 text-sm sm:text-base sm:gap-0'>
                            <img src={`${basePath}/images/tailoredServicesRetailers/9.svg`} className='h-32 sm:h-44 bg-white p-0 w-24 sm:w-44' alt="icon for A Strategic Edge" />
                            <div className=''>
                                <p className='font-normal mt-3'>A Strategic Edge</p>
                                <p className='mt-1 leading-tight'>
                                    In a marketplace where consumers
                                    increasingly make choices based on
                                    corporate ethics and sustainability, adopting
                                    Blukite’s EA-certified solutions positions your
                                    brand as a leader in responsible retailing.
                                </p>
                            </div>
                        </div>
                    </div>

                    <p>
                    <Speaker id="496" text="Embrace the opportunity to transform your retail experience. Contact us today to arrange your demonstration or consultation and let us illustrate how Blukite Finance can help you maximise your impact in a manner that resonates with your values and business objectives. Together, we can set new standards in the retail industry."/>
                        Embrace the opportunity to transform your retail experience. Contact us today to arrange your demonstration or consultation and let us illustrate how Blukite Finance can help you
                        maximise your impact in a manner that resonates with your values and business objectives. Together, we can set new standards in the retail industry.</p>
                </section>


                <div>
                </div>

            </div>
        </div>
    );
}

export default TailoredServicesRetailers;