import React, { useEffect, useState } from 'react';
import { Anchor, Select } from 'antd';
import { basePath } from '../../routes';
import { useLocation } from 'react-router-dom';
import Speaker from '../../components/speaker/speaker';


const RiskAssessment = () => {

  const location = useLocation();
  const [animate, setAnimate] = useState(false);
  const [activeSlide, setActiveSlide] = useState(1);
  const keyServicesSlides = [
    {
      id: 1,
      title: "Enhancing Decision-Making and Efficiency",
      textSpeaker: `ENHANCING DECISION-MAKING AND EFFICIENCY. Improved Accuracy in Credit Decisions: Our Risk Assessment & CreditScoring services leverage advanced analytics and machine learning algorithms, ensuring high accuracy in evaluating creditworthiness. This precision significantly reduces the risk of defaults, safeguarding your financial health. Faster Processing Times: Automation in credit scoring accelerates the decision-making process, enabling quicker loan approvals. This efficiency boosts customer satisfaction and positions your services as both responsive and reliable.`,
      content: [
        {
          title: "Improved Accuracy in Credit Decisions:",
          text: "Our Risk Assessment & CreditScoring services leverage advanced analytics and machine learning algorithms, ensuring high accuracy in evaluating creditworthiness. This precision significantly reduces the risk of defaults, safeguarding your financial health."
        },
        {
          title: "Faster Processing Times:",
          text: "Automation in credit scoring accelerates the decision-making process, enabling quicker loan approvals. This efficiency boosts customer satisfaction and positions your services as both responsive and reliable."
        }
      ]
    },

    {
      id: 2,
      title: "Expanding Market Reach and Inclusivity",
      textSpeaker: `Expanding Market Reach and Inclusivity. Access to Untapped Markets: By accurately assessing risk, our services enable you to extend credit to previously underserved segments, such as individuals with thin credit files or those in emerging markets. This inclusivity can expand your customer base and drive growth. Tailored Financial Products:  With detailed insights into risk profiles, you can design financial products that cater specifically to different customer segments. Personalised offerings enhance customer engagement and loyalty.`,
      content: [
        {
          title: "Access to Untapped Markets:",
          text: "By accurately assessing risk, our services enable you to extend credit to previously underserved segments, such as individuals with thin credit files or those in emerging markets. This inclusivity can expand your customer base and drive growth."
        },
        {
          title: "Tailored Financial Products: ",
          text: "With detailed insights into risk profiles, you can design financial products that cater specifically to different customer segments. Personalised offerings enhance customer engagement and loyalty."
        }
      ]
    },
    {
      id: 3, title: "Regulatory Compliance and Risk Mitigation",
      textSpeaker: `Regulatory Compliance and Risk Mitigation. Adherence to Regulatory Standards: Our solution ensures compliance with global credit reporting and risk assessment regulations, helping younavigate the complex regulatory environment without compromising on speed or innovation. Proactive Risk Management: Continuous monitoring and real-time updates on creditworthiness help in early detection of potential defaults,allowing for proactive risk management and mitigation strategies.`,
      content: [
        {
          title: "Adherence to Regulatory Standards:",
          text: "Our solution ensures compliance with global credit reporting and risk assessment regulations, helping younavigate the complex regulatory environment without compromising on speed or innovation."
        },
        {
          title: "Proactive Risk Management:",
          text: "Continuous monitoring and real-time updates on creditworthiness help in early detection of potential defaults,allowing for proactive risk management and mitigation strategies."
        }
      ]
    },
    {
      id: 4, title: "Competitive Advantage and Brand Reputation",
      textSpeaker: `Competitive Advantage and Brand Reputation. Strengthened Brand Trust: Demonstrating a commitment to responsible lending and data security enhances your brand's reputation. Trust is a critical factor in financial services, and our solution positions you as a trusted partner in your customers' financial journeys. Data-Driven Strategic Insights: Beyond individual credit decisions, theaggregated data and insights provided can inform broader business strategies, helping you stay ahead in a competitive market.`,
      content: [
        {
          title: "Strengthened Brand Trust:",
          text: "Demonstrating a commitment to responsible lending and data security enhances your brand's reputation. Trust is a critical factor in financial services, and our solution positions you as a trusted partner in your customers' financial journeys."
        },
        {
          title: "Data-Driven Strategic Insights:",
          text: "Beyond individual credit decisions, theaggregated data and insights provided can inform broader business strategies, helping you stay ahead in a competitive market."
        }
      ]
    },
  ]

  const activeSlideData = keyServicesSlides.find(slide => slide.id === activeSlide);

  useEffect(() => {
    const scrollToHash = () => {
      const hash = location.hash;
      if (hash) {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    scrollToHash();
  }, [location.hash]);


  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, [activeSlide]);


  return (
    <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage">
      <div className="hidden sm:block sidebar">
        <Anchor
          targetOffset={150}
          items={[
            {
              key: 'section1',
              href: '#section1',
              title: 'Overview'
            },
            {
              key: 'section2',
              href: '#section2',
              title: 'Advantages',
            },
          ]}
        />
      </div>

      <div className='block sm:hidden sidebar'>
        <Anchor
          direction="horizontal"
          targetOffset={150}
          items={[
            {
              key: 'section1',
              href: '#section1',
              title: 'Overview'
            },
            {
              key: 'section2',
              href: '#section2',
              title: 'Advantages',
            },
          ]}
        />
      </div>
      <div className="content fadeIn">
        <section id="section1">
          <h2 className='pageTitle'>Risk Assessment & Credit Scoring</h2>

          <p className='text'>
          <Speaker id="54" text="Risk Assessment & Credit Scoring. Consumer expectations and regulatory demands are constantly evolving, and  the importance of robust Risk Assessment & Credit Scoring services cannot be overstated. Blukite Finance introduces a comprehensive solution designed to meet these critical needs, offering fintech startups, financial institutions, and retailers a strategic advantage. Here's a detailed business case for why this service is indispensable:"/>
            Consumer expectations and regulatory demands are constantly evolving, and  the importance of robust Risk Assessment & Credit Scoring services cannot be overstated. Blukite
            Finance introduces a comprehensive solution designed to meet these critical needs, offering fintech startups, financial institutions, and retailers a strategic advantage. Here's a
            detailed business case for why this service is indispensable:</p>

        </section>

        <section id="section2" className='my-14'>
          <Select
            defaultValue={1}
            className='block lg:hidden mt-3'
            style={{
              width: '100%',
            }}
            onChange={(value) => setActiveSlide(value)}
            options={[
              {
                value: 1,
                label: 'Enhancing Decision-Making and Efficiency',
              },
              {
                value: 2,
                label: 'Expanding Market Reach and Inclusivity',
              },
              {
                value: 3,
                label: 'Regulatory Compliance and Risk Mitigation"',
              },
              {
                value: 4,
                label: 'Competitive Advantage and Brand Reputation',
              }
            ]}
          />

          <div className='hidden lg:block'>

            <div className='coreSolutions__keyServicesNavItems flex-row justify-start xl:justify-between mt-10 w-full 2xl:w-full'>
              {keyServicesSlides.map(slide => {
                return (
                  <div className={activeSlide === slide.id ? 'coreSolutions__keyServicesNavItem--active' : 'coreSolutions__keyServicesNavItem'} onClick={() => setActiveSlide(slide.id)}>{slide.title}</div>
                )
              }
              )}
            </div>

          </div>

          <div className={`my-5 md:my-10 flex flex-col xl:flex-row gap-5 xl:gap-10 justify-between items-center w-full mx-auto ${animate ? 'animate-slideLeft' : ''}`}>
            <div className='w-full xl:w-7/12 text-base sm:text-lg mt-0 xl:mt-14'>
              <div className=''>
                {activeSlideData.content.map((x, i) => {
                  return (
                    <div className='flex items-center gap-7 mb-10'>
                      <img src={`${basePath}images/ico/checkmark.svg`} alt="checkmark icon" />
                      <p><span className='font-medium'>{x.title}</span> {!i &&<Speaker id={54 + activeSlideData.id} text={activeSlideData.textSpeaker}/>}{x.text}</p>
                    </div>
                  )
                })}
                <p className='text'>
                  {activeSlideData.text}</p>
              </div>
            </div>
            <img className='p-0 xl:p-10 flex-1 coreSolutions__keyServicesItemImage' src={`${basePath}images/riskAssessmentSlide_${activeSlideData.id}.svg`} alt="User-Centric Design" />
          </div>

          <div className='bg-lightBlue p-5 lg:p-16 flex-col items-center gap-5 lg:gap-10 flex xl:flex-row mt-24 rounded'>
            <div className=' w-6/12 uppercase italic font-normal text-xl lg:text-3xl text-center xl:text-left'>PARTNERING WITH BLUKITE FINANCE</div>
            <div className='flex'>
              <img className='w-8 lg:w-14 self-start' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
              <div className=''>
                <p className='font-light text-base lg:text-lg lg:text-xl py-2 lg:py-5 ml-5'> <Speaker id="59" text="PARTNERING WITH BLUKITE FINANCE. Choosing Blukite Finance's Risk Assessment & Credit Scoring services means more than just accessing cutting-edge technology. It signifies a partnership that values innovation, efficiency, and ethical practices. Our dedicated support ensures seamless integration and ongoing optimisation of our services within your operations, enabling you to focus on growth and customer service excellence."/>Choosing Blukite Finance's Risk Assessment & Credit Scoring services means more than just accessing cutting-edge technology. It signifies a partnership that values innovation, efficiency, and ethical practices. Our dedicated support ensures seamless integration and ongoing optimisation of our services within your operations, enabling you to focus on growth and customer service excellence.</p>
              </div>
              <img className='w-8 lg:w-14 rotate-180 self-end ml-5' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
            </div>
          </div>

        </section>

      </div>
    </div>
  )
}

export default RiskAssessment