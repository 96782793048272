import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import Speaker from '../../components/speaker/speaker';
import { basePath, baseURL } from '../../routes';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';

const LoyaltyAndRewardsProgram = () => {

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage productDevelopmentAndMarketAnalysis">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Key Features',
                        },
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Key Features',
                        },
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <div className='flex flex-col lg:flex-row gap-16 xl:gap-24'>
                        <div>
                            <h2 className='pageTitle'>Loyalty & Rewards Program </h2>
                            <p className='text'>
                            <img className='block xl:hidden float-right ml-5 w-5/12 mt-0 sm:-mt-5 lg:-mt-10' src={`${basePath}/images/LoyaltyAndRewardsProgram/1.svg`} alt="illustration: hand with card" />
                            <Speaker id="126" text="Loyalty & Rewards Program.
                            In the dynamic landscape of financial services, effective marketing and customer acquisition strategies are critical for growth and sustainability. Blukite Finance offers a specialised suite of services designed to empower businesses in the United Kingdom with cutting-edge marketing solutions and customer acquisition techniques. Currently exclusive to the UK market, our services are tailored to navigate the unique challenges and opportunities within this region, ensuring our clients achieve optimal results."/>
                                
                                In the dynamic landscape of financial services, effective marketing and customer acquisition
                                strategies are critical for growth and sustainability. Blukite Finance offers a specialised suite of
                                services designed to empower businesses in the United Kingdom with cutting-edge marketing
                                solutions and customer acquisition techniques. Currently exclusive to the UK market, our services
                                are tailored to navigate the unique challenges and opportunities within this region, ensuring our
                                clients achieve optimal results.
                            </p>
                            <p className='subtitle font-normal italic'>Tailored Rewards for Diverse Customer Needs</p>
                            <p className='text'>
                            <Speaker id="127" text="Tailored Rewards for Diverse Customer Needs
                                Understanding that each business and its customers are unique, our Loyalty & Rewards Program offers customisable solutions that can be tailored to fit the specific needs and preferences of your customer base. Whether your focus is on financial services, retail, or any other sector, we ensure that the rewards resonate with your customers and align with your brand values.
                                Our Loyalty & Rewards Program is not just about transactions; it's about building a community of loyal customers who trust and advocate for your brand. By rewarding customers for their loyalty, you not only incentivise repeat business but also encourage positive word-of-mouth, expanding your customer base organically.
                                Key Features of Our Program Include.
                                Customisable Rewards.
                                From cashback and discounts to exclusive offers and VIP experiences, choose rewards that will truly delight your customers.
                                Seamless Integration.
                                Our program integrates smoothly with your existing operations, ensuring a seamless experience for both your team and your customers.
                                Data-Driven Insights.
                                Leverage customer data to refine and personalize the rewards, making each customer feel valued and understood.
                                Enhanced Engagement.
                                Utilise our platform to communicate directly with your customers, keeping them informed about new rewards and offers, and engaging them with your brand.
                                "/>
                                
                                Understanding that each business and its customers are unique, our Loyalty & Rewards Program
                                offers customisable solutions that can be tailored to fit the specific needs and preferences of your
                                customer base. Whether your focus is on financial services, retail, or any other sector, we ensure
                                that the rewards resonate with your customers and align with your brand values. <br />
                                Our Loyalty & Rewards Program is not just about transactions; it's about building a community of
                                loyal customers who trust and advocate for your brand. By rewarding customers for their loyalty,
                                you not only incentivise repeat business but also encourage positive word-of-mouth, expanding
                                your customer base organically.
                            </p>
                        </div>
                        <img data-aos="fade-left" data-aos-duration="700" className='hidden xl:block mr-20' src={`${basePath}/images/LoyaltyAndRewardsProgram/1.svg`} alt="illustration: hand with card" />
                    </div>
                </section>

                <section id="section2" className='my-16 sm:my-28 relative'>
                {/* <Speaker id="128" text="
                    Key Features of Our Program Include.
                    Customisable Rewards.
                    From cashback and discounts to exclusive offers and VIP experiences, choose rewards that will truly delight your customers.
                    Seamless Integration.
                    Our program integrates smoothly with your existing operations, ensuring a seamless experience for both your team and your customers.
                    Data-Driven Insights.
                    Leverage customer data to refine and personalize the rewards, making each customer feel valued and understood.
                    Enhanced Engagement.
                    Utilise our platform to communicate directly with your customers, keeping them informed about new rewards and offers, and engaging them with your brand."/> */}

                    <p className='subtitle font-normal italic text-center'>Key Features of Our Program Include</p>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-0 sm:gap-10 lg:gap-20 relative my-0 sm:my-16 text-left sm:text-center'>
                    <img className='hidden xl:block absolute top-14 w-9/12 left-1/2 transform -translate-x-1/2 -z-1' src={`${baseURL}/images/ico/LoyaltyAndRewardsProgram/dashedLine.svg`} alt="dashed arrow illustration" />

                        <div className='flex flex-row sm:flex-col items-center mt-0 xl:mt-24'>
                            <div className='p-5 sm:p-3 customShadow--big rounded-full bg-white w-20 h-20 flex-none sm:w-32 sm:h-32 flex items-center relative justify-center mr-5 sm:mr-0'>
                                <img src={`${baseURL}/images/ico/LoyaltyAndRewardsProgram/1.svg`} className='' alt="icon for Customisable Rewards" />
                            </div>
                            <div className='mt-4'>
                                <p className='font-normal mt-3'>Customisable Rewards</p>
                                <p className='mt-1 leading-tight'>From cashback and discounts to
                                    exclusive offers and VIP
                                    experiences, choose rewards that
                                    will truly delight your customers.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center'>
                            <div className='flex-none p-5 sm:p-3 customShadow--big rounded-full bg-white w-20 h-20 sm:w-32 sm:h-32 flex items-center relative justify-center mr-5 sm:mr-0'>
                                <img src={`${baseURL}/images/ico/LoyaltyAndRewardsProgram/2.svg`} className='' alt="icon for Seamless Integration" />
                            </div>
                            <div className='mt-4'>
                                <p className='font-normal mt-3'>Seamless Integration</p>
                                <p className='mt-1 leading-tight'>Our program integrates smoothly
                                    with your existing operations,
                                    ensuring a seamless experience for
                                    both your team and your customers.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center mt-0 xl:mt-24'>
                            <div className='flex-none p-5 sm:p-3 customShadow--big rounded-full bg-white w-20 h-20 sm:w-32 sm:h-32 flex items-center relative justify-center mr-5 sm:mr-0'>
                                <img src={`${baseURL}/images/ico/LoyaltyAndRewardsProgram/3.svg`} className='' alt="icon for Data-Driven Insights" />
                            </div>
                            <div className='mt-4'>
                                <p className='font-normal mt-3'>Data-Driven Insights</p>
                                <p className='mt-1 leading-tight'>
                                    Leverage customer data to refine
                                    and personalize the rewards,
                                    making each customer feel
                                    valued and understood.
                                </p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center'>
                            <div className='flex-none p-5 sm:p-3 customShadow--big rounded-full bg-white w-20 h-20 sm:w-32 sm:h-32 flex items-center relative justify-center mr-5 sm:mr-0'>

                                <img src={`${baseURL}/images/ico/LoyaltyAndRewardsProgram/4.svg`} className='' alt="icon for Enhanced Engagement" />
                            </div>
                            <div className='mt-4'>
                                <p className='font-normal mt-3'>Enhanced Engagement</p>
                                <p className='mt-1 leading-tight'>Utilise our platform to communicate
                                    directly with your customers,
                                    keeping them informed about new
                                    rewards and offers, and engaging
                                    them with your brand.</p>
                            </div>
                        </div>
                    </div>

                </section>


                <section id="section3">
                    <div className='bg-lightBlue p-5 lg:p-16 my-10 sm:my-32 rounded'>
                    <Speaker id="129" text="WHY CHOOSE BLUKITE FINANCE?
                    Expertise.
                    Benefit from our extensive experience in developing and managing loyalty programs across various industries.
                    Innovation.
                    Stay ahead of the curve with a program that leverages the latest in loyalty marketing technology and trends.
                    Support.
                    From setup to execution, our dedicated team is here to support you every step of the way."/>
                        <div className='flex-col gap-5 lg:gap-16 flex xl:flex-row'>
                            {/* <div className='mb-5 self-center'> */}
                            <div className='mb-5'>
                                <p className='uppercase italic font-normal text-xl lg:text-3xl leading-relaxed'>WHY Choose Blukite Finance?</p>
                            </div>
                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/1.svg`} alt="icon for Expertise" />
                                <p className='text-xl font-normal mt-3'>Expertise</p>
                                <p className='mt-2'>Benefit from our extensive
                                    experience in developing and
                                    managing loyalty programs
                                    across various industries.</p>
                            </div>

                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/2.svg`} alt="icon for Innovation" />
                                <p className='text-xl font-normal mt-3'>Innovation</p>
                                <p className='mt-2'>Stay ahead of the curve with
                                    a program that leverages the
                                    latest in loyalty marketing
                                    technology and trends.</p>
                            </div>

                            <div className='card'>
                                <img className='card__icon' src={`${basePath}images/ico/ProductDevelopmentAndMarketAnalysis/5.svg`} alt="icon for Support" />
                                <p className='text-xl font-normal mt-3'>Support</p>
                                <p className='mt-2'>From setup to execution, our
                                    dedicated team is here to
                                    support you every step of the
                                    way.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default LoyaltyAndRewardsProgram