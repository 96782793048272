import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Speaker from '../../components/speaker/speaker';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import './diversityAndInclusion.scss';
import '../coreSolutions/coreSolutions.scss';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';


const DiversityAndInclusion = () => {

    const location = useLocation();
    const [activeSlide, setActiveSlide] = useState(2);
    const [animate, setAnimate] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [slides, setSlides] = useState([
        { id: 1, title: "User-Centric Design", text: "Our technology is enabled with configurable EA (Enhanced Accessibility), allowing users to tailor their experience to their needs." },
        { id: 2, title: "Seamless Process", text: "Customers simply open their payment app and scan the Blukite code displayed on the terminal. This process initiates a discreet audio output, providing clear confirmation of  the payment details directly to the customer." },
        { id: 3, title: "Vibro Feedback", text: "At the payment terminal, after the merchant inputs the sale value, the customer is given control to accept or reject the payment. This step is user-configurable, ensuring a flexible and secure transaction process." },
        { id: 4, title: "Empowering Users", text: "Alongside audio confirmation, our solution includes vibro feedback. This feature  confirms image capture and prompts users to listen to their device, offering an  additional layer of interaction and assurance." },
    ])

    const activeSlideData = slides.find(slide => slide.id === activeSlide);
    const slider = useRef(null);


    const [activeSlideBenefits, setActiveSlideBenefits] = useState(1);
    const [benefitsSlides, setBenefitsSlides] = useState([
        {
            id: 1, title: "Visual Impairments", text: `Including partial sight and blindness, ensuring our services 
        are compatible with screen readers and provide sufficient 
        contrast and font size options.` },
        {
            id: 2, title: "Hearing Impairments", text: `Ensuring that any audio content has text alternatives or 
        subtitles, making information accessible to everyone.` },
        {
            id: 3, title: "Motor Disabilities", text: `Making our platforms navigable through keyboard shortcuts 
        andvoice commands for those who may find traditional input 
        devices challenging.` },
        {
            id: 4, title: "Cognitive Disabilities", text: `Including dyslexia, autism, and memory impairments, by 
        offering clear, concise, and predictable navigation and 
        information presentation.` },
        {
            id: 5, title: "Elderly Users", text: `Who may face a variety of accessibility challenges, including 
        reduced dexterity, hearing, and vision, as well as difficulties 
        with complex digital interfaces.` },
    ])
    const activeSlideBenefitsData = benefitsSlides.find(slide => slide.id === activeSlideBenefits);


    useEffect(() => {
        const scrollToHash = () => {
            const hash = location.hash;
            if (hash) {
                const targetElement = document.querySelector(hash);
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        scrollToHash();
    }, [location.hash]);

    useEffect(() => {
        setAnimate(true);
        const timeout = setTimeout(() => {
            setAnimate(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, [activeSlideData]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                setActiveSlide(activeSlide === slides.length ? 1 : activeSlide + 1);
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [activeSlide, slides.length, isHovered]);

    const nextSlide = () => {
        setActiveSlide(activeSlide === slides.length ? 1 : activeSlide + 1);
    };

    const prevSlide = () => {
        setActiveSlide(activeSlide === 1 ? slides.length : activeSlide - 1);
    };


    // ___
    const productOfferingsSlides = [
        {
            title: "Enhancing Technical Reliability with TGM",
            description: `TGM enhances the reliability of biometric fingerprint payment 
            cards by providing tactile guidance for accurate finger 
            placement, reducing partial or incorrect scans. Pilot testing 
            showed improved fingerprint recognition accuracy and fewer 
            transaction errors, leading to higher successful authentication
             rates and smoother transactions. This reliability benefits 
            retailers and consumers with faster, more dependable 
            transactions, reducing the overall time spent at the point of 
            sale, addressing concerns and promoting wider adoption of 
            biometric payment cards.`,
            id: 1,
        },
        {
            title: "Building Consumer Confidence with TGM",
            descriptionSmall: `The introduction of TGM has a profound impact on consumer 
            confidence in biometric payment technology. By addressing 
            common usability issues and improving the accuracy and reliability
             of fingerprint recognition, TGM helps to build trust among users.
            Surveys and feedback collected from pilot testing participants 
            indicate a significant increase in consumer trust and confidence 
            when using biometric fingerprint payment cards with TGM. Users 
            reported feeling more secure and assured that their transactions 
            would be successfully authenticated on the first attempt. This 
            reduction in transaction failures and the associated frustration 
            positively influences their perception of the technology. Furthermore, the ease of use and intuitive nature of TGM 
            contribute to a more positive user experience, reinforcing the 
            belief that biometric payment technology is not only secure but 
            also user-friendly.`,
            description: `The introduction of TGM has a profound impact on consumer 
            confidence in biometric payment technology. By addressing 
            common usability issues and improving the accuracy and reliability
             of fingerprint recognition, TGM helps to build trust among users.
            Surveys and feedback collected from pilot testing participants 
            indicate a significant increase in consumer trust and confidence 
            when using biometric fingerprint payment cards with TGM. Users 
            reported feeling more secure and assured that their transactions 
            would be successfully authenticated on the first attempt. This 
            reduction in transaction failures and the associated frustration 
            positively influences their perception of the technology. 
            Furthermore, the ease of use and intuitive nature of TGM 
            contribute to a more positive user experience, reinforcing the 
            belief that biometric payment technology is not only secure but 
            also user-friendly. The tactile feedback provided by TGM helps 
            users to place their finger quickly and accurately on the sensor, 
            leading to a smoother and more efficient payment process. This 
            increased confidence can lead to higher adoption rates, as 
            consumers are more likely to embrace technology that they 
            perceive as reliable and easy to use.`,
            id: 2,
        },
        {
            title: "Competitive Advantage of the Tactile Guidance Mechanism",
            description: `<div class="text-left"><p>TGM offers a competitive advantage for biometric fingerprint payment cards by addressing usability and reliability issues, significantly impacting market adoption:</p>
            <p class="mt-2"><span class="font-normal">Enhanced User Experience: </span>TGM's tactile feedback guides finger 
            placement, reducing failed authentication attempts and improving
            user experience.</p>
            <p><span class="font-normal">Increased Accuracy and Reliability: </span> Accurate finger placement 
            reduces transaction errors, attracting security-focused users.</p>
            <p><span class="font-normal">Accessibility and Inclusivity: </span>TGM benefits visually impaired users, expanding market appeal.</p></div>`,
            id: 3,
        },
        {
            title: "Competitive Advantage of the Tactile Guidance Mechanism",
            description: `<div class="text-left"><p><span class="font-normal">Differentiation from Mobile Payments: </span>TGM adds biometric 
            security, appealing to security-conscious consumers.</p>
            <p class="mt-2"><span class="font-normal">Reduced Learning Curve: </span>Intuitive tactile feedback eases the 
            transition to biometric cards.</p>
            <p><span class="font-normal">Positive Brand Perception: </span>TGM-equipped cards position 
            companies as innovators, attracting tech-savvy consumers and 
            enhancing loyalty.</p>
            <p><span class="font-normal">Competitive Differentiation: </span>TGM sets biometric cards apart in a 
            crowded market, aiding in market share capture.</p>
            <p>These advantages help TGM-equipped biometric cards attract new users and retain existing ones, driving sustained market adoption.</p></div>`,
            id: 4,
        }
    ]
    const [currentProductOfferIndex, setCurrentProductOfferIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    // swipes
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd() {

        if (touchStart - touchEnd > 150) {
            handleNext();
        }

        if (touchStart - touchEnd < -150) {
            handlePrevious();
        }
    }

    const handleNext = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        const currentSlide = document.getElementById(`slide-${currentProductOfferIndex}`);
        currentSlide.classList.add('moving-out');

        setTimeout(() => {
            setCurrentProductOfferIndex((currentProductOfferIndex + 1) % productOfferingsSlides.length);
            updateSlides(true);
            setIsTransitioning(false);
        }, 400); // Match this timeout to the CSS transition duration
    };

    const handlePrevious = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);

        setCurrentProductOfferIndex(currentProductOfferIndex === 0 ? productOfferingsSlides.length - 1 : currentProductOfferIndex - 1);
        updateSlides(false);

        const currentSlide = document.getElementById(`slide-${currentProductOfferIndex}`);
        currentSlide.classList.add('moving-in');
        setIsTransitioning(false);
    };

    function applyStyles(currentProductOfferIndex, isNext) {
        let styles = [
            'transform: translateX(0%) scale(1);',
            'transform: translateX(100%) scale(1);',
            'transform: translateX(200%) scale(1);',
            'transform: translateX(300%) scale(1);',
        ];

        for (let i = 0; i < styles.length; i++) {
            let slideIndex = (currentProductOfferIndex + i) % styles.length;
            let slideElement = document.getElementById(`slide-${slideIndex}`);
            slideElement.style = styles[i];

            if (isNext) {
                if (i !== styles.length - 1) {
                    slideElement.classList.add('slide');
                } else {
                    slideElement.classList.remove('slide');
                }

            } else {
                if (slideIndex !== 0) {
                    slideElement.classList.add('slide');
                } else {
                    slideElement.classList.remove('slide');
                }
            }
        }
    }

    const updateSlides = (isNext) => {
        productOfferingsSlides.forEach((slide, index) => {
            applyStyles(currentProductOfferIndex, isNext)
        });
    };

    useEffect(() => {
        updateSlides(true);
    }, [currentProductOfferIndex]);

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage diversityAndInclusion">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Inclusive Finance'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Accessibility Solutions',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'VIA Technology',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'User-Centric Banking',
                        },
                        {
                            key: 'section5',
                            href: '#section5',
                            title: 'Biometric Card',
                        },
                        {
                            key: 'section6',
                            href: '#section6',
                            title: 'TGM Technology',
                        },
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Inclusive Finance'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Accessibility Solutions',
                        },
                        {
                            key: 'section3',
                            href: '#section3',
                            title: 'VIA Technology',
                        },
                        {
                            key: 'section4',
                            href: '#section4',
                            title: 'User-Centric Banking',
                        },
                        {
                            key: 'section5',
                            href: '#section5',
                            title: 'Biometric Card',
                        },
                        {
                            key: 'section6',
                            href: '#section6',
                            title: 'TGM Technology',
                        },
                    ]}
                />
            </div>

            <div className="content">
                <section id="section1">
                    <h2 className='pageTitle'>Enhanced Accessibility: Our commitment to Inclusive Financial Experiences</h2>
                    <p className='text'>
                        <Speaker id="499" text="Enhanced Accessibility: Our commitment to Inclusive Financial Experiences. At Blukite Finance, we take our responsibility for developing fully immersive user experiences seriously, especially for consumers requiring additional support in their financial journeys. Our commitment to enhanced accessibility goes beyond compliance, aiming to set new standards in inclusivity within the payments and credit industry. We strive to ensure that our solutions are not only user-friendly but also empowering for all consumers, providing the tools and support needed to navigate financial services with ease and confidence." />
                        At Blukite Finance, we take our responsibility for developing fully immersive user experiences seriously, especially for consumers requiring additional support in their financial
                        journeys. Our commitment to enhanced accessibility goes beyond compliance, aiming to set new standards in inclusivity within the payments and credit industry. We strive to ensure
                        that our solutions are not only user-friendly but also empowering for all consumers, providing the tools and support needed to navigate financial services with ease  and confidence.</p>
                    <p className='text'>
                        <Speaker id="500" text="Blukite recognises the diverse needs of our customers and the importance of making financial services accessible to everyone. A significant portion of the population encounters barriers when using payment cards and credit, stemming from physical disabilities, cognitive impairments, and varying levels of digital literacy. These challenges can disproportionately affect elderly individuals, people with disabilities, and those in socioeconomically disadvantaged positions. Understanding these needs is at the heart of our commitment to enhanced accessibility. This approach not only aligns with our social responsibility goals but also enables us to engage with a broader customer base, creating opportunities for growth and innovation that benefit everyone.
2.5 billion
people by 2050 are projected to have some degree of hearing loss, with over 1 billion young adults at risk of avoidable hearing loss.
2.2 billion
people globally have a near or distance vision impairment, with at least 1 billion cases preventable or yet to be addressed​​.
1.3 billion
people experience significant motor disabilities, representing 16% of the world's population, or 1 in 6 individuals​​.
"/>
                        Blukite recognises the diverse needs of our customers and the importance of making financial services accessible to everyone. A significant portion of the population encounters
                        barriers when using payment cards and credit, stemming from physical disabilities, cognitive impairments, and varying levels of digital literacy. These challenges can disproportionately
                        affect elderly individuals, people with disabilities, and those in socioeconomically disadvantaged positions. Understanding these needs is at the heart of our commitment to enhanced
                        accessibility. This approach not only aligns with our social responsibility goals but also enables us to engage with a broader customer base, creating opportunities for growth and
                        innovation that benefit everyone. </p>


                    <div className='flex flex-col sm:flex-row gap-5 sm:gap-10 xl:gap-20 mb-10 lg:mb-20 mt-10 lg:mt-16'>
                        <div className='inclusiveFinanceItem'>
                            <p className='inclusiveFinanceItem__number text-3xl lg:text-5xl font-extralight italic'>2.5 bn</p>
                            <p className='mt-3'>people by 2050 are projected to have some degree of
                                hearing loss, with over 1 billion young adults at risk of
                                avoidable hearing loss.</p>
                        </div>
                        <div className='inclusiveFinanceItem'>
                            <p className='inclusiveFinanceItem__number text-3xl lg:text-5xl font-extralight italic'>2.2 bn</p>
                            <p className='mt-3'>people globally have a near or distance vision
                                impairment, with at least 1 billion cases preventable or
                                yet to be addressed​​.</p>
                        </div>
                        <div className='inclusiveFinanceItem'>
                            <p className='inclusiveFinanceItem__number text-3xl lg:text-5xl font-extralight italic'>1.3 bn</p>
                            <p className='mt-3'>people experience significant motor disabilities,
                                representing 16% of the world's population, or 1 in 6
                                individuals​​.</p>
                        </div>
                    </div>
                    </section>
                    <section id="section2" className='my-16 sm:my-20'>
                        <p className='subtitle italic text-center font-normal'>Types of Disabilities Benefiting from Blukite's Enhanced Accessibility</p>
                        <Select
                            defaultValue={1}
                            className='block lg:hidden mt-3'
                            style={{
                                width: '100%',
                            }}
                            onChange={(value) => setActiveSlideBenefits(value)}
                            options={[
                                {
                                    value: 1,
                                    label: 'Visual Impairments',
                                },
                                {
                                    value: 2,
                                    label: 'Hearing Impairments',
                                },
                                {
                                    value: 3,
                                    label: 'Motor Disabilities',
                                },
                                {
                                    value: 4,
                                    label: 'Cognitive Disabilities',
                                },
                                {
                                    value: 5,
                                    label: 'Elderly Users',
                                }
                            ]}
                        />

                        <div className='hidden lg:block'>

                            <div className='coreSolutions__keyServicesNavItems flex-row justify-start xl:justify-between mt-10 w-full 2xl:w-full'>
                                {benefitsSlides.map(slide => {
                                    return (
                                        <div className={activeSlideBenefits === slide.id ? 'coreSolutions__keyServicesNavItem--active' : 'coreSolutions__keyServicesNavItem'} onClick={() => setActiveSlideBenefits(slide.id)}>{slide.title}</div>
                                    )
                                }
                                )}
                            </div>

                        </div>

                        <div className={`my-5 md:my-14 flex flex-col xl:flex-row gap-10 justify-center w-full xl:w-10/12 mx-auto items-center`}>
                            <img className='' src={`${basePath}images/diversityAndInclusion/${activeSlideBenefitsData.id}.svg`} alt={activeSlideBenefitsData.title} />
                            <div className='text-base sm:text-lg w-full xl:w-7/12'>
                                <p className='font-normal'>{activeSlideBenefitsData.title}</p>
                                <p className='text'><Speaker id={500 + activeSlideBenefitsData.id} text={activeSlideBenefitsData.title + activeSlideBenefitsData.text} /> {activeSlideBenefitsData.text}</p>
                            </div>
                        </div>

                        <div className='bg-lightBlue p-5 lg:p-16 flex-col mb-0 sm:mb-10 my-10 sm:my-20 items-center gap-5 lg:gap-10 flex xl:flex-row rounded' data-aos="fade-up" data-aos-duration="700" data-aos-offset="200">
                            <div className=' flex-col gap-5 lg:gap-10 flex xl:flex-row items-center'>
                                <div className='max-w-full w-full mx-auto xl:max-w-44 uppercase italic font-normal text-xl lg:text-3xl text-center xl:text-left'>Inclusive financial FUture</div>
                                <div className='flex'>
                                    <img className='w-8 lg:w-14 self-start mt-0 sm:-mt-5' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                                    <p className='font-light text-base lg:text-lg ml-5 lg:leading-tight'>
                                        <Speaker id="506" text="INCLUSIVE FINANCIAL FUTURE. We are dedicated to developing solutions that not only meet but exceed the expectations of all our users, ensuring that our financial products and services are intuitive, user-friendly, and empowering. Through our ongoing efforts to enhance accessibility, Blukite Finance is paving the way for a more inclusive financial future, where everyone has the tools and support needed to manage their finances with confidence and independence."/>
                                        We are dedicated to developing solutions that not only meet but exceed the expectations of all our users, ensuring
                                    that our financial products and services are intuitive, user-friendly, and empowering. Through our ongoing efforts
                                    to enhance accessibility, Blukite Finance is paving the way for a more inclusive financial future, where everyone
                                    has the tools and support needed to manage their finances with confidence and independence.
                                    </p>
                                    <img className='w-8 lg:w-14 rotate-180 self-end ml-5 mb-0 sm:-mb-10' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                                </div>
                            </div>

                        </div>
                    </section>


                    <section id="section3" className='my-16 sm:my-24'>

                        <h2 className='pageTitle'>Blukite's Vision for Visually Impaired Access</h2>
                        <p className='text'>
                            <Speaker id="507" text="Blukite's Vision for Visually Impaired Access. Blukite Finance is proud to introduce a transformative solution for visually impaired users: the VIA (Visually Impaired Access) feature, a core component of our Blukite® EA platform. Designed with inclusivity at its heart, VIA utilises cutting-edge image-to-audio technology, enabling a seamless and empowering payment experience for all users. nnovating for Accessibility: VIA Image-to-Audio Technology." />
                            Blukite Finance is proud to introduce a transformative solution for visually impaired users: the VIA (Visually Impaired Access) feature, a core component of our Blukite® EA
                            platform. Designed with inclusivity at its heart, VIA utilises cutting-edge image-to-audio technology, enabling a seamless and empowering payment experience for all users.</p>
                        <p className='subtitle font-normal italic'>Innovating for Accessibility: VIA Image-to-Audio Technology </p>

                    <div className='my-14 flex flex-col xl:flex-row gap-10 items-center' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        <div className='hidden sm:flex items-center flex-wrap justify-center gap-3 lg:gap-5 sm:w-auto w-full'>
                            <div className={activeSlide === 1 ? 'fadeIn sliderItem--active' : 'sliderItem'} onClick={() => setActiveSlide(1)}><img className='w-10 sm:w-14' src={`${basePath}images/ico/diversity_slider_1.svg`} alt="User-Centric Design" /><p className='text-center font-normal'>User-Centric Design</p></div>
                            <div className='flex flex-row sm:flex-col gap-5'>
                                <div className={activeSlide === 2 ? 'fadeIn sliderItem--active' : 'sliderItem'} onClick={() => setActiveSlide(2)}><img className='w-12 sm:w-14' src={`${basePath}images/ico/diversity_slider_2.svg`} alt="User-Centric Design" /><p className='text-center font-normal'>Seamless Process</p></div>
                                <div className={activeSlide === 4 ? 'fadeIn sliderItem--active' : 'sliderItem'} onClick={() => setActiveSlide(4)}><img className='w-12 sm:w-16' src={`${basePath}images/ico/diversity_slider_3.svg`} alt="User-Centric Design" /><p className='text-center font-normal'>Empowering Users</p></div>
                            </div>
                            <div className={activeSlide === 3 ? 'fadeIn sliderItem--active' : 'sliderItem'} onClick={() => setActiveSlide(3)}><img className='w-10 sm:w-14' src={`${basePath}images/ico/diversity_slider_4.svg`} alt="User-Centric Design" /><p className='text-center font-normal'>Vibro Feedback</p></div>
                        </div>

                        <div className='ml-0 xl:ml-16 flex-1 w-full lg:w-8/12 xl:w-auto'>
                            <p className='uppercase text-2xl font-light'>HOW IT WORKS?</p>


                            <div className={`my-5 sm:my-10 flex gap-5 items-start ${animate ? 'fadeIn' : ''}`}>
                                <img className='block sm:hidden mt-1 w-12 sm:w-14' src={`${basePath}images/ico/diversity_slider_${activeSlideData.id}.svg`} alt="User-Centric Design" />
                                <div>
                                    <Speaker id={507 + activeSlideData.id} text={activeSlideData.title + ". " + activeSlideData.text} />
                                    <p className='font-normal text-lg'>{activeSlideData.title}</p>
                                    <p className='text'>{activeSlideData.text}</p>
                                </div>
                            </div>

                            <div className='mt-7 flex justify-between'>
                                <img onClick={prevSlide} className='w-16 cursor-pointer shadow-none hover:shadow-lg transition' src={`${basePath}images/ico/diversity_sliderLeftArrow.svg`} alt="slider left arrow" />
                                <img onClick={nextSlide} className='w-16 cursor-pointer shadow-none hover:shadow-lg transition' src={`${basePath}images/ico/diversity_sliderRightArrow.svg`} alt="slider right arrow" />
                            </div>
                        </div>
                    </div>
                    <p className='text mt-20'>
                        <Speaker id="512" text="This patent-pending VIA technology, complemented by vibro feedback, marks a significant step forward in making financial transactions accessible to the visually impaired community. By bridging the gap between technology and usability, Blukite Finance is not just enhancing the payment cycle but is also fostering independence and confidence among users who require additional support." />
                        This patent-pending VIA technology, complemented by vibro feedback, marks a significant step forward in making financial transactions accessible to the visually impaired
                        community. By bridging the gap between technology and usability, Blukite Finance is not just enhancing the payment cycle but is also fostering independence and confidence
                        among users who require additional support.</p>
                </section>

                <section id="section4" className='my-16 sm:my-24'>
                    <p className='subtitle font-normal italic mt-5  sm:mt-10'>User-Centric Design providing Personalisation at Your Fingertips </p>
                    <p className='text'>
                        <Speaker id="513" text="User-Centric Design providing Personalisation at Your Fingertips. Blukite Finance is at the forefront of financial accessibility, recognising the importance of a personalised user experience. Our Blukite EA application, enhanced with VIA (Visually Impaired Access), is a testament to our commitment to user-centric design. Here's how we're making banking and payment services more accessible and intuitive:" />
                        Blukite Finance is at the forefront of financial accessibility, recognising the importance of a personalised user experience. Our Blukite EA application, enhanced with VIA
                        (Visually Impaired Access), is a testament to our commitment to user-centric design. Here's how we're making banking and payment services more accessible and intuitive:</p>



                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-0 sm:gap-10 lg:gap-20 relative my-10 sm:my-16 text-left sm:text-center'>
                        <img className='hidden xl:block absolute top-10 w-9/12 -z-10  left-1/2 transform -translate-x-1/2' src={`${basePath}/images/ico/WhiteLabelConsumerApp/dashedLine.svg`} alt="dashed arrow illustration" />
                        <div className='flex flex-row sm:flex-col items-center'>
                            <img src={`${baseURL}/images/diversityAndInclusion/6.svg`} className='bg-white h-20 md:h-40' alt="icon for Screen Reader Compatibility" />
                            <div className=''>
                                <p className='font-normal mt-3'>Intuitive Application</p>
                                <p className='mt-1 leading-tight'>Designed for ease of use, the Blukite EA app offers an intuitive interface that allows visually impaired users to navigate financial transactions with confidence.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center'>
                            <img src={`${baseURL}/images/diversityAndInclusion/7.svg`} className='bg-white h-20 md:h-40' alt="icon for Easy Navigation" />
                            <div className=''>
                                <p className='font-normal mt-3'>Customisable Settings</p>
                                <p className='mt-1 leading-tight'>Users can tailor their app experience to suit their individual needs, ensuring that everyone can manage their finances independently and securely.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center'>
                            <img src={`${baseURL}/images/diversityAndInclusion/8.svg`} className='bg-white h-20 md:h-40' alt="icon for Subtitles and Transcripts" />
                            <div className=''>
                                <p className='font-normal mt-3'>Seamless Banking Integration</p>
                                <p className='mt-1 leading-tight'>The app seamlessly integrates with consumers' bank accounts, offering a unified and efficient way to manage finances across multiple accounts.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center'>
                            <img src={`${baseURL}/images/diversityAndInclusion/9.svg`} className='bg-white h-20 md:h-40' alt="icon for Adjustable Contrast & Font" />

                            <div className=''>
                                <p className='font-normal mt-3'>Wide Compatibility</p>
                                <p className='mt-1 leading-tight'>With support for both Android and iOS platforms, our app ensures that no user is left behind, regardless of their device preference.</p>
                            </div>
                        </div>
                    </div>


                    <p className='text'>
                        <Speaker id="514" text="By focusing on the user's needs and preferences, Blukite Finance ensures that our technology not only enhances accessibility but also enriches the banking experience for all
                        our users, making financial independence a reality for the visually impaired community. Blukite Finance's dedication to dismantling barriers to financial inclusion is
                        unwavering. We are committed to creating solutions that serve not just the visually impaired but also embrace the neurodivergent and the aging population. Our approach is
                        holistic, recognising the diverse needs within our community and striving to develop services that are not only accessible but also empowering. By fostering an environment
                        of inclusivity, we aim to ensure that everyone, regardless of their unique challenges, has equal access to financial services and the opportunity to participate fully in the
                        financial ecosystem."/>
                        By focusing on the user's needs and preferences, Blukite Finance ensures that our technology not only enhances accessibility but also enriches the banking experience for all
                        our users, making financial independence a reality for the visually impaired community. Blukite Finance's dedication to dismantling barriers to financial inclusion is
                        unwavering. We are committed to creating solutions that serve not just the visually impaired but also embrace the neurodivergent and the aging population. Our approach is
                        holistic, recognising the diverse needs within our community and striving to develop services that are not only accessible but also empowering. By fostering an environment
                        of inclusivity, we aim to ensure that everyone, regardless of their unique challenges, has equal access to financial services and the opportunity to participate fully in the
                        financial ecosystem.
                    </p>

                    <div className="relative w-full my-10">
                        <video className='w-full' loop controls={true} poster={`${basePath}/images/videoThubnail.webp`}>
                            <source src={`${basePath}/videos/the_accessible_payment_network.mp4`} type="video/mp4" />
                        </video>
                    </div>
                </section>


                <section id="section5" className='my-16 sm:my-24'>
                    <h2 className='pageTitle'>Enhance Security and Accessibility with Blukite Finance's Biometric Card</h2>
                    <p className='text'>
                        <Speaker id="515" text="Enhance Security and Accessibility with Blukite Finance's Biometric Card. Blukite Finance is proud to present our innovative biometric card, designed to provide enhanced security, seamless user experience, and increased accessibility for all our consumers. This cutting-edge technology integrates biometric verification directly into the payment process, ensuring your transactions are secure and convenient." />
                        Blukite Finance is proud to present our innovative biometric card, designed to provide enhanced security, seamless user experience, and increased accessibility for all our consumers.
                        This cutting-edge technology integrates biometric verification directly into the payment process, ensuring your transactions are secure and convenient.</p>


                    <div className='customerMonitoring__offers mb-5 sm:mb-20 '>
                        <div className='customerMonitoring__offerDashedLine hidden lg:block'>
                            <img src={`${basePath}images/dashedLine_4.svg`} alt="dashed line" />
                        </div>



                        {/* <img className='customerMonitoring__offerDashedLine hidden lg:block' src={`${basePath}images/CustomerMonitoring/dashedLine.svg`} alt="dashed line" /> */}
                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerImgWrapper justify-end' data-aos="fade-right" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/diversityAndInclusion/10.svg`} alt="image for WHO WE ARE" />
                            </div>
                            {/* <div className='customerMonitoring__offerNumber self-start'>1</div> */}
                            <div className='customerMonitoring__offerNumber'>1</div>
                            {/* <div className='customerMonitoring__offerItem self-start' data-aos="fade-left" data-aos-duration="700" data-aos-delay="300"> */}
                            <div className='customerMonitoring__offerItem' data-aos="fade-left" data-aos-duration="700">
                                <p className='font-medium'>Enhanced Security</p>
                                <p className='mt-2'><Speaker id="516" text="Enhanced Security. The biometric chip offers an unprecedented level of security for financial transactions. By requiring fingerprint authentication, it significantly reduces the risk of unauthorised access and fraudulent activities. This advanced security feature ensures peace of mind for Blukite Finance consumers." />
                                    The biometric chip offers an unprecedented level of security for
                                    financial transactions. By requiring fingerprint authentication, it
                                    significantly reduces the risk of unauthorised access and
                                    fraudulent activities. This advanced security feature ensures
                                    peace of mind for Blukite Finance consumers.</p>
                            </div>
                        </div>

                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerItem' data-aos="fade-right" data-aos-duration="700">
                                <p className='font-medium text-right'>Seamless User Experience</p>
                                <p className='mt-2 text-right'><Speaker isRight id="517" text="Seamless User Experience. Our biometric authentication process is quick and intuitive, streamlining transactions without the need for PINs or signatures. This ease of use enhances the overall consumer experience, making payments faster and more convenient." />
                                    Our biometric authentication process is quick and intuitive,
                                    streamlining transactions without the need for PINs or
                                    signatures. This ease of use enhances the overall consumer
                                    experience, making payments faster and more convenient.
                                </p>
                            </div>
                            <div className='customerMonitoring__offerNumber'>2</div>
                            <div className='customerMonitoring__offerImgWrapper' data-aos="fade-left" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/diversityAndInclusion/11.svg`} alt="image for WHAT WE DO" />
                            </div>
                        </div>

                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerImgWrapper justify-end' data-aos="fade-right" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/diversityAndInclusion/12.svg`} alt="image for WHY WE DO IT" />
                            </div>
                            <div className='customerMonitoring__offerNumber'>3</div>
                            <div className='customerMonitoring__offerItem' data-aos="fade-left" data-aos-duration="700">
                                <p className='font-medium'>Increased Accessibility</p>
                                <p className='mt-2'><Speaker id="518" text="Increased Accessibility. Biometric verification provides an accessible alternative for individuals with disabilities or those who find traditional security measures challenging. This aligns with Blukite's commitment to inclusivity, ensuring that all consumers can securely access their financial services." />Biometric verification provides an accessible alternative for
                                    individuals with disabilities or those who find traditional security
                                    measures challenging. This aligns with Blukite's commitment to
                                    inclusivity, ensuring that all consumers can securely access their
                                    financial services.
                                </p>
                            </div>
                        </div>
                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerItem' data-aos="fade-right" data-aos-duration="700">
                                <p className='font-medium text-right'>Privacy and Data Protection</p>
                                <p className='mt-2 text-right'><Speaker isRight id="519" text="Privacy and Data Protection. Our sensor technology is designed with privacy in mind, storing biometric data securely on the card itself rather than in a centralised database. This localised storage method minimises privacy concerns and protects consumer data." />
                                    Our sensor technology is designed with privacy in mind, storing
                                    biometric data securely on the card itself rather than in a
                                    centralised database. This localised storage method minimises
                                    privacy concerns and protects consumer data.
                                </p>
                            </div>
                            <div className='customerMonitoring__offerNumber'>4</div>
                            <div className='customerMonitoring__offerImgWrapper' data-aos="fade-left" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/diversityAndInclusion/13.svg`} alt="image for WHAT WE DO" />
                            </div>
                        </div>

                        <div className='customerMonitoring__offer'>
                            <div className='customerMonitoring__offerImgWrapper justify-end' data-aos="fade-right" data-aos-duration="700">
                                <img className='customerMonitoring__offerImg' src={`${basePath}images/diversityAndInclusion/14.svg`} alt="image for WHY WE DO IT" />
                            </div>
                            <div className='customerMonitoring__offerNumber'>5</div>
                            <div className='customerMonitoring__offerItem' data-aos="fade-left" data-aos-duration="700">
                                <p className='font-medium'>Future-Proof Technology</p>
                                <p className='mt-2'><Speaker id="520" text="Future-Proof Technology. As the financial industry evolves, biometric authentication positions Blukite Finance at the forefront of innovation. Adopting this technology reflects our forward-thinking approach, preparing consumers for the future of secure contactless payments." />As the financial industry evolves, biometric authentication
                                    positions Blukite Finance at the forefront of innovation. Adopting
                                    this technology reflects our forward-thinking approach, preparing
                                    consumers for the future of secure contactless payments.
                                </p>
                            </div>
                        </div>
                    </div>



                    <p className='subtitle font-normal italic text-center'>Accessibility for All</p>
                    <p className='text text-center w-full mx-auto'>Our biometric fingerprint card technology is designed to serve a broad spectrum of users, enhancing accessibility for various key demographics:</p>
                    <div
                        className="animatedInfo__cards my-10"
                    >
                        <div
                            className="animatedInfo__card relative"
                        >
                            <div className='absolute right-3 top-5'>
                                <Speaker id="521" text="Elderly Users. Older individuals who may struggle with remembering PINs or using mobile banking apps will find fingerprint authentication a simpler, more intuitive way to access their financial services." />
                            </div>
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/6.svg`} alt="icon for Elderly Users" />
                            </div>
                            <div className="animatedInfo__cardTitle">Elderly Users</div>
                            <div className="animatedInfo__cardText">Older individuals who may struggle with remembering PINs or using mobile banking apps will find fingerprint authentication a simpler, more intuitive way to access their financial services.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card"
                        >
                            <div className='absolute right-3 top-5'>
                                <Speaker id="522" text="Individuals with Physical Disabilities. For those with motor skill impairments or conditions that make traditional card use challenging, biometric cards offer a user-friendly alternative that requires minimal physical interaction." />
                            </div>
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/7.svg`} alt="icon for Individuals with Physical Disabilities" />
                            </div>
                            <div className="animatedInfo__cardTitle">Individuals with Physical Disabilities</div>
                            <div className="animatedInfo__cardText">For those with motor skill impairments or conditions that make traditional card use challenging, biometric cards offer a user-friendly alternative that requires minimal physical interaction.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card"
                        >
                            <div className='absolute right-3 top-5'>
                                <Speaker id="523" text="Consumers Seeking Enhanced Security. Users concerned about financial security will appreciate the added layer of protection that biometric authentication provides against fraud and theft." />
                            </div>
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/8.svg`} alt="icon for Consumers Seeking Enhanced Security card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Consumers Seeking Enhanced Security</div>
                            <div className="animatedInfo__cardText">Users concerned about financial security will appreciate the added layer of protection that biometric authentication provides against fraud and theft.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card"
                        >
                            <div className='absolute right-3 top-5'>
                                <Speaker id="524" text="Visually Impaired Users. While primarily relying on tactile interaction, the straightforward nature of fingerprint authentication can also benefit visually impaired users, especially when combined with audible feedback." />
                            </div>
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/9.svg`} alt="icon for Visually Impaired Users card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Visually Impaired Users</div>
                            <div className="animatedInfo__cardText">While primarily relying on tactile interaction, the straightforward nature of fingerprint authentication can also benefit visually impaired users, especially when combined with audible feedback.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>
                        <div
                            className="animatedInfo__card"
                        >
                            <div className='absolute right-3 top-5'>
                                <Speaker id="525" text="Tech-Savvy Users. Individuals who value the convenience and innovation of the latest financial technologies will find our biometric card a cutting-edge solution for managing transactions seamlessly." />
                            </div>
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/10.svg`} alt="icon for Tech-Savvy Users card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Tech-Savvy Users</div>
                            <div className="animatedInfo__cardText">Individuals who value the convenience and innovation of the latest financial technologies will find our biometric card a cutting-edge solution for managing transactions seamlessly.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>

                        <div
                            className="animatedInfo__card"
                        >
                            <div className='absolute right-3 top-5'>
                                <Speaker id="526" text="Users with Cognitive Disabilities. Biometric fingerprint cards provide secure, intuitive authentication for users with cognitive disabilities, reducing memory dependency and enhancing accessibility." />
                            </div>
                            <div className="icon">
                                <img className='w-16' src={`${basePath}images/ico/roundedSquareIcons/11.svg`} alt="icon for Users with Cognitive Disabilities card" />
                            </div>
                            <div className="animatedInfo__cardTitle">Users with Cognitive Disabilities</div>
                            <div className="animatedInfo__cardText">Biometric fingerprint cards provide secure, intuitive authentication for users with cognitive disabilities, reducing memory dependency and enhancing accessibility.</div>
                            <img className='animatedInfo__arrow' src={`${basePath}images/ico/rightArrow-thin.svg`} />
                        </div>
                    </div>

                    <p className='subtitle font-normal italic text-center mt-16 sm:mt-26'>Compliance with PSD II</p>
                    <p className='text text-center w-full lg:w-10/12 mx-auto'>
                        <Speaker id="527" text="Compliance with PSD II. Blukite Finance’s biometric card is designed to fully comply with the requirements of the Payment Services Directive 2 (PSD II). This directive aims to enhance consumer protection, promote innovation, and improve the security of payment services across the European Union." />
                        Blukite Finance’s biometric card is designed to fully comply with the requirements of the Payment Services Directive 2 (PSD II). This directive
                        aims to enhance consumer protection, promote innovation, and improve the security of payment services across the European Union.</p>


                    <div className='my-10 flex flex-col lg:flex-row gap-10 lg:gap-16'>

                        <div className='flex flex-col'>
                            <div className='bg-lightBlue rounded-2xl'>
                                <img className='' src={`${basePath}/images/diversityAndInclusion/15.svg`} alt="icon for No PIN Entry Required" />

                            </div>
                            <p className='mt-7 font-normal'>No PIN Entry Required</p>
                            <p className='mt-2'>
                                <Speaker id="528" text="No PIN Entry Required. Thanks to biometric authentication, the need for PIN entry is eliminated. This not only simplifies the transaction process but also increases security, as biometric data cannot be easily replicated or stolen." />
                                Thanks to biometric authentication, the need for PIN
                                entry is eliminated. This not only simplifies the
                                transaction process but also increases security, as
                                biometric data cannot be easily replicated or stolen.</p>
                        </div>

                        <div className='flex flex-col'>
                            <div className='bg-lightBlue rounded-2xl flex justify-center'>
                                <img className='' src={`${basePath}/images/diversityAndInclusion/16.svg`} alt="icon for Unlimited Contactless Transactions" />

                            </div>
                            <p className='mt-7 font-normal'>Unlimited Contactless Transactions</p>
                            <p className='mt-2'>
                                <Speaker id="529" text="Unlimited Contactless Transactions. Unlike traditional contactless cards that have a high-value transaction limit requiring PIN verification,
                                our biometric card allows for an unlimited number of contactless transactions without PIN entry. This
                                is possible because each transaction is authenticated biometrically, ensuring high security without
                                compromising convenience."/>
                                Unlike traditional contactless cards that have a high-value transaction limit requiring PIN verification,
                                our biometric card allows for an unlimited number of contactless transactions without PIN entry. This
                                is possible because each transaction is authenticated biometrically, ensuring high security without
                                compromising convenience.</p>
                        </div>
                    </div>


                </section>


                <section id="section6" className='my-16 sm:my-32'>
                    <h2 className='pageTitle'>Introducing TGM - A New Era in Accessible Payment Solutions</h2>
                    <p>
                        <Speaker id="530" text="Introducing TGM - A New Era in Accessible Payment Solutions. At Blukite Finance, we understand the challenges faced by visually impaired users and individuals with other impairments when using traditional payment cards. The evolution of payment card technology has introduced advanced features like contactless payments and biometric authentication. However, these advancements often overlook the needs of visually impaired users and those with other impairments. Traditional payment cards provide limited tactile feedback, making it difficult for these users to ascertain card orientation, securely input PINs, or authenticate transactions autonomously. The introduction of glass screen payment terminals further complicates these interactions. Biometric fingerprint sensors on payment cards can significantly improve security, but their effectiveness relies on precise finger placement, a challenge in fast-paced transaction environments. For accurate fingerprint recognition, at least 60% of the fingerprint needs to be correctly placed on the sensor. Users must maintain the correct digit positioning on the sensor, which can be difficult without visual cues, leading to increased error rates and failed payment attempts." />
                        At Blukite Finance, we understand the challenges faced by visually impaired users and individuals with other impairments when using traditional payment cards. The evolution of payment
                        card technology has introduced advanced features like contactless payments and biometric authentication. However, these advancements often overlook the needs of visually
                        impaired users and those with other impairments. Traditional payment cards provide limited tactile feedback, making it difficult for these users to ascertain card orientation,
                        securely input PINs, or authenticate transactions autonomously. The introduction of glass screen payment terminals further complicates these interactions. Biometric fingerprint
                        sensors on payment cards can significantly improve security, but their effectiveness relies on precise finger placement, a challenge in fast-paced transaction environments. For
                        accurate fingerprint recognition, at least 60% of the fingerprint needs to be correctly placed on the sensor. Users must maintain the correct digit positioning on the sensor, which
                        can be difficult without visual cues, leading to increased error rates and failed payment attempts.</p>
                    <p className='subtitle mt-20 font-normal text-center italic'>Our Innovative Solution: Tactile Guidance Markers (TGM)</p>
                    <p className='text text-center w-full lg:w-10/12 mx-auto italic'>
                        <Speaker id="531" text="Our Innovative Solution: Tactile Guidance Markers (TGM). To address these challenges, we developed TGM, Tactile Guidance Markers-enhanced biometric payment cards, designed to make secure transactions accessible to everyone. We integrated a tactile guidance system with biometric authentication on our payment cards." />
                        To address these challenges, we developed TGM, Tactile Guidance Markers-enhanced biometric payment cards, designed to make secure transactions accessible to everyone. We integrated a tactile guidance system with biometric authentication on our payment cards.</p>


                    <div className='leading-tight w-full customShadow rounded-3xl relative overflow-hidden w-full xl:w-auto flex flex-col-reverse lg:flex-row mt-7 gap-5 xl:gap-7' data-aos="fade-up" data-aos-duration="800" data-aos-offset="300">
                        <div className='w-full lg:w-6/12 xl:w-4/12 p-5 sm:p-10'>
                            <img src={`${basePath}/images/ico/blueCircles/3.svg`} alt="icons for Seamless User Experience" />
                            <p className='font-normal text-lg mt-5'>Seamless User Experience</p>
                            <p className='mt-2'>
                                <Speaker id="532" text="Seamless User Experience.TGM includes Braille and other tactile markers 
that provide physical guides for users to 
accurately locate and use the fingerprint sensor."/>
                                TGM includes Braille and other tactile markers
                                that provide physical guides for users to
                                accurately locate and use the fingerprint sensor.
                            </p>
                        </div>
                        <div className='w-full lg:w-6/12 xl:w-8/12'>
                            <video className='w-full h-full object-cover' autoPlay muted loop playsInline>
                                <source src={`${basePath}/videos/TaluDemo.mp4`} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className='flex flex-col xl:flex-row mt-7 gap-7'>
                        <div className='leading-tight customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-7/12' data-aos="fade-right" data-aos-duration="800" data-aos-offset="300">
                            <img src={`${basePath}/images/ico/blueCircles/4.svg`} alt="icons for Operational Excellence" />
                            <p className='font-normal text-lg mt-5'>Operational Excellence</p>
                                <p className='mt-2'>
                                    <Speaker id="533" text="Operational Excellence. Directional Indicators: TGM includes indicators like Braille letters for 'L' (left) and 'R' 
(right) to guide users towards the correct finger positioning on the sensor. Inclusive Design: Our design caters to a wide range of sensory and motor abilities, 
ensuring the tactile markers are easily detectable without compromising the card’s 
aesthetic and functional integrity. Durability and Compatibility: Constructed from high-quality, durable materials, TGM
cards meet industry standards for physical durability and sensor performance, 
ensuring reliable use in various conditions."/>
                                    <span className='font-normal'>Directional Indicators: </span>
                                    TGM includes indicators like Braille letters for 'L' (left) and 'R'
                                    (right) to guide users towards the correct finger positioning on the sensor. </p>

                                <p className='mt-2'><span className='font-normal'>Inclusive Design: </span>
                                    Our design caters to a wide range of sensory and motor abilities,
                                    ensuring the tactile markers are easily detectable without compromising the card’s
                                    aesthetic and functional integrity. </p>
                                <p className='mt-2'><span className='font-normal'>Durability and Compatibility: </span>Constructed from high-quality, durable materials, TGM
                                    cards meet industry standards for physical durability and sensor performance,
                                    ensuring reliable use in various conditions.</p>
                        </div>

                        <div className='leading-tight customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-5/12' data-aos="fade-right" data-aos-duration="800" data-aos-offset="300">
                            <img src={`${basePath}/images/ico/blueCircles/7.svg`} alt="icons for Design and Functionality" />
                            <p className='font-normal text-lg mt-5'>Design and Functionality</p>
                                <p className='mt-2'>
                                    <Speaker id="534" text="Design and Functionality. Braille and Raised Markers: The card features Braille dots and 
raised markers around the fingerprint sensor. These markers 
deliver positional feedback and directional cues, guiding the user’s
finger or thumb to the optimal placement on the sensor. Universal Tactile Feedback: Beyond Braille, TGM employs various
tactile features such as raised lines, textured patterns, or tactile 
ink. These elements ensure that all users, regardless of visual 
capability, can independently complete transactions with 
enhanced security and ease."/>
                                    <span className='font-normal'>Braille and Raised Markers: </span>
                                    The card features Braille dots and
                                    raised markers around the fingerprint sensor. These markers
                                    deliver positional feedback and directional cues, guiding the user’s
                                    finger or thumb to the optimal placement on the sensor.</p>
                                <p className='mt-2'><span className='font-normal'>Universal Tactile Feedback: </span>Beyond Braille, TGM employs various
                                    tactile features such as raised lines, textured patterns, or tactile
                                    ink. These elements ensure that all users, regardless of visual
                                    capability, can independently complete transactions with
                                    enhanced security and ease.</p>
                        </div>
                    </div>










                    <div className='mb-20'>
                        <div className='w-full leading-tight customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-auto flex flex-col xl:flex-row mt-7 gap-7 items-center' data-aos="fade-right" data-aos-duration="800" data-aos-offset="300">
                            <div className='w-10/12 sm:w-6/12 xl:w-3/12'>
                                <img className='w-full' src={`${baseURL}/images/WhiteLabelConsumerApp/4.svg`} alt='icon of TGM (Tactile Guidance Markers) - Patent Pending' />
                            </div>
                            <div className='w-full xl:w-9/12'>
                                <img src={`${basePath}/images/ico/blueCircles/6.svg`} alt="icons for Unanticipated Benefits for Sighted Users" />
                                <p className='font-normal text-lg mt-5'>Unanticipated Benefits for Sighted Users</p>
                                <p className='mt-2'>
                                    <Speaker id="535" text="Unanticipated Benefits for Sighted Users. While TGM was initially developed to assist visually impaired users, we discovered that sighted users also 
benefited significantly from the tactile features. The tactile markers guide them to the correct position on 
the sensor without the need to look at the card, making the experience more intuitive and efficient. This 
allows for a more seamless and subconscious interaction at the point of sale (POS) terminal, transforming 
the process into a muscle memory action rather than a visually dependent task."/>
                                    While TGM was initially developed to assist visually impaired users, we discovered that sighted users also
                                    benefited significantly from the tactile features. The tactile markers guide them to the correct position on
                                    the sensor without the need to look at the card, making the experience more intuitive and efficient. This
                                    allows for a more seamless and subconscious interaction at the point of sale (POS) terminal, transforming
                                    the process into a muscle memory action rather than a visually dependent task.
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col xl:flex-row mt-7 gap-7'>
                            <div className='flex-1 customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-auto' data-aos="fade-up-right" data-aos-duration="800" data-aos-offset="300">
                                <img src={`${basePath}/images/ico/blueCircles/8.svg`} alt="icons for The Impact of TGM" />
                                <p className='font-normal text-lg mt-5 leading-tight'>The Impact of TGM</p>
                                <div className='w-full'>
                                    <p className='mt-2 leading-tight'>
                                        <Speaker id="536" text="The Impact of TGM. Inclusive Financial Services: TGM significantly
enhances accessibility, empowering visually 
impaired users and individuals with other 
impairments to independently manage their 
financial transactions. This inclusivity ensures
equal opportunities for all users to participate
in the digital economy."/>
                                        <span className='font-normal'>Inclusive Financial Services: </span>
                                        TGM significantly
                                        enhances accessibility, empowering visually
                                        impaired users and individuals with other
                                        impairments to independently manage their
                                        financial transactions. This inclusivity ensures
                                        equal opportunities for all users to participate
                                        in the digital economy.</p>
                                </div>
                            </div>
                            <div className='flex-1 customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-auto' data-aos="fade-up" data-aos-duration="800" data-aos-offset="300">
                                <img src={`${basePath}/images/ico/TailoredServicesBanks/2.svg`} alt="icons for User-Friendly Experience" />
                                <p className='font-normal text-lg mt-5 leading-tight'>User-Friendly Experience</p>
                                <div className='w-full'>
                                    <p className='mt-2 leading-tight'>
                                        <Speaker id="537" text="User-Friendly Experience. The tactile markers make the card intuitive 
to use, providing clear tactile feedback for 
finger placement and simplifying the 
transaction process for all users. This design
ensures that transactions are not only secure
but also seamless and efficient."/>
                                        The tactile markers make the card intuitive
                                        to use, providing clear tactile feedback for
                                        finger placement and simplifying the
                                        transaction process for all users. This design
                                        ensures that transactions are not only secure
                                        but also seamless and efficient.</p>
                                </div>
                            </div>
                            <div className='flex-1 customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-auto' data-aos="fade-up-left" data-aos-duration="800" data-aos-offset="300">
                                <img src={`${basePath}/images/ico/blueCircles/4.svg`} alt="icons for Enhanced Security and Privacy" />
                                <p className='font-normal text-lg mt-5 leading-tight'>Enhanced Security and Privacy</p>
                                <div className='w-full'>
                                    <p className='mt-2 leading-tight'>
                                        <Speaker id="538" text="Enhanced Security and Privacy. The integration of biometric fingerprint 
sensors with TGM improves security by 
offering unique individual identification and 
reducing fraud risk. Fingerprint data is 
securely stored on the card, ensuring privacy 
and protection against external breaches."/>
                                        The integration of biometric fingerprint
                                        sensors with TGM improves security by
                                        offering unique individual identification and
                                        reducing fraud risk. Fingerprint data is
                                        securely stored on the card, ensuring privacy
                                        and protection against external breaches.</p>
                                </div>
                            </div>







                        </div>



                    </div>


                </section>


                <section id="section7" className='my-16 sm:my-32'>
                    <h2 className='pageTitle'>Join the TGM Revolution</h2>
                    <p className='text'>
                        <Speaker id="539" text="Join the TGM Revolution. TGM represents a significant step forward in making financial transactions accessible and secure for everyone. At Blukite Finance, we are committed to innovation that serves a diverse user base, ensuring that technology truly benefits all individuals. Discover how TGM can transform your transaction experience today.
"/>
                        TGM represents a significant step forward in making financial transactions accessible and secure for everyone. At Blukite Finance, we are committed to innovation that serves a diverse user
                        base, ensuring that technology truly benefits all individuals. Discover how TGM can transform your transaction experience today.</p>

                    <p className='subtitle italic font-normal mt-10'>Introduction to Biometric Fingerprint Payment Cards</p>
                    <p className='text'>
                        <Speaker id="540" text="Introduction to Biometric Fingerprint Payment Cards. In recent years, biometric fingerprint payment cards have emerged as an innovative solution aimed at enhancing the security and convenience of financial transactions. These cards integrate fingerprint recognition technology directly into the payment card, allowing users to authenticate transactions through a simple touch. This technology promises to reduce fraud by ensuring that only the cardholder can authorise payments, thereby providing a higher level of security compared to traditional PIN or signature-based methods. Biometric fingerprint payment cards operate by storing the user's fingerprint data securely within the card's chip during an initial setup process. When making a payment, the cardholder places their finger on the embedded sensor, and the card matches the scanned fingerprint with the stored data. If the fingerprint matches, the transaction is approved. This process not only enhances security but also streamlines the payment experience by eliminating the need for PIN entry or signature verification." />
                        In recent years, biometric fingerprint payment cards have emerged as an innovative solution aimed at enhancing the security and convenience of financial transactions. These
                        cards integrate fingerprint recognition technology directly into the payment card, allowing users to authenticate transactions through a simple touch. This technology promises
                        to reduce fraud by ensuring that only the cardholder can authorise payments, thereby providing a higher level of security compared to traditional PIN or signature-based methods.
                        Biometric fingerprint payment cards operate by storing the user's fingerprint data securely within the card's chip during an initial setup process. When making a payment, the
                        cardholder places their finger on the embedded sensor, and the card matches the scanned fingerprint with the stored data. If the fingerprint matches, the transaction is approved.
                        This process not only enhances security but also streamlines the payment experience by eliminating the need for PIN entry or signature verification.
                    </p>

                    <p className='subtitle italic font-normal mt-10 text-center'>Challenges Faced in Adoption</p>
                    <p className='text italic w-full sm:w-7/12 mx-auto text-center'>
                        <Speaker id="541" text="Challenges Faced in Adoption.
                    Despite the apparent benefits, the adoption of biometric fingerprint payment cards has been slower than anticipated. Several key challenges contribute to this slow uptake:
                    Security and Privacy Concerns.
                    Many consumers hesitate to share their biometric data due to fears of data breaches, potential for hacking, unauthorised access, and misuse.
                    High Production Costs.
                    Integrating fingerprint sensors into payment cards is expensive, increasing production expenses compared to traditional cards.
                    Infrastructure Costs.
                    Retailers need to upgrade their POS systems to accept biometric payments, requiring significant investment. Many are hesitant without a clear return on investment.
                    Technical Reliability Issues.
                    Fingerprint sensors can fail to recognise fingerprints due to dirt, moisture, or skin conditions, causing user frustration and decreased confidence in the technology.
                    Regulatory & Compliance Hurdles.
                    Strict regional regulatory requirements for biometric data can vary by region, which adds complexity and can slow down the adoption process.
                    Consumer Awareness.
                    Many consumers lack awareness and understanding of biometric payment cards, making them hesitant to adopt the technology without clear information on its benefits.
                    Competition from Current Technologies.
                    Biometric payment cards face stiff competition from widely accepted mobile payment systems and contactless cards.
                    "/>
                        Despite the apparent benefits, the adoption of biometric fingerprint payment cards has been slower than
                        anticipated. Several key challenges contribute to this slow uptake:</p>

                    <div className='flex gap-10 sm:gap-20 flex-wrap justify-center my-20'>
                        <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                            <img src={`${basePath}/images/ico/diversityAndInclusion/1.svg`} alt="icon for Security and Privacy Concerns" />
                            <p className='mt-2 font-normal text-lg'>Security and Privacy Concerns</p>
                            <p>Many consumers hesitate to share their biometric data due to fears of data breaches, potential for hacking, unauthorised access, and misuse.</p>
                        </div>
                        <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                            <img src={`${basePath}/images/ico/diversityAndInclusion/2.svg`} alt="icon for High Production Costs" />
                            <p className='mt-2 font-normal text-lg'>High Production Costs</p>
                            <p>Integrating fingerprint sensors into payment cards is expensive, increasing production expenses compared to traditional cards.</p>
                        </div>
                        <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                            <img src={`${basePath}/images/ico/diversityAndInclusion/3.svg`} alt="icon for Infrastructure Costs" />
                            <p className='mt-2 font-normal text-lg'>Infrastructure Costs</p>
                            <p>Retailers need to upgrade their POS systems to accept biometric payments, requiring significant investment. Many are hesitant without a clear return on investment.</p>
                        </div>
                        <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                            <img src={`${basePath}/images/ico/diversityAndInclusion/4.svg`} alt="icon for Technical Reliability Issues" />
                            <p className='mt-2 font-normal text-lg'>Technical Reliability Issues</p>
                            <p>Fingerprint sensors can fail to recognise fingerprints due to dirt, moisture, or skin conditions, causing user frustration and decreased confidence in the technology.</p>
                        </div>
                        <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                            <img src={`${basePath}/images/ico/diversityAndInclusion/5.svg`} alt="icon for Regulatory & Compliance Hurdles" />
                            <p className='mt-2 font-normal text-lg'>Regulatory & Compliance Hurdles</p>
                            <p>Strict regional regulatory requirements for biometric data can vary by region, which adds complexity and can slow down the adoption process.</p>
                        </div>
                        <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                            <img src={`${basePath}/images/ico/diversityAndInclusion/6.svg`} alt="icon for Consumer Awareness " />
                            <p className='mt-2 font-normal text-lg'>Consumer Awareness </p>
                            <p>Many consumers lack awareness and understanding of biometric payment cards, making them hesitant to adopt the technology without clear information on its benefits.</p>
                        </div>
                        <div className='tailoredServicesBanks__opportunitiesItem flex flex-col items-center text-center'>
                            <img src={`${basePath}/images/ico/diversityAndInclusion/7.svg`} alt="icon for Competition from Current Technologies" />
                            <p className='mt-2 font-normal text-lg'>Competition from Current Technologies</p>
                            <p>Biometric payment cards face stiff competition from widely accepted mobile payment systems and contactless cards.</p>
                        </div>
                    </div>

                    <div>
                        <p className='subtitle italic font-normal'>Introduction of Tactile Guidance Mechanism (TGM) as an Innovative Solution</p>
                        <p className='text'>
                            <Speaker id="542" text="Introduction of Tactile Guidance Mechanism (TGM) as an Innovative Solution. To address these challenges and enhance the usability of biometric fingerprint payment cards, the Tactile Guidance Mechanism was developed. TGM is a tactile feature integrated into the card that helps users correctly position their finger on the fingerprint sensor, ensuring accurate and efficient fingerprint recognition. By addressing the usability and accessibility challenges, TGM plays a crucial role in enhancing the overall user experience of biometric fingerprint payment cards. This case study explores how TGMs can address common barriers to adoption and significantly improve the overall usability of biometric fingerprint payment cards.
"/>
                            To address these challenges and enhance the usability of biometric fingerprint payment cards, the Tactile Guidance Mechanism was developed. TGM is a tactile feature integrated
                            into the card that helps users correctly position their finger on the fingerprint sensor, ensuring accurate and efficient fingerprint recognition. By addressing the usability and
                            accessibility challenges, TGM plays a crucial role in enhancing the overall user experience of biometric fingerprint payment cards. This case study explores how TGMs can address
                            common barriers to adoption and significantly improve the overall usability of biometric fingerprint payment cards.
                        </p>

                        <div className="tgmCards my-5 md:my-16">
                            <div className='customShadow rounded-lg p-5 text-center flex flex-col items-center'>
                                <img className='w-16' src={`${basePath}/images/ico/roundedSquareIcons/12.svg`} alt='icon for Improved Fingerprint Positioning' />
                                <p className='mt-4 text-lg font-normal'>Improved Fingerprint Positioning</p>
                                <p className='mt-2'>
                                    <Speaker id="543" text="Improved Fingerprint Positioning. TGM provides a physical guide that helps users place their finger in the correct position on the sensor, ensuring that at least 60% of the fingerprint is captured. This improves the accuracy and reliability of the fingerprint recognition process." />
                                    TGM provides a physical guide that helps users place their finger in the correct position on the sensor, ensuring that at least 60% of the fingerprint is captured. This improves the accuracy and reliability of the fingerprint recognition process.</p>
                            </div>


                            <div className='customShadow rounded-lg p-5 text-center flex flex-col items-center'>
                                <img className='w-16' src={`${basePath}/images/ico/roundedSquareIcons/7.svg`} alt='icon for Enhanced Accessibility' />
                                <p className='mt-4 text-lg font-normal'>Enhanced Accessibility</p>
                                <p className='mt-2'>
                                    <Speaker id="544" text="Enhanced Accessibility. TGM is particularly beneficial for visually impaired users, providing them with a reliable way to position their finger correctly without needing visual cues. This enhances the inclusivity of biometric payment cards." />
                                    TGM is particularly beneficial for visually impaired users, providing them with a reliable way to position their finger correctly without needing visual cues. This enhances the inclusivity of biometric payment cards.</p>
                            </div>

                            <div className='customShadow rounded-lg p-5 text-center flex flex-col items-center'>
                                <img className='w-16' src={`${basePath}/images/ico/roundedSquareIcons/13.svg`} alt='icon for Ease of Use' />
                                <p className='mt-4 text-lg font-normal'>Ease of Use</p>
                                <p className='mt-2'>
                                    <Speaker id="545" text="Ease of Use. The tactile feedback allows users to develop muscle memory for the correct finger placement. This makes the process intuitive and quick, reducing the need for users to look at the card or the sensor during transactions." />
                                    The tactile feedback allows users to develop muscle memory for the correct finger placement. This makes the process intuitive and quick, reducing the need for users to look at the card or the sensor during transactions.</p>
                            </div>
                            <div className='customShadow rounded-lg p-5 text-center flex flex-col items-center'>
                                <img className='w-16' src={`${basePath}/images/ico/roundedSquareIcons/14.svg`} alt='icon for Subconscious Action' />
                                <p className='mt-4 text-lg font-normal'>Subconscious Action</p>
                                <p className='mt-2'>
                                    <Speaker id="546" text="Subconscious Action. Over time, users can perform the fingerprint placement as a subconscious action, similar to typing on a keyboard without looking at the keys. This makes the transaction process smoother and faster." />
                                    Over time, users can perform the fingerprint placement as a subconscious action, similar to typing on a keyboard without looking at the keys. This makes the transaction process smoother and faster.
                                    <p className='block xl:hidden text-transparent select-none'>Subconscious Action ubconscious Action</p>
                                    </p>
                            </div>

                            <div className='customShadow rounded-lg p-5 text-center flex flex-col items-center'>
                                <img className='w-16' src={`${basePath}/images/ico/roundedSquareIcons/15.svg`} alt='icon for Increased Consumer Confidence' />
                                <p className='mt-4 text-lg font-normal'>Increased Consumer Confidence</p>
                                <p className='mt-2'>
                                    <Speaker id="547" text="Increased Consumer Confidence. By improving the reliability and ease of use, TGM helps build consumer trust in biometric payment technology. Users are more likely to adopt the technology when they are confident in its performance." />
                                    By improving the reliability and ease of use, TGM helps build consumer trust in biometric payment technology. Users are more likely to adopt the technology when they are confident in its performance.</p>
                            </div>

                            <div className='customShadow rounded-lg p-5 text-center flex flex-col items-center'>
                                <img className='w-16' src={`${basePath}/images/ico/keyFeatureItem_4.svg`} alt='icon for Market Differentiation' />
                                <p className='mt-4 text-lg font-normal'>Market Differentiation</p>
                                <p className='mt-2'>
                                    <Speaker id="548" text="Market Differentiation. TGM sets biometric payment cards apart from other payment methods by offering a unique user-friendly feature. This can be a competitive advantage in the market." />
                                    TGM sets biometric payment cards apart from other payment methods by offering a unique user-friendly feature. This can be a competitive advantage in the market.</p>
                            </div>
                        </div>

                        <div className='bg-lightBlue p-5 lg:p-16 flex-col mb-0 sm:mb-10 my-10 sm:my-20 items-center gap-5 lg:gap-10 flex xl:flex-row rounded' data-aos="fade-up" data-aos-duration="700" data-aos-offset="300">
                            <div className=' flex-col gap-5 lg:gap-10 flex xl:flex-row items-center'>
                                <div className='max-w-full w-full mx-auto xl:max-w-44 uppercase italic font-normal text-xl lg:text-3xl text-center xl:text-left'>Driving
                                    Market
                                    Adoption </div>

                                <div className='flex'>
                                    <img className='w-8 lg:w-14 self-start mt-0 sm:-mt-5' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                                    <p className='font-light text-base lg:text-lg ml-5 lg:leading-tight'>
                                        <Speaker id="549" text="RIVING MARKET ADOPTION. By leveraging these competitive advantages, biometric fingerprint payment cards with TGM can effectively differentiate themselves from other payment methods. This differentiation not only attracts new users but also encourages existing users to continue using the cards, thereby driving sustained market adoption.

"/>
                                        By leveraging these competitive advantages, biometric fingerprint payment cards with TGM can
                                        effectively differentiate themselves from other payment methods. This differentiation not only
                                        attracts new users but also encourages existing users to continue using the cards, thereby driving
                                        sustained market adoption.
                                    </p>
                                    <img className='w-8 lg:w-14 rotate-180 self-end ml-5 mb-0 sm:-mb-10' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='flex xl:flex-row flex-col justify-between gap-5 mt-10'>
                        <p className='font-normal subtitle italic leading-tight'>Advantages of TGM in Biometric Fingerprint Payment Cards</p>
                        <div className='mt-0 xl:mt-7 gap-10 flex justify-between'>
                            <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handlePrevious()} onClick={handlePrevious} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderLeftArrow.svg`} alt="slider left arrow" />
                            <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handleNext()} onClick={handleNext} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderRightArrow.svg`} alt="slider right arrow" />
                        </div>
                    </div>
                <div className='border-r border-separateLight liquidityProvidersCreditSolutions'>
                        <div id="slider" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                            <div className="cards mt-2 xl:mt-10">
                                {productOfferingsSlides.map((slide, index) => (
                                    <div id={`slide-${index}`} key={index} className={`cards__card ${index === currentProductOfferIndex ? 'activeCard' : index === (currentProductOfferIndex + 1) % productOfferingsSlides.length ? 'next' : 'sliderHidden'}`}>
                                        <div className="cards__content">
                                            <p className="font-normal text-base sm:text-lg leading-tight italic">{slide.title}</p>

                                            {slide.id === 2 ?
                                                <>
                                                    <p className="hidden sm:block text-sm sm:text-base font-light mt-3"><Speaker id={549 + slide.id} text={slide.title + "./" + slide.description} /> {slide.description}</p>
                                                    <p className="block sm:hidden text-sm sm:text-base font-light mt-3"><Speaker id={549 + slide.id} text={slide.title + "./" + slide.description} /> {slide.descriptionSmall}</p>
                                                </>
                                                : <>
                                                    <Speaker id={247 + slide.id} text={slide.title + "./" + slide.description} />
                                                    <div className="text-sm sm:text-base font-light mt-3" dangerouslySetInnerHTML={{ __html: slide.description }}>
                                                        {/* {slide.description} */}
                                                    </div>
                                                </>
                                            }
                                            {slide.id === 1 ?
                                                <object className='absolute left-1/2  transform -translate-x-1/2 sm:-bottom-20 w-full sm:w-11/12' data={`${basePath}/images/diversityAndInclusion/17.svg`}></object>
                                                : slide.id === 3 ?
                                                    <object className='absolute left-1/2 transform -translate-x-1/2 -bottom-5 sm:-bottom-16 w-full sm:w-auto' data={`${basePath}/images/diversityAndInclusion/18.svg`}></object>
                                                    : slide.id === 4 ?
                                                        <object className='absolute bottom-0 sm:-bottom-20 left-1/2 transform -translate-x-1/2 w-full' data={`${basePath}/images/diversityAndInclusion/19.svg`}></object>
                                                        : null}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default DiversityAndInclusion