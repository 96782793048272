import React from 'react'

const Careers = () => {
    return (
        <section className='fadeIn pt-10 sm:pt-32 pb-10'>
            <div className='container py-10'>
                <h1 className='uppercase text-2xl sm:text-4xl'>Join Our Journey and <span className='font-bold'>Shape the Future</span> of Finance with  <span className='font-bold'> <span className='text-blue'>Blu</span>kite Finance</span></h1>

                <div className='mt-5 text-left text-lg w-full mx-auto'>
                    <p>After three years of meticulous development with our dedicated team of 20, Blukite Finance is poised for a pivotal phase of growth as we prepare to enter the market. Our mission, to revolutionise the financial services industry through innovative and Inclusive solutions remains at the core of our expansion. We are seeking passionate individuals who are eager to contribute to this journey, driving forward our vision of accessible and equitable financial services for all. </p>

                    <p className='font-semibold mt-10'>Unlock Your Potential: Accelerating Growth and Learning at Blukite Finance</p>
                    <p>Blukite Finance, as we embark on our market launch, we are at an exciting juncture that promises rapid growth and unparalleled learning opportunities. This phase is not just about scaling our operations but also about enriching our team's expertise and impact. Joining us now means you will be part of a transformative journey, where your contributions directly influence our trajectory and success. We value innovation, creativity, and the drive to exceed expectations, offering a dynamic environment where every team member plays a critical role in our collective achievements.</p>

                    <p className='font-semibold mt-10'>Empowering Innovation:Our Culture of Flexibility and Drive at Blukite Finance</p>
                    <p>Our culture thrives on autonomy and the spirit of entrepreneurship. We have cultivated a hybrid working environment that champions flexibility and innovation, enabling our team to work where they are most productive, whether at home or in the office. We are looking for self-starters, dynamic individuals who can independently identify and tackle necessary tasks, driving projects forward with passion and precision. Our environment is designed for those who flourish when given the freedom to innovate, offering the perfect blend of support and autonomy to achieve breakthroughs.</p>

                    <p className='font-semibold mt-10'>Explore Your Future:Join Our Expanding Team at Blukite Finance</p>
                    <p>As Blukite Finance gears up for market entry, we are on the lookout for individuals across a spectrum of roles and talents to join our pioneering team. While we are open to a variety of positions, we particularly value expertise in areas such as product development, financial analysis, customer experience, and technology innovation. If you are driven, adaptable, and ready to contribute to a game-changing financial services venture, we encourage you to reach out.</p>

                    <p className='font-semibold mt-10'>Launch Your Career at Blukite Finance: Application Process</p>
                    <p>Ready to make an impact at Blukite Finance? We are excited to hear from innovative minds eager to join our journey. To apply, simply send your CV and a brief cover letter expressing your interest and how you envision contributing to our team to careers@blukitefinance.com. Whether you are applying for an open role or pitching your unique skills, we are open to exploring potential fits within our dynamic team.</p>
                </div>
            </div>

        </section>
    )
}

export default Careers