export const htmlCode =  `<h1 id='section1' style="text-align: justify;"><strong><span >1. Introduction to Blukite Finance Limited&rsquo;s Privacy Policy</span></strong></h1>
<span >Welcome to BluKite Finance Limited&rsquo;s (&lsquo;the Company&rsquo;) Data Privacy Policy. This document is designed as an internal document to articulate our organisation&apos;s approach to managing data privacy. As a credit card provider regulated by the Financial Conduct Authority (FCA) and committed to compliance with the General Data Protection Regulation (GDPR), this policy establishes the framework through which we safeguard the privacy and security of personal data within our operations. It outlines the internal guidelines and procedures that govern the collection, processing, and storage of customer and employee data. It is crucial for all staff members to familiarise themselves with this policy, as it forms the cornerstone of our commitment to maintaining the highest standards of data privacy. This internal policy is complemented by Bluekite&rsquo;s external Privacy Statement, accessible on our website, which serves as a transparent communication tool for our customers, detailing how their data is handled and their privacy rights. By aligning our internal practices with external transparency, we strive to build and maintain trust in our commitment to safeguarding the privacy of individuals&apos; data.1.1 What is a Privacy Policy?</span>
<p style="text-align: justify;"><span >&nbsp;</span></p>
<p style="text-align: justify;"><span >A privacy policy is a comprehensive document that outlines how the Company manages and protects the personal information it collects about customers and employees. It serves as a guiding framework for internal operations, defining the principles and procedures that govern data privacy practices. Bluekite&rsquo;s Data Privacy Policy not only establishes our commitment to compliance with regulatory standards such as GDPR but also reflects our dedication to transparency and accountability. This Policy is an essential tool for our employees to understand and adhere to the standards set forth in our data privacy practices, ensuring that customer and employee information is handled responsibly and ethically.</span></p>

<h2 style="text-align: justify;"><span >1.2 Why is a Privacy Policy Necessary?</span></h2>
<p style="text-align: justify;"><span >A Privacy Policy is essential for several reasons:</span></p>
<ol>
    <li >
        <p style="text-align: justify;"><strong><span >Legal Compliance:</span></strong><span >&nbsp;It ensures that we comply with the law. &nbsp;These laws mandate organisations to transparently disclose how personal data is collected, used, and managed (see. Section 1.3).</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Transparency:</span></strong><span >&nbsp;It builds trust customers and employees by being transparent about how we use personal data.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Ethics:</span></strong><span >&nbsp;It creates an internal framework which ensures that we treat our customer and employee data fairly and ethically.</span></p>
    </li>
</ol>
<h2 style="text-align: justify;"><span >1.3 UK Legislation Governing Privacy Policies</span></h2>
<p style="text-align: justify;"><span >In the UK, the key pieces of legislation that require the use of Privacy Policies are:</span></p>
<ol>
    <li >
        <p style="text-align: justify;"><strong><span >General Data Protection Regulation (GDPR):</span></strong><span >&nbsp;As a regulation in EU law on data protection and privacy, GDPR impacts UK businesses as it sets guidelines for the collection and processing of personal information from individuals who live in the European Union.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >UK Data Protection Act 2018:</span></strong><span >&nbsp;This Act controls how personal information is used by organisations, businesses, or the government. It is the UK&rsquo;s implementation of the GDPR.</span></p>
    </li>
</ol>
<p style="text-align: justify;"><span >We are committed to upholding these legal standards, ensuring the highest level of data protection and privacy for our customers and employees.</span></p>

<h2 style="text-align: justify;"><span >1.4 Point of Contact for GDPR and Data Protection Inquiries</span></h2>

<p style="text-align: justify;"><span >ROL</span><strong><span >Data Protection Officer:</span></strong></p>
<ul>
    <li style="list-style-type:disc;font-size:10pt;">
        <p style="text-align: justify;"><span >Dr. Ian Vickers is appointed as the person responsible for overseeing GDPR compliance and data protection strategies within Blukite. You can contact Dr. Vickers directly at&nbsp;</span><a href="mailto:contact@blukite.finance"><u><span >contact@blukite.finance</span></u></a><span >. This email address is monitored regularly to ensure timely responses to your data protection inquiries.</span></p>
    </li>
</ul>
<h1 id='section2' style="text-align: justify;"><strong><span >2. How We Use Your Personal Information</span></strong></h1>
<p style="text-align: justify;"><span >Your personal information is handled with utmost care and responsibility by Blukite. This section of our Privacy Policy is dedicated to explaining our commitment to safeguarding your personal information. It encompasses the details you share with us, the insights we gain while you are a customer, and the preferences you set regarding our marketing communications.</span></p>
<h2 style="text-align: justify;"><span >2.1 Our Promise to You:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Transparency and Control</span></strong><span >: We pledge to be transparent about the data we collect and how we use it, ensuring that you have control over your personal information.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Learning from Our Interactions</span></strong><span >: The information we gather through our interactions with you, as a customer, helps us tailor our services and improve your experience.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Marketing Preferences</span></strong><span >: We respect your choices regarding the marketing communications you wish to receive. You will have the option to opt-in or opt-out of our marketing messages, in accordance with your preferences.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >2.2 Your Privacy Rights:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Access and Rectification</span></strong><span >: You have the right to access the personal information we hold about you and to request corrections if any details are inaccurate.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Data Portability and Erasure</span></strong><span >: You can request the transfer of your data in a structured, commonly used format, and under certain conditions, you may also request the deletion of your personal information.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Objection and Restriction</span></strong><span >: You have the right to object to certain types of processing of your personal data and to request restrictions on how we use your information.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >2.3 Legal Protection and Compliance:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Adherence to Laws</span></strong><span >: Our practices are designed to comply with applicable data protection laws, including the GDPR and the UK Data Protection Act 2018. These laws provide a framework that ensures your data is processed lawfully, fairly, and transparently.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Security Measures</span></strong><span >: We implement robust security measures to protect your data against unauthorised access, alteration, disclosure, or destruction.</span></p>
    </li>
</ul>
<p style="text-align: justify;"><span >This privacy notice is reflective of our commitment to upholding your privacy rights and safeguarding your personal data in accordance with the law.</span></p>
<h2 style="text-align: justify;"><span >2.4 Our Privacy Promise</span></h2>
<p style="text-align: justify;"><span >At Blukite, we are deeply committed to the privacy and security of your personal information. Our promise to you encompasses the following key principles:</span></p>
<ol>
    <li >
        <p style="text-align: justify;"><strong><span >Safeguarding Your Information</span></strong><span >: We pledge to keep your personal information safe and private. We employ advanced security measures to protect your data against unauthorised access, disclosure, alteration, or loss.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Respect for Your Personal Data</span></strong><span >: We assure you that we will not sell your personal information to third parties. Our respect for your privacy is integral to our ethics and operations.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Control Over Marketing Communications</span></strong><span >: You have complete autonomy to manage and review your marketing preferences at any time. We provide easy and accessible ways for you to adjust these choices, ensuring that you receive marketing communications only as per your consent.</span></p>
    </li>
</ol>
<p style="text-align: justify;"><span >In addition to these promises, we are continuously monitoring and updating our practices to align with evolving data protection laws and standards. Our goal is to not only comply with legal requirements but to exceed them in safeguarding your privacy.</span></p>
<h1 id='section3' style="text-align: justify;"><strong><span >3. Personal Information and the Law</span></strong></h1>
<h2 style="text-align: justify;"><span >3.1 Who We Are</span></h2>
<p style="text-align: justify;"><span >Blukite Finance Limited is the legal entity that holds and processes your personal information, committed to managing it responsibly and lawfully.</span></p>
<h2 style="text-align: justify;"><span >3.2 Contacting Us About Data Privacy</span></h2>
<p style="text-align: justify;"><span >If you have questions or need more information about how we use your personal information, there are several convenient ways to get in touch with us:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >In-App Chat</span></strong><span >: For immediate assistance, you can use our in-app chat feature. This is a quick and easy way to get responses to your data privacy questions.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Website Contact Form</span></strong><span >: Visit our website and use the contact form for your queries. This method is suitable for detailed questions or feedback.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Direct Contact with Data Protection Officer</span></strong><span >: If your query is specific to data protection or if you are not satisfied with the initial response, you can directly message our Group Data Protection Officer as detailed in Section 1.4 of this document.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >3.3 How the Law Protects You</span></h2>
<p style="text-align: justify;"><span >In addition to our Privacy Promise, your privacy is safeguarded by law. The legal framework under which we operate ensures your data is handled in a compliant and secure manner.</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Legal Basis for Processing</span></strong><span >: Our processing of your personal data is always based on legal grounds, complying with GDPR and the UK Data Protection Act 2018. This includes processing necessary for fulfilling contracts, complying with our legal obligations, protecting vital interests, or processing based on your explicit consent.</span></p>
    </li>
</ul>
<h1 id='section4' style="text-align: justify;"><strong><span >4. Legal Basis for Using Your Personal Information</span></strong></h1>
<p style="text-align: justify;"><span >In addition to our commitment outlined in the Privacy Promise, your privacy is also protected by data protection laws. This part of our Privacy Policy explains how we abide by these laws in using your personal information.</span></p>
<h2 style="text-align: justify;"><span >4.1 Grounds for Processing Personal Information:</span></h2>
<p style="text-align: justify;"><span >Data Protection Law stipulates that we can only process your personal information if we have a valid reason for doing so. These reasons can include:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Contractual Necessity:</span></strong><span >&nbsp;When processing is necessary to fulfil a contract we have with you.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Legal Obligation:</span></strong><span >&nbsp;When we are required to process your data to comply with a legal duty.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Legitimate Interests:</span></strong><span >&nbsp;When we use your information for our legitimate business interests. We will clearly inform you about such interests and ensure that processing for these reasons does not unfairly impact your own interests and rights.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Consent:</span></strong><span >&nbsp;When you have given explicit consent to the processing of your personal data for a specific purpose.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Public Interest:</span></strong><span >&nbsp;When processing is necessary for reasons of substantial public interest.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >4.2 Special Categories of Personal Data:</span></h2>
<p style="text-align: justify;"><span >Certain types of sensitive information are classified as &lsquo;special categories of personal data&rsquo; under data protection laws. This includes data concerning your health, race, religious beliefs, sexual orientation, and other similar information. We adhere to the following principles in handling such data:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><span >We will not collect or use these categories of data without your explicit consent, unless legally permitted or required.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >If we do process such data, it will only be under circumstances where it is necessary for substantial public interest, or for establishing, exercising, or defending legal claims.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >4.3 How We Use Your Information and Our Legitimate Interests:</span></h2>
<p style="text-align: justify;"><span >Below is a detailed list of how we may use your personal information and the specific reasons we rely on for each type of use. For instances where we rely on our legitimate interests, we provide a clear explanation of those interests, ensuring transparency and safeguarding your rights.</span></p>
<h2 style="text-align: justify;"><span >4.4 Detailed Use of Personal Information and Legal Basis</span></h2>
<p style="text-align: justify;"><span >To ensure full transparency, we provide below a comprehensive table that details all the ways in which we may use your personal information. This table also outlines the legal reasons we rely on for each type of data usage and explains our legitimate interests where applicable.</span></p>
<p style="text-align: justify;"><span >The table is structured to offer you clear insights into our data processing activities, helping you understand:</span></p>
<ol>
    <li >
        <p style="text-align: justify;"><strong><span >The Specific Type of Data Usage</span></strong><span >: What we do with your personal information in various contexts.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Legal Basis for Use</span></strong><span >: The legal grounds under which we process your data, such as contractual necessity, legal obligation, legitimate interests, consent, or public interest.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Our Legitimate Interests</span></strong><span >: Where we rely on legitimate interests as the basis for processing, we explain what these interests are, ensuring that your rights and interests are considered and protected.</span></p>
    </li>
</ol>
<h2 style="text-align: justify;"><span >4.5 Serving you as a customer</span></h2>
<div align="left">
    <table style="border:none;border-collapse:collapse;">
        <tbody>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >What we use your personal information for</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >Our reasons</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >Our legitimate interests</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <ul>
                        <li >
                            <p><span >To manage our relationship with you or your business</span></p>
                        </li>
                        <li >
                            <p><span >To develop and carry out marketing activities</span></p>
                        </li>
                        <li >
                            <p><span >To study how our customers use products and services from us and other organisations</span></p>
                        </li>
                        <li >
                            <p><span >To communicate with you about our products and services</span></p>
                        </li>
						<li >
						<p><span >To develop and manage our brands, products and services.</span></p>
					</li>
                       
                    </ul>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <ul>
                        <li >
                            <p><span >Your consent</span></p>
                        </li>
                        <li >
                            <p><span >Fulfilling contracts</span></p>
                        </li>
                        <li >
                            <p><span >Our legitimate interests</span></p>
                        </li>
						<li >
						<p><span >Our legal duty</span></p>
					</li>
                    </ul>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <ul>
                        <li >
                            <p><span >Keeping our records up to date, working out which of our products and services may interest you and telling you about them</span></p>
                        </li>
                        <li >
                            <p><span >Developing products and services, and what we charge for them</span></p>
                        </li>
                        <li >
                            <p><span >Defining types of customers for new products or services</span></p>
                        </li>
                        <li >
                            <p><span >Seeking your consent when we need it to contact you</span></p>
                        </li>
                        <li >
                            <p><span >Being efficient about how we fulfil our legal and contractual duties</span></p>
                        </li>
						<li >
						<p><span >Managing competitions and promotions</span></p>
					</li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p style="text-align: justify;"><br></p>
<p style="text-align: justify;"><br></p>
<h2 style="text-align: justify;"><span >4.6 Business improvement</span></h2>
<div align="left">
    <table style="border:none;border-collapse:collapse;">
        <tbody>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >What we use your personal information for</span></p>
                </td>
                <td  style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >Our reasons</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >Our legitimate interests</span></p>
                </td>
            </tr>
        </tbody>
</div>

<div align="left">
        <tbody>
			<tr>
			<td style="border: solid #dddddd 0.75pt;">
				<ul>
					<li >
						<p><span >To test new products</span></p>
					</li>
					<li >
						<p><span >To manage how we work with other companies that provide services to us and our customers</span></p>
					</li>
					<li >
						<p><span >To develop new ways to meet our customers&apos; needs and to grow our business</span></p>
					</li>
				</ul>
			</td>
			<td style="border: solid #dddddd 0.75pt;">
				<ul>
					<li >
						<p><span >Fulfilling contracts</span></p>
					</li>
					<li >
						<p><span >Our legitimate interests</span></p>
					</li>
					<li >
						<p><span >Our legal duty</span></p>
					</li>
				</ul>
			</td>
			<td style="border: solid #dddddd 0.75pt;">
				<ul>
					<li >
						<p><span >Developing products and services, and what we charge for them</span></p>
					</li>
					<li >
						<p><span >Defining types of customers for new products or services</span></p>
					</li>
					<li >
						<p><span >Being efficient about how we fulfil our legal and contractual duties</span></p>
					</li>
				</ul>
			</td>
		</tr>
        </tbody>
    </table>
</div>
<p style="text-align: justify;"><br></p>
<h2 style="text-align: justify;"><span >4.7 Managing our operations</span></h2>
<div align="left">
    <table style="border:none;border-collapse:collapse;">
        <tbody>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >What we use your personal information for</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >Our reasons</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >Our legitimate interests</span></p>
                </td>
            </tr>
        </tbody>
</div>

<div align="left">
        <tbody>
		<tr>
		<td style="border: solid #dddddd 0.75pt;">
			<ul>
				<li >
					<p><span >To deliver of our products and services</span></p>
				</li>
				<li >
					<p><span >To make and manage customer payments</span></p>
				</li>
				<li >
					<p><span >To manage fees, charges and interest due on customer accounts</span></p>
				</li>
				<li >
					<p><span >To collect and recover money that is owed to us</span></p>
				</li>
				<li >
					<p><span >To manage and provide treasury and investment products and services</span></p>
				</li>
			</ul>
		</td>
		<td style="border: solid #dddddd 0.75pt;">
			<ul>
				<li >
					<p><span >Fulfilling contracts</span></p>
				</li>
				<li >
					<p><span >Our legitimate interests</span></p>
				</li>
				<li >
					<p><span >Our legal duty</span></p>
				</li>
			</ul>
		</td>
		<td style="border: solid #dddddd 0.75pt;">
			<ul>
				<li >
					<p><span >Being efficient about how we fulfil our legal and contractual duties</span></p>
				</li>
				<li >
					<p><span >Complying with rules and guidance from regulators</span></p>
				</li>
			</ul>
		</td>
	</tr>
        </tbody>
    </table>
</div>
<p style="text-align: justify;"><br></p>
<h2 style="text-align: justify;"><span >4.8 Managing security, risk and crime prevention</span></h2>
<div align="left">
    <table style="border:none;border-collapse:collapse;">
        <tbody>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >What we use your personal information for</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >Our reasons</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >Our legitimate interests</span></p>
                </td>
            </tr>
        </tbody>
</div>

<div align="left">
        <tbody>
	<tr>
	<td style="border: solid #dddddd 0.75pt;">
		<ul>
			<li >
				<p><span >To detect, investigate, report, and seek to prevent financial crime</span></p>
			</li>
			<li >
				<p><span >To manage risk for us and our customers</span></p>
			</li>
			<li >
				<p><span >To obey laws and regulations that apply to us</span></p>
			</li>
			<li >
				<p><span >To respond to complaints and seek to resolve them</span></p>
			</li>
		</ul>
	</td>
	<td style="border: solid #dddddd 0.75pt;">
		<ul>
			<li >
				<p><span >Fulfilling contracts</span></p>
			</li>
			<li >
				<p><span >Our legitimate interests</span></p>
			</li>
			<li >
				<p><span >Our legal duty</span></p>
			</li>
		</ul>
	</td>
	<td style="border: solid #dddddd 0.75pt;">
		<ul>
			<li >
				<p><span >Developing and improving how we deal with financial crime, as well as doing our legal duties in this respect</span></p>
			</li>
			<li >
				<p><span >Complying with rules and guidance from regulators</span></p>
			</li>
			<li >
				<p><span >Being efficient about how we fulfil our legal and contractual duties</span></p>
			</li>
		</ul>
	</td>
</tr>
        </tbody>
    </table>
</div>
<p style="text-align: justify;"><br></p>
<h2 style="text-align: justify;"><span >4.9 Business management</span></h2>
<div align="left">
    <table style="border:none;border-collapse:collapse;">
        <tbody>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                <p style="text-align: justify;"><span >What we use your personal information for</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >Our reasons</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >Our legitimate interests</span></p>
                </td>
            </tr>
        </tbody>
</div>

<div align="left">
        <tbody>
<tr>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span >To run our business in an efficient and proper way. This includes managing our financial position, business capability, planning, adding and testing systems and processes, managing communications, corporate governance, and audit</span></p>
		</li>
	</ul>
</td>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span >Our legitimate interests</span></p>
		</li>
		<li >
			<p><span >Our legal duty</span></p>
		</li>
	</ul>
</td>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span >Complying with rules and guidance from regulators</span></p>
		</li>
		<li >
			<p><span >Being efficient about how we fulfil our legal and contractual duties</span></p>
		</li>
	</ul>
</td>
</tr>
<tr>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span >To exercise our rights set out in agreements or contracts</span></p>
		</li>
	</ul>
</td>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span >Fulfilling contracts</span></p>
		</li>
	</ul>
</td>
<td style="border: solid #dddddd 0.75pt;"><br></td>
</tr>
        </tbody>
    </table>
</div>

<h2 style="text-align: justify;"><span >4.10 For processing special categories of personal information</span></h2>
<div align="left">
    <table style="border:none;border-collapse:collapse;">
        <tbody>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >What we use your personal information for</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p style="text-align: justify;"><span >Our legitimate interests</span></p>
                </td>
            </tr>
        </tbody>
</div>

<div align="left">
        <tbody>
<tr>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span >Preventing Crime</span></p>
		</li>
	</ul>
</td>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span >Using criminal records data to help prevent, detect, and prosecute unlawful acts and fraudulent behaviour</span></p>
		</li>
		<li >
			<p><span >Using criminal and health information as needed to provide insurance products</span></p>
		</li>
	</ul>
</td>
</tr>
<tr>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span >Responding to regulatory requirements</span></p>
		</li>
	</ul>
</td>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span >Showing whether we have assessed your situation in the right way</span></p>
		</li>
		<li >
			<p><span >Passing information to the regulator as needed to allow investigation into whether we have acted in the right way</span></p>
		</li>
	</ul>
</td>
</tr>
<tr>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span style="font-size:11.5pt;">Legal claims</span></p>
		</li>
	</ul>
</td>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span >Using any special categories of data as needed to establish, exercise or defend legal claims</span></p>
		</li>
	</ul>
</td>
</tr>
<tr>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span >Consent</span></p>
		</li>
	</ul>
</td>
<td style="border: solid #dddddd 0.75pt;">
	<ul>
		<li >
			<p><span >Telling you that we need your consent to process special categories of personal information, when that is what we rely on for doing so</span></p>
		</li>
	</ul>
</td>
</tr>
        </tbody>
    </table>
</div>
<h1 id='section5' style="text-align: justify;"><strong><span >5. Groups of Personal Information</span></strong></h1>
<p style="text-align: justify;"><span >In adherence to data protection laws, we recognise the importance of understanding the various types of personal information we handle. Our approach categorises this information into different groups, ensuring that each type is processed appropriately based on its nature and sensitivity.</span></p>
<h2 style="text-align: justify;"><span >5.1 Understanding the Categories:</span></h2>
<p style="text-align: justify;"><span >Below, you will find a comprehensive listing of the groups of personal information we may collect and process. This categorisation is designed to give you a clear view of the potential scope of information we have about you. This could range from data obtained through your interactions with our services to insights derived from your purchasing habits in different shops.</span></p>
<h2 style="text-align: justify;"><span >5.2 Diverse Uses of Personal Information:</span></h2>
<p style="text-align: justify;"><span >It&apos;s important to note that not all personal information is used in the same manner:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >For Marketing and Service Provision</span></strong><span >: Some data is instrumental for marketing purposes or for providing you with information regarding our services more effectively.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Opening Your Account:&nbsp;</span></strong><span >When you apply to open an account, we use the information provided to check that you meet our conditions. This might include checking your age, residency or domicile, nationality or financial position. In some circumstances, it might mean that we are unable to offer you the account which you have applied for.&nbsp;</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Operating Your Account:</span></strong><span >&nbsp;We use your data for essential account management purposes such as ensuring seamless transactions, accurate billing, and effective customer service.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Making Credit Decisions:&nbsp;</span></strong><span >We use data and an automated system to make informed decisions about lending money to our customers. The system uses information and data to assess how likely you will be able to repay any money that you borrow. This includes information you provide in your application form, data we might already hold (for example if you are an existing customer) and from credit reference agencies.&nbsp;</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Prevention of Crime:</span></strong><span >&nbsp;We are required by law to use customer data to help prevent financial crime such as fraud or money laundering. This also means that we are able to help protect our customers from being the victim of crime. If we observe activities on your account which seem unusual or any other activities we think may be a risk of fraud, it may be necessary for us to suspend access.&nbsp;</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >5.3 Upcoming Detailed Table:</span></h2>
<p style="text-align: justify;"><span >Following this section, we have included a detailed table that breaks down these categories further. The table will provide you with specific examples of the types of personal information in each group, helping you understand how and why we use each category of data.</span></p>
<div align="left">
    <table style="border:none;border-collapse:collapse;">
        <tbody>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Type of personal information</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Description</span></p>
                </td>
            </tr>
        </tbody>
</div>

<div align="left">
        <tbody>
          
		<tr>
		<td style="border: solid #dddddd 0.75pt;">
			<p><span >Contact</span></p>
		</td>
		<td style="border: solid #dddddd 0.75pt;">
			<p><span >Your name, where you live and how to contact you</span></p>
		</td>
	</tr>
	<tr>
		<td style="border: solid #dddddd 0.75pt;">
			<p><span >Financial</span></p>
		</td>
		<td style="border: solid #dddddd 0.75pt;">
			<p><span >Your financial position, status and history</span></p>
		</td>
	</tr>
	<tr>
		<td style="border: solid #dddddd 0.75pt;">
			<p><span >Socio-Demographic</span></p>
		</td>
		<td style="border: solid #dddddd 0.75pt;">
			<p><span >This includes details about your work or profession, nationality, education and where you fit into general social or income groupings</span></p>
		</td>
	</tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Transactional</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Details about payments to and from your accounts with us, and insurance claims you make</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Contractual</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Details about the products or services we provide to you</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Locational</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Data we get about where you are. This may come from your mobile phone or the place where you connect a computer to the internet. It can also include shops where you buy something with your card</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Behavioural</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Details about how you use products and services from us and other organisations</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Technical</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Details on the devices and technology you use</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Communications</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >What we learn about you from letters and emails you write to us and conversations between us</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Social Relationships</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Your family, friends and other relationships</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Open Data and Public Records</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Details about you that are in public records, such as the Electoral Register, and information about you that is openly available on the internet</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Usage Data</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Other data about how you use our products and services</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Documentary Data</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Details about you that are stored in documents in different formats, or copies of them. This could include things like your passport, drivers&rsquo; licence or birth certificate</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Gender Identity</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Information relating to the gender that you identify as</span></p>
                </td>
            </tr>
			<tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Special types of data</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >The law and other regulations treat some types of personal information as special. We will only collect and use these types of data if the law allows us to do so:</span></p>
                    <ul>
                        <li >
                            <p><span >Racial or ethnic origin</span></p>
                        </li>
                        <li >
                            <p><span >Religious, political or philosophical beliefs</span></p>
                        </li>
                        <li >
                            <p><span >Trade union membership</span></p>
                        </li>
                        <li >
                            <p><span >Genetic and bio-metric data</span></p>
                        </li>
                        <li >
                            <p><span >Health data</span></p>
                        </li>
                        <li >
                            <p><span >Information that could identify aspects of your sex life</span></p>
                        </li>
                        <li >
                            <p><span >Sexual orientation</span></p>
                        </li>
                        <li >
                            <p><span >Criminal records of convictions and offences</span></p>
                        </li>
                        <li >
                            <p><span >Allegations of criminal offences.</span></p>
                        </li>
                        <li >
                            <p><span >You can read how we may use special types of data in the table &apos;How the law protects you&apos;.</span></p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Consents</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >Any permissions, consents or preferences that you give us. This includes things like how you want us to contact you, whether you get paper statements, or prefer large-print formats</span></p>
                </td>
            </tr>
            <tr>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >National Identifier</span></p>
                </td>
                <td style="border: solid #dddddd 0.75pt;">
                    <p><span >A number or code given to you by a government to identify who you are, such as a National Insurance number or social security number, or Tax Identification Number (TIN)</span></p>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<h1 id='section6' style="text-align: justify;"><strong><span >6. Where we collect personal information from</span></strong></h1>
<p style="text-align: justify;"><span >In this section, we detail the diverse sources from which we collect personal information about you or your business. This information may come from other companies within the Blukite Finance Limited, as well as from a variety of external sources.</span></p>
<h2 style="text-align: justify;"><span >6.1 Directly from You:</span></h2>
<p style="text-align: justify;"><span >Much of the personal information we process is provided directly by you. This may occur in several situations, including but not limited to:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><span >Product and Service Applications: When you apply for our products or services.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Communications: This includes information you provide when you talk to us on the phone (note that these calls may be recorded for quality and training purposes), in person, through our websites, mobile apps, or web chat.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Written Correspondence: The information contained in emails, letters, or other forms of written communication.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Financial Reviews and Interviews: Personal data collected during any financial assessments or interviews.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Customer Surveys: Information you provide when participating in customer surveys.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >6.2 From People Associated with You or Your Business:</span></h2>
<p style="text-align: justify;"><span >We also collect information from individuals associated with you or your business. This could include:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><span >Joint account holders, trustees, or fellow company directors.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Representatives or agents acting on your behalf.</span></p>
    </li>
</ul>
<p style="text-align: justify;"><span >It&apos;s important to note that we handle all personal information, regardless of its source, with the same level of confidentiality and security. Our aim is to ensure that your data is protected and processed in accordance with applicable data protection laws and our internal policies.</span></p>
<h2 style="text-align: justify;"><span >6.3 Data We Collect When You Use Our Services</span></h2>
<p style="text-align: justify;"><span >This section outlines the kinds of personal data we collect and process when you access and use our services. It encompasses two main categories: transactional details and profile and usage data.</span></p>
<ol>
    <li >
        <p style="text-align: justify;"><span >Payment and Transaction Data:</span></p>
    </li>
<p style="text-align: justify;"><span >When you use our services for financial transactions, we collect data that includes, but is not limited to:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><span >Transaction Details: The amount, frequency, type, and location of your transactions, as well as the origin and recipients involved.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Credit Information: If you borrow money, this will also encompass details of repayments, including whether they are made timely and in full.</span></p>
    </li>
</ul>
    <li >
        <p style="text-align: justify;"><span >Profile and Usage Data:</span></p>
    </li>
</ol>
<p style="text-align: justify;"><span >This category involves information related to how you interact with our services:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><span >Security Information: The security credentials you create and utilise to access our services, such as passwords or biometric data.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Service Settings and Marketing Preferences: Your chosen settings within our services and your preferences regarding marketing communications.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Device Data: We collect data from the devices you use to access our internet, mobile, and telephone banking services. This includes, but is not limited to, hardware models, operating system versions, unique device identifiers, and network information.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Cookies and Tracking Technologies: Our use of cookies and similar technologies helps us understand how you interact with our websites and mobile apps. These tools gather data about your usage patterns, including how you respond to emails we send you. For more detailed information on how we use these technologies, please refer to our Cookies Policy.</span></p>
    </li>
</ul>
<p style="text-align: justify;"><span >Please note that all the data we collect is used in accordance with our Privacy Promise and is aimed at enhancing the quality, security, and personalisation of the services we offer you.</span></p>
<h2 style="text-align: justify;"><span >6.4 Data from Outside Organisations</span></h2>
<p style="text-align: justify;"><span >In the course of our operations, we may receive personal information about you from various external organisations. The following list provides an overview of these external sources:</span></p>
<ol>
    <li >
		<p style="text-align: justify;"><strong><span >Introduction and Referral Sources</span></strong><span >:</span></p>
       
    </li>
	<ul>
	<li >
		<p style="text-align: justify;"><span >Companies that introduce you to us, such as price comparison websites, brokers, stores, car dealerships offering finance through us.</span></p>
	</li>
</ul>
    <li >
        <p style="text-align: justify;"><strong><span >Financial Service Providers</span></strong><span >:&nbsp;</span></p>
       
    </li>
	<ul>
	<li >
		<p style="text-align: justify;"><span >Financial advisors.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Credit card providers like Visa and Mastercard.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Credit reference agencies, including TransUnion, Equifax, and Experian.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Other financial services companies for payment fulfilment or fraud prevention.</span></p>
	</li>
</ul>
    <li >
	
        <p style="text-align: justify;"><strong><span >Insurance and Retail Partners</span></strong><span >:</span></p>
     
    </li>
	<ul>
	<li >
		<p style="text-align: justify;"><span >Insurers.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Retailers.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Loyalty scheme operators.</span></p>
	</li>
</ul>
    <li >
        <p style="text-align: justify;"><strong><span >Online and Technology Platforms</span></strong><span >:</span></p>
      
    </li>
	<ul>
	<li >
		<p style="text-align: justify;"><span >Comparison websites.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Social networks and tech providers (e.g., interactions with our ads on platforms like Facebook or Google).</span></p>
	</li>
</ul>
    <li >
        <p style="text-align: justify;"><strong><span >Fraud Prevention and Legal Entities</span></strong><span >:</span></p>
       
    </li>
	<ul>
	<li >
		<p style="text-align: justify;"><span >Fraud prevention agencies.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Government and law enforcement agencies.</span></p>
	</li>
</ul>
    <li >
        <p style="text-align: justify;"><strong><span >Public Records and Information Sources</span></strong><span >:</span></p>
       
    </li>
	<ul>
	<li >
		<p style="text-align: justify;"><span >Public information sources such as the Electoral Register or Companies House.</span></p>
	</li>
</ul>
    <li >
        <p style="text-align: justify;"><strong><span >Business and Consulting Services</span></strong><span >:</span></p>
        
    </li>
	<ul>
            <li >
                <p style="text-align: justify;"><span >Agents, suppliers, sub-contractors, and advisers.</span></p>
            </li>
        </ul>
</ol>
<p style="text-align: justify;"><span >We collaborate with various firms that assist us in managing accounts and providing services to you. This collaboration extends to specialist companies who offer consultancy and advice on business development and improvement. The data collected from these entities plays a crucial role in enhancing our service offerings and operational efficiency. By understanding how these firms contribute to our operations, we can ensure that the data we receive is relevant and used effectively to benefit our customers.</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><span >Market research firms.</span></p>
    </li>
</ul>
<p style="text-align: justify;"><span >Market research firms play a vital role in helping us understand your needs and experiences. These firms might contact you directly on our behalf to gather your opinions and feedback. They often combine the insights you provide with data from other sources to conduct comprehensive analyses. The findings from these studies are used to generate reports and offer advice that is instrumental in understanding our customers&apos; perspectives. This information is crucial for us to continuously improve our business practices and enhance the services we offer to you.</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><span >Firms providing data services.</span></p>
    </li>
</ul>
<p style="text-align: justify;"><span >This list is not exhaustive but covers the primary sources from which we might receive data about you. We ensure that all data received from these external sources is handled with the same level of security and privacy as the data we collect directly from you. We also ensure that our use of this data is in line with the legal bases outlined in our Privacy Policy.</span></p>
<h1 id='section7' style="text-align: justify;"><strong><span >7. How Long We Keep Your Personal Information</span></strong></h1>
<p style="text-align: justify;"><span >Understanding the duration for which we retain your personal information and the reasons for it is crucial. Our data retention policies are designed to ensure that we keep your information for no longer than necessary.</span></p>
<h2 style="text-align: justify;"><span >7.1 While You Are a Customer:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >We retain your personal information for the entire duration of your relationship with us as a customer.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >7.2 After You Cease Being a Customer:</span></h2>
<ul>
    <div >
        <p style="text-align: justify;"><span >Post the end of your relationship with us, we may retain your personal data for up to 10 years. The reasons for this extended retention period include:</span></p>
        <ul>
            <li >
                <p style="text-align: justify;"><strong><span >To Address Inquiries or Complaints</span></strong><span >: To respond to any questions or complaints and to demonstrate whether we treated you fairly.</span></p>
            </li>
            <li >
                <p style="text-align: justify;"><strong><span >For Internal Research</span></strong><span >: To conduct studies and analyses as part of our internal research to improve our services.</span></p>
            </li>
            <li >
                <p style="text-align: justify;"><strong><span >Compliance with Legal and Regulatory Obligations</span></strong><span >: To adhere to legal and regulatory requirements that dictate record-keeping. For example, the Money Laundering Regulations mandate retaining certain data for a minimum of 5 and a maximum of 10 years.</span></p>
            </li>
        </ul>
    </div>
</ul>
<h2 style="text-align: justify;"><span >7.3 Exceptions to the 10-Year Limit:</span></h2>
<p style="text-align: justify;"><span >In specific instances, we may retain your data for periods longer than 10 years if we are legally unable to delete it due to regulatory, legal, or technical constraints.&nbsp;</span></p>
<p style="text-align: justify;"><span >In all cases, we assure you that your personal information will be used solely for the stated purposes, and we are committed to protecting your privacy throughout this period.</span></p>
<h1 id='section8' style="text-align: justify;"><strong><span >8. If You Choose Not to Give Personal Information</span></strong></h1>
<p style="text-align: justify;"><span >In this section, we explain the potential consequences of your decision not to provide personal information to us. While you have the right to withhold information, it&apos;s important to understand the impact this may have on our ability to provide services to you.</span></p>
<h2 style="text-align: justify;"><span >8.1 Mandatory Information for Legal and Contractual Reasons:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >Certain personal information may be required by law or for the purposes of entering into or fulfilling a contract with you. This could include data needed to comply with legal obligations or to provide the products and services you request from us.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >If you choose not to provide this mandatory information, it may lead to delays or prevent us from fulfilling our contractual obligations. In some cases, it could also hinder our ability to comply with legal requirements.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >8.2 Potential Consequences:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >Non-provision of necessary data may result in our inability to manage or provide certain accounts or services to you. This might lead to the cancellation of a product or service you have with us.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >8.3 Optional Information:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >At times, we may request information that is useful for enhancing our services but is not required by law or a contract. Whenever this is the case, we will clearly indicate that the provision of such information is optional.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Choosing not to provide this optional information will not impact the existing products or services you have with us. It is entirely at your discretion to decide whether to share these additional details.</span></p>
    </li>
</ul>
<h1 id='section9' style="text-align: justify;"><strong><span >9. Cookies and Similar Tracking Technologies</span></strong></h1>
<p style="text-align: justify;"><span >In this section, we detail how we use cookies and other tracking technologies on our websites and apps, as well as in the emails we send to you. Understanding these technologies is important for you to know how your data is being collected and used.</span></p>
<h2 style="text-align: justify;"><span >9.1 Cookies:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >What Are Cookies?</span></strong><span >: Cookies are small text files stored on your computer or mobile device when you visit a website. They are sent back to the originating website on subsequent visits or to another website that recognises that cookie.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Purpose of Cookies</span></strong><span >: Cookies are used to store information about your visits and preferences each time you visit our website. They help in enhancing your user experience by remembering your preferences and settings.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Types of Information Stored</span></strong><span >: While some cookies may store personal information, many only gather data related to your website usage or preferences. This data, whether or not it includes personal details, is protected under this Privacy Policy.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >9.2 Email Tracking:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Purpose of Email Tracking</span></strong><span >: We employ tracking within our emails to improve the effectiveness of our communications. This includes the use of tiny graphics known as pixels.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >What We Track</span></strong><span >: These pixels can tell us if and when you opened an email, how many times it was viewed, and the device used to view it. We may also use cookies to track whether you clicked on any links within the email.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >9.3 Further Information:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >For more comprehensive details on how we use cookies and email tracking, including how to manage your preferences or opt-out, please refer to our Cookie Policy.</span></p>
    </li>
</ul>
<h1 style="text-align: justify;"><strong><span >The Control You Have</span></strong></h1>
<h2 style="text-align: justify;"><span >Empowering You to Manage Your Personal Information</span></h2>
<p style="text-align: justify;"><span >In this key part of our Privacy Policy, we focus on the control you have over your personal information. It&apos;s important for us to not only inform you about how we use your data but also to empower you with the ability to manage, review, and update your data as needed. This section outlines the various tools and processes we have in place to help you exercise your data rights and preferences.</span></p>
<h1 id='section10' style="text-align: justify;"><strong><span >10. How to Complain</span></strong></h1>
<h2 style="text-align: justify;"><span >Your Feedback and Concerns Regarding Data Privacy</span></h2>
<p style="text-align: justify;"><span >We take your concerns about privacy and data protection seriously. If you are unhappy with how we have used your personal information, we encourage you to let us know so that we can address your concerns.</span></p>
<h2 style="text-align: justify;"><span >10.1 Contacting Us:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >You can reach out to us via our secure online contact form. We are committed to resolving any issues concerning your personal data promptly and fairly.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >10.2 Your Right to Complain to the Regulator:</span></h2>
<p style="text-align: justify;"><span >Besides contacting us, you have the right to make a complaint directly to the relevant data protection authority. In the UK, this is the Information Commissioner&rsquo;s Office (ICO).</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Information Commissioner&rsquo;s Office (ICO)</span></strong><span >: The ICO is responsible for enforcing data protection laws and handling complaints about data privacy. You can find out how to report a concern to the ICO on their website.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Appealing Decisions</span></strong><span >: If you are not satisfied with the outcome of your complaint, either with us or the ICO, you have the right to lodge an appeal.</span></p>
    </li>
</ul>
<p style="text-align: justify;"><span >We are dedicated to ensuring that all your data privacy concerns are addressed respectfully and efficiently.</span></p>
<h1 id='section11' style="text-align: justify;"><strong><span >11. How to Withdraw Your Consent</span></strong></h1>
<h2 style="text-align: justify;"><span >Your Right to Change Your Mind</span></h2>
<p style="text-align: justify;"><span >We understand that your preferences and decisions regarding your personal information may change over time. This section explains the process for withdrawing consent that you have previously given us.</span></p>
<h2 style="text-align: justify;"><span >11.1 Withdrawing Your Consent:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >If you wish to withdraw your consent at any time, please reach out to us directly. You can use our secure online contact form, or any other contact method provided in our Privacy Policy.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Withdrawing consent will affect only those data processing activities that are based on consent. For more comprehensive options regarding the restriction of your information use, please refer to the section titled &apos;Your Rights&apos;.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >11.2 Implications of Withdrawing Consent:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >It&apos;s important to note that withdrawing your consent may impact our ability to provide you with certain products or services. If such a situation arises, we will inform you accordingly.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >We ensure that any withdrawal of consent is processed promptly and that it ceases any further processing of your data for the purposes for which consent was originally given.</span></p>
    </li>
</ul>
<p style="text-align: justify;"><span >Your control over your personal information is a priority for us, and we are committed to facilitating your rights in a transparent and accessible manner.</span></p>
<h1 id='section12' style="text-align: justify;"><strong><span >12. Letting Us Know if Your Personal Information Is Incorrect</span></strong></h1>
<h2 style="text-align: justify;"><span >Your Right to Accurate Information</span></h2>
<p style="text-align: justify;"><span >Maintaining the accuracy of your personal information is a key priority for us. If you believe that any information we hold about you is incorrect, incomplete, or outdated, you have every right to have it corrected.</span></p>
<h2 style="text-align: justify;"><span >12.1 How to Notify Us:</span></h2>
<p style="text-align: justify;"><span >To raise concerns about the accuracy of your personal information, you can easily contact us through the following methods:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Online Contact Form</span></strong><span >: The most direct way to notify us is via the contact form available on our website.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >In-App Chat</span></strong><span >: You can also use our in-app chatbot for a swift response and assistance in updating your information.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >12.2 Our Commitment to You:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >Upon receiving your query, we will take reasonable steps to verify and, if necessary, correct the information in question.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >We are committed to responding to your concerns promptly and ensuring that your personal information is accurate and up to date.</span></p>
    </li>
</ul>
<p style="text-align: justify;"><span >Your confidence in the accuracy of the information we hold is fundamental to our relationship, and we are dedicated to upholding your right to correct any inaccuracies in your personal data.</span></p>
<h1 id='section13' style="text-align: justify;"><strong><span >13. How to Get a Copy of Your Personal Information</span></strong></h1>
<h2 style="text-align: justify;"><span >Accessing Your Data</span></h2>
<p style="text-align: justify;"><span >We acknowledge and support your right to access the personal information we hold about you. Whether you need a copy for personal use or to share with others, we have made the process straightforward and accessible.</span></p>
<h2 style="text-align: justify;"><span >13.1 Requesting Your Information:</span></h2>
<p style="text-align: justify;"><span >You can request a copy of all the personal information we have about you. To do so, you can either:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Write to Us</span></strong><span >: Send a written request to the following address:</span></p>
    </li>
</ul>
<p style="text-align: justify;"><strong><span >Blukite Finance Limited</span></strong><span >&nbsp;Unit 36-37 Tondu Enterprise Centre, Bryn Road, Aberkenfig, Bridgend, Wales, CF32 9BS</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Online Request</span></strong><span >: Alternatively, you can make this request online through our website or via our in-app features.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >13.2 Digital File for Ease of Use:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >Upon request, we can provide your personal data in a digital format. This makes it easy for you to use, store, or share the information as needed.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >13.3 Response Time:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >We strive to respond to your requests promptly and efficiently, in line with data protection laws.</span></p>
    </li>
</ul>
<p style="text-align: justify;"><span >Your right to access your personal information is a key aspect of data transparency and control. We are committed to facilitating this right in a user-friendly and efficient manner.</span></p>
<h2 style="text-align: justify;"><span >13.4 Data Portability: Sharing Your Data with Outside Companies</span></h2>
<p style="text-align: justify;"><strong><span >Your Right to Use and Transfer Your Personal Information</span></strong></p>
<p style="text-align: justify;"><span >In addition to accessing your personal data, you have the right to use your data for your own purposes and share it with other organisations as you see fit.</span></p>
<h2 style="text-align: justify;"><span >13.5 Getting Your Data in a Digital Format:</span></h2>
<p style="text-align: justify;"><span >We can provide you with your personal information in a digital file format. This format is designed to be easily reusable, allowing you to keep, use, or transfer your data as needed.</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Electronic Transfer</span></strong><span >: On your request, we can furnish your data in an electronic format that facilitates easy use and transfer.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >13.6 Passing Your Data to Other Organisations:</span></h2>
<p style="text-align: justify;"><span >If you wish, we can also directly transfer your personal data to other organisations on your behalf.</span></p>
<p style="text-align: justify;"><span >To initiate this process, you can reach us through:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Online Contact</span></strong><span >: The most efficient way to make this request is via our online contact form or through our in-app chat service.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >13.7 Maintaining Your Data Integrity:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >We ensure that all data transfers are conducted securely and in a manner that protects the integrity of your personal information.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Our commitment is to make the process of data portability as seamless and user-friendly as possible, respecting your rights to control and distribute your personal data.</span></p>
    </li>
</ul>
<h1 id='section14' style="text-align: justify;"><strong><span >14. Your Rights</span></strong></h1>
<h2 style="text-align: justify;"><span >Understanding and Exercising Your Data Privacy Rights</span></h2>
<p style="text-align: justify;"><span >This section explains your rights regarding your personal data and how you can exercise them. We are committed to not only respecting these rights but also to assisting you in exercising them.</span></p>
<h2 style="text-align: justify;"><span >14.1 Right to Object:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >You have the right to object to our processing of your personal information. If you feel that your data should not be used in a certain way, you can raise an objection.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >14.2 Right to Erasure (&lsquo;Right to be Forgotten&rsquo;):</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >You can request the deletion or removal of your personal data when there is no compelling reason for its continued processing. This right is known as the &lsquo;right to erasure&rsquo; or the &lsquo;right to be forgotten&rsquo;.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >14.3 Exceptions to Data Deletion:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >There may be circumstances under legal or regulatory obligations where we need to retain or use your data. However, we encourage you to inform us if you believe we should not be using your data.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >14.4 Right to Restrict Processing:</span></h2>
<p style="text-align: justify;"><span >In certain situations, you have the right to &lsquo;restrict&rsquo; or limit the ways in which we can use your personal information. These situations include:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><span >If you contest the accuracy of the data.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >If the data has been used unlawfully but you do not want it deleted.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >If the data is no longer needed but you wish to keep it for use in legal claims.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >If you have objected to our use of your data but are awaiting verification of our grounds for processing.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >While your data is restricted, we will not use or share it in other ways.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >14.5 Contacting Us to Exercise Your Rights:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >If you wish to exercise any of these rights, you can contact us through our secure online contact form or via our in-app chat service.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >We are here to help you understand and exercise your rights effectively, ensuring your data is handled in accordance with your wishes.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >14.6 Legislation and Regulatory Compliance</span></h2>
<p style="text-align: justify;"><span >In addition to outlining your personal data rights, it&rsquo;s important to acknowledge the legal and regulatory framework that underpins these rights. Blukite operates in compliance with several key regulations and legal requirements, especially concerning our consumer credit license and payment services. This includes, but is not limited to:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Financial Conduct Authority (FCA) Regulations</span></strong><span >: We adhere to FCA guidelines, which govern fair and responsible lending and financial services practices.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Payment Services Regulations</span></strong><span >: Our payment services are in line with the regulatory standards set out in the Payment Services Regulations, ensuring secure and efficient processing of payment transactions.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Consumer Credit Legislation</span></strong><span >: We operate our consumer credit services under strict compliance with relevant consumer credit legislation, which provides a framework for consumer rights and protections in credit agreements.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Data Protection Legislation</span></strong><span >: This includes the General Data Protection Regulation (GDPR) and the UK Data Protection Act 2018, which provide the foundation for your rights to access, rectify, erase, or restrict the processing of your personal data.</span></p>
    </li>
</ul>
<p style="text-align: justify;"><span >Understanding these regulations helps contextualise your rights and our obligations. Our commitment to these legal and regulatory frameworks ensures that we not only protect your personal data but also uphold your rights as a consumer in our credit and payment services.</span></p>
<h1 id='section15' style="text-align: justify;"><strong><span >15. How Personal Information is Used</span></strong></h1>
<h2 style="text-align: justify;"><span >Who We Share Your Personal Information With</span></h2>
<p style="text-align: justify;"><span >At Blukite, we may share your personal information with a range of outside organisations to provide you with our products and services, for business operations, and to comply with legal obligations. Below is a detailed list of the types of organisations we may share your information with:</span></p>
<ol>
    <li >
        <p style="text-align: justify;"><strong><span >Within Blukite&nbsp;</span></strong></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Group</span></strong><span >: Other companies in the Blukite Group.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Authorities</span></strong><span >: This includes central and local government, HM Revenue &amp; Customs, regulators, tax authorities, the UK Financial Services Compensation Scheme, deposit guarantee schemes, law enforcement, and fraud prevention agencies.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Banking and Financial Services</span></strong><span >: Entities that are part of providing banking and financial services, including credit reference agencies (like TransUnion, Equifax, and Experian), and other financial services companies for payments or fraud prevention.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Service Providers and Business Partners</span></strong><span >:</span></p>
      
    </li>
	<ul>
	<li >
		<p style="text-align: justify;"><span >Agents, suppliers, sub-contractors, and advisers that help run accounts, policies, and services.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Credit and collection agents.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Independent Financial Advisors, with your consent.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Price comparison websites and similar companies.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Open Banking partners, as directed by you.</span></p>
	</li>
</ul>
    <li >
        <p style="text-align: justify;"><strong><span >Additional Services and Schemes</span></strong><span >:</span></p>
       
    </li>
	<ul>
	<li >
		<p style="text-align: justify;"><span >Card transaction processors like Visa and Mastercard.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Direct Debit scheme operators.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Loyalty scheme operators.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Insurance and benefit providers.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Other lenders for joint loans or mortgages.</span></p>
	</li>
</ul>
    <li >
        <p style="text-align: justify;"><strong><span >General Business Operations</span></strong><span >:</span></p>
       
    </li>
	<ul>
	<li >
		<p style="text-align: justify;"><span >Joint venture partners and other business collaborators.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Market research firms for customer insights.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Advisers and consultants for business development.</span></p>
	</li>
	<li >
		<p style="text-align: justify;"><span >Advertisers and technology providers for marketing purposes.</span></p>
	</li>
</ul>
    <li >
        <p style="text-align: justify;"><strong><span >Mergers, Acquisitions, and Business Transfers</span></strong><span >:</span></p>
       
    </li>
	<ul>
	<li >
		<p style="text-align: justify;"><span >Potential buyers or partners in business sales, mergers, or acquisitions. We ensure data protection terms are agreed upon in these situations.</span></p>
	</li>
</ul>
    <li >
        <p style="text-align: justify;"><strong><span >Anonymised Data Sharing</span></strong><span >:</span></p>
       
    </li>
	<ul>
	<li >
		<p style="text-align: justify;"><span >We may share aggregated, anonymised data with outside companies for research and analysis. This data is grouped to prevent identification of individuals.</span></p>
	</li>
</ul>
</ol>
<p style="text-align: justify;"><span >In all instances of data sharing, our priority is to ensure your personal information is protected and used in accordance with this Privacy Policy.</span></p>
<h1 id='section16' style="text-align: justify;"><strong><span >16. How We Work Out What Marketing You Receive</span></strong></h1>
<h2 style="text-align: justify;"><span >Personalised Marketing and Your Choices</span></h2>
<p style="text-align: justify;"><span >Understanding how we use your personal information to tailor and present marketing to you is important. This section explains our approach to determining the marketing content that may interest you and how you can control what you receive.</span></p>
<h2 style="text-align: justify;"><span >16.1 Marketing Decisions Based on Your Information:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >We use your personal information to make informed decisions about the products, services, and offers that may be of interest to you. This process involves analysing data from your interactions with our services, information you provide, and data from external sources.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >This method of using personal information for marketing is often referred to as &lsquo;profiling&rsquo;.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >16.2 Consent and Legitimate Interest:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >We only send you marketing communications if we have your explicit consent or a &lsquo;legitimate interest&rsquo;. A legitimate interest means a business or commercial reason that justifies the use of your information, without unfairly impacting your own interests.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >16.3 Your Marketing Preferences:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >The marketing material you receive, be it online, in apps, via email, mobile phone, post, or through other digital channels, is dependent on your marketing preferences.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >You have the right to alter these preferences at any time or to opt out of marketing communications entirely.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >If you choose not to have your data collected via our websites or mobile apps, you may still see marketing from us, but it won&rsquo;t be specifically tailored to you. Our Cookies Policy details how this data is used.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >16.4 Receiving Essential Information:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >Regardless of your marketing preferences, you will continue to receive essential information, such as statements and updates about changes to your existing products and services.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >16.5 Protection of Your Personal Information:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >We do not sell your personal information to outside organisations.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >16.6 Updating Your Preferences:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >We may request you to confirm or update your marketing preferences in certain scenarios, such as when you acquire new products or services from us, or if there are changes in laws, regulations, or our business structure.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >You can update your choices anytime by contacting us.</span></p>
    </li>
</ul>
<h1 id='section17'><strong><span >17. How We Use Your Information to Make Automated Decisions</span></strong></h1>
<h2 style="text-align: justify;"><span >Understanding Automated Decision-Making and Your Rights</span></h2>
<p style="text-align: justify;"><span >This section explains how we use automated systems to make decisions based on your personal information. These decisions can significantly impact the products, services, or features we offer you, including their pricing.</span></p>
<h2 style="text-align: justify;"><span >17.1 Automated Decision-Making Processes:</span></h2>
<ol>
    <li >
        <p style="text-align: justify;"><strong><span >Pricing</span></strong><span >: Automated systems help determine prices for some products and services. For example, online mortgage calculators use your financial details to estimate mortgage offers, and insurance applications may use data to assess claim likelihood.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Tailoring Products, Services, and Marketing</span></strong><span >: We categorise customers into segments to better understand and meet needs, influencing our product and service design. This includes using segments from external partners, like social networks, to identify potential new customers with similar interests.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Detecting Fraud</span></strong><span >: We use automated systems to identify unusual activities that may indicate fraud or money-laundering in personal or business accounts.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Opening Accounts</span></strong><span >: When opening an account, automated checks ensure the product or service is suitable and that you meet the necessary criteria, such as age, residency, nationality, or financial standing.</span></p>
    </li>
</ol>
<h2 style="text-align: justify;"><span >17.2 Your Rights Regarding Automated Decisions:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Right to Challenge</span></strong><span >: You have the right to object to decisions made by automated systems. You can request that a person reviews an automated decision to ensure fairness and accuracy.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Further Information</span></strong><span >: For more information about these processes or to exercise your rights, please contact us using the methods outlined in our Privacy Policy.</span></p>
    </li>
</ul>
<h1 id='section18' style="text-align: justify;"><strong><span >18. Credit Reference Agencies (CRAs)</span></strong></h1>
<h2 style="text-align: justify;"><span >How We Use CRAs for Credit and Identity Checks</span></h2>
<p style="text-align: justify;"><span >This section explains our collaboration with Credit Reference Agencies (CRAs) to conduct credit and identity checks, essential for decisions about lending through products like credit cards or loans.</span></p>
<h2 style="text-align: justify;"><span >18.1 Credit and Identity Checks:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >When you apply for a product or service, we perform checks on your creditworthiness and verify your identity. CRAs assist us in this process.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >We also periodically review CRA information to manage your account effectively.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >18.2 Data Exchange with CRAs:</span></h2>
<p style="text-align: justify;"><span >We share your personal information with CRAs, and they provide us with information about you. The shared data includes:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><span >Name, address, and date of birth.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Credit application details.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Information on shared credit.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Financial situation and history.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Fraud prevention information.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Public information from sources like the Electoral Register, Companies House, media, and social networks.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >18.3 Use of CRA Data:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >We use this data to assess creditworthiness and affordability, verify accuracy, detect and prevent financial crime, manage accounts, recover debts, and ensure offers are appropriate for you.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Your information is shared with CRAs as long as you remain a customer, including account balance and transaction details.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >18.4 Linked Records:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >If you apply for a product jointly with someone else or have a financial association (like a spouse, partner, or business associate), we link your records with theirs.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >It&rsquo;s important to inform these individuals that their records will be linked and subject to credit searches.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >CRAs also link your records, which remain linked unless you or the other individual request and provide proof to break the link.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >18.5 Further Information from CRAs:</span></h2>
<p style="text-align: justify;"><span >More detailed information about CRAs can be found in the Credit Reference Agency Information Notice on their websites. This includes:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><span >CRA identity and role.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Their function as fraud prevention agencies.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >The data they hold and its usage.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >How they share personal information.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Data retention periods.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Your rights concerning data protection.</span></p>
    </li>
</ul>
<h1 id='section19' style="text-align: justify;"><strong><span >19. Fraud Prevention Agencies (FPAs)</span></strong></h1>
<h2 style="text-align: justify;"><span >Collaboration in Fighting Financial Crime</span></h2>
<p style="text-align: justify;"><span >This section describes how we collaborate with external organisations, including Fraud Prevention Agencies (FPAs), to combat financial crimes like fraud, money laundering, and terrorist financing.</span></p>
<h2 style="text-align: justify;"><span >19.1 Identity Confirmation and Fraud Checks:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >We conduct identity verification and fraud checks before providing products or services. This is crucial for ensuring the security and integrity of our transactions.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Once you are a customer, we continue to share information as necessary to combat fraud and other financial crimes.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >19.2 Organisations We Share Data With:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >We exchange data with Registered FPAs, other similar agencies, industry databases, and insurers for fraud prevention purposes.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >The mutual exchange of data helps us and these organisations in preventing, detecting, investigating, and prosecuting financial crimes.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >19.3 Reasons for Using Your Information:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >The use of your personal information is based on legal obligations or &lsquo;legitimate interests&rsquo;. We will specify the legitimate interests when they are the basis for processing your data.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >The purposes include confirming identities, preventing fraud and money laundering, and fulfilling contracts.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >19.4 Law Enforcement Access:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >In certain circumstances, we or an FPA may allow law enforcement agencies to access your personal information to aid in their crime prevention duties.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Different organisations may retain personal information for varied durations, up to a maximum of six years.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >19.5 Types of Information Used:</span></h2>
<p style="text-align: justify;"><span >The personal information used includes but is not limited to:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><span >Name, date of birth, addresses (current and historical), contact details, financial data, fraud victim status, data related to products or services, employment and vehicle details, and online identifiers like IP addresses.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >19.6 Automated Decisions in Fraud Prevention:</span></h2>
<p style="text-align: justify;"><span >We and other organisations may use automated systems to detect fraud. These systems analyse patterns in data to identify activities typical of fraudsters or unusual for you or your business.</span></p>
<h2 style="text-align: justify;"><span >19.7 Impact of Fraud Decisions:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >If fraud is suspected, actions may include freezing activity on accounts or blocking access.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >FPAs may keep records of fraud risks, which could influence other organisations&apos; decisions to offer you services or employment.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >19.8 International Data Transfers:</span></h2>
<p style="text-align: justify;"><span >FPAs may transfer data outside the UK and EEA. In such cases, contracts ensure that the data is protected to EEA standards, including adherence to international data sharing frameworks.</span></p>
<h2 style="text-align: justify;"><span >19.9 FPA Information Notice:</span></h2>
<p style="text-align: justify;"><span >For more details on how FPAs operate, please visit the CIFAS information notice page:&nbsp;</span><a href="https://www.cifas.org.uk/fpn"><u><span >CIFAS - Fraud Prevention Agency Information Notice</span></u></a></p>
<h1 id='section20' style="text-align: justify;"><strong><span >20. Sending Data Outside the UK and EEA</span></strong></h1>
<h2 style="text-align: justify;"><span >Safeguards for International Data Transfers</span></h2>
<p style="text-align: justify;"><span >This section details the measures we take to protect your personal information when it is transferred outside the UK and the European Economic Area (EEA).</span></p>
<h2 style="text-align: justify;"><span >20.1 Circumstances for Data Transfer:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Following Your Instructions</span></strong><span >: For instance, if you use a card to book a hotel abroad, we transfer necessary details to fulfil the payment.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Legal Compliance</span></strong><span >: We share information as required by law, such as reporting to foreign tax authorities.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Working with International Suppliers</span></strong><span >: To manage your accounts and services, we might need to transfer data to suppliers outside the UK and EEA.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >20.2 Safeguards for Protection:</span></h2>
<p style="text-align: justify;"><span >We ensure that your data is protected to the same standards as within the UK and EEA. This is achieved through:</span></p>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Adequacy Decisions</span></strong><span >: Transferring data to countries deemed by the European Commission to have adequate data protection laws. More information is available on the&nbsp;</span><a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"><u><span >European Commission Justice website</span></u></a><span >.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Standard Contractual Clauses</span></strong><span >: Implementing contracts with recipients that obligate them to protect data to UK and EEA standards. Further details can be found on the&nbsp;</span><a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"><u><span >European Commission Justice website</span></u></a><span >.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >20.3 Restriction on Marketing Data Transfer:</span></h2>
<p style="text-align: justify;"><strong><span >Blukite&rsquo;s Policy</span></strong><span >: It is important to note that Blukite does not transfer personal data outside of the UK for marketing purposes. This policy is in place to ensure greater control and protection of your personal data.</span></p>
<h1 id='section21' style="text-align: justify;"><strong><span >21. Data Security Measures</span></strong></h1>
<h2 style="text-align: justify;"><span >Protecting Your Personal Information</span></h2>
<p style="text-align: justify;"><span >At Blukite, we understand the importance of securing your personal data against unauthorised access, alteration, and loss. We implement a range of technical and organisational measures to ensure the highest level of data protection.</span></p>
<h2 style="text-align: justify;"><span >21.1 Our Security Practices:</span></h2>
<ol>
    <li >
        <p style="text-align: justify;"><strong><span >Encryption</span></strong><span >: We use advanced encryption technologies to protect data during transmission and while stored on our systems.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Access Control</span></strong><span >: Access to personal data is strictly controlled. Only authorised personnel have access to sensitive information, and only for designated purposes.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Regular Security Audits</span></strong><span >: We conduct regular audits and reviews of our security measures to ensure they are up to date and effective against emerging threats.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Secure Data Storage</span></strong><span >: Personal information is stored in secure facilities with restricted physical access. Online data is housed in servers with robust cybersecurity protections.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Data Processing Protocols</span></strong><span >: Our data processing protocols are designed to safeguard data integrity and prevent unauthorised access or leakage.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Employee Training</span></strong><span >: Staff receive regular training on data protection and security protocols, emphasising the importance of confidentiality and handling data responsibly.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Incident Response Plan</span></strong><span >: We have a comprehensive incident response plan to address any potential data breaches quickly and effectively, minimising any potential impact on your data.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Compliance with Standards</span></strong><span >: We adhere to internationally recognised standards and frameworks for data security, ensuring our practices meet global benchmarks.</span></p>
    </li>
</ol>
<h2 style="text-align: justify;"><span >21.2 Commitment to Data Security:</span></h2>
<p style="text-align: justify;"><span >Our commitment to data security is ongoing. We continually monitor and update our security practices in response to new threats and advancements in technology. Protecting your personal information is a responsibility we take very seriously, and we are dedicated to maintaining the confidentiality, integrity, and availability of your data.</span></p>
<h1 id='section22' style="text-align: justify;"><strong><span >22. Third-Party Links</span></strong></h1>
<h2 style="text-align: justify;"><span >Disclaimer for External Links</span></h2>
<p style="text-align: justify;"><span >Our website and services may include links to third-party websites, applications, and platforms that are not operated or controlled by Blukite. This section addresses our position and disclaimers regarding these external links.</span></p>
<h2 style="text-align: justify;"><span >22.1 Understanding Third-Party Links:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >When you follow a link to an external website, you are leaving our site and are subject to the privacy policy and security practices of that external website.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >These third-party sites are not under our control, and we are not responsible for their content, privacy policies, or security practices.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >22.2 Our Recommendations:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >We recommend that you review the privacy policies of these third-party websites to understand how they collect, use, and share your personal information.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >Be cautious when providing personal information on any external site, especially if the privacy practices are unclear or seem inadequate.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >22.3 Disclaimer:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >Blukite does not endorse, guarantee, or make any representations regarding the accuracy, reliability, or any use of information on third-party websites.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >The inclusion of a third-party link on our website or services does not imply an affiliation, sponsorship, endorsement, approval, investigation, verification, or monitoring by Blukite of any information contained in the linked website.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >22.4 User Responsibility:</span></h2>
<p style="text-align: justify;"><span >Your use of any third-party sites and their available services is at your own risk. We encourage users to be aware when they leave our site and to read the privacy statements of other sites that collect personally identifiable information.</span></p>
<h1 id='section23' style="text-align: justify;"><strong><span >23. User Rights in Detail</span></strong></h1>
<h2 style="text-align: justify;"><span >Your Rights Under Data Protection Laws</span></h2>
<p style="text-align: justify;"><span >Our commitment to your privacy includes ensuring you are fully aware of your rights under data protection laws, such as the GDPR and the UK Data Protection Act. Below is a detailed overview of these rights and how you can exercise them.</span></p>
<ol>
    <li >
        <p style="text-align: justify;"><strong><span >Right to Access</span></strong><span >: You have the right to request access to the personal information we hold about you. This includes the right to obtain confirmation of whether or not personal data concerning you is being processed.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Right to Rectification</span></strong><span >: If the information we hold about you is inaccurate or incomplete, you have the right to request that we correct or complete it.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Right to Erasure (&lsquo;Right to be Forgotten&rsquo;)</span></strong><span >: You can ask us to delete or remove your personal information in certain circumstances, such as when it is no longer necessary for the purpose for which it was collected.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Right to Restrict Processing</span></strong><span >: Under certain conditions, you have the right to restrict the processing of your personal data. For instance, if you contest the accuracy of your data, you can request a restriction on processing until verification.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Right to Data Portability</span></strong><span >: This right allows you to obtain and reuse your personal data for your own purposes across different services. It applies to data you have provided, where the processing is based on consent or a contract and is carried out by automated means.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Right to Object</span></strong><span >: You have the right to object to the processing of your personal data based on legitimate interests, direct marketing (including profiling), and processing for scientific or historical research and statistics.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Rights in Relation to Automated Decision Making and Profiling</span></strong><span >: You have the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or similarly significantly affects you.</span></p>
    </li>
</ol>
<h2 style="text-align: justify;"><span >23.1 How to Exercise Your Rights:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><span >To exercise any of these rights, please contact us at&nbsp;</span><a href="mailto:contact@blukite.net"><u><span >contact@blukite.net</span></u></a><span >&nbsp;</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><span >We will respond to your request in accordance with applicable data protection laws and within the legally required time frames.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >23.2 Further Assistance:</span></h2>
<p style="text-align: justify;"><span >If you have any questions or need assistance regarding your rights, our Data Protection Officer can be contacted at&nbsp;</span><a href="mailto:contact@blukite.finance"><u><span >contact@blukite.finance</span></u></a></p>
<h1 id='section24' style="text-align: justify;"><strong><span >24. Policy Updates and Changes</span></strong></h1>
<h2 style="text-align: justify;"><span >Keeping You Informed About Changes</span></h2>
<p style="text-align: justify;"><span >At Blukite, we periodically review and update our Privacy Policy to ensure it remains up to date with legal requirements, reflects changes in our data practices, or incorporates feedback from our users and stakeholders.</span></p>
<h2 style="text-align: justify;"><span >24.1 Notification of Changes:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Proactive Communication</span></strong><span >: When we make changes to our Privacy Policy, we will proactively inform you. This may be done through email notifications, alerts on our website, in-app messages, or other communication channels we use to engage with you.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Publication on Website</span></strong><span >: The updated Privacy Policy will always be published on our website, allowing you to review the changes at your convenience.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >24.2 Reviewing Changes:</span></h2>
<ul>
    <li >
        <p style="text-align: justify;"><strong><span >Highlighting Key Changes</span></strong><span >: In our communications, we will highlight key changes to the policy, especially those that might significantly impact the way we handle your personal information.</span></p>
    </li>
    <li >
        <p style="text-align: justify;"><strong><span >Effective Date</span></strong><span >: The date when the updated policy comes into effect will be clearly stated. We encourage you to review the policy as of this date to understand the current terms.</span></p>
    </li>
</ul>
<h2 style="text-align: justify;"><span >24.3 Your Acceptance of Changes:</span></h2>
<p style="text-align: justify;"><strong><span >Continued Use</span></strong><span >: Your continued use of our services after the effective date of these changes constitutes acceptance of the updated Privacy Policy. If you do not agree to the changes, you have the option to discontinue using our services.</span></p>
<h2 style="text-align: justify;"><span >24.4 Questions and Concerns:</span></h2>
<p style="text-align: justify;"><span >Should you have any questions or concerns about any changes made to our Privacy Policy, please do not hesitate to contact us. We are committed to addressing your queries and providing clarity on how these changes might affect you.</span></p>
<p style="text-align: center;"><span >&nbsp;</span></p>`