import React, { useEffect, useState, useRef } from 'react';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import Speaker from '../../components/speaker/speaker';

const MarketingAndCustomerAcquisition = () => {

    return (
        <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage productDevelopmentAndMarketAnalysis">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'UK Market',
                        }
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Overview'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'UK Market',
                        }
                    ]}
                />
            </div>

            <div className="content cardIssuingAndFulfilment">
                <section id="section1">
                    <h2 className='pageTitle'>Marketing & Customer Acquisition</h2>
                    <p className='text'>
                    <Speaker id="93" text="Marketing & Customer Acquisition. In the dynamic landscape of financial services, effective marketing and customer acquisition strategies are critical for growth and sustainability. Blukite Finance offers a
                        specialised suite of services designed to empower businesses in the United Kingdom with cutting-edge marketing solutions and customer acquisition techniques. Currently
                        exclusive to the UK market, our services are tailored to navigate the unique challenges and opportunities within this region, ensuring our clients achieve optimal results."/>
                        In the dynamic landscape of financial services, effective marketing and customer acquisition strategies are critical for growth and sustainability. Blukite Finance offers a
                        specialised suite of services designed to empower businesses in the United Kingdom with cutting-edge marketing solutions and customer acquisition techniques. Currently
                        exclusive to the UK market, our services are tailored to navigate the unique challenges and opportunities within this region, ensuring our clients achieve optimal results.
                    </p>
                </section>

                <section id="section2" className='my-5 mb-20'>
                    <p className='subtitle font-normal mt-10 lg:mt-20 italic text-center'>Our Comprehensive Approach Includes:</p>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-0 sm:gap-10 lg:gap-20 relative my-10 text-left sm:text-center'>
                        <img className='hidden xl:block absolute top-10 w-9/12 -z-10  left-1/2 transform -translate-x-1/2' src={`${baseURL}/images/ico/WhiteLabelConsumerApp/dashedLine.svg`} alt="dashed arrow illustration" />

                        <div className='flex flex-row sm:flex-col items-center'>
                            <img src={`${baseURL}/images/ico/MarketingAndCustomerAcquisition/1.png`} className='bg-white p-5 w-28 sm:w-32' alt="icon for Targeted Marketing Strategies" />
                            <div className=''>
                                <p className='font-normal mt-3'>Targeted Marketing Strategies</p>
                                <p className='mt-1 leading-tight'><Speaker id="94" text="Targeted Marketing Strategies. Utilising data-driven insights to
                                    develop and implement marketing
                                    campaigns that resonate with your
                                    ideal customer base."/>Utilising data-driven insights to
                                    develop and implement marketing
                                    campaigns that resonate with your
                                    ideal customer base.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center'>
                            <img src={`${baseURL}/images/ico/MarketingAndCustomerAcquisition/2.png`} className='bg-white p-5 w-28 sm:w-32' alt="icon for Digital Presence Optimisation" />
                            <div className=''>
                                <p className='font-normal mt-3'>Digital Presence Optimisation</p>
                                <p className='mt-1 leading-tight'><Speaker id="95" text="Digital Presence Optimisation. Enhancing your online visibility
                                    through SEO, content marketing, and
                                    social media engagement, tailored
                                    to the UK's competitive digital
                                    landscape."/>Enhancing your online visibility
                                    through SEO, content marketing, and
                                    social media engagement, tailored
                                    to the UK's competitive digital
                                    landscape.</p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center'>
                            <img src={`${baseURL}/images/ico/MarketingAndCustomerAcquisition/3.png`} className='bg-white p-5 w-28 sm:w-32' alt="icon for CRM Solutions" />
                            <div className=''>
                                <p className='font-normal mt-3'>CRM Solutions</p>
                                <p className='mt-1 leading-tight'>
                                    <Speaker id="96" text="CRM Solutions. CRM Solutions"/>
                                    Deploying advanced Customer
                                    Relationship Management (CRM)
                                    tools to nurture leads, improve
                                    customer retention, and increase
                                    lifetime value.
                                </p>
                            </div>
                        </div>

                        <div className='flex flex-row sm:flex-col items-center'>
                            <img src={`${baseURL}/images/ico/MarketingAndCustomerAcquisition/4.png`} className='bg-white p-5 w-28' alt="icon for Regulatory Compliance " />
                            <div className=''>
                                <p className='font-normal mt-3'>Regulatory Compliance </p>
                                <p className='mt-1 leading-tight'> <Speaker id="97" text="Regulatory Compliance. nsuring that all marketing activities
                                    not only are effective but also
                                    comply with the UK's stringent
                                    advertising and consumer
                                    protection laws."/>Ensuring that all marketing activities
                                    not only are effective but also
                                    comply with the UK's stringent
                                    advertising and consumer
                                    protection laws.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="section2" className=''>
                    <div className='my-10 2xl:my-32 w-full flex flex-col xl:flex-row gap-5 sm:gap-10 xl:gap-20 items-center'>
                        <div className='flex-1 relative mt-5 sm:mt-0'>
                        <div data-aos="fade-down" data-aos-duration="700">
                            <img className='w-20 sm:w-auto absolute left-1/2 transform -translate-x-1/2 -top-16 sm:-top-20' src={`${baseURL}/images/MarketingAndCustomerAcquisition/map_focus.svg`} alt="marker on the map focused on the UK"/>
                        </div>
                            <img className='w-full' src={`${baseURL}/images/MarketingAndCustomerAcquisition/world_map.svg`} alt="image of the world map" />
                            </div>
                        <div className='flex-1'>
                            <p className='subtitle font-normal italic'>Why Focus on the UK Market?</p>
                            <p className='mt-5 text w-full'>
                                <Speaker id="98" text="Why Focus on the UK Market? Focusing our Marketing & Customer Acquisition services in the UK capitalises on our
                                expertise in local market dynamics and regulations, enabling us to provide:
                                Localised Expertise
                                Our team's in-depth knowledge of the UK market allows us to craft marketing strategies that are highly relevant and effective.
                                Compliance Assurance
                                With the UK's specific regulatory requirements in mind, we ensure that all marketing efforts meet the highest standards of compliance.
                                Customised Solutions. We offer customised solutions that address unique challenges and
                                capitalise on local opportunities for the UK-based businesses.
                                "/>
                                
                                Focusing our Marketing & Customer Acquisition services in the UK capitalises on our
                                expertise in local market dynamics and regulations, enabling us to provide:</p>

                            <div className='mt-5 flex flex-col gap-5'>
                                <div className='py-3 px-7 rounded-xl customShadow flex gap-6' data-aos="fade-up" data-aos-duration="500">
                                    <img src={`${baseURL}/images/ico/MarketingAndCustomerAcquisition/5.svg`} className='w-10 flex-none' alt="icon for Localised Expertise" />
                                    <div>
                                        <p className='font-normal'>Localised Expertise</p>
                                        <p className='mt-1'>
                                            {/* <Speaker id="99" text="Localised Expertise. Our team's in-depth knowledge of the UK market allows us to craft
                                            marketing strategies that are highly relevant and effective."/> */}
                                            Our team's in-depth knowledge of the UK market allows us to craft
                                            marketing strategies that are highly relevant and effective.</p>
                                    </div>
                                </div>

                                <div className='py-3 px-7 rounded-xl customShadow flex gap-6 items-center'  data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
                                    <div className='w-10 flex-none h-min'>
                                        <img src={`${baseURL}/images/ico/MarketingAndCustomerAcquisition/6.svg`} className='w-8 mx-auto' alt="icon for Compliance Assurance" />
                                    </div>
                                    <div>
                                        <p className='font-normal'>Compliance Assurance</p>
                                        <p className='mt-1'>
                                            {/* <Speaker id="100" text="Compliance Assurance. With the UK's specific regulatory requirements in mind, we ensure
                                            that all marketing efforts meet the highest standards of compliance."/> */}
                                            With the UK's specific regulatory requirements in mind, we ensure
                                            that all marketing efforts meet the highest standards of compliance.</p>
                                    </div>
                                </div>

                                <div className='py-3 px-7 rounded-xl customShadow flex gap-6' data-aos="fade-up" data-aos-duration="500" data-aos-delay="600">
                                    <img src={`${baseURL}/images/ico/MarketingAndCustomerAcquisition/7.svg`} className='w-10 flex-none' alt="icon for Customised Solutions" />
                                    <div>
                                        <p className='font-normal'>Customised Solutions</p>
                                        <p className='mt-1'>
                                            {/* <Speaker id="101" text="Customised Solutions. We offer customised solutions that address unique challenges and
                                            capitalise on local opportunities for the UK-based businesses."/> */}
                                            We offer customised solutions that address unique challenges and
                                            capitalise on local opportunities for the UK-based businesses.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='bg-lightBlue p-5 lg:p-16 flex-col mb-20 my-0 sm:my-20 items-center gap-5 lg:gap-10 flex xl:flex-row rounded' data-aos="fade-up">
            <div className=' uppercase italic font-normal text-xl lg:text-3xl text-center xl:text-left'>Global Marketing Expansion</div>
            <div className='flex'>
              <img className='w-8 lg:w-14 self-start' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
              <div className=''>
                <p className='font-light text-base lg:text-lg lg:text-xl py-2 lg:py-5 ml-5'><Speaker id="102" text="GLOBAL MARKETING EXPANSION. As Blukite Finance continues to grow and evolve, we are actively exploring opportunities to expand our Marketing & Customer Acquisition services beyond the UK. Our goal is to bring our proven strategies and success to businesses in other regions, adapting our approach to meet local needs and regulations."/>As Blukite Finance continues to grow and evolve, we are actively exploring opportunities to expand our Marketing & Customer Acquisition services beyond the UK. Our goal is to bring our proven strategies and success to businesses in other regions, adapting our approach to meet local needs and regulations.</p>
              </div>
              <img className='w-8 lg:w-14 rotate-180 self-end ml-5' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
            </div>
          </div>
          
                </section>

            </div>
        </div>
    );
}

export default MarketingAndCustomerAcquisition 