import React, { useEffect, useState } from 'react';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import { useLocation } from 'react-router-dom';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';
import './tailoredServices.scss'

const LiquidityProvidersCreditSolutions = () => {

  const location = useLocation();

  useEffect(() => {
    const scrollToHash = () => {
      const hash = location.hash;
      if (hash) {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    scrollToHash();
  }, [location.hash]);

  const productOfferingsSlides = [
    {
      title: "Strategic Risk Management in Consumer Credit",
      description: `Blukite Finance employs sophisticated risk management 
        techniques to categorise borrower profiles and ensure a balanced 
        investment portfolio for underwriters. Utilising advanced data
         analytics and credit scoring technologies, Blukite meticulously 
        assesses each borrower's risk level, enabling precise segmentation.
         This approach allows for the strategic allocation of capital across 
        various risk categories, optimising the balance between risk and 
        return. By leveraging this data-driven methodology, Blukite not only
         enhances portfolio performance but also provides underwriters with the confidence that their investments are managed 
        with precision and insight into borrower creditworthiness.`,
      id: 1,
    },
    {
      title: "Regulatory Umbrella Program: Secure Investment Framework",
      descriptionSmall: `The Regulatory Umbrella Program by Blukite Finance offers underwriters a unique advantage in the consumer credit investment 
     landscape. This program provides a secure and compliant framework
      that mitigates regulatory risks, making it easier for investors to 
     navigate the complex regulations governing consumer lending. By 
     leveraging this program, underwriters can confidently invest in
      consumer credit, knowing that Blukite Finance's expertise and 
     infrastructure are in place to ensure compliance with all applicable 
     laws and regulations, thereby safeguarding investments and 
     optimising returns within a regulatory-compliant environment. Under the Regulatory Umbrella Program, Blukite Finance offers 
     services including regulatory compliance assistance, facilitating 
     access to consumer credit markets under Blukite's licenses, and 
     providing a comprehensive risk management framework.`,
      description: `The Regulatory Umbrella Program by Blukite Finance offers underwriters a unique advantage in the consumer credit investment 
       landscape. This program provides a secure and compliant framework
        that mitigates regulatory risks, making it easier for investors to 
       navigate the complex regulations governing consumer lending. By 
       leveraging this program, underwriters can confidently invest in
        consumer credit, knowing that Blukite Finance's expertise and 
       infrastructure are in place to ensure compliance with all applicable 
       laws and regulations, thereby safeguarding investments and 
       optimising returns within a regulatory-compliant environment.
       Under the Regulatory Umbrella Program, Blukite Finance offers 
       services including regulatory compliance assistance, facilitating 
       access to consumer credit markets under Blukite's licenses, and 
       providing a comprehensive risk management framework. The 
       program also includes support for navigating regulatory changes, 
       ensuring investments remain compliant with evolving financial 
       regulations. This suite of services allows underwriters to focus
        on optimising their investment strategies while Blukite handles the
        complexities of regulatory compliance and risk assessment, 
       ensuring a secure and efficient investment environment.`,
      id: 2,
    },
    {
      title: "Strategic Returns Management for Underwriters",
      description: `Blukite Finance's approach to optimising returns for underwriters involves a strategic blend of diversifying lending products 
        and implementing dynamic interest rate models. This method 
        ensures a robust portfolio that can adapt to market changes, 
        enhancing yield potential while managing risk. Diversification across
         consumer credit products broadens the investment base, mitigating
         risk and leveraging market opportunities. Simultaneously, dynamic 
        interest rate models adjust to economic indicators, optimising 
        profitability across various lending scenarios, ensuring underwriters can achieve the best possible returns on their investments.`,
      id: 3,
    }
  ]

  const [currentProductOfferIndex, setCurrentProductOfferIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // swipes
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {

    if (touchStart - touchEnd > 150) {
      handleNext();
    }

    if (touchStart - touchEnd < -150) {
      handlePrevious();
    }
  }

  const handleNext = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);

    const currentSlide = document.getElementById(`slide-${currentProductOfferIndex}`);
    currentSlide.classList.add('moving-out');

    setTimeout(() => {
      setCurrentProductOfferIndex((currentProductOfferIndex + 1) % productOfferingsSlides.length);
      updateSlides(true);
      setIsTransitioning(false);
    }, 400); // Match this timeout to the CSS transition duration
  };

  const handlePrevious = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);

    setCurrentProductOfferIndex(currentProductOfferIndex === 0 ? productOfferingsSlides.length - 1 : currentProductOfferIndex - 1);
    updateSlides(false);

    const currentSlide = document.getElementById(`slide-${currentProductOfferIndex}`);
    currentSlide.classList.add('moving-in');
    setIsTransitioning(false);
  };

  function applyStyles(currentProductOfferIndex, isNext) {
    let styles = [
      'transform: translateX(0%) scale(1);',
      'transform: translateX(100%) scale(1);',
      'transform: translateX(200%) scale(1);',
    ];

    for (let i = 0; i < styles.length; i++) {
      let slideIndex = (currentProductOfferIndex + i) % styles.length;
      let slideElement = document.getElementById(`slide-${slideIndex}`);
      slideElement.style = styles[i];

      if (isNext) {
        if (i !== styles.length - 1) {
          slideElement.classList.add('slide');
        } else {
          slideElement.classList.remove('slide');
        }

      } else {
        if (slideIndex !== 0) {
          slideElement.classList.add('slide');
        } else {
          slideElement.classList.remove('slide');
        }
      }
    }
  }

  const updateSlides = (isNext) => {
    productOfferingsSlides.forEach((slide, index) => {
      applyStyles(currentProductOfferIndex, isNext)
    });
  };

  useEffect(() => {
    updateSlides(true);
  }, [currentProductOfferIndex]);




  return (
    <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage liquidityProvidersCreditSolutions">
      <div className="hidden sm:block sidebar">
        <Anchor
          targetOffset={150}
          items={[
            {
              key: 'section1',
              href: '#section1',
              title: 'Overview'
            },
            {
              key: 'section2',
              href: '#section2',
              title: 'Comprehensive Solutions',
            }
          ]}
        />
      </div>

      <div className='block sm:hidden sidebar'>
        <Anchor
          direction="horizontal"
          targetOffset={150}
          items={[
            {
              key: 'section1',
              href: '#section1',
              title: 'Overview'
            },
            {
              key: 'section2',
              href: '#section2',
              title: 'Comprehensive Solutions',
            }
          ]}
        />
      </div>

      <div className="content">
        <section id="section1">
          <h2 className='pageTitle'>Optimising Returns for Underwriters: Credit Solutions by Blukite Finance</h2>
          <p className='text'>
          <Speaker id="205" text="Optimising Returns for Underwriters: Credit Solutions by Blukite Finance. Blukite Finance is uniquely positioned to guide Underwriters towards maximising returns through strategically crafted credit solutions. Our technology suite, specifically tailored to
            address the dynamic requirements of the investment sector, includes powerful API integrations, robust regulatory compliance frameworks, and enhanced risk assessment mechanisms.
            Partnering with Blukite offers Underwriters the opportunity to accelerate capital deployment, improve risk-adjusted returns, and capitalise on the growing demand for consumer
            credit, all within a secure and compliant investment environment."/>
            Blukite Finance is uniquely positioned to guide Underwriters towards maximising returns through strategically crafted credit solutions. Our technology suite, specifically tailored to
            address the dynamic requirements of the investment sector, includes powerful API integrations, robust regulatory compliance frameworks, and enhanced risk assessment mechanisms.
            Partnering with Blukite offers Underwriters the opportunity to accelerate capital deployment, improve risk-adjusted returns, and capitalise on the growing demand for consumer
            credit, all within a secure and compliant investment environment.</p>
          <p className='subtitle italic font-normal mt-10'>Leveraging Consumer Credit for High Returns</p>

          <div className='flex flex-col xl:flex-row gap-5 xl:gap-10 mt-10'>

            <div className='flex-1 flex flex-col lg:flex-row xl:flex-col gap-5 xl:gap-0 items-center'>
              <div className='rounded-xl min-w-full sm:min-w-44 xl:min-w-full h-40 xl:h-60 2xl:h-80 bg-lightBlue relative overflow-hidden'>
                <img data-aos="fade-up" data-aos-offset="0" data-aos-duration="700" className='absolute bottom-0 left-5 w-7/12 sm:w-10/12' src={`${basePath}/images/LiquidityProvidersCreditSolutions/1.svg`} alt="illustration laptop with arrow" />
              </div>
              <p className='mt-0 xl:mt-7'>
              <Speaker id="206" text="In the current financial climate, consumer credit emerges as a sector brimming with potential for high returns. Underwriters can capitalise on this by focusing on the sector's robust growth, driven by a rise in consumer spending and the popularity of flexible credit options. The segment offers attractive interest margins, often outperforming traditional investment avenues, while also presenting a favourable risk-reward ratio due to lower default rates and efficient capital allocation. "/>
                In the current financial climate, consumer credit emerges as a sector brimming with potential for high returns. Underwriters can capitalise on this by focusing on the sector's robust growth, driven by a rise in consumer spending and the popularity of flexible credit options. The segment offers attractive interest margins, often outperforming traditional investment avenues, while also presenting a favourable risk-reward ratio due to lower default rates and efficient capital allocation. </p>
            </div>

            <div className='flex-1 flex flex-col lg:flex-row xl:flex-col gap-5 xl:gap-0 items-center'>
              <div className='rounded-xl min-w-full sm:min-w-44 xl:min-w-full h-40 xl:h-60 2xl:h-80 bg-lightBlue relative overflow-hidden'>
                <img data-aos="fade-up-right" data-aos-offset="0" data-aos-duration="700" className='absolute bottom-0 left-0 w-7/12 sm:w-10/12 xl:w-auto' src={`${basePath}/images/LiquidityProvidersCreditSolutions/2.svg`} alt="illustration laptop with arrow" />
              </div>
              <p className='mt-0 xl:mt-7'>
                <Speaker id="207" text="Furthermore, integrating consumer credit into an investment portfolio allows for diversification, spreading risk, and tapping into new revenue streams. Blukite Finance provides the tools and insights to navigate this lucrative space, ensuring investments align with modern compliance and market trends. Investing in consumer credit through Blukite Finance unlocks attractive interest margins, lower capital requirements, and strategic diversification benefits."/>
                Furthermore, integrating consumer credit into an investment portfolio allows for diversification, spreading risk, and tapping into new revenue streams. Blukite Finance provides the tools and insights to navigate this lucrative space, ensuring investments align with modern compliance and market trends. Investing in consumer credit through Blukite Finance unlocks attractive interest margins, lower capital requirements, and strategic diversification benefits.</p>
            </div>

            <div className='flex-1 flex flex-col lg:flex-row xl:flex-col gap-5 xl:gap-0 items-center'>
              <div className='rounded-xl min-w-full sm:min-w-44 xl:min-w-full h-40 xl:h-60 2xl:h-80 bg-lightBlue relative overflow-hidden'>
                <img data-aos="fade-up" data-aos-offset="0" data-aos-duration="700" className='absolute left-28 sm:left-1/4 bottom-5 w-4/12 sm:w-7/12' src={`${basePath}/images/LiquidityProvidersCreditSolutions/3.svg`} alt="illustration laptop with arrow" />
              </div>
              <p className='mt-0 xl:mt-7'>
                <Speaker id="208" text="This sector not only demonstrates robust growth but also offers underwriters the chance to optimise returns through carefully managed risk profiles. Leveraging Blukite's sophisticated data analytics and credit scoring technologies ensures a balanced portfolio, enhancing the investment's overall value proposition."/>
                This sector not only demonstrates robust growth but also offers underwriters the chance to optimise returns through carefully managed risk profiles. Leveraging Blukite's sophisticated data analytics and credit scoring technologies ensures a balanced portfolio, enhancing the investment's overall value proposition.</p>
            </div>

          </div>

          <div className='bg-lightBlue p-5 lg:p-16 flex-col mb-0 sm:mb-10 my-10 sm:my-20 items-center gap-5 lg:gap-10 flex xl:flex-row rounded' data-aos="fade-up">
            <div className=' flex-col gap-5 lg:gap-10 flex xl:flex-row items-center'>
              <div className='max-w-full w-full mx-auto xl:max-w-64 uppercase italic font-normal text-xl lg:text-3xl text-center xl:text-left'>Maximise
                Your Impact
                with Blukite</div>

              <div className='flex'>
                <img className='w-8 lg:w-14 self-start' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
                <p className='font-light text-base lg:text-lg ml-5 lg:leading-tight'>
                <Speaker id="209" text="MAXIMISE YOUR IMPACT WITH BLUKITE. Blukite Finance invites underwriters to explore the comprehensive investment opportunities and partnership benefits within our ecosystem. We encourage you to reach out and discover how our tailored solutions can significantly enhance your investment impact, offering detailed insights into the lucrative consumer credit market. Join us in unlocking the full potential of your investments, leveraging our strategic expertise and innovative platform to achieve optimal returns."/>
                  Blukite Finance invites underwriters to explore the comprehensive investment opportunities and partnership benefits within our ecosystem. We encourage you to reach out and discover how our tailored solutions can significantly enhance your investment impact, offering detailed insights into the lucrative consumer credit market. Join us in unlocking the full potential of your investments, leveraging our strategic expertise and innovative platform to achieve optimal returns.
                </p>
                <img className='w-8 lg:w-14 rotate-180 self-end ml-5' src={`${basePath}images/ico/quote-light.svg`} alt="quote mark" />
              </div>
            </div>

          </div>


        </section >

        {/* <section id="section2" className='mt-10 mb-10 sm:mt-20 xl:mt-20 sm:mb-20'>
          <div className='flex flex-col lg:flex-row mt-7 gap-7'>
            <div className='customShadow rounded-3xl p-5 xl:p-8 relative overflow-hidden liquidityProvidersCreditSolutions__imageCard'>
              <img src={`${basePath}/images/LiquidityProvidersCreditSolutions/4.svg`} className='hidden xl:block absolute -bottom-10 left-28 w-8/12' data-aos="fade-up" data-aos-duration="800" />
              <div>
                <img src={`${basePath}/images/ico/TailoredServicesBanks/4.svg`} alt="icons for Strategic Risk Management in Consumer Credit" />

                <p className='w-full xl:w-9/12 font-normal text-lg mt-5 leading-tight'>Strategic Risk Management in Consumer Credit</p>
                <div className='w-full'>
                  <p className='mt-1'>Blukite Finance employs sophisticated risk management 
techniques to categorise borrower profiles and ensure a balanced 
investment portfolio for underwriters. Utilising advanced data
 analytics and credit scoring technologies, Blukite meticulously 
assesses each borrower's risk level, enabling precise segmentation.
 This approach allows for the strategic allocation of capital across 
various risk categories, optimising the balance between risk and 
return. By leveraging this data-driven methodology, Blukite not only
 enhances portfolio performance but also provides liquidity 
providers with the confidence that their investments are managed 
with precision and insight into borrower creditworthiness.</p>
                </div>
              </div>
            </div>
            <div className='customShadow rounded-3xl p-5 xl:p-8 relative overflow-hidden liquidityProvidersCreditSolutions__imageCard'>
              <img src={`${basePath}/images/LiquidityProvidersCreditSolutions/5.svg`} className='hidden xl:block absolute -bottom-5 left-40 w-6/12' data-aos="fade-up" data-aos-duration="800" />
              <div>
                <img src={`${basePath}/images/ico/TailoredServicesBuildingSocieties/1.svg`} alt="icons for Strategic Returns Management for Underwriters" />
                <p className='w-full xl:w-9/12 font-normal text-lg mt-5 leading-tight'>Strategic Returns Management for Underwriters</p>
                <div className='w-full'>
                  <p className='mt-1'>Blukite Finance's approach to optimising returns for liquidity 
providers involves a strategic blend of diversifying lending products 
and implementing dynamic interest rate models. This method 
ensures a robust portfolio that can adapt to market changes, 
enhancing yield potential while managing risk. Diversification across
 consumer credit products broadens the investment base, mitigating
 risk and leveraging market opportunities. Simultaneously, dynamic 
interest rate models adjust to economic indicators, optimising 
profitability across various lending scenarios, ensuring liquidity 
providers can achieve the best possible returns on their investments.</p>
                </div>
              </div>
            </div>
          </div>


          <div className='customShadow rounded-3xl p-5 xl:p-8 relative overflow-hidden' data-aos="fade-right" data-aos-duration="800">
            <div>
              <img src={`${basePath}/images/ico/TailoredServicesBanks/3.svg`} alt="icons for Regulatory Umbrella Program: Secure Investment Framework" />
              <p className='font-normal text-lg mt-5'>Regulatory Umbrella Program: Secure Investment Framework</p>
              <p>The Regulatory Umbrella Program by Blukite Finance offers liquidity
                providers a unique advantage in the consumer credit investment
                landscape. This program provides a secure and compliant framework
                that mitigates regulatory risks, making it easier for investors to
                navigate the complex regulations governing consumer lending. By
                leveraging this program, underwriters can confidently invest in
                consumer credit, knowing that Blukite Finance's expertise and
                infrastructure are in place to ensure compliance with all applicable
                laws and regulations, thereby safeguarding investments and
                optimising returns within a regulatory-compliant environment.
                Under the Regulatory Umbrella Program, Blukite Finance offers
                services including regulatory compliance assistance, facilitating
                access to consumer credit markets under Blukite's licenses, and
                providing a comprehensive risk management framework. The
                program also includes support for navigating regulatory changes,
                ensuring investments remain compliant with evolving financial
                regulations. This suite of services allows underwriters to focus
                on optimising their investment strategies while Blukite handles the
                complexities of regulatory compliance and risk assessment,
                ensuring a secure and efficient investment environment.</p>
                                          </div>
          </div>

        </section> */}

        <section id="section2" className='mt-10 mb-10 sm:mt-20 xl:mt-20 sm:mb-20'>
          <div className='flex xl:flex-row flex-col justify-between gap-5 mt-0'>
            <p className='font-normal subtitle italic leading-tight sm:w-auto'>Comprehensive Solutions for Underwriters</p>
            <div className='mt-0 xl:mt-7 gap-10 flex justify-between'>
              <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handlePrevious()}  onClick={handlePrevious} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderLeftArrow.svg`} alt="slider left arrow" />
              <img tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && handleNext()}  onClick={handleNext} className='w-16 cursor-pointer' src={`${basePath}images/ico/diversity_sliderRightArrow.svg`} alt="slider right arrow" />
            </div>
          </div>

          <div className='border-r border-separateLight'>
            <div id="slider" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
              <div className="cards mt-2 xl:mt-10">
                {productOfferingsSlides.map((slide, index) => (
                  <div id={`slide-${index}`} key={index} className={`cards__card ${index === currentProductOfferIndex ? 'activeCard' : index === (currentProductOfferIndex + 1) % productOfferingsSlides.length ? 'next' : 'sliderHidden'}`}>
                    <div className="cards__content">
                      <p className="font-normal text-base sm:text-lg leading-tight italic">{slide.title}</p>



                      {slide.id === 2 ?
                        <>
                          <p className="hidden sm:block text-sm sm:text-base font-light mt-3"><Speaker id={219 + slide.id} text={slide.title + "./" + slide.description}/> {slide.description}</p>
                          <p className="block sm:hidden text-sm sm:text-base font-light mt-3"><Speaker id={219 + slide.id} text={slide.title + "./" + slide.description}/> {slide.descriptionSmall}</p>
                        </>

                        : <p className="text-sm sm:text-base font-light mt-3">
                          <Speaker id={219 + slide.id} text={slide.title + "./" + slide.description}/>
                          {slide.description}</p>
                      }
                      {slide.id === 1 ?
                        <img className='relative sm:absolute sm:left-1/2 sm:-bottom-10 sm:transform sm:-translate-x-1/2' src={`${basePath}/images/LiquidityProvidersCreditSolutions/4.svg`} alt={`images for ${slide.title}`} />
                        : slide.id === 3 ?
                          <img className='relative sm:absolute sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:bottom-0' src={`${basePath}/images/LiquidityProvidersCreditSolutions/5.svg`} alt={`images for ${slide.title}`} />
                          : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div >
    </div >
  );
}

export default LiquidityProvidersCreditSolutions;