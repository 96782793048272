import React, { useEffect, useState } from 'react';
import { Anchor, Select } from 'antd';
import { basePath, baseURL } from '../../routes';
import { useLocation } from 'react-router-dom';
import Speaker from '../../components/speaker/speaker';
import '../regulatoryUmbrella Program/regulatoryUmbrellaProgram.scss';
import './tailoredServices.scss'

const FinTechsCreditSolutions = () => {

  const location = useLocation();
  const [activeSlide, setActiveSlide] = useState(1);
  const keyServicesSlides = [
    {
      id: 1,
      title: "Expanding into Consumer Credit",
      content:
      {
        title: "Customised FinTech Expansion Venturing into Credit Markets",
        text: `For FinTechs, the journey into consumer credit presents an untapped vista of 
          growth and innovation. Blukite Finance offers the key to unlocking this potential, 
          providing a seamless pathway for FinTechs to augment their debit-focused 
          portfolio with comprehensive credit services. Through strategic integration of 
          Blukite's advanced credit solutions, FinTechs can diversify their offerings, thereby
          catalysing user engagement and opening new revenue streams. This expansion is
          not just about adding products; it's about enhancing the financial ecosystem for 
          FinTechs and their customers, driving forward with increased market presence 
          and financial empowerment.`
      }

    },

    {
      id: 2,
      title: "Effortless Ecosystem Integration ",
      content:
      {
        title: "Effortless Ecosystem Integration for FinTechs with Blukite",
        text: `Blukite Finance's credit solutions are crafted for effortless integration into FinTech
          ecosystems, ensuring a smooth fusion of debit and credit services. Our technology 
          is designed to sync with your existing platforms seamlessly, enabling a synergistic 
          expansion of offerings that enhances user experience without operational 
          disruptions. With Blukite's flexible and intuitive integration process, FinTechs can 
          effortlessly onboard sophisticated credit solutions, bolstering their product line and 
          enriching their customer's financial options.`
      }
    },
    {
      id: 3,
      title: "Catalysing FinTech Expansion",
      content:
      {
        title: "Blukite Synergy: Catalysing FinTech Expansion",
        text: `Blukite's technological synergy offers FinTechs strategic advantages, fostering an 
          ecosystem where credit and debit services not only coexist but thrive together. Our 
          advanced APIs act as the cornerstone for flexible and rapid deployment of new credit 
          products, ensuring FinTechs can innovate at pace with market demands. Throughout 
          the integration process, Blukite provides an unmatched support system, ensuring that 
          transitions are smooth, and benefits are maximised. This collaboration is designed to 
          empower FinTechs with the tools and services necessary to expand their offerings, 
          enhance their market position, and deliver exceptional value to their customers.`
      },
    }
  ]

  const activeSlideData = keyServicesSlides.find(slide => slide.id === activeSlide);

  useEffect(() => {
    const scrollToHash = () => {
      const hash = location.hash;
      if (hash) {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    scrollToHash();
  }, [location.hash]);


  return (
    <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage finTechsCreditSolutions">
      <div className="hidden sm:block sidebar">
        <Anchor
          targetOffset={150}
          items={[
            {
              key: 'section1',
              href: '#section1',
              title: 'Consumer Credit'
            },
            {
              key: 'section2',
              href: '#section2',
              title: 'New Opportunities',
            }
          ]}
        />
      </div>

      <div className='block sm:hidden sidebar'>
        <Anchor
          direction="horizontal"
          targetOffset={150}
          items={[
            {
              key: 'section1',
              href: '#section1',
              title: 'Consumer Credit'
            },
            {
              key: 'section2',
              href: '#section2',
              title: 'New Opportunities',
            }
          ]}
        />
      </div>

      <div className="content">
        <section id="section1">
          <h2 className='pageTitle'>Empowering FinTech Innovation: Credit Solutions by Blukite Finance</h2>
          <p className='text'>
          <Speaker id="200" text="Empowering FinTech Innovation: Credit Solutions by Blukite Finance. Blukite Finance stands poised to empower FinTechs with customised technology solutions aimed at amplifying their market presence and operational efficiency. Our suite of services,
            tailored to the unique dynamics of the FinTech sector, includes sophisticated API integrations, robust regulatory compliance support, enhanced digital payment systems, and rigorous
            data security measures. Partnering with Blukite enables FinTechs to fast-track product development, streamline operations, and deliver exceptional customer experiences, all while
            fostering a culture of innovation and security."/>
            Blukite Finance stands poised to empower FinTechs with customised technology solutions aimed at amplifying their market presence and operational efficiency. Our suite of services,
            tailored to the unique dynamics of the FinTech sector, includes sophisticated API integrations, robust regulatory compliance support, enhanced digital payment systems, and rigorous
            data security measures. Partnering with Blukite enables FinTechs to fast-track product development, streamline operations, and deliver exceptional customer experiences, all while
            fostering a culture of innovation and security.</p>


          <Select
            defaultValue={1}
            className='block lg:hidden mt-10'
            style={{
              width: '100%',
            }}
            onChange={(value) => setActiveSlide(value)}
            options={[
              {
                value: 1,
                label: 'Expanding into Consumer Credit',
              },
              {
                value: 2,
                label: 'Effortless Ecosystem Integration ',
              },
              {
                value: 3,
                label: 'Catalysing FinTech Expansion',
              },
            ]}
          />

          <div className='hidden lg:block'>

            <div className='coreSolutions__keyServicesNavItems flex-row justify-start xl:justify-between mt-16 w-full 2xl:w-full'>
              {keyServicesSlides.map(slide => {
                return (
                  <div key={slide.id} className={activeSlide === slide.id ? 'coreSolutions__keyServicesNavItem--active' : 'coreSolutions__keyServicesNavItem'} onClick={() => setActiveSlide(slide.id)}>{slide.title}</div>
                )
              }
              )}
            </div>

          </div>
          <div data-aos="fade-left" data-aos-duration="700" key={activeSlide} className={`my-5 md:my-10 flex flex-col xl:flex-row gap-5 xl:gap-5 justify-between min-h-auto xl:min-h-96 items-center w-full 2xl:w-10/12 mx-auto`}>

            <div className='text-base sm:text-lg mt-0 xl:mt-14'>
              <div className=''>
                <p className='font-normal'>{activeSlideData.content.title}</p>
                <p className='text mt-3'>
                <Speaker id={200 + activeSlideData.id} text={activeSlideData.content.title + ". " + activeSlideData.content.text}/>
                  {activeSlideData.content.text}</p>
              </div>
            </div>
            <img className='p-0 xl:p-10 flex-1 coreSolutions__keyServicesItemImage my-0' src={`${basePath}images/finTechsCreditSolutions/${activeSlideData.id}.svg`} alt="User-Centric Design" />
          </div>

        </section>

        <section id="section2" className='mt-10 mb-10 sm:mt-20 xl:mt-40 sm:mb-20'>
          <p className='subtitle italic font-normal text-center'>Broadening Market Horizons with Blukite's Credit Solutions for FinTechs</p>
          <p className='text mt-5 italic text-center'>Blukite Finance empowers FinTechs to broaden their market horizons by incorporating consumer credit services into their offerings. </p>
          <img className='my-5 sm:my-20' src={`${basePath}images/finTechsCreditSolutions/map.png`} alt="Illusatrtion map" />
          <p className='text w-full sm:w-9/12 mx-auto text-center'>
          <Speaker id="204" text="Broadening Market Horizons with Blukite's Credit Solutions for FinTechs. Blukite Finance empowers FinTechs to broaden their market horizons by incorporating consumer credit services into their offerings. Our tailored solutions unlock new market segments, enabling FinTechs to cater to a wider customer base seeking comprehensive financial experiences.
            By leveraging Blukite's technology, FinTechs can innovate beyond traditional demographics and tap into diverse revenue streams, delivering holistic
            financial solutions that resonate with today's consumers' varied needs."/>
            Our tailored solutions unlock new market segments, enabling FinTechs to cater to a wider customer base seeking comprehensive financial experiences.
            By leveraging Blukite's technology, FinTechs can innovate beyond traditional demographics and tap into diverse revenue streams, delivering holistic
            financial solutions that resonate with today's consumers' varied needs.</p>
        </section>


        <div>
        </div>

      </div>
    </div>
  );
}

export default FinTechsCreditSolutions;