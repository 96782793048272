import './buttons.scss';
import { Button } from 'antd';


export const PlainButton = ({ text, onClick }) => {
    return (
        <Button tabIndex={0} onClick={onClick} className="customButton rounded w-full py-5 text-lg cursor-pointer hover:bg-brightBlue justify-between transition whitespace-nowrap flex-nowrap px-7 bg-blue text-white w-fit flex items-center gap-5">
            {text}
            <img className="w-7" src="../images/ico/rightArrow-light.svg" alt="arrow" />
        </Button>
    )
}

export const SearchButton = ({ text, onClick }) => {
    return (
        <Button tabIndex={0} onClick={onClick} className="customButton rounded-lg w-full py-5 text-lg cursor-pointer hover:bg-brightBlue justify-between transition whitespace-nowrap flex-nowrap px-3 sm:px-7 bg-blue text-white w-fit flex items-center gap-5">
            {text}
        </Button>
    )
}

export const EmptyButton = ({ text, onClick}) => {
    return (
        <Button tabIndex={0} onClick={onClick} className="customButton emptyButton rounded py-5 w-full text-lg text-darkBlue cursor-pointer justify-between transition hover:border-blue hover:text-blue px-7 whitespace-nowrap bg-white flex-nowrap border border-darkBlue w-fit flex items-center gap-5">
            {text}
            <svg className="w-7" width="50" height="26" viewBox="0 0 50 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M49.6695 11.7607L38.3062 0.397224C37.898 -0.0794282 37.1806 -0.134993 36.7039 0.2733C36.2273 0.681496 36.1717 1.39887 36.58 1.87552C36.6181 1.91995 36.6595 1.96145 36.7039 1.99944L46.1242 11.4311H1.13631C0.50878 11.4311 0 11.9399 0 12.5675C0 13.1951 0.50878 13.7038 1.13631 13.7038H46.1242L36.7039 23.1241C36.2273 23.5323 36.1717 24.2496 36.58 24.7263C36.9883 25.2029 37.7056 25.2585 38.1822 24.8502C38.2267 24.8121 38.2682 24.7707 38.3062 24.7263L49.6696 13.3628C50.1102 12.9198 50.1102 12.204 49.6695 11.7607Z" fill="#0D2A38" />
            </svg>
        </Button>
    )
}


export const DarkButton = ({ text, onClick }) => {
    return (
        <Button tabIndex={0} onClick={onClick} className="customButton rounded py-5 w-full text-lg text-white cursor-pointer justify-between transition px-7 whitespace-nowrap bg-darkBlue hover:bg-darkBlueHover border flex-nowrap border-darkBlue w-fit flex items-center gap-5">
            {text}
            <img className="w-7" src="../images/ico/rightArrow-light.svg" alt="arrow" />
        </Button>
    )
}